import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from "react-bootstrap";

const CategorysLoader = () => {
  return (
    <>
      <div className="new-explore-category-sec">
        <Container>
          <div className="new-home-title-sec">
            <Skeleton count={1} width={185} height={20} />
          </div>
          <div className="new-explore-category-box">
            {[...Array(5)].map((i) => (
              <div className="new-explore-category-card">
                <Skeleton count={1} height={50} width={160} />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  )
}

export default CategorysLoader