import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  Accordion,
} from "react-bootstrap";
import SellerRules from "./SellerRules";
import SellerCategories from "./SellerCategories";
import SellerVerify from "./SellerVerify";
import AllMostDone from "./AllMostDone";
import FinalApplicationSeller from "./FinalApplicatioSeller";
import SellerReturnAddress from "./SellerReturnAddress";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux"
import CommonCenterLoader from "../../Helper/CommonCenterLoader";
import {
  fetchUserDetailsStart,
} from "../../../store/actions/UserAction";
import VerificationPending from "./VerificationPending";

const NewSellerStep = (props) => {

  const t = useTranslation();
  const [initalStep, setInitalStep] = useState(0);

  useEffect(() => {
    if (props.profile.data.content_creator_step === 0) {
      setInitalStep(0);
    } else if (props.profile.data.content_creator_step == 1) {
      setInitalStep(2);
    } else if (props.profile.data.content_creator_step == 2) {
      setInitalStep(3);
    } else if (props.profile.data.content_creator_step == 3) {
      setInitalStep(4);
    } else if (props.profile.data.content_creator_step == 4) {
      setInitalStep(5);
    } else if (props.profile.data.content_creator_step == 5) {
      setInitalStep(5);
    } else if (props.profile.data.content_creator_step == 6) {
      setInitalStep(6);
    } else {
      setInitalStep("");
    }
  }, [props.profile.data]);

  useEffect(() => {
    props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <div className="new-outfyt-profile-sec">
        <div className="new-main-wrapper">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={10}>
                {props.profile.loading ? <CommonCenterLoader /> :
                  <div className="new-outfyt-seller-box">
                    <div className="new-outfyt-form-section">
                      <div className="new-outlfyt-main-tablist">
                        <ul className="list-unstyled new-outfyt-seller-steps-list">
                          <li>
                            <div className={initalStep >= 1 ? " new-outfyt-seller-steps-icon-sec active" :"new-outfyt-seller-steps-icon-sec "}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="none"
                                viewBox="0 0 19 20"
                              >
                                <path
                                  fill=" #151515"
                                  stroke="#151515"
                                  strokeWidth="0.5"
                                  d="M16.459 1H5.209c-1.036 0-1.875.775-1.875 1.73v10.732c0 .19.167.346.374.346s.375-.155.375-.347V2.731c0-.574.504-1.039 1.125-1.039h11.25c.622 0 1.126.465 1.126 1.039v14.538c0 .574-.504 1.039-1.125 1.039-.622 0-1.125-.465-1.125-1.039v-1.73c0-.383-.336-.693-.75-.693h-13.5c-.415 0-.75.31-.75.693v1.73c0 .956.839 1.731 1.874 1.731h14.25c1.036 0 1.876-.775 1.876-1.73V2.73c0-.955-.84-1.73-1.875-1.73zM2.209 18.308c-.622 0-1.126-.465-1.126-1.039v-1.73h13.5v1.73c0 .375.132.74.376 1.039H2.209z"
                                ></path>
                                <path
                                  fill="#151515"
                                  d="M6.333 7.5c0 .276.176.5.392.5h8.217c.216 0 .392-.224.392-.5s-.176-.5-.392-.5H6.725c-.216 0-.391.224-.391.5zM6.725 11h8.217c.216 0 .392-.224.392-.5s-.176-.5-.392-.5H6.725c-.216 0-.391.224-.391.5s.175.5.39.5z"
                                ></path>
                              </svg>
                              {/* <Image src={window.location.origin + "/images/outfyt/seller/rules.svg"} /> */}
                            </div>
                            <p>{t("rules")}</p>
                          </li>
                          <li>
                            <div className={initalStep >= 2 ? " new-outfyt-seller-steps-icon-sec active" : "new-outfyt-seller-steps-icon-sec "}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g fill="#151515" clipPath="url(#clip0_418_7313)">
                                  <path d="M15.553 17.438h-3.825a1.884 1.884 0 01-1.884-1.885v-5.147c0-.31.253-.562.562-.562h5.147c1.04 0 1.884.844 1.884 1.884v3.825c0 1.04-.843 1.884-1.884 1.884zm-4.584-6.47v4.585c0 .422.337.76.76.76h3.824c.422 0 .76-.338.76-.76v-3.825a.756.756 0 00-.76-.76H10.97zM6.272 17.438H2.447a1.884 1.884 0 01-1.885-1.885v-3.825c0-1.04.844-1.884 1.885-1.884h5.147c.31 0 .562.253.562.562v5.147c0 1.04-.844 1.884-1.884 1.884zm-3.825-6.47a.756.756 0 00-.76.76v3.825c0 .422.338.76.76.76h3.825c.422 0 .76-.338.76-.76V10.97H2.446zM15.553 8.156h-5.147a.564.564 0 01-.562-.562V2.447c0-1.04.844-1.885 1.884-1.885h3.825c1.04 0 1.884.844 1.884 1.885v3.825c0 1.04-.843 1.884-1.884 1.884zM10.97 7.031h4.584c.422 0 .76-.337.76-.76V2.448a.756.756 0 00-.76-.76h-3.825a.756.756 0 00-.76.76V7.03zM7.594 8.156H2.447A1.884 1.884 0 01.562 6.272V2.447c0-1.04.844-1.885 1.885-1.885h3.825c1.04 0 1.884.844 1.884 1.885v5.147c0 .31-.253.562-.562.562zM2.447 1.687a.756.756 0 00-.76.76v3.825c0 .422.338.76.76.76H7.03V2.446a.756.756 0 00-.76-.76H2.448z"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_418_7313">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <p>{t("category")}</p>
                          </li>
                          <li>
                            <div className={initalStep >= 3 ? " new-outfyt-seller-steps-icon-sec active" : "new-outfyt-seller-steps-icon-sec "}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="19"
                                fill="none"
                                viewBox="0 0 16 19"
                              >
                                <path
                                  fill="#000"
                                  d="M8.167 19c-1.32 0-7.5-6.2-7.5-11.25 0-2.055.79-4.026 2.197-5.48A7.38 7.38 0 018.167 0a7.38 7.38 0 015.303 2.27 7.884 7.884 0 012.197 5.48c0 5.067-6.18 11.25-7.5 11.25zm0-17.68a6.123 6.123 0 00-4.395 1.886 6.542 6.542 0 00-1.82 4.545c0 4.198 5.066 9.336 6.215 9.894 1.149-.558 6.214-5.696 6.214-9.894a6.542 6.542 0 00-1.82-4.545A6.123 6.123 0 008.168 1.32z"
                                ></path>
                                <path
                                  fill="#000"
                                  d="M8.167 11a3.5 3.5 0 113.5-3.5 3.508 3.508 0 01-3.5 3.5zm0-5.765a2.265 2.265 0 100 4.53 2.265 2.265 0 000-4.53z"
                                ></path>
                              </svg>
                            </div>
                            <p>{t("return_address")}</p>
                          </li>
                          <li>
                            <div className={initalStep >= 4 ? " new-outfyt-seller-steps-icon-sec active" : "new-outfyt-seller-steps-icon-sec "}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                fill="none"
                                viewBox="0 0 19 19"
                              >
                                <g
                                  fill="#000"
                                  stroke="#000"
                                  strokeWidth="0.5"
                                  clipPath="url(#clip0_446_3440)"
                                >
                                  <path d="M17.02 16.625H1.98c-1.092 0-1.98-.888-1.98-1.98V4.356c0-1.092.888-1.98 1.98-1.98h15.04c1.092 0 1.98.888 1.98 1.98v10.29c0 1.092-.888 1.98-1.98 1.98zM1.98 3.167c-.656 0-1.188.532-1.188 1.187v10.292c0 .654.532 1.187 1.187 1.187h15.042c.654 0 1.187-.533 1.187-1.187V4.354c0-.655-.532-1.187-1.187-1.187H1.979z"></path>
                                  <path d="M5.937 9.5c-1.09 0-1.979-.888-1.979-1.979 0-1.09.888-1.979 1.98-1.979 1.09 0 1.978.888 1.978 1.98 0 1.09-.888 1.978-1.979 1.978zm0-3.166c-.655 0-1.187.532-1.187 1.187S5.282 8.71 5.937 8.71s1.188-.533 1.188-1.188c0-.655-.533-1.187-1.188-1.187zM9.104 13.459a.396.396 0 01-.396-.396v-.792c0-.655-.532-1.187-1.187-1.187H4.354c-.655 0-1.187.533-1.187 1.187v.792a.396.396 0 01-.792 0v-.792c0-1.09.888-1.979 1.98-1.979H7.52c1.09 0 1.979.888 1.979 1.98v.79a.396.396 0 01-.396.397zM16.229 7.125h-4.75a.396.396 0 010-.792h4.75a.396.396 0 010 .792zM16.229 10.292h-4.75a.396.396 0 010-.792h4.75a.396.396 0 010 .792zM16.229 13.459h-4.75a.396.396 0 010-.792h4.75a.396.396 0 010 .792z"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_446_3440">
                                    <path fill="#fff" d="M0 0H19V19H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <p>{t("verify_identity")}</p>
                          </li>
                          <li>
                            <div className={initalStep >= 5 ? " new-outfyt-seller-steps-icon-sec active" : "new-outfyt-seller-steps-icon-sec "}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="18"
                                fill="none"
                                viewBox="0 0 21 18"
                              >
                                <path
                                  stroke="#151515"
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                  d="M5.25 8.784l3.75 4.2 7.25-7.969"
                                ></path>
                              </svg>
                            </div>
                            <p>{t("done")}!</p>
                          </li>
                        </ul>
                      </div>
                      <section>
                        {initalStep == 0 && (
                          <div className="step-1">
                            <SellerRules setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 1 && (
                          <div className="step-2">
                            <SellerCategories setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 2 && (
                          <div className="step-3">
                            <SellerReturnAddress setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 3 && (
                          <div className="step-4">
                            <SellerVerify setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 4 && (
                          <div className="step-4">
                            <VerificationPending setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 5 && (
                          <div className="step-5">
                            <AllMostDone setStep={setInitalStep} />
                          </div>
                        )}
                        {initalStep == 6 && (
                          <div className="step-6">
                            <FinalApplicationSeller setStep={setInitalStep} />
                          </div>
                        )}
                      </section>
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewSellerStep));
