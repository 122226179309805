import React, { useState } from "react";
import { Container, Image, Button, Row, Col, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./LiveStreaming.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import ProductListCard from './ProductListCard';
import {
  fetchScheduledLiveVideosStart,
  fetchMoreScheduledLiveVideosStart,
  liveVideoStartCallStart,
  liveVideoDeleteStart,
} from '../../store/actions/LiveVideoAction';
import { connect } from 'react-redux';
import { useEffect } from "react";
import TableOrderLoader from "../Helper/TableOrderLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";

const ScheduledLiveVideos = (props) => {

  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchScheduledLiveVideosStart({
      skip: 0,
      take: 12,
    }))
  }, [])

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreScheduledLiveVideosStart({
        skip: props.liveVideos.data.live_video_upcomings.length,
        take: 12,
      })
    );
  };

  const handleLiveStart = (event, live_video_id) => {
    event.preventDefault();
    props.dispatch(
      liveVideoStartCallStart({
        live_video_id: live_video_id,
      })
    );
  };

  const handleDelete = (event, live_video_id) => {
    event.preventDefault();
    props.dispatch(
      liveVideoDeleteStart({
        live_video_id: live_video_id,
      })
    );
  };

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="live-video-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("scheduled_live_videos")}</h3>
            </div>
            <div className="live-video-header-btn-sec">
              <Link to="/go-live" className="default-btn">
                {t("go_live")}
              </Link>
              <Link to="/live-videos-history" className="default-btn">
                {t("live_videos_history")}
              </Link>
            </div>
          </div>
          <div className="live-video-history-box">
            {props.liveVideos.loading ?
              <TableOrderLoader />
              :
              <Row>
                <Col md={12}>
                  <div className="order-transaction-sec">
                    <div className="order-list-table">
                      {Object.keys(props.liveVideos.data).length > 0 &&
                        props.liveVideos.data.live_video_upcomings.length > 0
                        ?
                        <InfiniteScroll
                          dataLength={props.liveVideos.data.live_video_upcomings.length}
                          next={fetchMoreData}
                          hasMore={
                            props.liveVideos.data.live_video_upcomings.length <
                            props.liveVideos.data.total}
                          loader={<TableOrderLoader />}
                        >
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>{t("image")}</th>
                                <th>{t("title")}</th>
                                <th>{t("username")}</th>
                                <th>{t("stream_date")}</th>
                                <th>{t("scheduled_time")}</th>
                                <th>{t("revenue")}</th>
                                <th>{t("action_order")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.liveVideos.data.live_video_upcomings.map(
                                (video) => (
                                  <tr>
                                    <td>
                                      <div className="product-list-img-sec">
                                        {video.preview_file_type == "image" ?
                                          <Image
                                            src={video.preview_file}
                                            className="product-list-img"
                                          />
                                          :
                                          <video
                                            autoplay
                                            controls
                                            id="myVideo"
                                            className="schedule-preview"
                                          >
                                            <source src={video.preview_file} type="video/mp4" />
                                          </video>
                                        }
                                      </div>
                                    </td>
                                    <td>
                                      <h5>{video.title}</h5>
                                    </td>
                                    <td>{video.username}</td>
                                    <td>{video.created_at_formatted}</td>
                                    <td> {video.formatted_schedule_time}</td>
                                    <td>
                                      <h5>{video.user_amount_formatted}</h5>
                                    </td>
                                    <td>
                                      <div className="live-video-submit-btn">
                                        {video.live_schedule_type == 2 ?
                                          <div className="btn-flex">
                                            <Link
                                              to={`/edit-scheduled-live-videos/${video.live_video_unique_id}`}
                                              className="btn btn-info edit">
                                              {t("edit_scheduled_videos")}
                                            </Link>
                                            <Button
                                              className="live-btn-blue"
                                              onClick={(event) =>
                                                handleLiveStart(event, video.live_video_id)
                                              }
                                            >
                                              {t("start_now")}
                                            </Button>
                                            <Button
                                              className="live-btn-blue"
                                              onClick={(event) =>
                                                handleDelete(event, video.live_video_id)
                                              }
                                            >
                                              {t("delete")}
                                            </Button>
                                          </div>
                                          :
                                          ''}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                        :
                        <NoDataFound />
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liveVideos: state.liveVideo.ScheduledLiveVideos,
});

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ScheduledLiveVideos));

