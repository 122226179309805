import { call, select, put, takeLatest, all, take } from "redux-saga/effects";
import {
  fetchFollowersFailure,
  fetchFollowersSuccess,
  fetchActiveFollowersFailure,
  fetchActiveFollowersSuccess,
  fetchFollowingFailure,
  fetchFollowingSuccess,
  followUserFailure,
  followUserSuccess,
  unFollowUserFailure,
  unFollowUserSuccess,
  fetchActiveFollowingSuccess,
  fetchActiveFollowingFailure,
} from "../actions/FollowAction";

import { fetchSingleUserSuccess } from "../actions/UserAction";
import { fetchSingleLiveVideoSuccess } from "../actions/LiveVideoAction";
import {
  FOLLOW_USER_START,
  UNFOLLOW_USER_START,
  FETCH_FOLLOWERS_START,
  FETCH_MORE_FOLLOWERS_START,
  FETCH_ACTIVE_FOLLOWERS_START,
  FETCH_MORE_ACTIVE_FOLLOWERS_START,
  FETCH_FOLLOWING_START,
  FETCH_MORE_FOLLOWING_START,
  FETCH_ACTIVE_FOLLOWING_START,
  FETCH_MORE_ACTIVE_FOLLOWING_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/ToastNotification";
import api from "../../Environment";


function* followUserAPI(action) {
  let singleUserListData = yield select((state) => state.users.singleUser.data);
  let singleVideoData = yield select((state) => state.liveVideo.singleLiveVideo.data);
  try {
    const response = yield api.postMethod("follow_users", action.data);
    if (response.data.success) {
      yield put(followUserSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (Object.keys(singleUserListData).length > 0 &&
        singleUserListData.user.user_id == response.data.data.user_id) {
        yield put(fetchSingleUserSuccess({
          ...singleUserListData,
          is_following: response.data.data.is_follow,
        }));
      }
      if (Object.keys(singleVideoData).length > 0 &&
        singleVideoData.user_id == response.data.data.user_id) {
        yield put(fetchSingleLiveVideoSuccess({
          ...singleVideoData,
          is_following: response.data.data.is_following,
        }));
      }
    } else {
      yield put(followUserFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(followUserFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* unFollowUserAPI(action) {
  let singleUserListData = yield select((state) => state.users.singleUser.data);
  let singleVideoData = yield select((state) => state.liveVideo.singleLiveVideo.data);
  try {
    const response = yield api.postMethod("unfollow_users", action.data);
    if (response.data.success) {
      yield put(unFollowUserSuccess(response.data.data));
      if (Object.keys(singleUserListData).length > 0 &&
        singleUserListData.user.user_id == response.data.data.user_id) {
        yield put(fetchSingleUserSuccess({
          ...singleUserListData,
          is_following: response.data.data.is_following,
        }));
      }
      if (Object.keys(singleVideoData).length > 0 &&
        singleVideoData.user_id == response.data.data.user_id) {
        yield put(fetchSingleLiveVideoSuccess({
          ...singleVideoData,
          is_following: response.data.data.is_following,
        }));
      }
      yield call(getSuccessNotificationMessage, response.data.message);
      let activeFollowingListData = yield select((state) => state.follow.activeFollowing.data);
      let activeFollowersListData = yield select((state) => state.follow.activeFollowers.data);
      const followersListData = yield select(state => state.follow.followers.data);
      if (Object.keys(activeFollowingListData).length > 0) {
        yield put(fetchActiveFollowingSuccess({
          ...activeFollowingListData,
          total: response.data.data.total_followings,
          followers: activeFollowingListData.followers.filter(follower =>
            (follower.user_id != response.data.data.user_id)),
        }));
      }
      if (Object.keys(activeFollowersListData).length > 0) {
        yield put(fetchActiveFollowersSuccess({
          ...activeFollowersListData,
          followers: activeFollowersListData.followers.map(follower =>
            follower.follower_id == response.data.data.user_id
              ? {
                ...follower,
                otherUser: { ...follower.otherUser, show_follow: response.data.data.is_follow }
              } : follower,
          )
        }));
      }
      if (Object.keys(followersListData).length > 0) {
        yield put(fetchFollowersSuccess({
          ...followersListData,
          followers: followersListData.followers.map(follower =>
            follower.follower_id == response.data.data.user_id
              ? {
                ...follower,
                otherUser: { ...follower.otherUser, show_follow: response.data.data.is_follow }
              } : follower,
          )
        }));
      }
    } else {
      yield put(unFollowUserFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(unFollowUserFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchFollowersAPI() {
  try {
    const response = yield api.postMethod("followers");
    if (response.data.success) {
      yield put(fetchFollowersSuccess(response.data.data));
    } else {
      yield put(fetchFollowersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchFollowersFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchActiveFollowersAPI() {
  try {
    const response = yield api.postMethod("active_followers");
    if (response.data.success) {
      yield put(fetchActiveFollowersSuccess(response.data.data));
    } else {
      yield put(fetchActiveFollowersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchActiveFollowersFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchFollowingAPI() {
  try {
    const response = yield api.postMethod("followings");
    if (response.data.success) {
      yield put(fetchFollowingSuccess(response.data.data));
    } else {
      yield put(fetchFollowingFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchFollowingFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

export default function* FollowSaga() {
  yield all([
    yield takeLatest(FOLLOW_USER_START, followUserAPI),
    yield takeLatest(UNFOLLOW_USER_START, unFollowUserAPI),
    yield takeLatest(FETCH_FOLLOWERS_START, fetchFollowersAPI),
    yield takeLatest(FETCH_FOLLOWING_START, fetchFollowingAPI),
  ])
}
