import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import { savePickupAddressStart, viewPickupAddressStart } from "../../../store/actions/PickupAddressAction";
import PhoneInput from "react-phone-number-input";

const SellerReturnAddress = (props) => {

  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [formAddress, setFormAddress] = useState({});
  const [mobileValue, setMobileValue] = useState(0);

  const addAddressSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    contact_number: Yup.string().required(t("contact_number_is_required")),
    address: Yup.string().required(t("address_is_required")),
    city: Yup.string().required(t("city_is_required")),
    state: Yup.string().required(t("state_is_required")),
    country: Yup.string().required(t("country_is_required")),
    pincode: Yup.string().required(t("pincode_is_required"))
      .matches(/^(?=.*[0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters"))
      .max(6, t("must_contain_6_characters")),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let newValues = formAddress;
    if (!props.profile.loading && Object.keys(props.profile.data.pickup_address).length > 0) {
      newValues = {
        ...formAddress,
        pickup_address_id: props.profile.data.pickup_address.pickup_address_id,
      };
    }
    newValues = { ...formAddress, contact_number: mobileValue };
    props.dispatch(savePickupAddressStart(newValues));
  };

  useEffect(() => {
    if (!skipRender && !props.pickupAddressSave.loading && Object.keys(props.pickupAddressSave.data).length > 0) {
      props.setStep(3);
    }
    setSkipRender(false);
  }, [props.pickupAddressSave]);

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0 &&
      Object.keys(props.profile.data.pickup_address).length > 0) {
      let values = { pickup_address_unique_id: props.profile.data.pickup_address.pickup_address_unique_id };
      props.dispatch(viewPickupAddressStart(values));
    }
  }, [props.profile])

  useEffect(() => {
    if (!skipRender && !props.singlePickupAddress.loading && Object.keys(props.singlePickupAddress.data).length > 0) {
      setFormAddress(props.singlePickupAddress.data.pickup_address);
      setMobileValue(props.singlePickupAddress.data.pickup_address.contact_number)
    }
    setSkipRender(false);
  }, [props.singlePickupAddress]);

  return (
    <div className="address-new-outlyt-sec">
      <Formik
        validationSchema={addAddressSchema}
      >
        {({ touched, errors, values }) => (
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row className="justify-content-md-center">
              <Col md={9} className="text-center">
                <h3>{t("return_address")}</h3>
                <p>{t("return_address_para")}</p>
              </Col>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("full_name")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("name")}
                    type="text"
                    autoFocus={true}
                    name="name"
                    value={formAddress.name}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        name: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.name ?
                    <ErrorMessage
                      name="name"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("mobile")}: (*)</Form.Label>
                  <PhoneInput
                    defaultCountry="IN"
                    placeholder={t("mobile")}
                    name="contact_number"
                    value={mobileValue}
                    onChange={setMobileValue}
                    international
                  />
                  {!mobileValue ?
                    <ErrorMessage
                      name="contact_number"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("address_line")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("address")}
                    type="text"
                    name="address"
                    value={formAddress.address}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        address: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.address ?
                    <ErrorMessage
                      name="address"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("address_line_2")}: ({t("optional")})</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("address_line_2")}
                    type="text"
                    name="address_line_2"
                    value={formAddress.address_line_2}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        address_line_2: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("landmark")}: ({t("optional")})
                  </Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("landmark")}
                    type="text"
                    name="landmark"
                    value={formAddress.landmark}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        landmark: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("city")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("city")}
                    type="text"
                    name="city"
                    value={formAddress.city}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        city: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.city ?
                    <ErrorMessage
                      name="city"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("state")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("state")}
                    type="text"
                    name="state"
                    value={formAddress.state}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        state: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.state ?
                    <ErrorMessage
                      name="state"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("country")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("country")}
                    type="text"
                    name="country"
                    value={formAddress.country}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        country: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.country ?
                    <ErrorMessage
                      name="country"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <Col md={6}>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("pincode")}: (*)</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("pincode")}
                    type="number"
                    name="pincode"
                    value={formAddress.pincode}
                    onChange={(event) => {
                      setFormAddress({
                        ...formAddress,
                        pincode: event.currentTarget.value,
                      });
                    }}
                  />
                  {!formAddress.pincode ?
                    <ErrorMessage
                      name="pincode"
                      component={"div"}
                      className="text-danger pass-txt"
                    />
                    :
                    null
                  }
                </Form.Group>
              </Col>
              <div className="new-outfyt-seller-footer-btn-sec">
                <Button className="outfyt-outline-btn" onClick={() => props.setStep(1)}>
                  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4999 4.99999H4.32992L7.20992 2.11999C7.3969 1.93301 7.50195 1.67942 7.50195 1.41499C7.50195 1.15056 7.3969 0.896967 7.20992 0.70999C7.02295 0.523012 6.76935 0.417969 6.50492 0.417969C6.2405 0.417969 5.9869 0.523012 5.79992 0.70999L1.20992 5.29999C1.11722 5.3925 1.04367 5.50239 0.993492 5.62337C0.94331 5.74434 0.91748 5.87402 0.91748 6.00499C0.91748 6.13596 0.94331 6.26564 0.993492 6.38661C1.04367 6.50759 1.11722 6.61748 1.20992 6.70999L5.79992 11.3C5.89251 11.3926 6.00242 11.466 6.12338 11.5161C6.24435 11.5662 6.37399 11.592 6.50492 11.592C6.63586 11.592 6.7655 11.5662 6.88647 11.5161C7.00743 11.466 7.11734 11.3926 7.20992 11.3C7.30251 11.2074 7.37595 11.0975 7.42605 10.9765C7.47616 10.8556 7.50195 10.7259 7.50195 10.595C7.50195 10.4641 7.47616 10.3344 7.42605 10.2134C7.37595 10.0925 7.30251 9.98257 7.20992 9.88999L4.32992 6.99999H17.4999C18.0499 6.99999 18.4999 6.54999 18.4999 5.99999C18.4999 5.44999 18.0499 4.99999 17.4999 4.99999Z" fill="#151515" />
                  </svg>
                  {("back")}
                </Button>
                <Button
                  type="submit"
                  className="outfyt-btn"
                  disabled={!formAddress.name || !mobileValue ||
                    !formAddress.address ||
                    !formAddress.city || !formAddress.state ||
                    !formAddress.country || !formAddress.pincode ||
                    props.pickupAddressSave.buttonDisable}
                >
                  {props.pickupAddressSave.buttonDisable ? t("loading") : t("next")}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToPros = (state) => ({
  pickupAddressSave: state.pickupAddress.pickupAddressSave,
  singlePickupAddress: state.pickupAddress.singlePickupAddress,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SellerReturnAddress));