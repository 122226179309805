import React, { useState, useEffect } from 'react';
import {
  fetchCategoryVideosStart,
  fetchMoreCategoryVideosStart,
  fetchSubCategoryVideosStart,
  fetchMoreSubCategoryVideosStart,
} from '../../store/actions/HomePageAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-multi-lang';
import { Container, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import LiveVideoCard from './LiveVideoCard';
import ExploreCategoryLoader from '../Helper/ExploreCategoryLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../Helper/NoDataFound';
import { followCategoriesStart } from '../../store/actions/LookUpAction';
import SubCategories from '../OutFyt/Home/SubCategories';
import LiveVideoLoader from '../Helper/LiveVideosLoader';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Categories from '../OutFyt/Home/Categories';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const CategoryVideos = (props) => {

  const params = useParams();
  let query = useQuery();

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);

  const [videos, setVideos] = useState({ loading: true });
  const [followStatus, setFollowStatus] = useState(false);


  useEffect(() => {
    if (query.get("category_id") != null) {
      props.dispatch(
        fetchCategoryVideosStart({
          category_id: query.get("category_id"),
          skip: 0,
          take: 3,
        })
      );
    }
  }, [query.get("category_id")]);

  useEffect(() => {
    if (query.get("sub_category_id") != null) {
      props.dispatch(
        fetchSubCategoryVideosStart({
          sub_category_id: query.get("sub_category_id"),
          skip: 0,
          take: 3,
        })
      );
    }
  }, [query.get("sub_category_id")]);

  console.log(props.subCategoryVideos);

  useEffect(() => {
    if (query.get("sub_category_id") != null) {
      setVideos(props.subCategoryVideos);
      setFollowStatus(props.subCategoryVideos.data.is_following);
    } else {
      setVideos(props.categoryVideos);
      setFollowStatus(props.categoryVideos.data.is_following);
    }
  }, [props.categoryVideos, props.subCategoryVideos]);

  const fetchMoreData = () => {
    if (query.get("sub_category_id") != null) {
      props.dispatch(
        fetchMoreSubCategoryVideosStart({
          sub_category_id: query.get("sub_category_id"),
          skip: props.categoryVideos.data.live_videos.length,
          take: 3,
        })
      );
    } else {
      props.dispatch(
        fetchMoreCategoryVideosStart({
          category_id: query.get("category_id"),
          skip: props.categoryVideos.data.live_videos.length,
          take: 3,
        })
      );
    }
  };

  const followConfirmation = () => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      if (query.get("sub_category_id") != null) {
        props.dispatch(
          followCategoriesStart({ sub_category_id: query.get("sub_category_id") })
        );
        setFollowStatus(followStatus == 1 ? 0 : 1);
      } else {
        props.dispatch(followCategoriesStart({
          category_id: query.get("category_id"),
        }));
        setFollowStatus(followStatus == 1 ? 0 : 1);
      }
    } else {
      navigate("/login")
    }
  }


  return (
    <>
      <div className="new-outfyt-home-page-sec new-category-outfyt-sec">
        {videos.loading ?
          <ExploreCategoryLoader />
          :
          <div className="new-main-wrapper">
            {query.get("category_id") != null ?
              <SubCategories category_id={query.get("category_id")} />
              :
              <Categories />
            }
            <div className="new-category-sec-1">
              <Container>
                <div className="new-category-header-sec">
                  <h1 className='text-uppercase'>{videos.data.name}</h1>
                  {followStatus ?
                    <Button
                      type="button"
                      className="outfyt-btn"
                      onClick={followConfirmation}
                    >
                      {t("following")}
                    </Button>
                    :
                    <Button
                      type="button"
                      className="outfyt-btn"
                      onClick={followConfirmation}
                    >
                      {t("follow")}
                    </Button>
                  }
                </div>
              </Container>
            </div>
            <div className="new-home-latest-live-streaming-sec">
              {Object.keys(videos.data).length > 0 &&
                videos.data.live_videos.length > 0 ?
                <Container>
                  <InfiniteScroll
                    dataLength={videos.data.live_videos.length}
                    next={fetchMoreData}
                    hasMore={
                      videos.data.live_videos.length <
                      videos.data.total_live_videos
                    }
                    loader={<LiveVideoLoader />}
                  >
                    <div className="new-home-latest-live-streaming-box-video">
                      {videos.data.live_videos.map(
                        (live_video) => (
                          <LiveVideoCard live_video={live_video} />
                        ))}
                    </div>
                  </InfiniteScroll>
                </Container>
                : <NoDataFound />}
            </div>
          </div>

        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  categoryVideos: state.homepage.categoryVideos,
  followCategories: state.lookUp.followCategories,
  subCategoryVideos: state.homepage.subCategoryVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)(withTranslation(CategoryVideos));
