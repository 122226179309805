import React from 'react'
import Skeleton from "react-loading-skeleton";

const LiveVideoLoader = () => {
    return (
        <div className="new-home-latest-live-streaming-box-video">
            {[...Array(3)].map((i) => (
               <div className="new-home-latest-live-streaming-img-sec">
                    <Skeleton count={1} height={330} width={310} />
                    <Skeleton count={1} width={100} />
                    <Skeleton count={1} width={250} />
                </div>
            ))}
        </div>
    )
}

export default LiveVideoLoader;