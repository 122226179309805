import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
    registerSuccess,
    registerFailure,
    loginSuccess,
    loginFailure,
    fetchUserDetailsSuccess,
    fetchUserDetailsFailure,
    updateUserDetailsSuccess,
    updateUserDetailsFailure,
    changePasswordSuccess,
    changePasswordFailure,
    deleteAccountSuccess,
    deleteAccountFailure,
    usernameValidationSuccess,
    usernameValidationFailure,
    registerVerifySuccess,
    registerVerifyFailure,
    registerVerifyResendSuccess,
    registerVerifyResendFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    verifyForgotPasswordSuccess,
    verifyForgotPasswordFailure,
    usersSearchSuccess,
    usersSearchFailure,
    fetchSingleUserSuccess,
    fetchSingleUserFailure,
    becomeSellorSuccess,
    becomeSellorFailure,
    getKycDocumentSuccess,
    getKycDocumentFailure,
    userCategoriesSaveSuccess,
    userCategoriesSaveFailure,
    userDocumentsSaveSuccess,
    userDocumentsSaveFailure,
    userInventoryFilesUploadSuccess,
    userInventoryFilesUploadFailure,
    userVerificationUpdateSuccess,
    userVerificationUpdateFailure,
    fetchStripeConnectStart,
    fetchStripeConnectSuccess,
    fetchStripeConnectFailure,
    getStripeConnectUrlSuccess,
    getStripeConnectUrlFailure,
    deleteStripeConnectSuccess,
    deleteStripeConnectFailure,
} from "../actions/UserAction";

import {
    REGISTER_START,
    LOGIN_START,
    FETCH_USER_DETAILS_START,
    UPDATE_USER_DETAILS_START,
    CHANGE_PASSWORD_START,
    DELETE_ACCOUNT_START,
    USERNAME_VALIDATION_START,
    REGISTER_VERIFY_START,
    REGISTER_VERIFY_RESEND_START,
    FORGOT_PASSWORD_START,
    RESET_PASSWORD_START,
    VERIFY_FORGOT_PASSWORD_START,
    USERS_SEARCH_START,
    FETCH_SINGLE_USER_DETAILS_START,
    BECOME_SELLER_START,
    GET_KYC_DOCUMENT_START,
    USER_CATEGORIES_SAVE_START,
    USER_DOCUMENTS_SAVE_START,
    USER_INVENTORY_FILES_UPLOAD_START,
    USER_VERIFICATION_UPDATE_START,
    DELETE_STRIPE_CONNECT_START,
    GET_STRIPE_CONNECT_URL_START,
    FETCH_STRIPE_CONNECT_START,
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import api from "../../Environment";

import { fetchUserDetailsStart } from "../actions/UserAction";


function* userRegisterAPI(action) {
    try {
        const response = yield api.postMethod("register", action.data);
        if (response.data.success) {
            yield put(registerSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            if (response.data.code == 1001) {
                localStorage.setItem("email", response.data.data.email);
            } else {
                localStorage.setItem("email", response.data.data.email);
                localStorage.setItem("name", response.data.data.name);
                localStorage.setItem("userLoginStatus", true);
                localStorage.setItem("is_content_creator", response.data.data.is_content_creator);
                localStorage.setItem("userId", response.data.data.user_id);
                localStorage.setItem("accessToken", response.data.data.token);
                localStorage.setItem("username", response.data.data.username);
            }
        } else {
            yield put(registerFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(registerFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* userLoginAPI(action) {
    try {
        const response = yield api.postMethod("login", action.data);
        if (response.data.success) {
            yield put(loginSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            if (response.data.code === 1001) {
                localStorage.setItem("email", response.data.data.email)
            }
            else {
                localStorage.setItem("userLoginStatus", true);
                localStorage.setItem("userId", response.data.data.user_id);
                localStorage.setItem("accessToken", response.data.data.token);
                localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
                localStorage.setItem("user_picture", response.data.data.picture);
                localStorage.setItem("is_content_creator", response.data.data.is_content_creator);
                localStorage.setItem("user_cover", response.data.data.cover);
                localStorage.setItem("name", response.data.data.name);
                localStorage.setItem("username", response.data.data.username);
            }
        } else {
            yield put(loginFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    }
    catch (error) {
        yield put(loginFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* getUserDetailsAPI(action) {
    try {
        const response = yield api.postMethod("profile", action.data);
        if (response.data.success) {
            yield put(fetchUserDetailsSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchUserDetailsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    }
    catch (error) {
        yield put(fetchUserDetailsFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* updateUserDetailsAPI(action) {
    try {
        const response = yield api.postMethod("update_profile", action.data);
        if (response.data.success) {
            yield put(updateUserDetailsSuccess(response.data.data));
            yield put(fetchUserDetailsSuccess(response.data.data));
            localStorage.setItem("username", response.data.data.username);
            localStorage.setItem("name", response.data.data.name);
            localStorage.setItem("picture", response.data.data.picture);
            localStorage.setItem("cover", response.data.data.cover)
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(updateUserDetailsFailure(response.data.error));
            yield call(updateUserDetailsFailure, response.data.error);
        }
    }
    catch (error) {
        yield put(updateUserDetailsFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* changePasswordAPI(action) {
    try {
        const response = yield api.postMethod("change_password", action.data);
        if (response.data.success) {
            yield put(changePasswordSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(changePasswordFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    }
    catch (error) {
        yield put(changePasswordFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* deleteAccountAPI(action) {
    try {
        const response = yield api.postMethod("delete_account", action.data);
        if (response.data.success) {
            yield put(deleteAccountSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(deleteAccountFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deleteAccountFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* usernameValidationAPI(action) {
    try {
        const response = yield api.postMethod("username_validation", action.data);
        if (response.data.success) {
            yield put(usernameValidationSuccess(response.data));
        } else {
            yield put(usernameValidationFailure(response));
        }
    } catch (error) {
        yield put(usernameValidationFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* registerVerify(action) {
    try {
        const response = yield api.postMethod("verify_email", action.data);

        if (response.data.success) {
            yield put(registerVerifySuccess(response.data.data));
            yield put(fetchUserDetailsSuccess(response.data.data));
            localStorage.setItem("userLoginStatus", true);
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
            localStorage.setItem("user_picture", response.data.data.picture);
            localStorage.setItem("username", response.data.data.username);
            localStorage.setItem("is_model", response.data.data.is_model);
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(registerVerifyFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(registerVerifyFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* registerVerifyResend(action) {
    try {
        const response = yield api.postMethod("regenerate_email_verification_code", action.data);

        if (response.data.success) {
            yield put(registerVerifyResendSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(registerVerifyResendFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(registerVerifyResendFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* forgotPasswordAPI(action) {
    try {
        const response = yield api.postMethod("forgot_password", action.data);

        if (response.data.success) {
            yield put(forgotPasswordSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            if (response.data.code == 102) {
                localStorage.setItem("email", response.data.data.email);
            } else {
                localStorage.setItem("name", response.data.data.name);
                localStorage.setItem("userId", response.data.data.user_id);
                localStorage.setItem("accessToken", response.data.data.token);
            }
        } else {
            yield put(forgotPasswordFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }

    } catch (error) {
        yield put(forgotPasswordFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* verifyForgotPasswordAPI(action) {
    try {
        const response = yield api.postMethod("verify_forgot_password_code", action.data);
        if (response.data.success) {
            yield put(verifyForgotPasswordSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            localStorage.setItem("accessToken", response.data.data.token);
        } else {
            yield put(verifyForgotPasswordFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(verifyForgotPasswordFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* resetPasswordAPI(action) {
    try {
        const response = yield api.postMethod("reset_password", action.data);
        if (response.data.success) {
            yield put(resetPasswordSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("email");
        } else {
            yield put(resetPasswordFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(resetPasswordFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* usersSearchAPI(action) {
    try {
        const response = yield api.postMethod("users_search", action.data);
        if (response.data.success) {
            yield put(usersSearchSuccess(response.data.data));
        } else {
            yield put(usersSearchFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(usersSearchFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* fetchSingleUserAPI(action) {
    try {

        const response = yield api.postMethod("other_profile", action.data);
        if (response.data.success) {
            yield put(fetchSingleUserSuccess(response.data.data));
        } else {
            yield put(fetchSingleUserFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchSingleUserFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* becomeSellerAPI(action) {
    try {

        const response = yield api.postMethod("become_a_seller", action.data);
        if (response.data.success) {
            yield put(becomeSellorSuccess(response.data.data));
            yield put(fetchUserDetailsSuccess(response.data.data.user));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(becomeSellorFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(becomeSellorFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* getKycDocumentAPI(action) {
    try {
        const response = yield api.postMethod("documents_list", action.data);
        if (response.data.success) {
            yield put(getKycDocumentSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(getKycDocumentFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(getKycDocumentFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* userCategoriesSaveAPI(action) {
    try {
        const response = yield api.postMethod("user_categories_save", action.data);
        if (response.data.success) {
            yield put(userCategoriesSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(fetchUserDetailsStart());
        } else {
            yield put(userCategoriesSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(userCategoriesSaveFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* userDocumentsSaveAPI(action) {
    try {
        const response = yield api.postMethod("documents_save", action.data);
        if (response.data.success) {
            yield put(userDocumentsSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(userDocumentsSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(userDocumentsSaveFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* userInventoryFilesUploadStartAPI(action) {
    try {
        const response = yield api.postMethod("user_inventory_files_upload", action.data);
        if (response.data.success) {
            yield put(userInventoryFilesUploadSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(userInventoryFilesUploadFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(userInventoryFilesUploadFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* userVerificationUpdateAPI(action) {
    try {
        const response = yield api.postMethod("verification_update", action.data);
        if (response.data.success) {
            yield put(userVerificationUpdateSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(userVerificationUpdateFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(userVerificationUpdateFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}


function* fetchStripeConnectAPI(action) {
    try {
        const response = yield api.postMethod(
            "stripe_connect",
            action.data
        );
        if (response.data.success) {
            yield put(fetchStripeConnectSuccess(response.data.data));
        } else {
            yield put(fetchStripeConnectFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchStripeConnectFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* getStripeConnectUrlAPI(action) {
    try {
        const response = yield api.postMethod(
            "stripe_connect_get_url",
            action.data
        );
        if (response.data.success) {
            yield put(fetchStripeConnectStart());
            yield put(getStripeConnectUrlSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(getStripeConnectUrlFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(getStripeConnectUrlFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* deleteStripeConnectAPI(action) {
    try {
        const response = yield api.postMethod(
            "stripe_connected_accounts_remove",
            action.data
        );
        if (response.data.success) {
            yield put(deleteStripeConnectSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);

            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            yield put(deleteStripeConnectFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(deleteStripeConnectFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}


export default function* UserSaga() {
    yield all([
        yield takeLatest(REGISTER_START, userRegisterAPI),
        yield takeLatest(LOGIN_START, userLoginAPI),
        yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
        yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
        yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
        yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
        yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
        yield takeLatest(REGISTER_VERIFY_START, registerVerify),
        yield takeLatest(REGISTER_VERIFY_RESEND_START, registerVerifyResend),
        yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
        yield takeLatest(VERIFY_FORGOT_PASSWORD_START, verifyForgotPasswordAPI),
        yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
        yield takeLatest(USERS_SEARCH_START, usersSearchAPI),
        yield takeLatest(FETCH_SINGLE_USER_DETAILS_START, fetchSingleUserAPI),
        yield takeLatest(BECOME_SELLER_START, becomeSellerAPI),
        yield takeLatest(USER_CATEGORIES_SAVE_START, userCategoriesSaveAPI),
        yield takeLatest(USER_DOCUMENTS_SAVE_START, userDocumentsSaveAPI),
        yield takeLatest(USER_INVENTORY_FILES_UPLOAD_START, userInventoryFilesUploadStartAPI),
        yield takeLatest(USER_VERIFICATION_UPDATE_START, userVerificationUpdateAPI),
        yield all([yield takeLatest(GET_KYC_DOCUMENT_START, getKycDocumentAPI)]),
        yield takeLatest(DELETE_STRIPE_CONNECT_START, deleteStripeConnectAPI),
        yield takeLatest(GET_STRIPE_CONNECT_URL_START, getStripeConnectUrlAPI),
        yield takeLatest(FETCH_STRIPE_CONNECT_START, fetchStripeConnectAPI),
    ])
}

