import React, { useState, useEffect } from "react";
import {Form, Button, Image, Modal} from "react-bootstrap";
import Slider from "react-slick";
import { ArraySchema } from "yup";

const ShippingViewModal = (props) => {

    const [step, setStep] = useState(1);

    useEffect(() => {
      setStep(1)
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

  return (
    <>
      <Modal
        className="modal-dialog-center shipping-view-modal-sec"
        size="lg"
        centered
        show={props.shippingView}
        onHide={props.closeShippingViewModal}
      >
        <div className="close-btn-sec">
          <Button type="button" className="close-modal-popup" onClick={() => props.setShippingView(false)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#fff"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                >
                <path d="M23.229 9.219l-3.224-5.5A5.52 5.52 0 0015.26 1H8.709a5.523 5.523 0 00-4.745 2.719l-3.222 5.5a5.502 5.502 0 000 5.562l3.223 5.501A5.522 5.522 0 008.71 23h6.551a5.52 5.52 0 004.745-2.719l3.224-5.5a5.505 5.505 0 000-5.561zm-2.588 4.045l-3.224 5.5A2.51 2.51 0 0115.26 20H8.709c-.883 0-1.71-.474-2.157-1.236l-3.223-5.5a2.502 2.502 0 010-2.528l3.223-5.499A2.51 2.51 0 018.709 4h6.551c.884 0 1.71.474 2.157 1.236l3.224 5.5c.456.78.456 1.749 0 2.529zM15.81 10.31l-1.689 1.689 1.689 1.689a1.5 1.5 0 11-2.122 2.121l-1.689-1.689-1.689 1.689c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121l1.689-1.689-1.689-1.689a1.5 1.5 0 112.121-2.121l1.689 1.689 1.689-1.689a1.5 1.5 0 112.121 2.121z"></path>
            </svg>
          </Button>
        </div>
        <Form>
          <Modal.Body>
          {step == 1 && (
            <div className="step-1">
                <div className="shipping-view-modal-box">
                    <div className="shipping-view-slider">
                        <Slider {...settings}>
                            <div>
                                <div className="shipping-view-img-sec">
                                    <Image
                                        className="shipping-view-img"
                                        src={
                                            window.location.origin + "/images/outfyt/product/product-1.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="shipping-view-img-sec">
                                    <Image
                                        className="shipping-view-img"
                                        src={
                                            window.location.origin + "/images/outfyt/product/product-2.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="shipping-view-img-sec">
                                    <Image
                                        className="shipping-view-img"
                                        src={
                                            window.location.origin + "/images/outfyt/product/product-1.png"
                                        }
                                    />
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="shipping-view-product-description">
                        <h3>Product Description</h3>
                        <div className="shipping-view-product-description-item">
                            <div className="shipping-view-product-description-box">
                                <div className="shipping-view-product-description-card">
                                    <h4>Size:</h4>
                                </div>
                                <div className="shipping-view-product-description-card">
                                    <h4>Qty:</h4>
                                </div>
                            </div>
                            <div className="shipping-view-product-description-box">
                                <div className="shipping-view-product-description-card">
                                    <h4>Paymemt</h4>
                                </div>
                                <div className="shipping-view-product-description-card">
                                    <h4>Paymemt details with edit option</h4>
                                </div>
                            </div>
                            <div className="shipping-view-product-description-box">
                                <div className="shipping-view-product-description-card">
                                    <h4>Shipping</h4>
                                </div>
                                <div className="shipping-view-product-description-card">
                                    <div className="shipping-view-address-change-sec">
                                        <h4>Default Address</h4>
                                        <Button className="change-address-btn" onClick={() => setStep(2)}>
                                            Change Address
                                        </Button>
                                    </div>
                                    <h4>Jessy Pinkman</h4>
                                    <p>69, 3rd Cross, Teachers colony,near Dayananada Sagara College of Engineering,Bangalore, Karanataka - 560078</p>
                                    <p>Mobile Number: +917887654367</p>
                                </div>
                            </div>
                            <div className="shipping-view-product-description-box">
                                <div className="shipping-view-product-description-card">
                                    <h4>Subtotal</h4>
                                    <h4>Shipping and Handling</h4>
                                    <h4>Est. Tax</h4>
                                    <h4>Total</h4>
                                </div>
                                <div className="shipping-view-product-description-card">
                                    <h4>$xxx.xx</h4>
                                    <h4>$xxx.xx</h4>
                                    <h4>$xxx.xx</h4>
                                    <h4>$xxx.xx</h4>
                                </div>
                            </div>
                        </div>
                        <div className="shipping-view-product-description-btn-sec">
                            <Button className="buy-now-btn">
                                BUY NOW
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
          )}
           {step == 2 && (
            <div className="step-2">
                <div className="shipping-change-address-sec">
                    <div className="shipping-change-address-header-sec">
                        <div className="shipping-change-address-header-left-sec">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="none"
                                viewBox="0 0 37 38"
                                >
                                <path
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="3"
                                    d="M18.096 30.332L7.226 19.463l10.87-10.87m-9.36 10.87h22.04"
                                ></path>
                            </svg>
                            <h4>Delivery Address</h4>
                        </div>
                        <div className="shipping-change-address-header-right-sec">
                            <Button className="change-address-btn" onClick={() => setStep(3)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 24 24"
                                    >
                                    <path d="M17 11h-4V7a1 1 0 00-1-1 1 1 0 00-1 1v4H7a1 1 0 00-1 1 1 1 0 001 1h4v4a1 1 0 001 1 1 1 0 001-1v-4h4a1 1 0 001-1 1 1 0 00-1-1z"></path>
                                </svg>
                                Add Address
                            </Button>
                        </div>
                    </div>
                    <div className="shipping-change-address-body-sec">
                        <div className="shipping-change-address-box">
                            <div className="shipping-change-address-card">
                                <input type="radio" name="pricing" id="card1"/>
                                <label for="card1">
                                    <div className="shipping-change-address-item">
                                        <div className="default-address-sec">
                                            <h4>Lincoln Xavier</h4>
                                            <div className="default-address-info">
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17"
                                                height="18"
                                                fill="none"
                                                viewBox="0 0 17 18"
                                                >
                                                <path
                                                    fill="#fff"
                                                    d="M8.5.5A8.503 8.503 0 000 9c0 4.692 3.808 8.5 8.5 8.5S17 13.692 17 9 13.192.5 8.5.5zm0 15.3A6.809 6.809 0 011.7 9c0-3.748 3.051-6.8 6.8-6.8 3.748 0 6.8 3.051 6.8 6.8 0 3.748-3.052 6.8-6.8 6.8zm3.902-10.557L6.8 10.844 4.598 8.652 3.4 9.85l3.4 3.4 6.8-6.8-1.198-1.207z"
                                                ></path>
                                                </svg>
                                                <span>Default Address</span>
                                            </div>
                                        </div>
                                        <p>69, 3rd Cross, Teachers colony, Kumaraswamy layout,, near Dayananada Sagara College of Engineering,Bangalore, Karanataka - 560078</p>
                                        <p>mobile: <span>+917887654367</span></p>
                                    </div>
                                </label>
                            </div>
                            <div className="shipping-change-address-card">
                                <input type="radio" name="pricing" id="card2" checked/>
                                <label for="card2">
                                    <div className="shipping-change-address-item">
                                        <div className="default-address-sec">
                                            <h4>Jessy Pinkman</h4>
                                        </div>
                                        <p>69, 3rd Cross, Teachers colony, Kumaraswamy layout,, near Dayananada Sagara College of Engineering,Bangalore, Karanataka - 560078</p>
                                        <p>mobile: <span>+917887654367</span></p>
                                        <div className="shipping-change-address-btn-sec">
                                            <Button className="set-as-default-btn">
                                                SET AS DEFAULT
                                            </Button>
                                            <span>|</span>
                                            <Button className="remove-btn">
                                                SET AS DEFAULT
                                            </Button>
                                            <Button className="edit-btn">
                                                EDIT
                                            </Button>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="shipping-view-product-description-btn-sec">
                        <Button className="buy-now-btn">
                        Confirm Shipping Address
                        </Button>
                    </div>
                </div>
            </div>
           )}

            {step == 3 && (
                <div className="step-3">
                    <div className="shipping-add-new-address-sec">
                        <div className="shipping-change-address-header-sec">
                            <div className="shipping-change-address-header-left-sec">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="none"
                                    viewBox="0 0 37 38"
                                    >
                                    <path
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="3"
                                        d="M18.096 30.332L7.226 19.463l10.87-10.87m-9.36 10.87h22.04"
                                    ></path>
                                </svg>
                                <h4>Add New Address</h4>
                            </div>
                        </div>
                        <div className="shipping-add-new-address-body-sec">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="number" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Country</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Landmark</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Zipcode</Form.Label>
                                <Form.Control type="number" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>State</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group>
                        </div>
                        <div className="shipping-view-product-description-btn-sec">
                            <Button className="buy-now-btn">
                                ADD ADDRESS
                            </Button>
                        </div>
                    </div>
                </div>
            )}

          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default ShippingViewModal;