import React from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";

const NewFooterIndex = () => {
  return (
    <>
     <div className="new-footer-sec">
        <Container>
            <Row className="align-items-center">
                <Col md={4}>
                    <div className="new-copyrighttext">
                        Copyright © 2023 outfyt.io Inc. All rights reserved.
                    </div>
                </Col>
                <Col md={8}>
                    <div className="new-footer-link-sec">
                        <ul className="list-unstyled new-footer-link">
                            <li>
                                <Link to="#">
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Careers
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    FAQs
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Privacy
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Terms
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
     </div>
    </>
  );
};

export default NewFooterIndex;
