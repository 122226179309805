import React, { useState } from "react";
import { Container, Image, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewHeaderIndex from "../Home/NewHeaderIndex";
import NewFooterIndex from "../Home/NewFooterIndex";
import { t } from "react-multi-lang";

const SellerRules = (props) => {
  return (
    <>
      <div className="rules-for-seller-before-fill-sec disabled">
        <h3>{t("rules_para")}</h3>
        <ul className="list-unstyled">
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-one.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("ship_within_bussiness_days")}</h4>
                <p>{t("please_ship")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-two.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("be_nice")}</h4>
                <p>{t("make_sure_para")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-three.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("follow_our_policies")}</h4>
                <p>{t("we_have_got_para")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-four.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("package_items_safely")}</h4>
                <p>{t("package_para")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-five.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("dont_lie_about_an_item")}</h4>
                <p>{t("dont_lie_para")}</p>
              </div>
            </div>
          </li>
          <li>
            <div className="list-for-seller-main-part">
              <div className="list-seller-img">
                <Image src={window.location.origin + "/images/outfyt/seller/list-six.png"} />
              </div>
              <div className="list-seller-content">
                <h4>{t("dont_sell_counterfeits")}</h4>
                <p>{t("dont_sell_para")}</p>
              </div>
            </div>
          </li>
        </ul>

        <div className="btn-outfyt-section-seller">
          <Button
            className="outfyt-btn text-uppercase agree-btn"
            onClick={() => props.setStep(1)}>
            {t("i_agree_to_the_rule")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SellerRules;