import React from 'react'
import Skeleton from 'react-loading-skeleton';
import {  Container } from 'react-bootstrap';

const SingleProductLoader = () => {
  return (
    <>
      <Container>
        <div className="new-outfyt-single-product-header-sec">
          <p> <Skeleton count={1} height={30} width={380} /></p>
        </div>
      </Container>
      <div className="new-outfyt-single-product-body-sec">
        <div className="new-outfyt-single-product-body-left-sec">
          <div className="new-outfyt-single-product-img-card">
            <Skeleton count={1} height={400} />
          </div>
        </div>
        <div className="new-outfyt-single-product-body-right-sec">
          <Skeleton count={1} height={25} width={80} />
          <Skeleton className="mb-3" count={1} height={30} width={130} />
          <Skeleton count={1} height={25} width={80} />
          <Skeleton count={1} height={25} width={80} />
          <Skeleton className="mt-3" count={1} height={20} width={130} />
          <Skeleton count={1} height={25} width={80} />
          <Skeleton count={1} height={25} width={100} />
          <Skeleton count={1} height={25} width={120} />
          <div className="new-outfyt-single-product-varient-box">
            {[...Array(3)].map((i) => (
              <Skeleton count={1} width={220} height={180} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleProductLoader;