import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { usersSearchStart, fetchUserDetailsStart } from "../../../store/actions/UserAction";
import { homeSearchStart } from "../../../store/actions/HomePageAction";
import CommonCenterLoader from "../../Helper/CommonCenterLoader";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const HeaderIndex = (props) => {
  const t = useTranslation();
  const [show, toggleShow] = useState(false);
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (event) => {
    setInputValue(event.target.value);
    if (event.currentTarget.value === "") {
      toggleShow(false);
    }
    else {
      toggleShow(true);
      props.dispatch(
        homeSearchStart({ search_key: event.target.value })
      )
    }
  }
  const resetInputField = () => {
    setInputValue("");
    toggleShow(false);
  }

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken"))
      props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">
                <Image
                  className="logo"
                  src={configuration.get("configData.site_logo")}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.svg"}
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link
                  to="/categories"
                  className={`category-link ${location.pathname === "/categories" ? "active" : ""
                    }`}>
                  {t("categories")}</Link>
                <Link
                  to="/brand"
                  className={`category-link ${location.pathname === "/brand" ? "active" : ""
                    }`}>{t("brands")}</Link>
              </Nav>
              <Nav className="m-auto">
                <Form>
                  <div className="header-search">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={window.location.origin + "/images/search.svg"}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("search")}
                        aria-label="Username"
                        name="key"
                        value={inputValue}
                        onChange={handleSearch}
                        autoComplete="off"
                        type="text"
                        aria-describedby="basic-addon1"
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        className="search-close"
                        style={{
                          border: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {show != "" && (
                          <Image
                            onClick={resetInputField}
                            src={window.location.origin + "/images/closes.svg"}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    {show && (
                      <div className="search-suggestion-sec">
                        <ul className="list-unstyled search-dropdown-list-sec">
                          {props.homeSearch.loading ? (
                            <CommonCenterLoader />
                          ) : Object.keys(props.homeSearch.data).length > 0 &&
                            (props.homeSearch.data.users.length > 0 || props.homeSearch.data.categories.length > 0 || 
                              props.homeSearch.data.sub_categories.length > 0 || props.homeSearch.data.live_videos.length > 0
                              || props.homeSearch.data.products.length > 0)
                            ?
                            <>
                              {props.homeSearch.data.users.length > 0
                                && props.homeSearch.data.users.map((user) => (
                                  <li className="search-res">
                                    <Link
                                      to={`/${user.user_unique_id}`}
                                      onClick={() => toggleShow(false)}
                                    >
                                      <div className="search-body">
                                        <div className="user-img-sec">
                                          <CustomLazyLoad
                                            src={user.picture}
                                            placeholderSrc={window.location.origin + "/images/loading.svg"}
                                            className="user-img"
                                          />
                                        </div>
                                        <div className="search-content">
                                          <h5 className="title">{user.name}</h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              {props.homeSearch.data.categories.length > 0 &&
                                props.homeSearch.data.categories.map((category) => (
                                  <li className="search-res">
                                    <Link
                                      to={`/category-videos/?category_id=${category.unique_id}`}
                                      onClick={() => toggleShow(false)}
                                    >
                                      <div className="search-body">
                                        <div className="user-img-sec">
                                          <CustomLazyLoad
                                            src={category.picture}
                                            placeholderSrc={window.location.origin + "/images/loading.svg"}
                                            className="user-img"
                                          />
                                        </div>
                                        <div className="search-content">
                                          <h5 className="title">{category.name}</h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              {props.homeSearch.data.sub_categories.length > 0
                                && props.homeSearch.data.sub_categories.map((sub_category) => (
                                  <li className="search-res">
                                    <Link
                                      to={`/category-videos/?sub_category_id=${sub_category.unique_id}`}
                                      onClick={() => toggleShow(false)}
                                    >
                                      <div className="search-body">
                                        <div className="user-img-sec">
                                          <CustomLazyLoad
                                            src={sub_category.picture}
                                            placeholderSrc={window.location.origin + "/images/loading.svg"}
                                            className="user-img"
                                          />
                                        </div>
                                        <div className="search-content">
                                          <h5 className="title">{sub_category.name}</h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              {props.homeSearch.data.live_videos.length > 0 &&
                                props.homeSearch.data.live_videos.map((live_video) => (
                                  <li className="search-res">
                                    <Link
                                      to={`/live-stream/${live_video.live_video_unique_id}`}
                                      onClick={() => toggleShow(false)}
                                    >
                                      <div className="search-body">
                                        <div className="user-img-sec">
                                          <CustomLazyLoad
                                            src={live_video.preview_file}
                                            placeholderSrc={window.location.origin + "/images/loading.svg"}
                                            className="user-img"
                                          />
                                        </div>
                                        <div className="search-content">
                                          <h5 className="title">{live_video.title}</h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              {props.homeSearch.data.products.length > 0
                                && props.homeSearch.data.products.map((product) => (
                                  <li className="search-res">
                                    <Link
                                      to={`/single-product/${product.unique_id}`}
                                      onClick={() => toggleShow(false)}
                                    >
                                      <div className="search-body">
                                        <div className="user-img-sec">
                                          <CustomLazyLoad
                                            src={product.picture}
                                            placeholderSrc={window.location.origin + "/images/loading.svg"}
                                            className="user-img"
                                          />
                                        </div>
                                        <div className="search-content">
                                          <h5 className="title">{product.name}</h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </>
                            : (
                              <div className="search-no-result-sec">
                                <p>{t("no_search_result_found")}</p>
                                <div className="no-result-search-header">
                                  <div className="no-result-search-icon">
                                    <Image
                                      src={window.location.origin + "/images/search-opt.svg"}
                                    />
                                  </div>
                                  <div className="no-result-search-info">
                                    <p>"{inputValue}"</p>
                                  </div>
                                </div>
                              </div>
                            )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Form>
              </Nav>
              {localStorage.getItem("userId") && localStorage.getItem("accessToken") ?
                <Nav>
                  {props.profile.data.is_content_creator == 1
                    ?
                    <Link to="/new-seller">{t("become_a_seller")}</Link>
                    : null}
                  <Link to="/chat">{t("messages")}</Link>
                  <Link to="/profile">{t("account")}</Link>
                  <Link to="/ecom-cart" className="header-cart-item">
                    <Image
                      className="header-cart-icon"
                      src={window.location.origin + "/images/shopping-cart.svg"}
                    />
                    <span className="add-cart-count">
                      {localStorage.getItem("userId")
                        ? Object.keys(props.profile.data).length > 0
                          ? props.profile.data.cart_count
                          : 0
                        : null}
                    </span>
                  </Link>
                </Nav>
                : null}
              <Nav className="align-items-center nav-end">
                {!localStorage.getItem("userId") ||
                  !localStorage.getItem("accessToken") ?
                  <Nav.Link>
                    <Link className="head-sign" to="/login">
                      <div className="header-invite-btn">
                        <Image
                          src={window.location.origin + "/images/sign-in.svg"}
                        />
                        {t("sign_in")}
                      </div>
                    </Link>
                  </Nav.Link>
                  : (
                    <>
                      <Nav.Link href="#">
                        <div className="user-dropdown-sec">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <Image
                                src={props.profile.data.picture}
                                className="head-profile-img"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Link to="/profile">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 21 21"
                                  >
                                    <path
                                      stroke="#000"
                                      strokeWidth="1.5"
                                      d="M10 13a3 3 0 100-6 3 3 0 000 6z"
                                    ></path>
                                    <path
                                      stroke="#000"
                                      strokeWidth="1.5"
                                      d="M11.685 1.137C11.335 1 10.89 1 10 1s-1.335 0-1.685.137a1.92 1.92 0 00-.62.39 1.797 1.797 0 00-.414.585c-.087.2-.123.435-.136.776a1.393 1.393 0 01-.21.7c-.132.214-.32.392-.544.518a1.617 1.617 0 01-1.496.007c-.32-.16-.553-.248-.783-.277a1.995 1.995 0 00-1.41.356c-.3.219-.524.582-.969 1.308-.445.726-.668 1.089-.717 1.444a1.7 1.7 0 00.049.702c.065.228.177.442.33.63.14.172.338.317.645.5.451.266.741.722.741 1.224s-.29.958-.741 1.224c-.307.183-.505.328-.646.5a1.76 1.76 0 00-.329.63 1.7 1.7 0 00-.049.702c.05.354.272.718.716 1.444.446.726.669 1.089.97 1.308.198.144.425.25.668.31.242.062.494.077.743.046.23-.029.461-.117.782-.277a1.62 1.62 0 011.496.007c.46.252.735.716.754 1.218.013.342.048.576.136.777.096.218.237.417.414.584.178.167.388.3.62.39C8.665 19 9.11 19 10 19s1.335 0 1.685-.137a1.92 1.92 0 00.62-.39c.177-.167.318-.366.414-.584.088-.201.123-.435.136-.777.02-.502.293-.967.754-1.218a1.618 1.618 0 011.496-.007c.32.16.553.248.782.277.248.031.501.015.743-.046.243-.06.47-.166.669-.31.3-.218.523-.582.968-1.308.445-.726.668-1.089.717-1.444a1.7 1.7 0 00-.049-.701 1.76 1.76 0 00-.33-.63c-.14-.173-.338-.318-.645-.5a1.5 1.5 0 01-.538-.522c-.13-.214-.2-.456-.203-.703 0-.502.29-.958.741-1.224.307-.183.505-.328.646-.5a1.76 1.76 0 00.329-.63 1.7 1.7 0 00.049-.702c-.05-.354-.273-.718-.716-1.444-.446-.726-.669-1.089-.97-1.308a1.958 1.958 0 00-.668-.31 2.021 2.021 0 00-.743-.046c-.23.029-.461.117-.783.277a1.619 1.619 0 01-1.495-.007 1.5 1.5 0 01-.544-.517 1.392 1.392 0 01-.21-.7c-.013-.343-.048-.577-.136-.777a1.798 1.798 0 00-.414-.585 1.921 1.921 0 00-.62-.39z"
                                    ></path>
                                  </svg>
                                </span>
                                {t("settings")}
                              </Link>
                              {!props.profile.loading && props.profile.data.is_content_creator === 2 ?
                                <Link to="/go-live">
                                  <span>
                                    <Image
                                      className="go-live-icon"
                                      src={window.location.origin + "/images/profile/go-live.svg"}
                                    />
                                  </span>
                                  {t("go_live")}
                                </Link>
                                : ''}
                              <Link to="/ecom-cart">
                                <span>
                                  <Image
                                    src={window.location.origin + "/images/view-cart.svg"}
                                    className="cart-product-img"
                                  />
                                </span>
                                {t("view_cart")}
                              </Link>
                              <Link to="/logout">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 23"
                                  >
                                    <path
                                      fill="#000"
                                      d="M12.868 2a.863.863 0 00-.868.857c0 .474.389.857.868.857 4.637 0 8.395 3.71 8.395 8.286s-3.758 8.286-8.395 8.286a.863.863 0 00-.868.857c0 .473.389.857.868.857C18.464 22 23 17.523 23 12S18.464 2 12.868 2z"
                                    ></path>
                                    <path
                                      fill="#000"
                                      d="M7.839 9.366a.768.768 0 000-1.132.884.884 0 00-1.2 0l-3.39 3.2a.768.768 0 000 1.132l3.39 3.2a.884.884 0 001.2 0 .768.768 0 000-1.132L5.895 12.8h9.257c.468 0 .848-.358.848-.8 0-.442-.38-.8-.848-.8H5.895l1.944-1.834z"
                                    ></path>
                                  </svg>
                                </span>
                                {t("logout")}
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Nav.Link>
                    </>
                  )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
const mapStateToProps = (state) => ({
  profile: state.users.profile,
  homeSearch: state.homepage.homeSearch,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(HeaderIndex));

