import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import { sendWithDrawRequestStart } from "../../store/actions/WalletAction";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { fetchWalletDetailsStart, fetchWithDrawalsStart } from "../../store/actions/WalletAction";
import { fetchAllTransactionStart } from "../../store/actions/TransactionAction";
import Skeleton from "react-loading-skeleton";

const WithdrawModal = (props) => {
  const t = useTranslation("");

  useEffect(() => {
    props.dispatch(getBankAccountStart());
  }, []);

  const [inputData, setInputData] = useState({
    requested_amount: 10,
    user_billing_account_id: "",

  });
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(sendWithDrawRequestStart(inputData));
    setInputData({})
  };

  useEffect(() => {
    if (!skipRender && !props.sendWithDraw.loading && Object.keys(props.sendWithDraw.data).length > 0) {
      props.closeWithdrawModal();
      props.dispatch(fetchWalletDetailsStart());
      props.dispatch(fetchAllTransactionStart());
      props.dispatch(fetchWithDrawalsStart());
    }
    setSkipRender(false);
  }, [props.sendWithDraw]);

  return (
    <>
      <Modal
        className="modal-dialog-center withdraw-modal"
        size="md"
        centered
        show={true}
        onHide={props.closeWithdrawModal}
      >

        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("send_request_to_admin")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.payments.loading ? (
              <Skeleton count={2} height={80} />
            ) : (
              <Row>
                <Col md="12">
                  <div className="mb-5">
                    <h4 className="mb-2">
                      {t("min_amount_required")}:{" "}
                      <span className="text-muted">
                        {
                          props.payments.data
                            .user_withdrawals_min_amount_formatted
                        }
                      </span>
                    </h4>
                    <h4 className="text-muted">
                      {t("wallet_balance")}:{" "}
                      <span className="text-muted">
                        {props.payments.data.user_wallet
                          ? props.payments.data.user_wallet.remaining_formatted
                          : 0.00}
                      </span>
                    </h4>
                  </div>
                </Col>
              </Row>
            )}
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="number"
                  placeholder={t("amount")}
                  value={inputData.requested_amount}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      requested_amount: event.currentTarget.value,
                    })
                  }
                />
                <span className="highlight"></span>
                <label className="default-label">{t("enter_amount")}</label>
              </div>
              <div className="floating-label">
                <label className="label-default-1 bank-hd">{t("choose_bank_account")}</label>
                <Form>
                  {["radio"].map((type) => (
                    <div key={`custom-inline-${type}`} className="mb-3">
                      {props.bankAccount.loading ? (
                        <Skeleton count={1} height={30} />
                      ) :
                        Object.keys(props.bankAccount.data).length > 0 &&
                          props.bankAccount.data.user_billing_accounts.length > 0 ? (
                          props.bankAccount.data.user_billing_accounts.map((account) => (
                            <Form.Check
                              custom
                              inline
                              label={account.first_name}
                              type={type}
                              id={account.user_billing_account_id}
                              value={account.user_billing_account_id}
                              name="user_billing_account_id"
                              onChange={(event) =>
                                setInputData({
                                  ...inputData,
                                  user_billing_account_id:
                                    account.user_billing_account_id,
                                })
                              }
                            />
                          ))
                        ) : (
                          <h4>
                            {t("no_bank_accounts_added")}. {t("to_add_account")}{" "}
                            <Link className="text-sm" to={`/add-bank-account`}>
                              {t("click_here")}
                            </Link>
                          </h4>
                        )}
                    </div>
                  ))}
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              onClick={props.closeWithdrawModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              className="btn btn-success"
              data-dismiss="modal"
              disabled={!inputData.requested_amount && props.sendWithDraw.buttonDisable
                ? props.sendWithDraw.buttonDisable : inputData.user_billing_account_id ? false : true}
            >
              {props.sendWithDraw.loadingButtonContent !== null
                ? props.sendWithDraw.loadingButtonContent
                : t("send_request")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>

  )
}

const mapStateToPros = (state) => ({
  sendWithDraw: state.wallet.sendWithDraw,
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(WithdrawModal));
