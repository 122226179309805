import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  ordersCreateByWalletStart,
  ordersCreateByStripeStart,
  ordersCreateByStripeCheckoutStart
} from "../../../store/actions/OrderAction";
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";

const ProductsPaymentModal = (props) => {

  const [paymentType, setPaymentType] = useState("WALLET");
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

  const formdata = {
    cart_ids:
      !props.cart.loading &&
      props.cart.data.carts.map((cart) => cart.cart_id).toString(),
  };

  useEffect(() => {
    if (props.paymentModal === "wallet") 
      props.dispatch(fetchWalletDetailsStart());
  }, [props.paymentModal]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.paymentModal === "wallet")
    {
        props.dispatch(
        ordersCreateByWalletStart({
          cart_ids: formdata.cart_ids,
          delivery_address_id: props.delivery_address_id,
        })
      );
      props.closePaymentModal();
    }
    else
     props.dispatch(
      ordersCreateByStripeCheckoutStart({
        cart_ids: formdata.cart_ids,
        delivery_address_id: props.delivery_address_id,
      }));
  };

  useEffect(() => {
    if (!skipRender && !props.createOrderCheckoutStripe.loading && Object.keys(props.createOrderCheckoutStripe.data).length > 0) 
      {
        window.location.href = (props.createOrderCheckoutStripe.data.redirection_url);
        props.closePaymentModal();
      }
      setSkipRender(false);
  }, [props.createOrderCheckoutStripe]);

  return (
    <>
      <Modal
        className="modal-dialog-center  withdraw-modal"
        size="md"
        centered
        show={props.paymentModal}
        onHide={props.closePaymentModal}
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title>{t("buy_now")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="subscription-tip-ppv-tab">
            {props.paymentModal == "wallet" ? (
              <React.Fragment>
                {props.wallet.loading ? (
                  ""
                ) : Object.keys(props.wallet.data).length > 0 &&
                  props.wallet.data.user_wallet ? (
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <h4 className="text-muted">
                          {t("wallet_balance")}:{" "}
                          <span className="text-muted">
                            {props.wallet.data.user_wallet
                              ? props.wallet.data.user_wallet.remaining
                              : null}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <h4 className="text-muted">Payment By Card</h4>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={paymentType}
            >
              <Row>
                <Col sm={12}>
                  <div className="card-stripe-box">
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder={t("pay_amount")}
                          value={props.cartAmount}
                          disabled
                        />
                      </Form.Group>
                      <Tab.Content>
                        {configuration.get("configData.is_stripe_enabled") ==
                          1 &&
                        configuration.get(
                          "configData.stripe_publishable_key"
                        ) !== "" &&
                        configuration.get("configData.stripe_secret_key") !==
                          "" ? (
                          <Tab.Pane eventKey="CARD">
                            <div className="card-stripe-item">
                              <Link to="/wallet">
                                <div className="add-account-item">
                                  <Image
                                    className="add-account-icon"
                                    src={
                                      window.location.origin +
                                      "/images/icons/new/add-card.svg"
                                    }
                                  />
                                  <h5 className="text-muted">
                                    {t("add_money")}
                                  </h5>
                                </div>
                              </Link>
                            </div>
                          </Tab.Pane>
                        ) : null}
                        {props.paymentModal == "wallet" ? 
                        (configuration.get(
                          "configData.is_wallet_payment_enabled"
                        ) == 1 ? (
                          <Tab.Pane eventKey="WALLET">
                            {props.wallet.loading ? (
                              ""
                            ) : (
                              <div className="card-stripe-box">
                                {props.cart.data.total_amount >
                                props.wallet.data.user_wallet.remaining ? (
                                  <div className="">
                                    <p className="conv-desc desc">
                                      {t(
                                        "low_wallet_balance_tips_payment_para"
                                      )}
                                    </p>
                                    <div className="d-flex">
                                      <Link
                                        to="/wallet"
                                        className="withdraw-money-btn"
                                      >
                                        {t("add_wallet_amount")}
                                      </Link>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Tab.Pane>
                        ) : null) : ''}
                      </Tab.Content>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-danger cancel-btn"
              data-dismiss="modal"
              onClick={props.closePaymentModal}
            >
              {t("cancel")}
            </Button>
            <Button
              type="button"
              className="btn btn-success pay-option"
              data-dismiss="modal"
              onClick={handleSubmit}
              disabled={props.ordersCreateWallet.buttonDisable}
            >
              {props.ordersCreateWallet.loadingButtonContent !== null
                ? props.ordersCreateWallet.loadingButtonContent
                : t("pay_now")}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  livePayStripe: state.order.createOrderStripe,
  ordersCreateWallet: state.order.ordersCreateWallet,
  wallet: state.wallet.walletData,
  createOrderCheckoutStripe: state.order.createOrderCheckoutStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProductsPaymentModal));
