import {
  CHAT_ASSET_FILE_UPLOAD_START,
  CHAT_ASSET_FILE_UPLOAD_SUCCESS,
  CHAT_ASSET_FILE_UPLOAD_FAILURE,
} from "./ActionConstant";


export function chatAssetFileUploadStart(data) {
  return {
    type: CHAT_ASSET_FILE_UPLOAD_START,
    data,
  };
}

export function chatAssetFileUploadSuccess(data) {
  return {
    type: CHAT_ASSET_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function chatAssetFileUploadFailure(data) {
  return {
    type: CHAT_ASSET_FILE_UPLOAD_FAILURE,
    data,
  };
}
