import React, { useState, useEffect } from "react";
import { Accordion, Button, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchCategoriesListStart,
} from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import SidebarLoader from "../../Helper/SidebarLoader";

const HomeSidebar = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const t = useTranslation();
  const [isCurrentEventKey, setIsCurrentEventKey] = useState("");

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  return (
    <>
      {/* <div className="resp-categories-btn" onClick={showSidebar}>
        <Button>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="#fff" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
        </Button>
      </div> */}
      {props.categoriesList.loading ?
        <SidebarLoader />
        :
        <div className={sidebar ? 'new-home-left show' : 'new-home-left'} >
          <div className="categories-head ">
            <h3 className="text-uppercase">{t("categories")}</h3>
            <button onClick={showSidebar}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </button>
          </div>
          <div className="categories-nav-sec ">
          <Accordion>
            {Object.keys(props.categoriesList.data).length > 0 &&
              props.categoriesList.data.categories.length > 0 ?
              props.categoriesList.data.categories.map(
                (category, i) => (
                  <React.Fragment>
                    <Accordion.Toggle onClick={() =>
                     setIsCurrentEventKey(isCurrentEventKey === category.category_id ? " " : category.category_id)}
                      className={isCurrentEventKey === category.category_id ? 'active-state' : ' link-state'}
                      as={Button} variant="link" eventKey={category.category_id}>
                      <span>{category.name}</span>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector" d="M10 5.71429H5.71429V10H4.28571V5.71429H0V4.28571H4.28571V0H5.71429V4.28571H10V5.71429Z" fill="#151515" />
                      </svg>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={category.category_id}>
                      <div className="categories-sub-nav" id="nav-collapse-one">
                        <ul>
                          <li key={i}>
                            <Link
                              to={`/category-videos/?category_id=${category.unique_id}`}
                            >
                              {t("Shop All")}
                            </Link>
                          </li>
                          {category.sub_categories.map((subCategory, j) => (
                            <li key={j}>
                              <Link
                                to={`/category-videos/?sub_category_id=${subCategory.unique_id}`}
                              >
                                {subCategory.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Accordion.Collapse>
                    </React.Fragment>
                ))
              :
              null
            }
            </Accordion>
          </div>
        </div>
      }
    </>
  );
}


const mapStateToProps = (state) => ({
  categoriesList: state.homepage.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(HomeSidebar));