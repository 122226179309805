import {
    FETCH_CATEGORIES_LIST_START,
    FETCH_CATEGORIES_LIST_SUCCESS,
    FETCH_CATEGORIES_LIST_FAILURE,
    FETCH_MORE_CATEGORIES_LIST_START,
    FETCH_FOLLOWING_CATEGORIES_LIST_START,
    FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS,
    FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE,
    FETCH_SUB_CATEGORY_START,
    FETCH_SUB_CATEGORY_SUCCESS,
    FETCH_SUB_CATEGORY_FAILURE,
    FETCH_SUB_CATEGORY_VIDEOS_START,
    FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
    FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
    FETCH_MORE_SUB_CATEGORY_VIDEOS_START,
    FETCH_ONGOING_LIVE_VIDEOS_START,
    FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
    FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
    FETCH_MORE_ONGOING_LIVE_VIDEOS_START,
    RECENT_CATEGORIES_START,
    RECENT_CATEGORIES_SUCCESS,
    RECENT_CATEGORIES_FAILURE,
    RECENT_MORE_PRODUCTS_START,
    LIVE_VIDEO_BOOKMARK_SAVE_START,
    LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
    LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
    FETCH_BOOKMARKED_VIDEOS_START,
    FETCH_BOOKMARKED_VIDEOS_SUCCESS,
    FETCH_BOOKMARKED_VIDEOS_FAILURE,
    FETCH_MORE_BOOKMARKED_VIDEOS_START,
    FETCH_SUB_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_SUCCESS,
    FETCH_SUB_CATEGORIES_FAILURE,
    FETCH_CATEGORY_VIDEOS_START,
    FETCH_CATEGORY_VIDEOS_SUCCESS,
    FETCH_CATEGORY_VIDEOS_FAILURE,
    FETCH_MORE_CATEGORY_VIDEOS_START,
    FETCH_LATEST_LIVE_VIDEOS_START,
    FETCH_LATEST_LIVE_VIDEOS_SUCCESS,
    FETCH_LATEST_LIVE_VIDEOS_FAILURE,
    FETCH_MORE_LATEST_LIVE_VIDEOS_START,
    FETCH_UPCOMING_LIVESTREAMS_START,
    FETCH_UPCOMING_LIVESTREAMS_SUCCESS,
    FETCH_UPCOMING_LIVESTREAMS_FAILURE,
    FETCH_MORE_UPCOMING_LIVESTREAMS_START,
    FETCH_CATEGORIES_FOLLOW_START,
    FETCH_CATEGORIES_FOLLOW_SUCCESS,
    FETCH_CATEGORIES_FOLLOW_FAILURE,
    FETCH_MORE_CATEGORIES_FOLLOW_START,
    RECENT_PRODUCTS_START,
    RECENT_PRODUCTS_SUCCESS,
    RECENT_PRODUCTS_FAILURE,
    HOME_SEARCH_START,
    HOME_SEARCH_SUCCESS,
    HOME_SEARCH_FAILURE,
    SELLER_LIST_START,
    SELLER_LIST_SUCCESS,
    SELLER_LIST_FAILURE,
    FETCH_MORE_SELLER_LIST_START,
} from "./ActionConstant";

export function fetchCategoriesListStart(data) {
    return {
        type: FETCH_CATEGORIES_LIST_START,
        data,
    };
}

export function fetchCategoriesListSuccess(data) {
    return {
        type: FETCH_CATEGORIES_LIST_SUCCESS,
        data,
    };
}

export function fetchCategoriesListFailure(error) {
    return {
        type: FETCH_CATEGORIES_LIST_FAILURE,
        error,
    };
}
export function fetchMoreCategoriesListStart(data) {
    return {
        type: FETCH_MORE_CATEGORIES_LIST_START,
        data,
    };
}



export function fetchFollowingCategoriesListStart(data) {
    return {
        type: FETCH_FOLLOWING_CATEGORIES_LIST_START,
        data,
    };
}

export function fetchFollowingCategoriesListSuccess(data) {
    return {
        type: FETCH_FOLLOWING_CATEGORIES_LIST_SUCCESS,
        data,
    };
}

export function fetchFollowingCategoriesListFailure(error) {
    return {
        type: FETCH_FOLLOWING_CATEGORIES_LIST_FAILURE,
        error,
    };
}

export function fetchSubCategoryStart(data) {
    return {
        type: FETCH_SUB_CATEGORY_START,
        data,
    };
}

export function fetchSubCategorySuccess(data) {
    return {
        type: FETCH_SUB_CATEGORY_SUCCESS,
        data,
    };
}

export function fetchSubCategoryFailure(error) {
    return {
        type: FETCH_SUB_CATEGORY_FAILURE,
        error,
    };
}
export function fetchSubCategoryVideosStart(data) {
    return {
        type: FETCH_SUB_CATEGORY_VIDEOS_START,
        data,
    };
}

export function fetchSubCategoryVideosSuccess(data) {
    return {
        type: FETCH_SUB_CATEGORY_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchSubCategoryVideosFailure(error) {
    return {
        type: FETCH_SUB_CATEGORY_VIDEOS_FAILURE,
        error,
    };
}

export function fetchMoreSubCategoryVideosStart(data) {
    return {
        type: FETCH_MORE_SUB_CATEGORY_VIDEOS_START,
        data,
    };
}

export function fetchOngoingLiveVideosStart(data) {
    return {
        type: FETCH_ONGOING_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchOngoingLiveVideosSuccess(data) {
    return {
        type: FETCH_ONGOING_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchOngoingLiveVideosFailure(error) {
    return {
        type: FETCH_ONGOING_LIVE_VIDEOS_FAILURE,
        error,
    };
}

export function fetchMoreOngoingLiveVideosStart(data) {
    return {
        type: FETCH_MORE_ONGOING_LIVE_VIDEOS_START,
        data,
    };
}

export function recentCategoriesStart(data) {
    return {
        type: RECENT_CATEGORIES_START,
        data,
    };
}

export function recentCategoriesSuccess(data) {
    return {
        type: RECENT_CATEGORIES_SUCCESS,
        data,
    };
}

export function recentCategoriesFailure(error) {
    return {
        type: RECENT_CATEGORIES_FAILURE,
        error,
    };
}

export function liveVideoBookmarkSaveStart(data) {
    return {
        type: LIVE_VIDEO_BOOKMARK_SAVE_START,
        data,
    }
}

export function liveVideoBookmarkSaveSuccess(data) {
    return {
        type: LIVE_VIDEO_BOOKMARK_SAVE_SUCCESS,
        data,
    }
}

export function liveVideoBookmarkSaveFailure(error) {
    return {
        type: LIVE_VIDEO_BOOKMARK_SAVE_FAILURE,
        error,
    }
}

export function fetchBookmarkedVideosStart(data) {
    return {
        type: FETCH_BOOKMARKED_VIDEOS_START,
        data,
    }
}


export function fetchBookmarkedVideosSuccess(data) {
    return {
        type: FETCH_BOOKMARKED_VIDEOS_SUCCESS,
        data,
    }
}

export function fetchBookmarkedVideosFailure(error) {
    return {
        type: FETCH_BOOKMARKED_VIDEOS_FAILURE,
        error,
    }
}

export function fetchMoreBookmarkedVideosStart(data) {
    return {
        type: FETCH_MORE_BOOKMARKED_VIDEOS_START,
        data,
    }
}

export function fetchSubCategoriesStart(data) {
    return {
        type: FETCH_SUB_CATEGORIES_START,
        data,
    };
}

export function fetchSubCategoriesSuccess(data) {
    return {
        type: FETCH_SUB_CATEGORIES_SUCCESS,
        data,
    };
}

export function fetchSubCategoriesFailure(error) {
    return {
        type: FETCH_SUB_CATEGORIES_FAILURE,
        error,
    };
}

export function fetchCategoryVideosStart(data) {
    return {
        type: FETCH_CATEGORY_VIDEOS_START,
        data,
    };
}

export function fetchCategoryVideosSuccess(data) {
    return {
        type: FETCH_CATEGORY_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchCategoryVideosFailure(error) {
    return {
        type: FETCH_CATEGORY_VIDEOS_FAILURE,
        error,
    }
}

export function fetchMoreCategoryVideosStart(data) {
    return {
        type: FETCH_MORE_CATEGORY_VIDEOS_START,
        data,
    };
}
export function fetchLatestLiveVideosStart(data) {
    return {
        type: FETCH_LATEST_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchLatestLiveVideosSuccess(data) {
    return {
        type: FETCH_LATEST_LIVE_VIDEOS_SUCCESS,
        data,
    };
}

export function fetchLatestLiveVideosFailure(error) {
    return {
        type: FETCH_LATEST_LIVE_VIDEOS_FAILURE,
        error,
    }
}

export function fetchMoreLatestLiveVideosStart(data) {
    return {
        type: FETCH_MORE_LATEST_LIVE_VIDEOS_START,
        data,
    };
}

export function fetchUpcomingLiveStreamsStart(data) {
    return {
        type: FETCH_UPCOMING_LIVESTREAMS_START,
        data,
    };
}

export function fetchUpcomingLiveStreamsSuccess(data) {
    return {
        type: FETCH_UPCOMING_LIVESTREAMS_SUCCESS,
        data,
    };
}

export function fetchUpcomingLiveStreamsFailure(error) {
    return {
        type: FETCH_UPCOMING_LIVESTREAMS_FAILURE,
        error,
    }
}

export function fetchMoreUpcomingLiveStreamsStart(data) {
    return {
        type: FETCH_MORE_UPCOMING_LIVESTREAMS_START,
        data,
    };
}

export function fetchCategoriesFollowStart(data) {
    return {
        type: FETCH_CATEGORIES_FOLLOW_START,
        data,
    };
}

export function fetchCategoriesFollowSuccess(data) {
    return {
        type: FETCH_CATEGORIES_FOLLOW_SUCCESS,
        data,
    };
}

export function fetchCategoriesFollowFailure(error) {
    return {
        type: FETCH_CATEGORIES_FOLLOW_FAILURE,
        error,
    }
}

export function fetchMoreCategoriesFollowStart(data) {
    return {
        type: FETCH_MORE_CATEGORIES_FOLLOW_START,
        data,
    };
}


export function recentProductsStart(data) {
    return {
        type: RECENT_PRODUCTS_START,
        data,
    };
}

export function recentProductsSuccess(data) {
    return {
        type: RECENT_PRODUCTS_SUCCESS,
        data,
    };
}

export function recentProductsFailure(error) {
    return {
        type: RECENT_PRODUCTS_FAILURE,
        error,
    };
}

export function recentMoreProductsStart(data) {
    return {
        type:RECENT_MORE_PRODUCTS_START,
        data,
    };
}

export function homeSearchStart(data) {
    return {
        type: HOME_SEARCH_START,
        data,
    };
}

export function homeSearchSuccess(data) {
    return {
        type: HOME_SEARCH_SUCCESS,
        data,
    };
}

export function homeSearchFailure(error) {
    return {
        type: HOME_SEARCH_FAILURE,
        error,
    };
}

export function sellerListStart(data) {
    return {
        type: SELLER_LIST_START,
        data,
    };
}

export function sellerListSuccess(data) {
    return {
        type: SELLER_LIST_SUCCESS,
        data,
    };
}

export function sellerListFailure(error) {
    return {
        type: SELLER_LIST_FAILURE,
        error,
    };
}

export function fetchMoreSellerListStart(data) {
    return {
        type: FETCH_MORE_SELLER_LIST_START,
        data,
    };
}