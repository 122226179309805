import React, { useState, useEffect } from "react";
import { Col, Container, Form, Image, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { saveDeliveryAddressStart } from "../../store/actions/DeliveryAddressAction";
import ProfileSidebar from "../Profile/ProfileSidebar";
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select'
import getAllCountries from 'react-select-country-list';
const getCountryISO3 = require("country-iso-2-to-3");

const AddDeliveryAddress = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [mobileValue, setMobileValue] = useState(0);

  const options = [
    { value: "savings", label: "Savings" },
    { value: "current account", label: "Current Account" },
  ];

  const addbillingSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    pincode: Yup.string().required("Pincode is required"),
    state: Yup.string().required("State is required"),
    // contact_number: Yup.string().required("Contact Number is required"),
    country: Yup.string().required("Country is required"),
  })
  useEffect(() => {
    if (!skipRender && !props.deliveryAddressSave.loading &&
      Object.keys(props.deliveryAddressSave.data).length > 0) {
      navigate("/delivery-address");
    }
    setSkipRender(false);
  }, [props.deliveryAddressSave]);

  const handleSubmit = (values) => {
    let newValues = {};
    newValues = { ...values, contact_number: mobileValue };
    props.dispatch(saveDeliveryAddressStart(newValues));
  };


  const customStyles = {
    ///.....
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "rgba(57, 57, 57, .07)",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(57,57,57,.07)",
      borderColor: "#e7e7e7",
      boxShadow: "none",
      height: "50px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "var(----active-color)",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "rgba(57, 57, 57, .07)" : "rgba(57, 57, 57, .07)",
        color: "#000",
      };
    },
    ///.....
  };

  const excludedCountries = [
    { value: 'PK', label: 'Pakistan' },
  ];

  const allCountries = getAllCountries().data.filter(country =>
    !excludedCountries.some(obj => obj.value === country.value)
  );
  return (
    <>
      <div className="single-page-sec">
        <Container fluid className="p-0">
          <div className="new-category-sec">
            <div className="new-category-left-sec">
              <ProfileSidebar />
            </div>
            <div className="new-category-right-sec">
              <div className="profile-sidebar-right-sec">
                <div className="profile-sidebar-right-detail-sec">
                  <div className="single-page-header pt-0">
                    <div className="single-page-title">
                      <Link to="#" onClick={() => navigate(-1)}>
                        <Image src={window.location.origin + "/images/back.svg"} />
                      </Link>
                      <h3>{t("add_delivery_address")}</h3>
                    </div>
                  </div>
                  <div className="add-bank-sec">
                    <Formik
                      initialValues={{
                        name: "",
                        address: "",
                        pincode: "",
                        state: "",
                        country: "",
                        contact_number: "",
                        landmark: "",
                      }}
                      validationSchema={addbillingSchema}
                      onSubmit={(values => handleSubmit(values))}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        setFieldValue,
                      }) => (
                        <FORM>
                          <div className="add-bank-details">
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("name")}: (*)</Form.Label>
                                  <Field
                                    type="text"
                                    name="name"
                                    placeholder={t("name")}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component={"div"}
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("address")}: (*)</Form.Label>
                                  <Field
                                    type="text"
                                    name="address"
                                    placeholder={t("address")}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component={"div"}
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("pincode")} / {t("zipcode")}: (*)</Form.Label>
                                  <Field
                                    type="text"
                                    name="pincode"
                                    placeholder={t("pincode")}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="pincode"
                                    component={"div"}
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("state")}: (*)</Form.Label>
                                  <Field
                                    type="text"
                                    name="state"
                                    placeholder={t("state")}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="state"
                                    component={"div"}
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{("country")}: (*)</Form.Label>
                                  <Select
                                    name="country"
                                    options={allCountries}
                                    styles={customStyles}
                                    isSearchable={true}
                                    onChange={(selectedOption) => {
                                      setFieldValue("country", selectedOption.label)
                                      setFieldValue("country_code", getCountryISO3(selectedOption.value))
                                    }}
                                    placeholder={t("country")}
                                  />
                                  <ErrorMessage
                                    name="country"
                                    component={"div"}
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("contact_number")}: (*)</Form.Label>
                                  <PhoneInput
                                    className="contact-selects"
                                    defaultCountry="IN"
                                    placeholder={t("contact_number")}
                                    name="contact_number"
                                    value={values.contact_number}
                                    onChange={setMobileValue}
                                    international
                                  />
                                  {!mobileValue ?
                                    <ErrorMessage
                                      name="contact_number"
                                      component={"div"}
                                      className="text-danger text-right"
                                    />
                                    :
                                    null}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-4">
                                  <Form.Label> {t("landmark")}: ({t("optional")})</Form.Label>
                                  <Field
                                    type="text"
                                    name="landmark"
                                    placeholder={t("landmark")}
                                    className="form-control"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="submit-btn">
                              <Button type="submit"
                                className="default-btn"
                                disabled={props.deliveryAddressSave.buttonDisable}
                              >
                                {props.deliveryAddressSave.loadingButtonContent ?
                                  props.deliveryAddressSave.loadingButtonContent :
                                  t("submit")}
                              </Button>
                            </div>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  deliveryAddressSave: state.deliveryAddress.deliveryAddressSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(AddDeliveryAddress));

