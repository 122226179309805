import React from "react";
import NewLatestLiveStreamingIndex from "./NewLatestLiveStreamingIndex";
import UpComingLiveStreamingIndex from "./UpComingLiveStreamingIndex";
import CategoryFollowIndex from "./CategoryFollowIndex";
import AccessoriesHomeIndex from "./AccessoriesHomeIndex";
import NewSellerCategory from "./NewSellerCategory";

const BrandIndex = () => {

  return (
    <>
      <div className="new-outfyt-home-page-sec brand-new-page-sec">
        {/* <NewHeaderIndex/> */}
        <div className="new-main-wrapper">
              <NewSellerCategory />
              <NewLatestLiveStreamingIndex />
              <UpComingLiveStreamingIndex />
              {localStorage.getItem("userId") &&
                localStorage.getItem("accessToken") ?
                <CategoryFollowIndex />
                : null
              }
              <AccessoriesHomeIndex />
          </div>
      </div>
    </>
  );
};

export default BrandIndex;
