import {
  FETCH_PICKUP_ADDRESS_START,
  FETCH_PICKUP_ADDRESS_SUCCESS,
  FETCH_PICKUP_ADDRESS_FAILURE,
  SAVE_PICKUP_ADDRESS_START,
  SAVE_PICKUP_ADDRESS_SUCCESS,
  SAVE_PICKUP_ADDRESS_FAILURE,
  DELETE_PICKUP_ADDRESS_START,
  DELETE_PICKUP_ADDRESS_SUCCESS,
  DELETE_PICKUP_ADDRESS_FAILURE,
  SET_DEFAULT_PICKUP_ADDRESS_START,
  SET_DEFAULT_PICKUP_ADDRESS_SUCCESS,
  SET_DEFAULT_PICKUP_ADDRESS_FAILURE,
  VIEW_PICKUP_ADDRESS_START,
  VIEW_PICKUP_ADDRESS_SUCCESS,
  VIEW_PICKUP_ADDRESS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  pickupAddress: {
    data: {},
    loading: true,
    error: false,
  },
  pickupAddressSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  pickupAddressDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  defaultpickupAddress: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
  },
  singlePickupAddress: {
    data: {},
    loading: true,
    error: false,
  },
};

const PickupAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PICKUP_ADDRESS_START:
      return {
        ...state,
        pickupAddress: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_PICKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        pickupAddress: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_PICKUP_ADDRESS_FAILURE:
      return {
        ...state,
        pickupAddress: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SAVE_PICKUP_ADDRESS_START:
      return {
        ...state,
        pickupAddressSave: {
          data: {},
          loading: true,
          error: false,
					loadingButtonContent: "Loading",
    			buttonDisable: true,
        },
      };
    case SAVE_PICKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        pickupAddressSave: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case SAVE_PICKUP_ADDRESS_FAILURE:
      return {
        ...state,
        pickupAddressSave: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
    case DELETE_PICKUP_ADDRESS_START:
      return {
        ...state,
        pickupAddressDelete: {
          data: {},
          loading: true,
					loadingButtonContent: "Loading",
          error: false,
        },
      };
    case DELETE_PICKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        pickupAddressDelete: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case DELETE_PICKUP_ADDRESS_FAILURE:
      return {
        ...state,
        pickupAddressDelete: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
    case SET_DEFAULT_PICKUP_ADDRESS_START:
      return {
        ...state,
        defaultpickupAddress: {
          data: {},
          loading: true,
					loadingButtonContent: "Loading",
          error: false,
        },
      };
    case SET_DEFAULT_PICKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        defaultpickupAddress: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case SET_DEFAULT_PICKUP_ADDRESS_FAILURE:
      return {
        ...state,
        defaultpickupAddress: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
    case VIEW_PICKUP_ADDRESS_START:
      return {
        ...state,
        singlePickupAddress: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case VIEW_PICKUP_ADDRESS_SUCCESS:
      return {
        ...state,
        singlePickupAddress: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case VIEW_PICKUP_ADDRESS_FAILURE:
      return {
        ...state,
        singlePickupAddress: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default PickupAddressReducer;
