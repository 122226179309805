import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button, Form, Accordion, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const VerificationPending = (props) => {

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={7}>
          <div className="categories-new-outlyt-sec">
            <div className="text-center">
              <h3>Your request is pending validation</h3>
              <p>You will be notified when it is processed.</p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VerificationPending;