import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useParams, Link } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  userProductVariantsSaveStart,
  fetchUserProductVariantsStart,
} from '../../store/actions/ProductsAction';
import Skeleton from 'react-loading-skeleton';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getErrorNotificationMessage } from '../Helper/ToastNotification';

const ProductVariants = (props) => {

  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [previewImage, setPreviewImage] = useState("");
  const [error, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);

  useEffect(() => {
    props.dispatch(
      fetchUserProductVariantsStart({
        user_product_id: params.user_product_id,
      })
    );
  }, []);

  const [variantFormFields, setVariantFormFields] = useState([
    { name: '', quantity: 0, picture: '' },
  ])

  const [sizeFormFields, setSizeFormFields] = useState([
    { name: '', quantity: 0, price: 0 },
  ])

  useEffect(() => {
    if (!skipRender &&
      !props.productVariants.loading &&
      Object.keys(props.productVariants.data).length > 0) {
      // setImages(props.productView.data.user_product.picture);
      let size_data = []; let variant_data = [];
      props.productVariants.data.product_size.forEach((size, key) => {
        size_data.push({ name: size.name, quantity: size.quantity, price: size.price, product_size_id: size.product_size_id });
      });
      setSizeFormFields(size_data);

      props.productVariants.data.product_variants.forEach((variant, key) => {
        variant_data.push({ name: variant.name,
           quantity: variant.quantity, product_variant_id: variant.product_variant_id });
      });
      setVariantFormFields(variant_data);

    }
    setSkipRender(false);
  }, [props.productVariants]);

  const handleFormChange = (event, index) => {
    let data = [...variantFormFields];
    if (event.target.name == 'picture') {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function () {
        setPreviewImage(reader.result);
        data[index][event.target.name] = reader.result;
      };

      // data[index][event.target.name] = URL.createObjectURL(event.target.files[0]);
    } else if (event.target.name == 'picture') {

    } else {
      data[index][event.target.name] = event.target.value;
    }
    setVariantFormFields(data);
    validateNumber(variantFormFields);
  }

  const validateNumber = (values) => {
    values.map((value) => {
      if (value.quantity < 0) {
        setError(true);
      } else {
        setError(false);
      }
    });
  };



  const handleSizeFormChange = (event, index) => {
    let data = [...sizeFormFields];
    if (event.target.name == 'picture') {
      data[index][event.target.name] = event.target.files[0];
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setSizeFormFields(data);
    validatePrice(sizeFormFields);
  }

  const validatePrice = (values) => {
    values.map((value) => {
      if (value.quantity < 0 || value.price < 0) {
        setPriceError(true);
      } else {
        setPriceError(false);
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const variantEmptyFields = variantFormFields.some(
      (field) => field.name === '' && field.quantity === 0 && field.picture === ''
    );
    const sizeEmptyFields = sizeFormFields.some(
      (field) => field.name === '' && field.quantity === 0 && field.price === 0
    );
    if(!variantEmptyFields && !sizeEmptyFields)
      props.dispatch(
        userProductVariantsSaveStart({
          user_product_id: params.user_product_id,
          product_variants: JSON.stringify(variantFormFields),
          product_sizes: JSON.stringify(sizeFormFields),
        })
      );
    else
      getErrorNotificationMessage("Add some data first")
  }

  const addVariantFields = () => {
    let object = {
      name: '',
      quantity: 0,
      picture: '',
    }

    setVariantFormFields([...variantFormFields, object])
  }

  const addSizeFields = () => {
    let object = {
      name: '',
      quantity: 0,
      price: 0,
    }

    setSizeFormFields([...sizeFormFields, object])
  }

  const removeFields = (index) => {
    let data = [...variantFormFields];
    data.splice(index, 1)
    setVariantFormFields(data)
  }

  const removeSizeFields = (index) => {
    let data = [...sizeFormFields];
    data.splice(index, 1)
    setSizeFormFields(data)
  }
  useEffect(() => {
    if (!skipRender && !props.productVariantSave.loading &&
      Object.keys(props.productVariantSave.data).length > 0) {
      props.dispatch(
        fetchUserProductVariantsStart({
          user_product_id: params.user_product_id,
        })
      );
    }
    setSkipRender(false);
  }, [props.productVariantSave]);

  return (
    <>
      <div className="add-product-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("product_variant_size")}</h3>
            </div>
          </div>

          <div>
            <Form className="profile-details-form" onSubmit={handleSubmit}>
              <Row className="mt-4">
                <div className="single-page-header">
                  <div className="single-page-title">
                    <h3>{t("product_variants")}</h3>
                  </div>
                </div>
                <Col md={12}>
                  <div className="border-right-divider add-product-form-sec">
                    {variantFormFields.map((form, index) => {
                      return (
                        <div key={index}>
                          <div className="">
                            <Row className="mt-4">
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("name")}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder={t("name")}
                                    name="name"
                                    className="form-control"
                                    onChange={event => handleFormChange(event, index)}
                                    value={form.name}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("quantity")}</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder={t("quantity")}
                                    name="quantity"
                                    className="form-control"
                                    onChange={event => handleFormChange(event, index)}
                                    value={form.quantity}
                                    pattern="[0-9]*"
                                    min="0"
                                    inputmode="numeric"
                                  />
                                  {error && <p className="error-msg text-danger text-right quantity-txt">
                                    {t("should_not_be_less_than")}</p>}
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("picture")}</Form.Label>
                                  <Form.Control
                                    type="file"
                                    placeholder={t("picture")}
                                    name="picture"
                                    accept="image/*"
                                    className="form-control"
                                    onChange={event => handleFormChange(event, index)}
                                  // value={form.picture}
                                  />
                                </Form.Group>
                                {previewImage !== "" ? (
                                  <div className="upload-img-preview-sec">
                                    <Image
                                      className="upload-img-preview"
                                      src={previewImage}
                                    />
                                  </div>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>

                          </div>
                          <button className='default-btn' onClick={() => removeFields(index)}>{t("remove")}</button>
                        </div>
                      )
                    })}
                  </div>
                </Col>

              </Row>
            </Form>
            <button className='default-btn mt-4' onClick={addVariantFields}>{t("add_more")}..</button>
          </div>

          <div>
            <Form className="profile-details-form" onSubmit={handleSubmit}>
              <Row className="mt-4">
                <div className="single-page-header">
                  <div className="single-page-title">
                    <h3>{t("product_size")}</h3>
                  </div>
                </div>
                <Col md={12}>
                  <div className="border-right-divider add-product-form-sec">
                    {sizeFormFields.map((sizeForm, index) => {
                      return (
                        <div key={index}>
                          <div className="">
                            <Row className="mt-4">
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("name")}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder={t("name")}
                                    name="name"
                                    className="form-control"
                                    onChange={event => handleSizeFormChange(event, index)}
                                    value={sizeForm.name}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("quantity")}</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder={t("quantity")}
                                    name="quantity"
                                    className="form-control"
                                    onChange={event => handleSizeFormChange(event, index)}
                                    value={sizeForm.quantity}
                                    pattern="[0-9]*"
                                    min="0"
                                    inputmode="numeric"
                                  />
                                  {sizeForm.quantity < 0 &&
                                    <p className="error-msg text-danger text-right quantity-txt">
                                      {t("should_not_be_less_than")}</p>}
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Form.Group className="mb-4">
                                  <Form.Label>{t("price")}</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder={t("price")}
                                    name="price"
                                    className="form-control"
                                    onChange={event => handleSizeFormChange(event, index)}
                                    value={sizeForm.price}
                                    pattern="[0-9]*"
                                    min="0"
                                    inputmode="numeric"
                                  />
                                  {sizeForm.price < 0 &&
                                    <p className="error-msg text-danger text-right quantity-txt">
                                      {t("should_not_be_less_than")}</p>}
                                </Form.Group>
                              </Col>
                            </Row>

                          </div>
                          <button className='default-btn ' onClick={() => removeSizeFields(index)}>
                            {t("remove")}</button>
                        </div>
                      )
                    })}
                  </div>
                </Col>

              </Row>
            </Form>
            <button className='default-btn mt-4' onClick={addSizeFields}>{t("add_more")}..</button>
          </div>

          <div className='text-right mt-4 mb-4'>
            <Button className=" default-btn "
              disabled={error || priceError || (Object.keys(variantFormFields).length == 0 && Object.keys(sizeFormFields).length == 0)}
              onClick={handleSubmit}>{t("submit")}</Button>
          </div>

        </Container>
      </div >

    </>
  )
}

const mapStateToPros = (state) => ({
  productVariants: state.userProducts.productVariants,
  productVariantSave: state.userProducts.productVariantSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProductVariants));