import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchCategoriesListStart,
  fetchMoreCategoriesListStart
} from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import CategorysLoader from "../../Helper/CategorysLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const NewExploreCategory = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart({
      skip: 0,
      take: 12
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreCategoriesListStart({
      skip: props.categoriesList.data.categories.length,
      take: 12,
    }));
  }

  return (
    <>
      {props.categoriesList.loading ?
        <CategorysLoader />
        :
        Object.keys(props.categoriesList.data).length > 0 &&
          props.categoriesList.data.categories.length > 0 ?
          <div className="new-explore-category-sec">
            <Container>
              <div className="new-home-title-sec">
                <h2>{t("explore_categories")}</h2>
              </div>
              <InfiniteScroll
                dataLength={props.categoriesList.data.categories.length}
                next={fetchMoreData}
                hasMore={
                  props.categoriesList.data.categories.length <
                  props.categoriesList.data.total}
                loader={<div className="new-explore-category-box">
                  {[...Array(5)].map((i) => (
                    <div className="new-explore-category-card">
                      <Skeleton count={1} height={50} width={160} />
                    </div>
                  ))}
                </div>
                }
              >
                <div className="new-explore-category-box">
                  {props.categoriesList.data.categories.map(
                    (category) => (
                      <div className="new-explore-category-card">
                        <Link className="expo-btn" to={`/category-videos/?category_id=${category.unique_id}`}>
                          {/* <Image
                            src={category.picture}
                            className="new-explore-category-img"
                          /> */}
                          {category.name}
                        </Link>
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </Container>
          </div>
          :
          ''
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  categoriesList: state.homepage.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(NewExploreCategory));



