import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    ORDERS_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_START,
    CANCEL_ORDER_START,
    ORDERS_MORE_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
    FETCH_MORE_ORDER_PAYMENT_LIST_START,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_WALLET_START,
    UPDATE_TRACKING_NUMBER_START,
    ORDERS_CREATE_BY_STRIPE_START,
    ORDERS_CREATE_BY_WALLET_START,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_START,
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    fetchOrderListSuccess,
    fetchOrderListFailure,
    fetchOrderPaymentListSuccess,
    fetchOrderPaymentListFailure,
    cancelOrderSuccess,
    cancelOrderFailure,
    ordersViewForOthersSuccess,
    ordersViewForOthersFailure,
    fetchOrderListStart,
    createOrderByWalletSuccess,
    createOrderByWalletFailure,
    createOrderByStripeSuccess,
    createOrderByStripeFailure,
    updateOrderStatusSuccess,
    updateOrderStatusFailure,
    updateTrackingNumberSuccess,
    updateTrackingNumberFailure,
    ordersCreateByWalletSuccess,
    ordersCreateByWalletFailure,
    ordersCreateByStripeSuccess,
    ordersCreateByStripeFailure,
    ordersCreateByStripeCheckoutSuccess,
    ordersCreateByStripeCheckoutFailure,
} from "../actions/OrderAction";
import config from "react-global-configuration";
import io from "socket.io-client";
import {
    fetchLiveVideosProductsStart
} from "../actions/LiveVideoAction";

function* fetchOrderListAPI(action) {
    try {
        const response = yield api.postMethod("orders_list", action.data);
        if (response.data.success) {
            yield put(fetchOrderListSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchOrderListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchOrderListFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchOrderPaymentListAPI(action) {
    try {
        const response = yield api.postMethod("order_payments_list", action.data);
        if (response.data.success) {
            yield put(fetchOrderPaymentListSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchOrderPaymentListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchOrderPaymentListFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* cancelOrderAPI(action) {
    try {
        const response = yield api.postMethod("orders_cancel", action.data);
        if (response.data.success) {
            yield put(cancelOrderSuccess(response.data.data));
            yield put(fetchOrderListStart());
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(cancelOrderFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(cancelOrderFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* ordersViewForOthersAPI(action) {
    try {
        const response = yield api.postMethod("orders_view_for_others", action.data);

        if (response.data.success) {
            yield put(ordersViewForOthersSuccess(response.data.data));
        } else {
            yield put(ordersViewForOthersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(ordersViewForOthersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* createOrderByWalletAPI(action) {
    try {
        const response = yield api.postMethod("orders_create_by_wallet", action.data);
        if (response.data.success) {
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(createOrderByWalletSuccess(response.data.data));
            yield put(fetchLiveVideosProductsStart({
                live_video_id: action.data.live_video_id,
            }));
            let chatSocket;
            let chatSocketUrl = config.get("configData.chat_socket_url");
            chatSocket = io(chatSocketUrl, {
                query: `room: '` + response.data.data.live_video.virtual_id + `'`,
            });
            console.log(response.data.data.live_video.virtual_id);
            chatSocket.emit("livevideo buynow", response.data.data.live_video.virtual_id);

        } else {
            yield put(createOrderByWalletFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(createOrderByWalletFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* createOrderByStripeAPI(action) {
    try {
        const response = yield api.postMethod("orders_create_by_stripe_checkout", action.data);
        if (response.data.success) {
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(createOrderByStripeSuccess(response.data.data));
        } else {
            yield put(createOrderByStripeFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(createOrderByStripeFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* updateTrackingNumberAPI(action) {
    try {
        const response = yield api.postMethod("orders_update_tracking_number", action.data);
        if (response.data.success) {
            yield put(updateTrackingNumberSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(updateTrackingNumberFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(updateTrackingNumberFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* OrdersCreateByWalletAPI(action) {
    try {
        const response = yield api.postMethod("orders_payment_by_wallet", action.data);
        if (response.data.success) {
            yield put(ordersCreateByWalletSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            // window.location.assign(`/order-view/${response.data.data.unique_id}`)
        } else {
            yield put(ordersCreateByWalletFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);

        }
    } catch (error) {
        yield put(ordersCreateByWalletFailure(error));
        yield call(getErrorNotificationMessage, error.message);

    }
}

function* OrdersCreateByStripeAPI(action) {
    try {
        const response = yield api.postMethod("orders_payment_by_stripe", action.data);
        if (response.data.success) {
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(ordersCreateByStripeSuccess(response.data.data));
        } else {
            yield put(ordersCreateByStripeFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(ordersCreateByStripeFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* OrdersCreateByStripeCheckoutAPI(action) {
    try {
        const response = yield api.postMethod("orders_payment_by_stripe_checkout_create", action.data);
        if (response.data.success) {
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(ordersCreateByStripeCheckoutSuccess(response.data.data));
        } else {
            yield put(ordersCreateByStripeCheckoutFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(ordersCreateByStripeCheckoutFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

export default function* OrderSaga() {
    yield all([
        yield takeLatest(ORDERS_LIST_START, fetchOrderListAPI),
        yield takeLatest(ORDERS_MORE_LIST_START, fetchOrderListAPI),
        yield takeLatest(FETCH_ORDER_PAYMENT_LIST_START, fetchOrderPaymentListAPI),
        yield takeLatest(FETCH_MORE_ORDER_PAYMENT_LIST_START, fetchOrderPaymentListAPI),
        yield takeLatest(CANCEL_ORDER_START, cancelOrderAPI),
        yield takeLatest(ORDERS_VIEW_FOR_OTHERS_START, ordersViewForOthersAPI),
        yield takeLatest(CREATE_ORDER_BY_WALLET_START, createOrderByWalletAPI),
        yield takeLatest(CREATE_ORDER_BY_STRIPE_START, createOrderByStripeAPI),
        yield takeLatest(UPDATE_TRACKING_NUMBER_START, updateTrackingNumberAPI),
        yield takeLatest(ORDERS_CREATE_BY_WALLET_START, OrdersCreateByWalletAPI),
        yield takeLatest(ORDERS_CREATE_BY_STRIPE_START, OrdersCreateByStripeAPI),
        yield takeLatest(CREATE_ORDER_BY_STRIPE_CHECKOUT_START, OrdersCreateByStripeCheckoutAPI),

    ]);
}


