import React, { useState } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import LandingFooter from "./Footer/LandingFooter";

const MainLayout = (props) => {

  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">
        {props.children}
      </div>
      <LandingFooter />
    </>
  );
};

export default MainLayout;
