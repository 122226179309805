import {
    ORDERS_LIST_START,
    ORDERS_LIST_SUCCESS,
    ORDERS_LIST_FAILURE,
    FETCH_ORDER_PAYMENT_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_SUCCESS,
    FETCH_ORDER_PAYMENT_LIST_FAILURE,
    CANCEL_ORDER_START,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    ORDERS_MORE_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
    ORDERS_VIEW_FOR_OTHERS_SUCCESS,
    ORDERS_VIEW_FOR_OTHERS_FAILURE,
    FETCH_MORE_ORDER_PAYMENT_LIST_START,
    CREATE_ORDER_BY_WALLET_START,
    CREATE_ORDER_BY_WALLET_SUCCESS,
    CREATE_ORDER_BY_WALLET_FAILURE,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_STRIPE_SUCCESS,
    CREATE_ORDER_BY_STRIPE_FAILURE,
    UPDATE_TRACKING_NUMBER_START,
    UPDATE_TRACKING_NUMBER_SUCCESS,
    UPDATE_TRACKING_NUMBER_FAILURE,
    ORDERS_CREATE_BY_WALLET_START,
    ORDERS_CREATE_BY_WALLET_SUCCESS,
    ORDERS_CREATE_BY_WALLET_FAILURE,
    ORDERS_CREATE_BY_STRIPE_START,
    ORDERS_CREATE_BY_STRIPE_SUCCESS,
    ORDERS_CREATE_BY_STRIPE_FAILURE,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_FAILURE,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_START,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_SUCCESS,
} from "./ActionConstant";

export function fetchOrderListStart(data) {
    return {
        type: ORDERS_LIST_START,
        data,
    };
}

export function fetchOrderListSuccess(data) {
    return {
        type: ORDERS_LIST_SUCCESS,
        data,
    };
}

export function fetchOrderListFailure(error) {
    return {
        type: ORDERS_LIST_FAILURE,
        error,
    };
}

export function fetchMoreOrderListStart(data) {
    return {
        type: ORDERS_MORE_LIST_START,
        data,
    };
}

export function fetchOrderPaymentListStart(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_START,
        data,
    };
}

export function fetchOrderPaymentListSuccess(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_SUCCESS,
        data,
    };
}

export function fetchOrderPaymentListFailure(data) {
    return {
        type: FETCH_ORDER_PAYMENT_LIST_FAILURE,
        data,
    }
}
export function fetchMoreOrderPaymentListStart(data) {
    return {
        type: FETCH_MORE_ORDER_PAYMENT_LIST_START,
        data,
    };
}


export function cancelOrderStart(data) {
    return {
        type: CANCEL_ORDER_START,
        data,
    };
}

export function cancelOrderSuccess(data) {
    return {
        type: CANCEL_ORDER_SUCCESS,
        data,
    };
}

export function cancelOrderFailure(error) {
    return {
        type: CANCEL_ORDER_FAILURE,
        error,
    }
}

export function ordersViewForOthersStart(data) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_START,
        data,
    };
}

export function ordersViewForOthersSuccess(data) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_SUCCESS,
        data,
    };
}

export function ordersViewForOthersFailure(error) {
    return {
        type: ORDERS_VIEW_FOR_OTHERS_FAILURE,
        error,
    };
}

export function createOrderByWalletStart(data) {
    return {
        type: CREATE_ORDER_BY_WALLET_START,
        data,
    };
}

export function createOrderByWalletSuccess(data) {
    return {
        type: CREATE_ORDER_BY_WALLET_SUCCESS,
        data,
    };
}

export function createOrderByWalletFailure(error) {
    return {
        type: CREATE_ORDER_BY_WALLET_FAILURE,
        error
    };
}

export function createOrderByStripeStart(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_START,
        data,
    };
}

export function createOrderByStripeSuccess(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_SUCCESS,
        data,
    };
}

export function createOrderByStripeFailure(error) {
    return {
        type: CREATE_ORDER_BY_STRIPE_FAILURE,
        error,
    };
}

export function updateTrackingNumberStart(data) {
    return {
        type: UPDATE_TRACKING_NUMBER_START,
        data,
    };
}

export function updateTrackingNumberSuccess(data) {
    return {
        type: UPDATE_TRACKING_NUMBER_SUCCESS,
        data,
    };
}

export function updateTrackingNumberFailure(error) {
    return {
        type: UPDATE_TRACKING_NUMBER_FAILURE,
        error,
    };
}

export function ordersCreateByWalletStart(data) {
    return {
        type: ORDERS_CREATE_BY_WALLET_START,
        data,
    };
}

export function ordersCreateByWalletSuccess(data) {
    return {
        type: ORDERS_CREATE_BY_WALLET_SUCCESS,
        data,
    };
}

export function ordersCreateByWalletFailure(error) {
    return {
        type: ORDERS_CREATE_BY_WALLET_FAILURE,
        error
    };
}

export function ordersCreateByStripeStart(data) {
    return {
        type: ORDERS_CREATE_BY_STRIPE_START,
        data,
    };
}

export function ordersCreateByStripeSuccess(data) {
    return {
        type: ORDERS_CREATE_BY_STRIPE_SUCCESS,
        data,
    };
}

export function ordersCreateByStripeFailure(error) {
    return {
        type: ORDERS_CREATE_BY_STRIPE_FAILURE,
        error,
    };
}

// Stripe Checkout

export function ordersCreateByStripeCheckoutStart(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_CHECKOUT_START,
        data,
    };
}

export function ordersCreateByStripeCheckoutSuccess(data) {
    return {
        type: CREATE_ORDER_BY_STRIPE_CHECKOUT_SUCCESS,
        data,
    };
}

export function ordersCreateByStripeCheckoutFailure(error) {
    return {
        type: CREATE_ORDER_BY_STRIPE_CHECKOUT_FAILURE,
        error,
    };
}
