import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Form, Image, Media } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchUserSingleProductStart,
  fetchProductCategoriesStart,
  fetchProductSubCategoriesStart,
  userProductsSaveStart,
  userProductPictureSaveStart,
} from '../../store/actions/ProductsAction';
import Skeleton from 'react-loading-skeleton';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { fetchSubCategoriesStart } from '../../store/actions/HomePageAction';
import { useDispatch } from 'react-redux';
import PickupAddressModal from './PickupAddressModal';

const DetectCategories = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.category_id) {
      dispatch(fetchSubCategoriesStart({ category_id: values.category_id }));
    }
  }, [values.category_id]);
}

const EditProduct = (props) => {

  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [pickupAddress, setPickupAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { setFieldValue } = useFormikContext() ?? {};
  const [productPictures, setProductPictures] = useState([]);

  const closePickupAddressModal = () => {
    setPickupAddress(false);
  };

  const productSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    quantity: Yup.string().required(t("quantity_is_required")),
    price: Yup.string().required(t("price_is_required")),
    category_id: Yup.string().required(t("category_id_is_required")),
    sub_category_id: Yup.string().required(t("sub_category_id_is_required")),
  })

  useEffect(() => {
    props.dispatch(fetchUserSingleProductStart({
      user_product_id: params.user_product_id,
    }));
    props.dispatch(fetchProductCategoriesStart());
  }, []);


  const handleSubmit = (values) => {
    if (files[0]) {
      props.dispatch(userProductsSaveStart({
        picture: files[0],
        user_product_picture_id: productPictures.map(picture => picture.user_product_picture_id).toString(),
        user_product_id: params.user_product_id,
        ...values,
        pickup_address_id: selectedAddress.pickup_address_id,
      }));
    }
    else {
      props.dispatch(userProductsSaveStart({
        user_product_id: params.user_product_id,
        user_product_picture_id: productPictures.map(picture => picture.user_product_picture_id).toString(),
        ...values,
        pickup_address_id: selectedAddress.pickup_address_id,
      }));
    }
  };

  useEffect(() => {
    if (!skipRender && !props.productSave.loading && Object.keys(props.productSave.data).length > 0) {
      navigate(`/single-product/` + props.productSave.data.unique_id);
    }
    setSkipRender(false);
  }, [props.productSave]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'video/mp4': [],
    },
    onDrop: (acceptedFiles) => {
      let files = {};
      acceptedFiles.forEach((file, key) => {
        let name = 'file[' + key + ']';
        files = { ...files, [name]: file }
      });
      props.dispatch(userProductPictureSaveStart({
        ...files,
      }));
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    }
  });

  const images = productPictures.map(file => (
    file.file_type === "video" ?
      <div className="preview-added-image">
        <Button className="preview-cancel" onClick={e => {
          window.confirm(t("asset_remove_msg"))
            ? removeMedia(file.user_product_picture_id)
            : e.preventDefault();
        }}>
          <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z" fill="#D2D2D6"></path></svg>
        </Button>
        <video
          autoplay
          controls
          id="myVideo"
          className="user-profile1 w-100"
        >
          <source src={file.picture} type="video/mp4" />
        </video>
      </div>
      :
      <div className="preview-added-image">
        <Button className="preview-cancel" onClick={e => {
          window.confirm(t("asset_remove_msg"))
            ? removeMedia(file.user_product_picture_id)
            : e.preventDefault();
        }}>
          <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z" fill="#D2D2D6"></path></svg>
        </Button>
        <Image
          className="product-preview"
          key={file.user_product_picture_id}
          src={file.picture}
          alt="image-preview" />
      </div>
  ))

  useEffect(() => {
    if (!props.productView.loading && Object.keys(props.productView.data).length > 0 &&
      props.productView.data.user_product.pickup_address != 'null') {
      setSelectedAddress(props.productView.data.user_product.pickup_address);
      setProductPictures(props.productView.data.user_product.userProductFiles);
    }
  }, [props.productView])

  useEffect(() => {
    if (!skipRender && !props.productPictureSave.loading && Object.keys(props.productPictureSave.data).length > 0) {
      setProductPictures(props.productPictureSave.data.user_product_pictures)
    }
    setSkipRender(false);
  }, [props.productPictureSave]);

  const removeMedia = productPictureId => {
    setProductPictures(productPictures.filter(asset => asset.user_product_picture_id !== productPictureId));
  }

  return (
    <>
      <div className="add-product-sec">
        <Container>
          {props.productView.loading ?
            <Row className="mt-4">
              <Col md={6}>
                <Skeleton className='mb-5' count={5} height={50} />

              </Col>
              <Col md={6}>
                <Skeleton className='mb-4' count={1} height={50} />
                <Skeleton className='mb-4' count={1} height={250} />
                <Skeleton className='mb-4' count={1} height={100} />
              </Col>
            </Row>
            :
            Object.keys(props.productView.data).length > 0 ?
              <Formik
                initialValues={{
                  name: props.productView.data.user_product.name,
                  quantity: props.productView.data.user_product.quantity,
                  price: props.productView.data.user_product.price,
                  category_id: props.productView.data.user_product.category_id,
                  sub_category_id: props.productView.data.user_product.sub_category_id,
                  description: props.productView.data.user_product.description,
                  pickup_address_id: props.productView.data.user_product.pickup_address_id
                }}
                validationSchema={productSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <FORM className="profile-details-form mt-0">
                    <div className="single-page-header">
                      <div className="single-page-title">
                        <Link to="#" onClick={() => navigate(-1)}>
                          <Image src={window.location.origin + "/images/back.svg"} />
                        </Link>
                        <h3>{t("edit_product")}</h3>
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="border-right-divider add-product-form-sec">
                          <Form.Group className="mb-4">
                            <Form.Label>{t("name")}*</Form.Label>
                            <Field
                              type="text"
                              placeholder={t("name")}
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("quantity")}*</Form.Label>
                            <Field
                              type="number"
                              placeholder={t("quantity")}
                              name="quantity"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="quantity"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("price")}*</Form.Label>
                            <Field
                              type="number"
                              min="1"
                              placeholder={t("price")}
                              name="price"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="price"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("category")}*</Form.Label>
                            <Field
                              as="select"
                              className="form-control"
                              name="category_id"
                            >
                              <option value="">{t("select_category")}</option>
                              {props.categories.loading ?
                                <Skeleton count={1} height={40} />
                                :
                                Object.keys(props.categories.data).length > 0 &&
                                  props.categories.data.product_categories.length > 0
                                  ?
                                  props.categories.data.product_categories.map((category, index) => (
                                    <option
                                      value={category.category_id}
                                      key={index}
                                    >{category.name}</option>
                                  ))
                                  :
                                  null
                              }
                            </Field>
                            <DetectCategories />
                            <ErrorMessage
                              name="category_id"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("sub_category")}*</Form.Label>
                            <Field
                              as="select"
                              className="form-control"
                              name="sub_category_id"
                            >
                              <option value="">{t("select_sub_category")}</option>
                              {props.subCategories.loading ?
                                <Skeleton count={1} />
                                :
                                Object.keys(props.subCategories.data).length > 0 &&
                                  props.subCategories.data.sub_categories.length > 0 ?
                                  props.subCategories.data.sub_categories.map((sub_category) => (
                                    <option value={sub_category.sub_category_id}>{sub_category.name}</option>
                                  ))
                                  :
                                  null
                              }
                            </Field>
                            <ErrorMessage
                              name="sub_category_id"
                              component={"div"}
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4 pick-add-sec">
                            <Form.Label>{t("pickup_address")}*</Form.Label>
                            <Field
                              as="select"
                              className="form-control"
                              name="pickup_address_id"
                            >
                              {selectedAddress &&
                                <option value={selectedAddress.address}>{selectedAddress.address}</option>
                              }
                            </Field>
                            {!values.pickup_address_id ?
                              <ErrorMessage
                                name="pickup_address_id"
                                component={"div"}
                                className="text-danger text-right"
                              />
                              :
                              null
                            }
                            <Button onClick={() => setPickupAddress(true)} variant="primary">{t("select_pickup_address")}</Button>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="add-product-upload-file-sec">
                          <Form.Label>{t("upload_product_image")}</Form.Label>
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()} />
                            <div className="add-product-upload-box">
                              <Image src={window.location.origin + "/images/upload-icon.svg"} className="upload-icon" />
                              <p>{t("select_a_image")}</p>
                            </div>

                            <div className="product-img-notes">
                              {t("please_upload_jpg")}
                            </div>
                          </div>
                          <div className="preview-added-image-sec">
                            {images}
                          </div>
                          <Form.Group className="mb-4">
                            <Form.Label>{t("description")}</Form.Label>
                            <Field
                              as="textarea"
                              rows={3}
                              name="description"
                              className="height-auto form-control"
                              placeholder={t("description")}
                            />
                          </Form.Group>
                          <div className="add-product-btn-sec text-center">
                            <Button
                              type="submit"
                              className="add-product-btn"
                              disabled={props.productSave.buttonDisable}
                            >
                              {props.productSave.loadingButtonContent !== null ?
                                props.productSave.loadingButtonContent :
                                t("update_product")
                              }
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </FORM>
                )}
              </Formik>
              :
              null}
          {pickupAddress &&
            <PickupAddressModal
              pickupAddress={pickupAddress}
              closePickupAddressModal={closePickupAddressModal}
              setPickupAddress={setPickupAddress}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
            />
          }
        </Container>
      </div>

    </>
  )
}

const mapStateToPros = (state) => ({
  productView: state.userProducts.productView,
  categories: state.userProducts.productCategories,
  subCategories: state.homepage.subCategories,
  productSave: state.userProducts.productSave,
  productPictureSave: state.userProducts.productPictureSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(EditProduct));