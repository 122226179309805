import React, { useState, useEffect, useMemo } from "react";
import { Container, Image, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { registerStart, usernameValidationStart } from "../../../store/actions/UserAction";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import SocialButton from '../../Helper/SocialButton';
import Select from 'react-select'
import getAllCountries from 'react-select-country-list';
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
const getCountryISO3 = require("country-iso-2-to-3");

const NewRegisterIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [userName, setUserName] = useState("");
  const [skipRender, setSkipRender] = useState(false);

  const registervalidation = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    username: Yup.string().required(t("user_name_is_required")),
    email: Yup.string().email(t("invalid_email_address_format"))
      .required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters")),
    confirm_password: Yup.string()
      .required(t("password_required"))
      .oneOf([Yup.ref('password'), null], t("passwords_must_match")),
    country: Yup.string().required(t("country_is_required"))
  })

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value) return t("should_not_contain_empty_space");
    return "";
  };

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return t("no_white_space_allowed");
      }
    } else {
      setIsValidUserName(false);
      return t("must_contain_4_characters");
    }
  };

  useEffect(() => {
    if (!skipRender && !props.register.loading &&
      Object.keys(props.register.data).length > 0) {
      if (props.register.data.code === 1001) {
        navigate("/register/verify");
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.register])

  const handleSubmit = (values) => {
    props.dispatch(registerStart(values));
  }

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #000!important",
      boxShadow: "none",
      height: "50px",
      width: "100%",
      borderRadius: "0.175rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#999",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
    ///.....
  };

  const excludedCountries = [
    { value: 'PK', label: 'Pakistan' },
  ];

  const allCountries = getAllCountries().data.filter(country =>
    !excludedCountries.some(obj => obj.value === country.value)
  );

  return (
    <>
      <div className="outfyt-auth-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="outfyt-auth-box">
                <div className="outfyt-auth-left-sec">
                  <CustomLazyLoad
                    src={window.location.origin + "/images/outfyt/auth/auth-image.jpg"}
                    className="outfyt-auth-img"
                  />
                </div>
                <div className="outfyt-auth-right-sec">
                  <div className="outfyt-auth-body-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/images/site-logo.png"
                      }
                      className="outfyt-auth-logo"
                    />
                    <Formik
                      initialValues={{
                        name: "",
                        username: "",
                        email: "",
                        password: "",
                        country: "",
                        country_code: "",
                        confirm_password: "",
                      }}
                      validationSchema={registervalidation}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({
                        touched,
                        errors,
                        values,
                        setFieldValue,
                      }) => (
                        <FORM className="outfyt-auth-form">
                          <Form.Group className="mb-3">
                            <Field
                              type="text"
                              placeholder={t("name")}
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Field
                              type="text"
                              placeholder={t("user_name")}
                              name="username"
                              className="form-control"
                              validate={handleUsernameValidation}
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="username"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                            {props.validation.isInValid &&
                              isvalidUserName ? (
                              <div class="text-danger">
                                {props.validation.errorMessage ??
                                  t("username_already_taken")}
                              </div>
                            ) : (
                              ""
                            )}
                            {props.validation.isValid && isvalidUserName ? (
                              <div class="text-success">
                                {t("looks_good")}
                              </div>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Field
                              type="email"
                              placeholder={t("email_address")}
                              name="email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <InputGroup className="mb-3">
                            <Field
                              placeholder={t("password")}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control"
                              validate={passwordValidation}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <button
                                onClick={() =>
                                  setShowPassword(!showPassword)
                                }
                                className="btn password-eye"
                                type="button"
                              >{showPassword ?
                                <Image
                                  className="pass-eye-open"
                                  src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                                />
                                :
                                <Image
                                  className="pass-eye-open"
                                  src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                                />
                                }
                              </button>
                            </InputGroup.Text>
                            <ErrorMessage
                              name="password"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <Field
                              placeholder={t("confirm_password")}
                              type="password"
                              name="confirm_password"
                              className="form-control"
                              validate={passwordValidation}
                            />
                            <ErrorMessage
                              name="confirm_password"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </InputGroup>
                          <Form.Group className="mb-4">
                            <Select
                              name="country_name"
                              options={allCountries}
                              styles={customStyles}
                              isSearchable={true}
                              onChange={(selectedOption) => {
                                setFieldValue("country", selectedOption.label)
                                setFieldValue("country_code", getCountryISO3(selectedOption.value))
                              }}

                              placeholder={t("country")}

                            />
                            {!values.country_code ?
                              <ErrorMessage
                                name="country"
                                component={"div"}
                                className="text-danger pass-txt"
                              />
                              :
                              null
                            }
                          </Form.Group>
                          <div className="outfyt-auth-btn-sec">
                            <Button
                              type="submit"
                              className="outfyt-auth-btn w-100"
                              disabled={props.register.buttonDisable}
                            >
                              {props.register.loadingButtonContent !== null ?
                                props.register.loadingButtonContent :
                                t("register")
                              }
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                  <div className="outfyt-auth-social-link-sec">
                    <ul className="list-unstyled outfyt-auth-social-link-list">
                      {configuration.get("configData.FB_CLIENT_ID") ?
                        <li>
                          <SocialButton
                            provider="facebook"
                            appId={configuration.get("configData.FB_CLIENT_ID")}
                            onLoginSuccess={handleFacebookLogin}
                            onLoginFailure={handleSocialLoginFailure}
                            className="social-button"
                            id="facebook-connect"
                          >
                            <Image
                              className="outfyt-auth-social-link-icon"
                              src={window.location.origin + "/images/outfyt/auth/facebook-icon.svg"}
                            />
                            {t("login_facebook")}
                          </SocialButton>
                        </li>
                        :
                        null
                      }
                      <li>
                        {configuration.get("configData.GOOGLE_CLIENT_ID") ?
                          <SocialButton
                            provider="google"
                            key={"google"}
                            appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                            onLoginSuccess={handleGoogleLogin}
                            onLoginFailure={handleSocialLoginFailure}
                            className="social-button"
                            id="google-connect"
                          >
                            <Image
                              className="outfyt-auth-social-link-icon"
                              src={window.location.origin + "/images/outfyt/auth/gmail-icon.svg"}
                            />
                            {t("login_google")}
                          </SocialButton>
                          :
                          null
                        }
                      </li>
                    </ul>
                  </div>
                  <div className="outfyt-footer-sec">
                    {t("already_have_an_account")} <Link to="/login">{t("login")}</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  register: state.users.register,
  validation: state.users.validationInputData,

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(NewRegisterIndex));
