import React, { useState, useEffect, useRef } from "react";
import { Container, Image, Row, Col, Form, Button, InputGroup, Dropdown, Overlay, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewHeaderIndex from "../Home/NewHeaderIndex";
import NewFooterIndex from "../Home/NewFooterIndex";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import HeaderIndex from "../../layouts/Header/HeaderIndex";
import {
  updateChatMessagesSuccess,
  fetchChatMessagesStart,
  fetchChatUsersStart,
} from "../../../store/actions/ChatAction";
import configuration from "react-global-configuration";
import io from "socket.io-client";
import ChatUserList from "./ChatUserList";
import CommonCenterLoader from "../../Helper/CommonCenterLoader";
import NoDataFound from "../../Helper/NoDataFound";

let chatSocket;

const ChatIndex = (props) => {

  const t = useTranslation();
  const [activeChat, setActiveChat] = useState(0);
  const [toUserId, setToUserId] = useState(0);
  const [inputMessage, setInputMessage] = useState("");
  const [initialHeight, setInitialHeight] = useState(0);
  const [newMsg, setNewMsg] = useState(false);
  const [message, setMessage] = useState("");

  const messageRef = useRef();

  const [chatAssetUpload, setChatAssetUpload] = useState(false);
  const [fileType, setFileType] = useState("picture");

  const closeChatAssetUploadModal = () => {
    setChatAssetUpload(false);
  };

  const [emojiPickerState, SetEmojiPicker] = useState(false);

  const invalidMessageRef = useRef(null);

  const messageField = useRef();
  const latest = useRef();

  const [emptyMessageCheck, setEmptyMessageCheck] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    props.dispatch(fetchChatUsersStart({ search_key: searchKey }));
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl === "") {

    }
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);

  useEffect(() => {
    console.log("Number of times called");
    if (
      props.chatUsers.loading === false &&
      props.chatUsers.data.users.length > 0
    ) {
      console.log("Number of times called true  ");
      setToUserId(props.chatUsers.data.users[0].to_user_id);
      chatSocketConnect(props.chatUsers.data.users[0].to_user_id);
      changeUser(props.chatUsers.data.users[0], 0);
    } else {
    }
  }, [!props.chatUsers.loading]);

  const chatSocketConnect = (to_user_id) => {
    // check the socket url is configured
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    console.log("chatSocket", chatSocketUrl);
    console.log("Input ID", to_user_id);
    if (chatSocketUrl) {
      chatSocket = io(chatSocketUrl, {
        query:
          `commonid:'user_id_` +
          localStorage.getItem("userId") +
          `_to_user_id_` +
          to_user_id +
          `',myid:` +
          localStorage.getItem("userId"),
      });
      console.log("chatSocket", chatSocket);
      chatSocket.emit("update sender", {
        commonid:
          "user_id_" +
          localStorage.getItem("userId") +
          "_to_user_id_" +
          to_user_id,
        myid: localStorage.getItem("userId"),
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        console.log(newData);
        setNewMsg(true);
        props.dispatch(updateChatMessagesSuccess(newData));
      });
    }
  };

  // Scroll down function..
  useEffect(() => {
    console.log("Scroll down..");
    const objDiv = document.getElementById("options-holder");
    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      if (differenceNumber > 280) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = initialHeight;
        setInitialHeight(initialHeight + 20);
      }
    }
  }, [props.chatMessages.data.messages]);

  const changeUser = (chat, index) => {
    chatSocket.disconnect();
    // if (isMobile) {
    //   window.location.assign(
    //     "/user-chat-room/" + chat.from_user_id + "/" + chat.to_user_id
    //   );
    // }
    // event.preventDefault();
    setActiveChat(index);
    let to_user_id =
      chat.to_user_id == localStorage.getItem("userId")
        ? chat.from_user_id
        : chat.to_user_id;
    setToUserId(to_user_id);
    console.log("before", to_user_id, chat.from_user_id)
    props.dispatch(
      fetchChatMessagesStart({
        to_user_id: chat.to_user_id,
        from_user_id: chat.from_user_id,
      })
    );
    chatSocketConnect(to_user_id);
  };

  const handleChatSubmit = ({ msgAmount = 0, fileType = "text", chatAssets = [] }) => {
    let chatSocketUrl = configuration.get("configData.chat_socket_url");

    if (inputMessage.length == 0) {
      setEmptyMessageCheck(true);
    }

    if (chatSocketUrl != undefined && inputMessage) {
      let chatData = [
        {
          from_user_id: localStorage.getItem("userId"),
          to_user_id: toUserId,
          message: inputMessage,
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: Date(),
          chat_asset_id: chatAssets.map(asset => asset.chat_asset_id).toString(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
        },
      ];
      chatSocket.emit("message", chatData[0]);
      console.log(chatData);
      setMessage("");
      props.dispatch(updateChatMessagesSuccess(chatData[0]));
      setInputMessage("");
      messageField.current.focus();
    }
  };

  useEffect(() => {
    props.assetUpload.loading || handleMediaSubmit();
  }, [props.assetUpload.loading]);

  const handleMediaSubmit = () => {
    const assetData = props.assetUpload.data.chat_asset;
    const assetMessage = props.assetUpload.data.chat_message;
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl != undefined && assetData && assetMessage) {
      let chatData = [
        {
          from_user_id: localStorage.getItem("userId"),
          to_user_id: toUserId,
          chat_message_id: assetMessage ? assetMessage.chat_message_id : "",
          message: assetMessage ? assetMessage.message : "",
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: Date(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
          chat_asset_id: assetData ? assetData.chat_asset_id : "",
          chat_asset_url:
            assetMessage.amount > 0 ? assetData.blur_file : assetData.file,
          file_type: assetData.file_type,
          is_user_needs_pay: assetMessage.amount > 0 ? 1 : 0,
          amount: assetMessage.amount > 0 ? assetMessage.amount : 0,
          amount_formatted:
            assetMessage.amount > 0 ? assetMessage.amount_formatted : 0,
          payment_text:
            assetMessage.amount > 0
              ? "UNLOCK MESSAGE FOR " + assetMessage.amount_formatted
              : "",
        },
      ];

      let chatMessageData = [
        {
          from_user_id: localStorage.getItem("userId"),
          to_user_id: toUserId,
          chat_message_id: assetMessage ? assetMessage.chat_message_id : "",
          message: assetMessage ? assetMessage.message : "",
          type: "uu",
          user_picture: localStorage.getItem("user_picture"),
          loggedin_user_id: localStorage.getItem("userId"),
          created: Date(),
          from_username: localStorage.getItem("username"),
          from_displayname: localStorage.getItem("name"),
          from_userpicture: localStorage.getItem("user_picture"),
          from_user_unique_id: "",
          to_username: "",
          to_displayname: "",
          to_userpicture: "",
          to_user_unique_id: "",
          chat_asset_id: assetData ? assetData.chat_asset_id : "",
          chat_asset_url: assetData.file,
          file_type: assetData.file_type,
          amount: assetMessage.amount > 0 ? assetMessage.amount : 0,
          amount_formatted:
            assetMessage.amount > 0 ? assetMessage.amount_formatted : 0,
          is_user_needs_pay: 0,
          payment_text: "",
        },
      ];
      chatSocket.emit("message", chatData[0]);
      let messages;
      if (props.chatMessages.data.messages != null) {
        messages = [...props.chatMessages.data.messages, ...chatMessageData];
      } else {
        messages = [...chatMessageData];
      }
      if (assetData) {
        closeChatAssetUploadModal();
      }
      setInputMessage("");
      // props.dispatch(addMessageContent(chatMessageData));
    }
  };

  const chatInputChange = (value) => {
    setInputMessage(value);
    if (inputMessage.length > 0) {
      setEmptyMessageCheck(false);
    }
  };

  const searchUser = (value) => {
    setSearchKey(value);
    props.dispatch(fetchChatUsersStart({ search_key: value }));
  };

  return (
    <>
      <div className="new-outfyt-chat-page-sec">
        {/* <NewHeaderIndex /> */}
        <HeaderIndex />
        <div className="new-main-wrapper">
          <Container>
            <div className="chat-ui-sec">
              <div className="chat-ui-sidebar">
                <div className="chat-ui-sidebar-search">
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("searches")}
                    />
                    <InputGroup.Text id="basic-addon2"><i className="fas fa-search"></i></InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="chat-ui-sidebar-collapse">
                  <div className="chat-ui-collapse-body">
                    {props.chatUsers.loading ? <CommonCenterLoader /> : props.chatUsers.data &&
                      props.chatUsers.data.users.length > 0 ? (
                      <ChatUserList
                        chatUsers={props.chatUsers.data}
                        activeChat={activeChat}
                        setActiveChat={setActiveChat}
                        changeUser={changeUser}
                      />
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
              <div className="chat-ui-main-wrapper order-bottom">
                {props.chatMessages.loading ? (
                  ''
                ) : props.chatMessages.data.user &&
                  props.chatMessages.data.user.user_unique_id ? (
                  <>
                    <div className="chat-ui-main-wrapper-header">
                      <Link
                        to={`/` + props.chatMessages.data.user.user_unique_id}
                        className="user-click">
                        <div className="chat-ui-main-wrapper-user">
                          <div className="user-message-img-sec">
                            <Image
                              src={props.chatMessages.data.user.picture}
                              className="user-message-header-img"
                            />
                          </div>
                          <div className="user-message-header-info">
                            <h5>{props.chatMessages.data.user.name}</h5>
                          </div>
                        </div>
                      </Link>
                      <div className="chat-ui-main-wrapper-icon-sec">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="chat-ui-main-wrapper-body" id="options-holder">
                      {props.chatMessages.data.messages.length > 0 ?
                        <div className="message-content-sec" ref={messageRef}>
                          {props.chatMessages.data.messages.map(
                            (chatMessage, index) => (
                              <>
                                {chatMessage.from_user_id ==
                                  localStorage.getItem("userId") ? (
                                  <div className="message-right-align">
                                    <div className="message-user-img-sec">
                                      <Image
                                        src={localStorage.getItem(
                                          "user_picture"
                                        )}
                                        alt=""
                                        className="user-message-img"
                                      />
                                    </div>
                                    <div className="message-user-info">
                                      {/* {chatMessage.chat_asset_url &&
                                        chatMessage.file_type == "video" ? (
                                        <ReactPlayer
                                          url={chatMessage.chat_asset_url}
                                          controls={true}
                                          className="post-video-size chat-video"
                                          width="450px"
                                          height="450px"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {chatMessage.chat_asset_url &&
                                        chatMessage.file_type == "audio" ? (
                                        <ReactAudioPlayer
                                          src={chatMessage.chat_asset_url}
                                          controls={true}
                                          width="450px"
                                          height="450px"
                                          className="chat-room-audio-display"
                                          autoPlay={false}
                                          controlsList={"nodownload"}
                                        />
                                      ) : (
                                        // <ReactPlayer
                                        //   url={chatMessage.chat_asset_url}
                                        //   controls={true}
                                        //   width="450px"
                                        //   height="450px"
                                        //   className="chat-room-audio-display"
                                        // />
                                        ""
                                      )}
                                      <div>
                                        {chatMessage.chat_asset_url &&
                                          chatMessage.file_type == "image" ? (
                                          <ReactFancyBox
                                            image={chatMessage.chat_asset_url}
                                            className="chat-view-image"
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {chatMessage.is_user_needs_pay ===
                                          1 ? (
                                          <div className="gallery-top-btn-sec">
                                            <Button
                                              className="subscribe-post-btn-sec"
                                              onClick={(event) =>
                                                handleAssetPayment(
                                                  event,
                                                  chatMessage.chat_message_id,
                                                  chatMessage.amount,
                                                  chatMessage.amount_formatted
                                                )
                                              }
                                            >
                                              {chatMessage.payment_text}
                                            </Button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div> */}
                                      {chatMessage.message == "" ? null : (
                                        <>
                                          <p>{t("you")}, {chatMessage.created}</p>
                                          <h6 className="float-right">
                                            {chatMessage.message}
                                          </h6>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-left-align">
                                    <div className="message-user-img-sec">
                                      <Image
                                        src={
                                          props.chatMessages.data.user.picture
                                        }
                                        alt=""
                                        className="user-message-img"
                                      />
                                    </div>
                                    <div className="message-user-info">
                                      {/* {chatMessage.chat_asset_url &&
                                        chatMessage.file_type == "video" ? (
                                        chatMessage.is_user_needs_pay ===
                                          1 ? (
                                          <Image
                                            src={chatMessage.chat_asset_url}
                                            className="chat-view-image"
                                          ></Image>
                                        ) : (
                                          <ReactPlayer
                                            url={chatMessage.chat_asset_url}
                                            controls={true}
                                            className="post-video-size chat-room-video-display chat-video"
                                            width="450px"
                                            height="450px"
                                          />
                                        )
                                      ) : (
                                        ""
                                      )}
                                      <div>
                                        {chatMessage.chat_asset_url &&
                                          chatMessage.file_type == "image" ? (
                                          <Image
                                            src={chatMessage.chat_asset_url}
                                            className="chat-view-image"
                                          // onClick={(event) =>
                                          //   handleImagePreview(event, 1)
                                          // }
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {chatMessage.chat_asset_url &&
                                          chatMessage.file_type == "audio" ? (
                                          chatMessage.is_user_needs_pay ===
                                            1 ? (
                                            <Image
                                              src={chatMessage.chat_asset_url}
                                              className="chat-view-image"
                                            ></Image>
                                          ) : (
                                            <ReactAudioPlayer
                                              src={chatMessage.chat_asset_url}
                                              controls={true}
                                              width="450px"
                                              height="450px"
                                              className="chat-room-audio-display"
                                              autoPlay={false}
                                              controlsList={"nodownload"}
                                            />
                                            // <ReactPlayer
                                            //   url={chatMessage.chat_asset_url}
                                            //   controls={true}
                                            //   width="450px"
                                            //   height="450px"
                                            //   className="chat-room-audio-display"
                                            // />
                                          )
                                        ) : (
                                          ""
                                        )}

                                      </div> */}
                                      {chatMessage.message == "" ? null : (
                                        <>
                                          <p>
                                            {
                                              props.chatMessages.data.user
                                                .name
                                            }
                                            , {chatMessage.created}
                                          </p>
                                          <h6>{chatMessage.message}</h6>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}{" "}
                              </>
                            )
                          )}
                        </div>
                        : <NoDataFound />}
                      {/* <p className="typing-text">Jason Doyle is typing...</p> */}
                    </div>
                    <div className="chat-ui-main-wrapper-footer">
                      <Form
                        id="chat_post_form"
                        className="has-advanced-upload"
                        onSubmit={e => {
                          e.preventDefault();
                          handleChatSubmit({});
                        }}
                      >
                        <InputGroup className="mb-0">
                          <InputGroup.Text>
                            <div className="upload-btn-wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M6.892 17.153l-.864-.864 2.121-2.122.864.864a3.517 3.517 0 004.966 0l6.005-6.004a3.516 3.516 0 000-4.966c-1.195-1.195-3.042-1.378-4.403-.489a8.517 8.517 0 00-3.1-.58h-.002c-.233 0-.465.01-.694.028l1.112-1.112A6.491 6.491 0 0117.502.003c1.667 0 3.237.577 4.684 2.025 2.454 2.545 2.43 6.61-.08 9.12l-6.005 6.004c-1.27 1.27-2.937 1.904-4.604 1.904s-3.335-.635-4.604-1.904zm-5.066 4.822C3.355 23.504 4.845 24.001 6.512 24a6.486 6.486 0 004.602-1.904l1.112-1.112c-.23.018-.461.028-.694.028h-.002a8.496 8.496 0 01-3.1-.58c-1.36.889-3.208.705-4.403-.49a3.516 3.516 0 010-4.966l6.005-6.004a3.516 3.516 0 014.966 0l.864.864 2.121-2.122-.864-.864a6.519 6.519 0 00-9.208 0l-6.005 6.005c-2.51 2.51-2.534 6.575-.08 9.12z"></path>
                              </svg>
                              <input type="file" name="myfile" />
                            </div>
                          </InputGroup.Text>
                          <Form.Control
                            placeholder={t("type_a_message")}
                            aria-label="Type a message"
                            aria-describedby="basic-addon2"
                            value={inputMessage}
                            ref={messageField}
                            onChange={(event) => {
                              chatInputChange(event.currentTarget.value);
                            }}
                          />
                          <InputGroup.Text>
                            <Button
                              type="button"
                              className="btn-send"
                              onClick={handleChatSubmit}
                              ref={invalidMessageRef}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#151515"
                                  d="M23.445 11.117L2.387.574a.956.956 0 00-1.034.115.959.959 0 00-.316.958l2.537 9.365H14.4v1.917H3.574L.999 22.264a.96.96 0 00.957 1.208c.15 0 .297-.037.43-.105l21.06-10.544a.957.957 0 000-1.706z"
                                ></path>
                              </svg>
                            </Button>
                          </InputGroup.Text>
                          <Overlay
                            target={invalidMessageRef}
                            show={emptyMessageCheck}
                            placement="top"
                          >
                            {(props) => (
                              <Tooltip id="chat-invalid" {...props}>
                                {t("please_type_a_message")}
                              </Tooltip>
                            )}
                          </Overlay>
                        </InputGroup>
                      </Form>
                    </div>
                  </>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </Container>
        </div>
        <NewFooterIndex />
      </div >
    </>
  );
};

const mapStateToProps = (state) => ({
  chatUsers: state.chat.chatUsers,
  chatMessages: state.chat.chatMessages,
  assetUpload: state.chatAsset.saveAssetUpload,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ChatIndex));
