import React, { useState, useEffect } from "react";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Table,
  Image,
  Button,
  Media
} from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import {
  fetchCartListStart,
  removeCartDetailsStart
} from "../../../store/actions/ProductsAction";
import { connect } from "react-redux";
import SingleCartCard from "./SingleCartCard";
import NoDataFound from "../../Helper/NoDataFound";
import { withTranslation, useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import { confirmAlert } from 'react-confirm-alert';

const EcomCartIndex = (props) => {

  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    props.dispatch(fetchCartListStart());
  }, []);

  const removeCartConfirmation = () => {
    confirmAlert({
      message: t("are_you_sure_want_to_remove_from_cart"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => props.dispatch(removeCartDetailsStart())
        },
        {
          label: t("no"),
        }
      ]
    });
  }
  useEffect(() => {
    if (!skipRender &&
      !props.cartRemove.loading &&
      Object.keys(props.cartRemove.data).length > 0) {
      props.dispatch(fetchCartListStart());
    }
    setSkipRender(false);
  }, [props.cartRemove]);

  return (
    <>
      <div className="ecom-cart-sec">
        <Container>
          <div className="ecom-navbar">
            <ul className="list-unstyled ecom-nav-link">
              <Media as="li">
                {localStorage.getItem("is_content_creator") == 2 ? (
                  <Link to="/product-list">
                    <Image
                      className="add-product-img"
                      src={
                        window.location.origin +
                        "/images/product-list.svg"
                      }
                    />
                    <span>{t("products")}</span>
                  </Link>
                ) : null}
                <Link to="/order-list">
                  <Image
                    className="add-product-img"
                    src={
                      window.location.origin +
                      "/images/orders-list.svg"
                    }
                  />
                  <span>{t("orders")}</span>
                </Link>
                <Link to="/order-transaction">
                  <Image
                    className="add-product-img"
                    src={
                      window.location.origin +
                      "/images/transaction-list.svg"
                    }
                  />
                  <span>{t("ecom_transactions")}</span>
                </Link>
              </Media>
            </ul>
          </div>
          <Breadcrumb className="ecom-cart-header">
            <Breadcrumb.Item active>{t("shopping_cart")}</Breadcrumb.Item>
            <Breadcrumb.Item>
              {t("proceed_to_checkout")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("order_completed")}</Breadcrumb.Item>
          </Breadcrumb>
          {props.cartList.loading ?
            <Row>
              <Col md={9}>
                <div className="ecom-cart-table">
                  <Skeleton count={4} height={60} />
                </div>
              </Col>
              <Col md={3}>
                <div className="cart-summary-sec">
                  <Skeleton count={1} height={240} />
                </div>
              </Col>
            </Row>

            :
            (
              <Row>
                {props.cartList.data.carts.length > 0 ? (
                  <>
                    <Col md={9}>
                      <div className="ecom-cart-table">
                        <Table size="sm" className="ecom-cart-table">
                          <thead>
                            <tr>
                              <th class='text-center'>{t('product_image')}</th>
                              <th>{t("name")}</th>
                              <th>{t("Price")}</th>
                              <th>{t("quantity")}</th>
                              <th>{t("subtotal")}</th>
                              <th class='text-center'>{t("action_order")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {props.cartList.data.carts.map((cart, i) => (
                                <SingleCartCard cart={cart} i={i} />
                              ))}
                              <tr >
                                <td colspan="10">
                                  <div className="ecom-cart-action-btn-sec">
                                    <Link to="/"
                                      className="btn-button">
                                      {t("continue_shopping")}
                                    </Link>
                                    <Button type="submit" className="btn-button btn-update tiny"
                                      onClick={removeCartConfirmation}>
                                      {t("remove_all_cart")}
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="cart-summary-sec">
                        <h4>{t("price_details")}</h4>
                        <div className="cart-sub-total">
                          <h5>{t("subtotal")}</h5>
                          <p>{props.cartList.data.sub_total_formatted}</p>
                        </div>
                        <div className="estimate-shipping-tax-sec">
                          <div className="ecom-cart-total-amount">
                            <h5>{t("total")}</h5>
                            <h3>{props.cartList.data.total_formatted}</h3>
                          </div>
                          <div className="ecom-cart-checkout-btn-sec">
                            <Link type="submit" className="check-out-btn" to="/checkout">
                              {t("proceed_to_checkout")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                )
                  :
                  <Col md={12}>
                    <NoDataFound />
                  </Col>
                }
              </Row>
            )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.userProducts.cartList,
  cartRemove: state.userProducts.cartRemove,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(EcomCartIndex));
