import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    FETCH_DELIVERY_ADDRESS_START,
    SAVE_DELIVERY_ADDRESS_START,
    DELETE_DELIVERY_ADDRESS_START,
    SET_DEFAULT_DELIVERY_ADDRESS_START,
    VIEW_DELIVERY_ADDRESS_START,
} from '../actions/ActionConstant';
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    fetchDeliveryAddressSuccess,
    fetchDeliveryAddressFailure,
    saveDeliveryAddressSuccess,
    saveDeliveryAddressFailure,
    deleteDeliveryAddressSuccess,
    deleteDeliveryAddressFailure,
    setDefaultDeliveryAddressSuccess,
    setDefaultDeliveryAddressFailure,
    fetchDeliveryAddressStart,
    viewDeliveryAddressSuccess,
    viewDeliveryAddressFailure
} from '../actions/DeliveryAddressAction';

function* fetchDeliveryAddressAPI() {
    try {
        const response = yield api.postMethod("delivery_addresses_list");
        if (response.data.success) {
            yield put(fetchDeliveryAddressSuccess(response.data.data));
        } else {
            yield put(fetchDeliveryAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchDeliveryAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* saveDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_save", action.data);

        if (response.data.success) {
            yield put(saveDeliveryAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
            // window.location.assign("/delivery-address");
        } else {
            yield put(saveDeliveryAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(saveDeliveryAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* deleteDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_delete", action.data);

        if (response.data.success) {
            yield put(deleteDeliveryAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
            yield put(fetchDeliveryAddressStart());
        } else {
            yield put(deleteDeliveryAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deleteDeliveryAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* setDefaultDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_default", action.data);

        if (response.data.success) {
            yield put(setDefaultDeliveryAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
            yield put(fetchDeliveryAddressStart());
        } else {
            yield put(setDefaultDeliveryAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(setDefaultDeliveryAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* viewDeliveryAddressAPI(action) {
    try {
        const response = yield api.postMethod("delivery_addresses_view", action.data);
        if (response.data.success) {
            yield put(viewDeliveryAddressSuccess(response.data.data));
        } else {
            yield put(viewDeliveryAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchDeliveryAddressFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}


export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_DELIVERY_ADDRESS_START, fetchDeliveryAddressAPI),
        yield takeLatest(SAVE_DELIVERY_ADDRESS_START, saveDeliveryAddressAPI),
        yield takeLatest(DELETE_DELIVERY_ADDRESS_START, deleteDeliveryAddressAPI),
        yield takeLatest(SET_DEFAULT_DELIVERY_ADDRESS_START, setDefaultDeliveryAddressAPI),
        yield takeLatest(VIEW_DELIVERY_ADDRESS_START, viewDeliveryAddressAPI),
    ]);
}