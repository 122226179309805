import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import CookieConsent, { Cookies } from "react-cookie-consent";
import en from "../translations/en.json";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import BecomeASellerIndex from "../Seller/BecomeASellerIndex";
import BookmarIndex from "../Bookmark/BookmarIndex";
import AddBankIndex from "../BankAccounts/AddBankIndex";
import AddDeliveryAddress from "../DeliveryAddress/AddDeliveryAddress";
import OrderListIndex from "../OrderList/OrderListIndex";
import ProfileIndex from "../Profile/ProfileIndex";
import ChangePasswordIndex from "../Profile/ChangePassworIndex";
import StripeConnectIndex from "../Profile/StripeConnectIndex";
import DeactivateAccountIndex from "../Profile/DeactivateAccountIndex";
import LogoutIndex from "../Auth/LogoutIndex";
import VerificationIndex from "../Verification/VerificationIndex";
import BankAccount from "../BankAccounts/BankAccount";
import DeliveryAddressIndex from "../DeliveryAddress/DeliveryAddressIndex";
import VerfiyForgotPassword from "../Auth/VerfiyForgotPassword";
import ResetPassword from "../Auth/ResetPassword";
import EditBankAccount from "../BankAccounts/EditBankAccount";
import CategoryIndex from "../Category/CategoryIndex";
import OrderTransaction from "../OrderList/OrderTransaction";
import WalletIndex from "../Wallet/WalletIndex";
import UploadDocumentIndex from "../UploadDocument/UploadDocumentIndex";
import AddProduct from "../Product/AddProduct";
import EditProduct from "../Product/EditProduct";
import ProductsList from "../Product/ProductList";
import ProductVariants from "../Product/ProductVariants";
import SingleProductIndex from "../Product/SingleProductIndex";
import LiveVideosHistory from "../LiveStream/LiveVideosHistory";
import ScheduledLiveVideos from "../LiveStream/ScheduledLiveVideos";
import GoLiveIndex from "../LiveStream/GoLiveIndex";
import LiveVideoOrder from "../LiveStream/LiveVideoOrder";
import SingleProductOrders from "../Product/SingleProductOrders";
import OrderView from "../OrderList/OrderView";
import OnGoingLiveVideos from "../LandingPage/OnGoingLiveVideos";
import NewLoginIndex from "../OutFyt/Auth/NewLoginIndex";
import NewRegisterIndex from "../OutFyt/Auth/NewRegisterIndex";
import NewForgotPasswordIndex from "../OutFyt/Auth/NewForgotPasswordIndex";
import NewHomeIndex from "../OutFyt/Home/NewHomeIndex";
import NewCategoriesIndex from "../OutFyt/Categories/NewCategoriesIndex";
import AboutUsIndex from "../OutFyt/AboutUs/AboutUsIndex";
import NewSingleProductIndex from "../OutFyt/Product/NewSingleProductIndex";
import VideoCallLayout from "../layouts/VideoCallLayout";
import VideoCallIndex from "../VideoCall/VideoCallIndex";
import ChatIndex from "../OutFyt/Chat/ChatIndex";
import NewChatIndex from "../OutFyt/Chat/NewChatIndex";
import NewProfileIndex from "../OutFyt/NewProfile/NewProfileIndex";
import NewstaticIndex from "../OutFyt/Static/NewstaticIndex";
import NewSellerStep from "../OutFyt/SellerSteps/NewSellerStep";
import PaymentsIndex from "../Wallet/PaymentsIndex";
import Page404 from "../Helper/Page404";
import SecondSellerIndex from "../OutFyt/SecondSteps/SecondSellerIndex";
import NewVerifyForgotPasswordIndex from "../OutFyt/Auth/NewVerifyForgotPasswordIndex";
import NewResetPasswordIndex from "../OutFyt/Auth/NewResetPasswordIndex";
import MoblieIndex from "../Profile/MoblieIndex";
import UpcomingLiveStreams from "../LiveVideos/UpcomingLiveStreams";
import LatestLiveVideos from "../LiveVideos/LatestLiveVideos";
import CategoriesFollow from "../LiveVideos/CategoriesFollow";
import CategoryVideos from "../LiveVideos/CategoryVideos";
import BookmarkIndex from "../LiveVideos/BookmarkIndex";
import EditDeliveryAddress from "../DeliveryAddress/EditDeliveryAddress";
import EcomCartIndex from "../OutFyt/Product/EcomCartIndex";
import EcomPaymentIndex from "../OutFyt/Product/EcomPaymentIndex";
import EditLiveVideoIndex from "../LiveStream/EditLiveVideoIndex";
import BrandIndex from "../OutFyt/Home/BrandIndex";
import AccessoriesIndex from "../OutFyt/Home/AccessoriesIndex";

setTranslations({ en });

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {

  setLanguage("en");

  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <CookieConsent
        // disableStyles={true}
        location="bottom"
        // buttonClasses="btn btn-primary"
        containerClasses="col-lg-9"
        // contentClasses="text-capitalize"
        buttonText="Okay"
        cookieName="cookiesAccept"
        style={{ background: "#000", color: "#fff" }}
        expires={1500}
      >
        We use cookies to help personalize content, tailor and measure ads, and
        provide a safer experience. By navigating the site, you agree to the use of
        cookies to collect information. Read our{" "}
        <a className="btn-cookie-policy" href="/page/privacy" target="_blank">
          {" "}
          Cookie Policy{" "}
        </a>{" "}
        to learn more.
      </CookieConsent>

      <ToastContainer />
      {/* <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet> */}
      <BrowserRouter>
        <Routes>
          <Route
            path={"/live-stream/:live_video_unique_id"}
            element={
              <AppRoute component={VideoCallIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/become-a-seller"}
            element={
              <AppRoute component={BecomeASellerIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/bookmarks"}
            element={<AppRoute component={BookmarIndex} layout={MainLayout} />}
          />
          <Route
            path={"/order-list"}
            element={
              <AppRoute component={OrderListIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/profile"}
            element={<PrivateRoute component={ProfileIndex} layout={MainLayout} />}
          />
          {/* <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={EmptyLayout} />}
          /> */}
          {/* <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={EmptyLayout} />}
          /> */}
          <Route
            path={"/change-password"}
            element={<PrivateRoute component={ChangePasswordIndex} layout={MainLayout} />}
          />

          <Route
            path={"/stripe-connect"}
            element={<PrivateRoute component={StripeConnectIndex} layout={MainLayout} />}
          />

          <Route
            path={"/deactivate-account"}
            element={<PrivateRoute component={DeactivateAccountIndex} layout={MainLayout} />}
          />
          <Route
            path={"/logout"}
            element={<PrivateRoute component={LogoutIndex} layout={MainLayout} />}
          />
          <Route
            path={"/register/verify"}
            element={<AppRoute component={VerificationIndex} layout={MainLayout} />}
          />
          {/* <Route
            path={"/forgot-password"}
            element={<AppRoute component={ForgotPasswordIndex} layout={MainLayout} />}
          /> */}
          <Route
            path={"/verify-forget-password"}
            element={<AppRoute component={VerfiyForgotPassword} layout={EmptyLayout} />}
          />
          <Route
            path={"/reset-password"}
            element={<AppRoute component={ResetPassword} layout={EmptyLayout} />}
          />
          <Route
            path="/add-bank-account"
            element={<PrivateRoute component={AddBankIndex} layout={MainLayout} />}
          />
          <Route
            path="/bank-accounts"
            element={<PrivateRoute component={BankAccount} layout={MainLayout} />}
          />
          <Route
            path="/add-delivery-address"
            element={<PrivateRoute component={AddDeliveryAddress} layout={MainLayout} />}
          />
          <Route
            path="/delivery-address"
            element={<PrivateRoute component={DeliveryAddressIndex} layout={MainLayout} />}
          />
          <Route
            path="/edit-bank-account/:user_billing_account_id"
            element={<PrivateRoute component={EditBankAccount} layout={MainLayout} />}
          />
          {/* <Route
            path="/:user_unique_id"
            element={<AppRoute component={OtherProfile} layout={MainLayout} />}
          /> */}
          <Route
            path="/category/:category_id/:sub_category_id"
            element={<AppRoute component={CategoryIndex} layout={MainLayout} />}
          />
          <Route
            path={"/order-transaction"}
            element={
              <PrivateRoute component={OrderTransaction} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet"}
            element={
              <PrivateRoute component={WalletIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/add-document"}
            element={
              <AppRoute component={UploadDocumentIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/add-product"
            element={<PrivateRoute component={AddProduct} layout={MainLayout} />}
          />
          <Route
            path="/product-list"
            element={<PrivateRoute component={ProductsList} layout={MainLayout} />}
          />
          <Route
            path="/edit-product/:user_product_id"
            element={<PrivateRoute component={EditProduct} layout={MainLayout} />}
          />

          <Route
            path="/product-variants/:user_product_id"
            element={<PrivateRoute component={ProductVariants} layout={MainLayout} />}
          />

          <Route
            path={"/single-product-old/:product_unique_id"}
            element={
              <PrivateRoute component={SingleProductIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/view-order/:unique_id/:user_product_id"}
            element={
              <PrivateRoute component={SingleProductOrders} layout={MainLayout} />
            }
          />

          <Route
            path={"/scheduled-live-videos"}
            element={
              <PrivateRoute component={ScheduledLiveVideos} layout={MainLayout} />
            }
          />

          <Route
            path={"/live-videos-history"}
            element={
              <PrivateRoute component={LiveVideosHistory} layout={MainLayout} />
            }
          />
          <Route
            path={"/go-live"}
            element={
              <PrivateRoute component={GoLiveIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/edit-scheduled-live-videos/:live_video_unique_id"
            element={<PrivateRoute component={EditLiveVideoIndex} layout={MainLayout} />}
          />
          <Route
            path={"/live-orders/:live_video_id"}
            element={
              <PrivateRoute component={LiveVideoOrder} layout={MainLayout} />
            }
          />
          <Route
            path={"/order-view/:unique_id"}
            element={
              <PrivateRoute component={OrderView} layout={MainLayout} />
            }
          />
          <Route
            path={"/order-transaction"}
            element={
              <PrivateRoute component={OrderTransaction} layout={MainLayout} />
            }
          />
          <Route
            path={"/ongoing-live-shows"}
            element={
              <PrivateRoute component={OnGoingLiveVideos} layout={MainLayout} />
            }
          />
          {/* <Route
            path={"/category-videos/:categoryid/:category_id"}
            element={
              <PrivateRoute component={CategoryVideos} layout={MainLayout} />
            }
          /> */}
          <Route
            path={"/moblie-Profile-Index"}
            element={<PrivateRoute component={MoblieIndex} layout={MainLayout} />}
          />

          {/* OUTFYT  */}

          <Route
            path="/login"
            element={<AppRoute component={NewLoginIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/register"
            element={<AppRoute component={NewRegisterIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/forgot-password"
            element={<AppRoute component={NewForgotPasswordIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/outfyt-verify-forgot-password"
            element={<AppRoute component={NewVerifyForgotPasswordIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/outfyt-reset-password"
            element={<AppRoute component={NewResetPasswordIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/"
            element={<AppRoute component={NewHomeIndex} layout={MainLayout} />}
          />
          <Route
            path="/brand"
            element={<AppRoute component={BrandIndex} layout={MainLayout} />}
          />
          <Route
            path="/categories"
            element={<AppRoute component={NewCategoriesIndex} layout={MainLayout} />}
          />
          <Route
            path="/upcoming-livestreams"
            element={<AppRoute component={UpcomingLiveStreams} layout={MainLayout} />}
          />
          <Route
            path="/latest-live-videos"
            element={<AppRoute component={LatestLiveVideos} layout={MainLayout} />}
          />
          <Route
            path="/categories-follow"
            element={<AppRoute component={CategoriesFollow} layout={MainLayout} />}
          />
          <Route
            path={"/category-videos/:category_id?/:sub_category_id?"}
            element={<AppRoute component={CategoryVideos} layout={MainLayout} />}
          />
          <Route
            path="/bookmark-lists"
            element={<AppRoute component={BookmarkIndex} layout={MainLayout} />}
          />
          <Route
            path="/about-us"
            element={<AppRoute component={AboutUsIndex} layout={EmptyLayout} />}
          />
          <Route
            path="/single-product/:product_unique_id"
            element={<AppRoute component={NewSingleProductIndex} layout={MainLayout} />}
          />
          <Route
            path="/ecom-cart"
            element={<AppRoute component={EcomCartIndex} layout={MainLayout} />}
          />

          <Route
            path="/checkout"
            element={<AppRoute component={EcomPaymentIndex} layout={MainLayout} />}
          />

          <Route
            path="/old-chat"
            element={<PrivateRoute component={ChatIndex} layout={EmptyLayout} />}
          />

          <Route
            path="/chat"
            element={<PrivateRoute component={NewChatIndex} layout={MainLayout} />}
          />

          <Route
            path={"/page/:unique_id"}
            element={<AppRoute component={NewstaticIndex} layout={MainLayout} />}
          />
          <Route
            path="/:user_unique_id"
            element={<PrivateRoute component={NewProfileIndex} layout={MainLayout} />}
          />
          <Route
            path="/new-seller"
            element={<AppRoute component={NewSellerStep} layout={MainLayout} />}
          />
          <Route
            path="/second-seller"
            element={<AppRoute component={SecondSellerIndex} layout={MainLayout} />}
          />
          <Route
            path={"/payments"}
            element={
              <PrivateRoute component={PaymentsIndex} layout={MainLayout} />
            }
          />
          <Route
            path="/edit-delivery-address/:delivery_address_id"
            element={<PrivateRoute component={EditDeliveryAddress} layout={MainLayout} />}
          />
          <Route
            path="/explore-products"
            element={<AppRoute component={AccessoriesIndex} layout={MainLayout} />}
          />
          {/* <Route
            path="/ecom"
            element={<PrivateRoute component={EcomIndex} layout={MainLayout} />}
          /> */}
          <Route
            path={"/*"}
            element={
              <AppRoute component={Page404} layout={MainLayout} />
            }
          />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
