import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  recentProductsStart,
  recentMoreProductsStart
} from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const AccessoriesIndex = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(recentProductsStart({
      skip: 0,
      take: 12
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(recentMoreProductsStart({
      skip: props.recentProducts.data.recent_products.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="accessories-home-sec mt-4">
        <Container>
          {props.recentProducts.loading ?
            <LiveVideoLoader />
            :
            <>
              {Object.keys(props.recentProducts.data).length > 0 &&
                props.recentProducts.data.recent_products.length > 0 ?
                <>
                  <div className="new-home-title-sec">
                    <h2>{t("explore_products")}</h2>
                  </div>
                  <InfiniteScroll
                    dataLength={props.recentProducts.data.recent_products.length}
                    next={fetchMoreData}
                    hasMore={
                      props.recentProducts.data.recent_products.length <
                      props.recentProducts.data.total}
                    loader={<LiveVideoLoader />}
                  >
                    <div className="accessories-box-products">
                      {props.recentProducts.data.recent_products.map(
                        (product) => (
                          <Link to={localStorage.getItem("userId") && localStorage.getItem("accessToken") ? `/single-product/${product.unique_id}` : '/login'}>
                            <div className="accessories-card">
                              <div className="accessories-img-sec">
                                {product.file_type == "image" ?
                                  <Image
                                    src={product.picture}
                                    className="accessories-img"
                                  />
                                  :
                                  <video
                                    autoplay
                                    controls
                                    id="myVideo"
                                    className="user-product w-100"
                                  >
                                    <source src={product.picture} type="video/mp4" />
                                  </video>
                                }
                              </div>
                              <div className="new-home-latest-live-streaming-info">
                                <div className="category-card-profile">
                                  <Link to={`/${product.user.user_unique_id}`}>
                                    <Image src=
                                      {product.user_picture}
                                      className="cat-pro-pic"
                                    />
                                    <h5>{product.user.name}</h5>
                                  </Link>
                                </div>
                              </div>
                              <div className="accessories-info">
                                <h6>{product.name}</h6>
                                <h4>{product.category_name}</h4>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </InfiniteScroll>
                </>
                :
                ''}
            </>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  recentProducts: state.homepage.recentProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(AccessoriesIndex));

