import React from 'react'
import { Col, Container, Form, Table, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  getBankAccountStart,
  makeDefaultBankAccountStart,
  deleteBankAccountStart,
} from "../../store/actions/BankAccountAction";
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../Helper/NoDataFound';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const BankAccount = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(getBankAccountStart());
  }, []);

  const makeDefault = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };

  const deleteAccount = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      deleteBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };

  const makeDefaultConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_make_this_as_default"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => makeDefault(event, user_billing_account_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  const deleteConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: t("are_you_sure_Want_to_delete_this_account"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteAccount(event, user_billing_account_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };

  return (
    <Container>
      <div className="bank-account-list-card">
        <Row>
          <Col md={12}>
            <div className="bank-account-list-btn">
              <Link to="/add-bank-account" className="add-new-account-btn">
                {t("add_new_account")}
              </Link>
            </div>
            {props.bankAccount.loading ?
              <Skeleton count={4} height={50} />
              : props.bankAccount.data.user_billing_accounts.length > 0 ?
                <Table responsive className="bank-account-list-table">
                  <thead>
                    <tr>
                      <th>{t("first_name")} </th>
                      <th>{t("last_name")}</th>
                      <th>{t("account_number")}</th>
                      <th>{t("routing_numer")}</th>
                      <th>{t("bank_type")}</th>
                      <th>{t("business_name")}</th>
                      < th > {t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.bankAccount.data.user_billing_accounts.map(
                      (account) =>
                        <tr>
                          <td>{account.first_name}</td>
                          <td> {account.last_name}</td>
                          <td> {account.account_number}</td>
                          <td>{account.route_number} </td>
                          <td> {account.bank_type}</td>
                          <td> {account.business_name !== null ? account.business_name : t("n_a")}</td>
                          <td>
                            <div className="btn-flex">
                              <Link 
                              to={`/edit-bank-account/` + account.user_billing_account_id} 
                              className="btn btn-info edit"> {t("edit")} </Link>
                              {account.is_default === 0 ?
                                <Button
                                  type="submit"
                                  className="btn btn-warning default"
                                  onClick={(event) => {
                                    makeDefaultConfirmation(event,
                                      account.user_billing_account_id)
                                  }}
                                >
                                  {t("make_as_default")}
                                </Button>
                                : null
                              }
                              <Button
                                type="submit"
                                className='btn btn-danger delete-account'
                                onClick={(event) => {
                                  deleteConfirmation(event,
                                    account.user_billing_account_id)
                                }}
                              >
                                {t("delete")}
                              </Button>
                            </div>
                          </td>
                        </tr>
                    )}
                  </tbody>
                </Table>
                :
                <NoDataFound />
            }
          </Col>
        </Row>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(BankAccount));
