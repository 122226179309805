import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategoriesListStart } from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../../Helper/NoDataFound";
import CategorysLoader from "../../Helper/CategorysLoader";
import { useParams } from 'react-router-dom';

const Categories = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  return (
    <>
      {props.categoriesList.loading ?
        <CategorysLoader />
        :
        Object.keys(props.categoriesList.data).length > 0 &&
          props.categoriesList.data.categories.length > 0 ?
          <div className="new-explore-category-sec">
            <Container>
              <div className="new-home-title-sec">
              <h2>{t("explore_categories")}</h2>
              </div>
              <div className="new-explore-category-box">
                {props.categoriesList.data.categories.map(
                  (category) => (
                    <div className="new-explore-category-card">
                      <Link className="expo-btn"
                        to={`/category-videos/?category_id=${category.unique_id}`}>
                        {category.name}
                      </Link>
                    </div>
                  ))}
              </div>
            </Container>
          </div>
          :
          ''
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  categoriesList: state.homepage.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(Categories));



