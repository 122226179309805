import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    FETCH_PICKUP_ADDRESS_START,
    SAVE_PICKUP_ADDRESS_START,
    DELETE_PICKUP_ADDRESS_START,
    SET_DEFAULT_PICKUP_ADDRESS_START,
    VIEW_PICKUP_ADDRESS_START,
} from '../actions/ActionConstant';
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    fetchPickupAddressSuccess,
    fetchPickupAddressFailure,
    savePickupAddressSuccess,
    savePickupAddressFailure,
    deletePickupAddressSuccess,
    deletePickupAddressFailure,
    setDefaultPickupAddressSuccess,
    setDefaultPickupAddressFailure,
    fetchPickupAddressStart,
    viewPickupAddressSuccess,
    viewPickupAddressFailure,
} from '../actions/PickupAddressAction';

import { fetchUserDetailsStart } from "../actions/UserAction";

function* fetchPickupAddressAPI() {
    try {
        const response = yield api.postMethod("pickup_addresses_list");
        if (response.data.success) {
            yield put(fetchPickupAddressSuccess(response.data.data));
        } else {
            yield put(fetchPickupAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchPickupAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* savePickupAddressAPI(action) {
    try {
        const response = yield api.postMethod("pickup_addresses_save", action.data);

        if (response.data.success) {
            yield put(savePickupAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(fetchUserDetailsStart());
        } else {
            yield put(savePickupAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(savePickupAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* deletePickupAddressAPI(action) {
    try {
        const response = yield api.postMethod("pickup_addresses_delete", action.data);

        if (response.data.success) {
            yield put(deletePickupAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
            yield put(fetchPickupAddressStart());
        } else {
            yield put(deletePickupAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deletePickupAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* setDefaultPickupAddressAPI(action) {
    try {
        const response = yield api.postMethod("pickup_addresses_make_default", action.data);

        if (response.data.success) {
            yield put(setDefaultPickupAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
            yield put(fetchPickupAddressStart());
        } else {
            yield put(setDefaultPickupAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(setDefaultPickupAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}
function* viewPickupAddressAPI(action) {
    try {
        const response = yield api.postMethod("pickup_addresses_view", action.data);

        if (response.data.success) {
            yield put(viewPickupAddressSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.error);
        } else {
            yield put(viewPickupAddressFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(viewPickupAddressFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}


export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_PICKUP_ADDRESS_START, fetchPickupAddressAPI),
        yield takeLatest(SAVE_PICKUP_ADDRESS_START, savePickupAddressAPI),
        yield takeLatest(DELETE_PICKUP_ADDRESS_START, deletePickupAddressAPI),
        yield takeLatest(SET_DEFAULT_PICKUP_ADDRESS_START, setDefaultPickupAddressAPI),
        yield takeLatest(VIEW_PICKUP_ADDRESS_START, viewPickupAddressAPI),
    ]);
}