import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga"
import PageSaga from './PageSaga';
import BankAccountSaga from "./BankAccountSaga";
import HomePageSaga from "./HomePageSaga";
import LiveVideoSaga from "./LiveVideoSaga";
import OrderSaga from "./OrderSaga";
import ProductSaga from "./ProductsSaga";
import WalletSaga from "./WalletSaga";
import TransactionSaga from "./TransactionSaga";
import LookUpSaga from "./LookUpSaga"
import OtherUserSaga from "./OtherUserSaga"
import DeliveryAddressSaga from "./DeliveryAddressSaga"
import PickupAddressSaga from "./PickupAddressSaga"
import ChatSaga from "./ChatSaga";
import ChatAssetSaga from "./ChatAssetSaga"
import FollowSaga from "./FollowSaga"

export default function* rootSaga() {
    yield all([
        fork(UserSaga),
        fork(PageSaga),
        fork(BankAccountSaga),
        fork(HomePageSaga),
        fork(LiveVideoSaga),
        fork(OrderSaga),
        fork(ProductSaga),
        fork(WalletSaga),
        fork(TransactionSaga),
        fork(LookUpSaga),
        fork(OtherUserSaga),
        fork(DeliveryAddressSaga),
        fork(PickupAddressSaga),
        fork(ChatSaga),
        fork(ChatAssetSaga),
        fork(FollowSaga)
    ]);
}






