import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button, Form, Accordion, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";

const AllMostDone = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [step, setStep] = useState(2);
  const t = useTranslation();

  useEffect(() => {
    setStep(2);
  }, []);
  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={7}>
          <div className="categories-new-outlyt-sec">
            <div className="text-center">
              <h3>{t("almost_done")}</h3>
              <p>{t("congrats_para")}</p>
            </div>
            <div className="btn-outfyt-section-seller veryt-set">
              <Link to="/second-seller"
               className="outfyt-btn text-uppercase start-btn">
              {t("start_application")}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation(AllMostDone);