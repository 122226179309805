import {
  FETCH_DELIVERY_ADDRESS_START,
  FETCH_DELIVERY_ADDRESS_SUCCESS,
  FETCH_DELIVERY_ADDRESS_FAILURE,
  SAVE_DELIVERY_ADDRESS_START,
  SAVE_DELIVERY_ADDRESS_SUCCESS,
  SAVE_DELIVERY_ADDRESS_FAILURE,
  DELETE_DELIVERY_ADDRESS_START,
  DELETE_DELIVERY_ADDRESS_SUCCESS,
  DELETE_DELIVERY_ADDRESS_FAILURE,
  SET_DEFAULT_DELIVERY_ADDRESS_START,
  SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS,
  SET_DEFAULT_DELIVERY_ADDRESS_FAILURE,
  VIEW_DELIVERY_ADDRESS_START,
  VIEW_DELIVERY_ADDRESS_SUCCESS,
  VIEW_DELIVERY_ADDRESS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  deliveryAddress: {
    data: {},
    loading: true,
    error: false,
  },
  deliveryAddressSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  deliveryAddressDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false,
  },
  defaultDeliveryAddress: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
  },
  viewDeliveryAddress: {
    data: {},
    loading: true,
    error: false,
  },
};

const DeliveryAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_ADDRESS_START:
      return {
        ...state,
        deliveryAddress: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddress: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        deliveryAddress: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SAVE_DELIVERY_ADDRESS_START:
      return {
        ...state,
        deliveryAddressSave: {
          data: {},
          loading: true,
          error: false,
					loadingButtonContent: "Loading",
    			buttonDisable: true,
        },
      };
    case SAVE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddressSave: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case SAVE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        deliveryAddressSave: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
    case DELETE_DELIVERY_ADDRESS_START:
      return {
        ...state,
        deliveryAddressDelete: {
          data: {},
          loading: true,
					loadingButtonContent: "Loading",
          error: false,
        },
      };
    case DELETE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        deliveryAddressDelete: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case DELETE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        deliveryAddressDelete: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
    case SET_DEFAULT_DELIVERY_ADDRESS_START:
      return {
        ...state,
        defaultDeliveryAddress: {
          data: {},
          loading: true,
					loadingButtonContent: "Loading",
          error: false,
        },
      };
    case SET_DEFAULT_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        defaultDeliveryAddress: {
          data: action.data,
          loading: false,
					loadingButtonContent: "",
          error: false,
        },
      };
    case SET_DEFAULT_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        defaultDeliveryAddress: {
          data: {},
          loading: false,
					loadingButtonContent: "",
          error: action.error,
        },
      };
      case VIEW_DELIVERY_ADDRESS_START:
        return {
            ...state,
            viewDeliveryAddress: {
                data: {},
                loading: true,
                error: false,
            },
        };
    case VIEW_DELIVERY_ADDRESS_SUCCESS:
        return {
            ...state,
            viewDeliveryAddress: {
                data: action.data,
                loading: false,
                error: false,
            },
        };
    case VIEW_DELIVERY_ADDRESS_FAILURE:
        return {
            ...state,
            viewDeliveryAddress: {
                data: {},
                loading: true,
                error: action.error,
            },
        };
    default:
      return state;
  }
};

export default DeliveryAddressReducer;
