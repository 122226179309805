import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Tabs,
  Table,
  Tab,
  Image,
  Button,
} from "react-bootstrap";
import "./PaymentsIndex.css";
import { fetchAllTransactionStart, fetchMoreAllTransactionStart } from "../../store/actions/TransactionAction";
import { fetchWithDrawalsStart, fetchMoreWithdrawalsStart } from "../../store/actions/WalletAction";
import WithdrawModal from "../Helper/WithdrawModal";
import CancelWithdrawModal from "../Helper/CancelWithdrawModal";
import { fetchPaymentsStart } from "../../store/actions/WalletAction";
import NoDataFound from "../Helper/NoDataFound";
import { withTranslation, useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const PaymentsIndex = (props) => {
  const t = useTranslation("");
  const [withdrawModal, setWithdrawModal] = useState(false);

  useEffect(() => {
    props.dispatch(fetchAllTransactionStart({
      skip: 0,
      take: 12,
    }));
    props.dispatch(fetchWithDrawalsStart({
      skip: 0,
      take: 12,
    }));
    props.dispatch(fetchPaymentsStart());
  }, []);

  const fetchMoreTransactions = () => {
    props.dispatch(
      fetchMoreAllTransactionStart({
        skip: props.transaction.data.history.length,
        take: 12,
      })
    );
  };

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreWithdrawalsStart({
        skip: props.withDrawals.data.history.length,
        take: 12,
      })
    );
  }

  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };

  const [data, setData] = useState("");
  const [cancelWithdrawModal, setCancelWithdrawModal] = useState(false);
  const closeCancelWithdrawModal = () => {
    setCancelWithdrawModal(false);
    setIsLoading(false);
  };

  const showCancelWithdrawModel = (data) => {
    setCancelWithdrawModal(true);
    setData(data);
    setIsLoading(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="payment-sec">
      <Container>
        <Row>
          <Col sm={12} md={12} xl={8}>
            <Link
              className="bookmarkes-list notify-title back-button"
              to={"/profile"}
            >
              <img
                src={window.location.origin + "/images/back.svg"}
                className="svg-clone"
              />
              <h3 className="ml-2 mb-0 text-uppercase payment-heading">{t("payments")}</h3>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="payment-tabs-card">
              <div className="flex-content">

                <Button
                  className="send-withdraw-btn"
                  onClick={() => setWithdrawModal(true)}
                >
                  {t("send_withdraw")}
                </Button>
              </div>
              <div>
                <Tabs defaultActiveKey="earnings" id="uncontrolled-tab-example">
                  <Tab eventKey="earnings"
                    title={t("transactions")}
                  >
                    <div className="payment-tabs-content">
                      {props.transaction.loading ? (
                        <Skeleton count={4} height={60} />
                      ) : Object.keys(props.transaction.data).length > 0 &&
                        props.transaction.data.history.length > 0 ? (
                        <InfiniteScroll
                          dataLength={props.transaction.data.history.length}
                          next={fetchMoreTransactions}
                          hasMore={
                            props.transaction.data.history.length <
                            props.transaction.data.total
                          }
                          loader={<Skeleton count={4} height={100} />}
                        >
                          <Table borderedless responsive>
                            <thead>
                              <tr className="bg-white">
                                <th>{t("date")}</th>
                                <th>{t("transaction_id")}</th>
                                <th>{t("mode")}</th>
                                <th className="text-capitalize">{t("message")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("service_fee")}</th>
                                <th>{t("status")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.transaction.data.history.map((history) => (
                                <tr>
                                  <td>{history.paid_date}</td>
                                  <td>{history.payment_id}</td>
                                  <td>{history.payment_mode}</td>
                                  <td>{history.message}</td>
                                  <td>{history.paid_amount_formatted}</td>
                                  <td>{history.admin_amount_formatted}</td>
                                  <td>
                                    <p>
                                      <i className="far fa-check-circle mr-2"></i>
                                      {history.status_formatted}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </Tab>

                  <Tab eventKey="payments" title="Withdraws">
                    <div className="payment-tabs-content">
                      {props.withDrawals.loading ? (
                        <Skeleton count={4} height={60} />
                      ) :
                        Object.keys(props.withDrawals.data).length > 0 &&
                          props.withDrawals.data.history.length > 0 ? (
                          <InfiniteScroll
                            dataLength={props.withDrawals.data.history.length}
                            next={fetchMoreData}
                            hasMore={
                              props.withDrawals.data.history.length <
                              props.withDrawals.data.total
                            }
                            loader={<Skeleton count={4} height={100} />}
                          >
                            <Table borderedless responsive>
                              <thead>
                                <tr className="bg-white">
                                  <th>{t("date")}</th>
                                  <th>{t("transaction_id")}</th>
                                  <th>{t("billing_account")}</th>
                                  <th className="text-capitalize">{t("requested")}</th>
                                  <th className="text-capitalize">{t("paid")}</th>
                                  <th>{t("status")}</th>
                                  <th className="text-capitalize">{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.withDrawals.data.history.map(
                                  (withDrawRequest) => (
                                    <tr>
                                      <td>{withDrawRequest.created}</td>
                                      <td>
                                        {withDrawRequest.user_withdrawal_unique_id}
                                      </td>
                                      <td>{withDrawRequest.billing_account_name}</td>
                                      <td>
                                        {withDrawRequest.requested_amount_formatted}
                                      </td>
                                      <td>{withDrawRequest.paid_amount_formatted}</td>
                                      <td>{withDrawRequest.status_formatted}</td>
                                      <td>
                                        {withDrawRequest.cancel_btn_status == 1 ? (
                                          <Button
                                            onClick={() => showCancelWithdrawModel(withDrawRequest)}
                                            className="cancel-btn"
                                          >
                                            {t("cancel")}
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </InfiniteScroll>
                        ) : (
                          <NoDataFound />
                        )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {withdrawModal ?
        <WithdrawModal
          closeWithdrawModal={closeWithdrawModal}
          payments={props.payments}
        />
        :
        null
      }
      {cancelWithdrawModal ?
        <CancelWithdrawModal
          closeCancelWithdrawModal={closeCancelWithdrawModal}
          data={data}
          cancelData={isLoading}
        />
        :
        null
      }
    </div>
  )
}

const mapStateToPros = (state) => ({
  withDrawals: state.wallet.withDrawals,
  transaction: state.transaction.allTransaction,
  payments: state.wallet.payments,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(PaymentsIndex));
