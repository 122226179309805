import React, { useState, useEffect } from "react";
import { Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { updateUserDetailsStart } from "../../store/actions/UserAction";
import Skeleton from "react-loading-skeleton";
import { Accordion } from 'react-bootstrap-accordion';

const ProfileSidebar = (props) => {
  const t = useTranslation();

  const [coverImgPreview, setCoverImgPreview] = useState(null);
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);

  const profileImgAdd = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    props.dispatch(
      updateUserDetailsStart({
        picture: e.target.files[0],
      })
    );
  };
  const coverUpdateImg = (e) => {
    setCoverImgPreview(URL.createObjectURL(e.target.files[0]));
    props.dispatch(
      updateUserDetailsStart({
        cover: e.target.files[0],
      })
    );
  };

  return (
    <>
      <div className="home-page-left-sec profile-sidebar">
        <div className="profile-sidebar-sec">
          {props.profile.loading ? (
            <>
              <Skeleton count={1} height={130} />
              <div className="profile-sidebar-image">
                <div className="profile-img-input">
                  <Skeleton circle={true} width={80} height={80} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="profile-sidebar-banner-img">
                <Form.Group controlId="formFiles">
                  <Form.Label className="sidebar-cover-img">
                    <CustomLazyLoad
                      src={
                        coverImgPreview
                          ? coverImgPreview
                          : props.profile.data.cover
                      }
                      className="cover-select-img"
                      placeholderSrc={
                        window.location.origin + "/images/loading.svg"
                      }
                    />
                    <div className="input-edit-banner-img">
                      <Image
                        src={
                          window.location.origin + "/images/profile/edit.svg"
                        }
                        type="image/png"
                        className="edit-img"
                      />
                    </div>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    name="cover"
                    className="image-input"
                    onChange={(e) => coverUpdateImg(e)}
                  />
                </Form.Group>
              </div>
              <div className="profile-sidebar-image">
                <div className="profile-img-input">
                  <Form.Group controlId="formFile">
                    <Form.Label>
                      <CustomLazyLoad
                        className="profile-select-img"
                        src={
                          imgPreviewAdd
                            ? imgPreviewAdd
                            : props.profile.data.picture
                        }
                        placeholderSrc={
                          window.location.origin + "/images/loading.svg"
                        }
                      />
                      <div className="input-edit-img">
                        <Image
                          src={
                            window.location.origin + "/images/profile/edit.svg"
                          }
                          type="image/png"
                        />
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      name="picture"
                      className="image-input"
                      onChange={(e) => profileImgAdd(e)}
                    />
                  </Form.Group>
                </div>

                <h3>{props.profile.data.name}</h3>
              </div>
            </>
          )}
        </div>
        <div className="category-list-accordion">
          <Accordion title="Account Management">
            <div className="sidebar-footer-list">
              <ul className="list-unstyled">
                <li>
                  <Link to="/profile">
                    <Image
                      src={
                        window.location.origin + "/images/profile/user.svg"
                      }
                    />
                    <span>{t("edit_profile")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/change-password">
                    <Image
                      src={
                        window.location.origin + "/images/profile/lock.svg"
                      }
                    />
                    <span>{t("change_password")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/bookmark-lists">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/bookmarks.svg"
                      }
                    />
                    <span>{t("bookmarks")}</span>
                  </Link>
                </li>

                {!props.profile.loading && props.profile.data.is_content_creator === 2 ?
                  <>
                    <li>
                      <Link to="/stripe-connect">
                        <Image
                          src={
                            window.location.origin +
                            "/images/profile/wallet.svg"
                          }
                        />
                        <span>{t("stripe_connect")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/live-videos-history">
                        <Image
                          src={
                            window.location.origin +
                            "/images/profile/live videos history.svg"
                          }
                        />
                        <span>{t("live_videos_history")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/product-list">
                        <Image
                          src={
                            window.location.origin +
                            "/images/profile/product list.svg"
                          }
                        />
                        <span>{t("product_list")}</span>
                      </Link>
                    </li>
                  </> : ''}
                <li>
                  <Link to="/wallet">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/wallet.svg"
                      }
                    />
                    <span>{t("wallet")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/order-list">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/My orders.svg"
                      }
                    />
                    <span>{t("my_orders")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/payments">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/payments.svg"
                      }
                    />
                    <span>{t("payments")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/deactivate-account">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/delete-user.svg"
                      }
                    />
                    <span>{t("deactivate_account")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </Accordion>
          <Accordion title="Bank Details">
            <div className="sidebar-footer-list accordion-border">
              <ul className="list-unstyled">
                <li>
                  <Link to="/add-bank-account">
                    <Image
                      src={
                        window.location.origin + "/images/profile/user.svg"
                      }
                    />
                    <span>{t("add_bank")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/bank-accounts">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/bank-accounts.svg"
                      }
                    />
                    <span>{t("bank_account_lists")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </Accordion>
          <Accordion title="Delivery Address">
            <div className="sidebar-footer-list accordion-border">
              <ul className="list-unstyled">
                <li>
                  <Link to="/add-delivery-address">
                    <Image
                      src={
                        window.location.origin + "/images/profile/add-address.svg"
                      }
                    />
                    <span>{t("add_delivery_address")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/delivery-address">
                    <Image
                      src={
                        window.location.origin +
                        "/images/profile/delivery-address.svg"
                      }
                    />
                    <span>{t("delivery_address")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </Accordion>
        </div>
        {/* <StaticSidebar /> */}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ProfileSidebar));
