import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchSubCategoriesStart } from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../../Helper/NoDataFound";
import CategorysLoader from "../../Helper/CategorysLoader";
import { useParams } from 'react-router-dom';

const SubCategories = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchSubCategoriesStart({ category_unique_id: props.category_id }));
  }, []);

  return (
    <>
      {props.subCategories.loading ?
        <CategorysLoader />
        :
        Object.keys(props.subCategories.data).length > 0 &&
          props.subCategories.data.sub_categories.length > 0 ?
          <div className="new-explore-category-sec sub-catehory-outfyt">
            <Container>
              <div className="new-home-title-sec">
                <h2>{t("explore_subcategories")}</h2>
              </div>
              <div className="new-explore-category-box">
                {props.subCategories.data.sub_categories.map(
                  (sub_category) => (
                    <div className="new-explore-category-card">
                      <Link className="expo-btn"
                        to={`/category-videos/?sub_category_id=${sub_category.unique_id}`}>
                        {/* <Image
                          src={sub_category.picture}
                          className="new-explore-category-img"
                        /> */}
                        {sub_category.name}
                      </Link>
                    </div>
                  ))}
              </div>
            </Container>
          </div>
          :
          ''
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  subCategories: state.homepage.subCategories,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(SubCategories));



