import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  CHAT_ASSET_FILE_UPLOAD_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
  chatAssetFileUploadSuccess,
  chatAssetFileUploadFailure,
} from "../actions/ChatAssetAction";

function* chatAssetFileUploadAPI() {
  try {
    const inputData = yield select(
      (state) => state.chatAsset.chatAssetInputData.inputData
    );
    const response = yield api.postMethod("chat_assets_save", inputData);
    if (response.data.success) {
      console.log(response.data.data);
      yield put(chatAssetFileUploadSuccess(response.data.data));
    } else {
      yield put(chatAssetFileUploadFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(chatAssetFileUploadFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(CHAT_ASSET_FILE_UPLOAD_START, chatAssetFileUploadAPI),
  ]);
}
