import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Button,
  Row,
  Col,
  Table,
  Form,
  Collapse,
} from "react-bootstrap";
import { withTranslation, t } from "react-multi-lang";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form as FORM, Field } from 'formik';
import { connect } from "react-redux";
import "./LiveStreaming.css";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Dropzone from "react-dropzone";
import { fetchUserProductsStart } from "../../store/actions/ProductsAction";
import { videoCallBroadcastStart } from "../../store/actions/LiveVideoAction";
import { fetchSubCategoriesStart } from "../../store/actions/HomePageAction";
import {
  fetchProductCategoriesStart,
} from "../../store/actions/ProductsAction";
import SingleProductLoader from "../Helper/SingleProductLoader";
import { fetchSingleLiveVideoStart } from "../../store/actions/LiveVideoAction";
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import Skeleton from "react-loading-skeleton";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const DetectCategories = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.category_id) {
      dispatch(fetchSubCategoriesStart({ category_id: values.category_id }));
    }
  }, [values.category_id]);
}

const EditLiveVideoIndex = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(true);
  const [skipRender, setSkipRender] = useState(true);
  const [startTime, setStartTime] = useState(new Date());
  const [inputData, setInputData] = useState({ live_schedule_type: 2 });
  const [previewImage, setPreviewImage] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    props.dispatch(
      fetchSingleLiveVideoStart({
        live_video_unique_id: params.live_video_unique_id,
      })
    );
  }, [params.live_video_unique_id]);


  useEffect(() => {
    if (!skipRender && !props.liveVideo.loading && Object.keys(props.liveVideo.data).length > 0) {
      setProductId(props.liveVideo.data.live_video_id);
      if (props.liveVideo.data.preview_file_type == "image") {
        setPreviewImage(props.liveVideo.data.preview_file);
      } else {
        setPreviewVideo(props.liveVideo.data.preview_file);
      }
    }
    setSkipRender(false)
  }, [props.liveVideo]);

  const handleChangeImage = (files) => {
    if (files.length === 1) {
      let reader = new FileReader();
      let file = files[0];
      console.log(file)
      let fileType = "";
      if (file.type.indexOf("image") === 0) {
        reader.onloadend = () => {
          setPreviewImage(reader.result);
          setPreviewVideo("");
        };
        fileType = "image";
      } else {
        reader.onloadend = () => {
          setPreviewVideo(reader.result);
          setPreviewImage("");
        };
        fileType = "video";
      }
      if (file) {
        reader.readAsDataURL(file);
      }
      setInputData({
        ...inputData,
        preview_file: file,
        preview_file_type: fileType,
      });
    }
  };

  const handleSelectProduct = (event) => {
    let input = event.currentTarget;
    if (input.type === "checkbox") {
      if (input.checked) {
        if (inputData["product_ids"] === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          setInputData({
            ...inputData,
            product_ids: array,
          });
        } else {
          const value = parseInt(input.value);
          inputData["product_ids"].push(value);
          setInputData(inputData);
        }
      } else {
        const value = parseInt(input.value);
        let index = inputData["product_ids"].indexOf(value);
        if (index !== -1) {
          inputData["product_ids"].splice(index, 1);
          setInputData(inputData);
        }
      }
    }
  };

  useEffect(() => {
    props.dispatch(fetchUserProductsStart());
    props.dispatch(fetchProductCategoriesStart());
  }, [])

  const handleSubmit = (values) => {
    let scheduleTime = dayjs(startTime).format("YYYY-MM-DD H:m:s");
    if (inputData.live_schedule_type === 1) {
      scheduleTime = dayjs
        .tz(new Date())
        .format(
          "YYYY-MM-DD H:m:s",
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
    }
    let newInputData = {
      ...inputData,
      ...values,
      schedule_time: scheduleTime,
    };
    setInputData(newInputData);
    props.dispatch(videoCallBroadcastStart({
      ...newInputData,
      live_video_id: productId,
    }));
  };

  return (
    <>
      <div className="go-live-sec">
        <Container>
          <div className="live-video-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <div className="go-live-header-info">
                <h3>{t("lets_get_started")}</h3>
                <p>{t("create_a_live_stream")}</p>
              </div>
            </div>
          </div>
          <div className="go-live-box">
            {props.liveVideo.loading ?
              <div className="profile-details-form">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-4">
                      <Skeleton className="mb-4" count={1} height={50} />
                      <Skeleton className="mb-4" count={1} height={50} />
                      <div className="golive-upload-produts">
                        <Skeleton className="mb-4" count={1} height={208} />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-4">
                      <Skeleton className="mb-4" count={1} height={50} />
                      <Skeleton className="mb-4" count={1} height={90} />
                      <Skeleton count={2} height={24} width={125} />
                      <div className="go-live-date-time-picker">
                        <Skeleton className="mt-4" count={1} height={50} />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              :
              Object.keys(props.liveVideo.data).length > 0 ?
                <Formik
                  initialValues={{
                    title: props.liveVideo.data.title,
                    description: props.liveVideo.data.description,
                    category_id: props.liveVideo.data.category_id,
                    sub_category_id: props.liveVideo.data.sub_category_id,
                    group1: props.liveVideo.data.live_schedule_type,
                  }}
                  onSubmit={(values => handleSubmit(values))}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <FORM className="profile-details-form">
                      <Row>
                        <Col md={6}>
                          <div className="">
                            <Form.Group className="mb-4">
                              <Form.Label>{t("title")}</Form.Label>
                              <Field
                                type="text"
                                placeholder={t("title")}
                                name="title"
                                className="form-control"
                              />
                            </Form.Group>
                            <Form.Group className="mb-4">
                              <Form.Label>{t("sub_category")}</Form.Label>
                              <Field
                                as="select"
                                className="form-control"
                                name="sub_category_id"
                              >
                                <option value="">{t("select_sub_category")}</option>
                                {props.subCategories.loading ?
                                  "loading"
                                  :
                                  Object.keys(props.subCategories.data).length > 0 &&
                                    props.subCategories.data.sub_categories.length > 0 ?
                                    props.subCategories.data.sub_categories.map((sub_category) => (
                                      <option value={sub_category.sub_category_id}>{sub_category.name}</option>
                                    ))
                                    :
                                    null
                                }
                              </Field>
                            </Form.Group>
                          </div>
                          <Form.Group>
                            <Dropzone
                              maxFiles={1}
                              onDrop={(acceptedFiles) =>
                                handleChangeImage(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div className="golive-upload-produts">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/images/upload-icon.svg"
                                      }
                                      className="upload-icon"
                                    />
                                    <p>{t("upload_a_preview")}</p>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Form.Group>
                          {previewImage !== "" ? (
                            <div className="upload-img-preview-sec image">
                              <Image
                                className="upload-img-preview"
                                src={previewImage}
                              />
                            </div>
                          ) : (
                            null
                          )}
                          {previewVideo !== "" ? (
                            <Row>
                              <Col sm={12} md={12}>
                                <div className="post-img-preview-sec my-3 my-lg-4">
                                  <video
                                    autoplay
                                    controls
                                    id="myVideo"
                                    className="edit-scehedule-preview"
                                  >
                                    <source src={previewVideo} type="video/mp4" />
                                  </video>
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                        <Col md={6}>
                          <div className="">
                            <Form.Group className="mb-4">
                              <Form.Label>{t("category")}</Form.Label>
                              <Field
                                as="select"
                                className="form-control"
                                name="category_id"
                              >
                                <option value="">{t("select_category")}</option>
                                {props.categories.loading ?
                                  "loading"
                                  :
                                  Object.keys(props.categories.data).length > 0 &&
                                    props.categories.data.product_categories.length > 0
                                    ?
                                    props.categories.data.product_categories.map((category, index) => (
                                      <option
                                        value={category.category_id}
                                        key={index}
                                      >{category.name}</option>
                                    ))
                                    :
                                    null
                                }
                              </Field>
                              <DetectCategories />
                            </Form.Group>
                            <Form.Group className="mb-4">
                              <Form.Label>{t("description")}</Form.Label>
                              <Field
                                as="textarea"
                                className="height-auto form-control"
                                placeholder={t("enter_the_content")}
                                name="description"
                                rows={3}
                              />
                              <Form.Text className="text-muted">
                                {t("description_max_char")}
                              </Form.Text>
                            </Form.Group>
                            <Form.Label>{t("streaming_type")}</Form.Label>
                            {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Now"
                                  name="group1"
                                  type="checkbox"
                                  checked={values.group1 == 1}
                                  onChange={() => setFieldValue("group1", 1)}
                                />
                                <Form.Check
                                  inline
                                  label="Later"
                                  name="group1"
                                  type="checkbox"
                                  checked={values.group1 == 2}
                                  onChange={() => setFieldValue("group1", 2)}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="go-live-date-time-picker">
                            <DatePicker
                              onChange={setStartTime}
                              value={startTime}
                              minDate={new Date()}
                              format="MM/DD/YYYY HH:mm:ss"
                              placeholder={t("select_date_and_time")}
                              plugins={[<TimePicker position="bottom" />]}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="go-live-product-sec">
                            <Button
                              onClick={() => setOpen(!open)}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                            >
                              {t("click_to_choose_products")}
                            </Button>
                            {props.userProducts.loading ? <SingleProductLoader /> : (
                              <>
                                {(Object.keys(props.userProducts.data).length > 0 && props.userProducts.data.user_products.length > 0) ? (
                                  <Collapse in={open}>
                                    <div id="example-collapse-text">
                                      <div className="product-check-box-sec">
                                        <ul className="list-unstyled">
                                          {props.userProducts.data.user_products.length >
                                            0
                                            ? props.userProducts.data.user_products.map(
                                              (product, index) => (
                                                <li
                                                  as="li"
                                                  key={`myCheckbox` + index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    id={`myCheckbox` + index}
                                                    value={product.user_product_id}
                                                    onChange={(event) =>
                                                      handleSelectProduct(event)
                                                    }
                                                  />
                                                  <label htmlFor={`myCheckbox` + index}>
                                                    {product.file_type == "image" ?
                                                      <Image
                                                        className=""
                                                        src={product.picture}
                                                      />
                                                      :
                                                      <video
                                                        autoplay
                                                        controls
                                                        id="myVideo"
                                                        className="product-img"
                                                      >
                                                        <source src={product.picture}
                                                          type="video/mp4" />
                                                      </video>
                                                    }
                                                    <div className="products-info">
                                                      <h5>{product.name}</h5>
                                                      <p>{product.category_name}</p>
                                                    </div>
                                                  </label>
                                                </li>
                                              )
                                            )
                                            : null}
                                        </ul>
                                      </div>
                                    </div>
                                  </Collapse>
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className="lgo-live-btn-sec">
                            <Button
                              type="submit"
                              className="default-btn"
                              disabled={props.videocall.buttonDisable}
                            >
                              {props.videocall.loadingButtonContent !== null ?
                                props.videocall.loadingButtonContent :
                                t("go_live")
                              }
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </FORM>
                  )}
                </Formik>
                :
                null}
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  videocall: state.liveVideo.saveLiveVideo,
  userProducts: state.userProducts.products,
  categories: state.userProducts.productCategories,
  subCategories: state.homepage.subCategories,
  liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(EditLiveVideoIndex));
