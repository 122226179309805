import React from 'react'
import { Container } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";
import LiveVideoLoader from './LiveVideosLoader';

const ExploreCategoryLoader = () => {
  return (
    <> <Container>
     <div className="new-explore-category-sec">
        <Container>
          <div className="new-home-title-sec">
            <Skeleton count={1} width={185} height={20} />
          </div>
          <div className="new-explore-category-box">
            {[...Array(5)].map((i) => (
              <div className="new-explore-category-card">
                <Skeleton count={1} height={50} width={160} />
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Container>
        <div className='new-category-sec-1'>
          <Skeleton count={1} height={100} width={"100%"} />
        </div>
      </Container>
      <div className="new-home-latest-live-streaming-box-video">
        {[...Array(3)].map((i) => (
          <div className="new-home-latest-live-streaming-img-sec">
            <Skeleton count={1} height={330} width={310} />
            <Skeleton count={1} width={100} />
            <Skeleton count={1} width={250} />
          </div>
        ))}
      </div>
    </Container>
    </>
  )
}

export default ExploreCategoryLoader;