import React, { useState } from "react";
import NewExploreCategory from "./NewExploreCategory";
import NewLatestLiveStreamingIndex from "./NewLatestLiveStreamingIndex";
import UpComingLiveStreamingIndex from "./UpComingLiveStreamingIndex";
import CategoryFollowIndex from "./CategoryFollowIndex";
import AccessoriesHomeIndex from "./AccessoriesHomeIndex";
import { useTranslation, withTranslation } from "react-multi-lang";
import HomeSidebar from "./HomeSidebar";

const NewHomeIndex = () => {

  const t = useTranslation();

  return (
    <>
      <div className="new-outfyt-home-page-sec">
        {/* <NewHeaderIndex/> */}
        <div className="new-main-wrapper">
          <div className="new-index-section">
            <HomeSidebar />
            <div className="new-home-right" >
              <NewExploreCategory />
              <NewLatestLiveStreamingIndex />
              <AccessoriesHomeIndex />
              <UpComingLiveStreamingIndex />
              {localStorage.getItem("userId") &&
                localStorage.getItem("accessToken") ?
                <CategoryFollowIndex />
                : null
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(NewHomeIndex);
