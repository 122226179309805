import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button, Form, Accordion, Modal, InputGroup, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { getKycDocumentStart, userDocumentsSaveStart } from "../../../../store/actions/UserAction";
import CommonCenterLoader from "../../../Helper/CommonCenterLoader";


const VerifyStepOne = (props) => {

  const [step, setStep] = useState(1);
  const [checked, setChecked] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

  useEffect(() => {
    setStep(1);
    props.dispatch(getKycDocumentStart());
  }, []);

  useEffect(() => {
    if (!props.kycDocDetails.loading && Object.keys(props.kycDocDetails.data).length > 0 &&
      Object.keys(props.kycDocDetails.data.user_document).length > 0) {
      setChecked(props.kycDocDetails.data.user_document.document_id);
    }
  }, [props.kycDocDetails])

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    }
  });

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  const docs = files.map((file, index) => (
    <div className="upload-preview-outfyt-sec" key={file.name}>
      <svg width="24" height="24" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z" fill="#151515" />
      </svg>
      <div className="w-100">
        <p>{file.name}</p>
        <span>{(file.size / 1024).toFixed(2) + "kb"}</span>
      </div>
      <div onClick={() => removeFile(index)}>
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z" fill="#D2D2D6" />
        </svg>
      </div>
    </div>
  ));

  const handleSubmit = () => {
    props.dispatch(userDocumentsSaveStart({
      document_file: files[0],
      document_id: checked,
    }));
  };

  useEffect(() => {
    if (!skipRender && !props.userDocuments.loading && Object.keys(props.userDocuments.data).length > 0) {
      props.setStep(4);
      props.setModalShow(false);
    }
    setSkipRender(false);
  }, [props.userDocuments]);

  return (
    <>
      <div className="final-paert-verfiy-sec">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            {step == 1 && (
              <div className="fgh">
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {t("select_identification_type")}
                  </Modal.Title>
                </Modal.Header>
                <div className="verify-one-step-sec">
                  {props.kycDocDetails.loading ? '' :
                    props.kycDocDetails.data.documents.length > 0 ?
                      <>
                        <Form>
                          {props.kycDocDetails.data.documents.map(
                            (document) => (

                              <Form.Check
                                className="verify-document"
                                inline
                                label={document.name}
                                name="document_id"
                                type="radio"
                                id="inline-radio-1"
                                checked={checked == document.document_id}
                                value={document.document_id}
                                onClick={(e) => {
                                  setChecked(e.target.value);
                                }}
                              />
                            ))}
                          <Button disabled={checked === 0} onClick={() => setStep(2)}>{t("next")}</Button>
                        </Form>

                      </>
                      : ''
                  }


                </div>
              </div>
            )}
            {step == 2 && (
              <div className="fgh">
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   {t("upload_images")}
                  </Modal.Title>
                </Modal.Header>
                <div className="one-verify-modal-one-sec-img">
                  <div className="verify-one-step-sec-img">
                    <Form className="one-verify-modal-card">
                      <label>{t("upload_your")} {checked == 1 ? " DOC V" : " Eve Richmond"}</label>
                      <div className="verify-upload-file-card">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Image src={window.location.origin + "/images/upload-img-out.png"} />
                          <p>{t("drag_and_drop")} <br></br> {t("ors")}
                            <span className="browse-files">{t("browse")}</span></p>
                        </div>
                      </div>
                      <div className="verify-upload-file-note-sec">
                        <p>{t("file_supported")} </p>
                      </div>
                      {docs}
                      <Button
                       type="button"
                        disabled={props.userDocuments.buttonDisable || files.length === 0}
                        onClick={handleSubmit}>
                        {props.userDocuments.buttonDisable ? t("loading") : t("submit")}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            )}
            {step == 3 && (
              <div className="fgh">
                <Modal.Header closeButton>
                  <h4></h4>
                </Modal.Header>
                <div className="final-paert-verfiy-sec-body">
                  <Image src={window.location.origin + "/images/final-submit.png"} />
                  <h3>{t("verification_submitted")}</h3>
                  <p>{t("thank _you_for_providing_your_information")}</p>
                  <Button onClick={() => props.setModalShow(false)}>{t("continue")}</Button>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  kycDocDetails: state.users.kycDocDetails,
  userDocuments: state.users.userDocuments,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(VerifyStepOne));