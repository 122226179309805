import './App.css';
import Base from "./components/Base";
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux';
import store from './store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-loading-skeleton/dist/skeleton.css'
import configuration from "react-global-configuration";
import { useState, useEffect } from 'react';
import { apiConstants } from './components/Constant/constant';
import "../src/components/OutFyt/OutFyt.css";
import "../src/components/OutFyt/Default.css";
import 'react-confirm-alert/src/react-confirm-alert.css';


function App() {

  const [configLoading, setConfigLoading] = useState(true);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <Provider store={store} >
      {!configLoading &&
        <Base />
      }
    </Provider>
  );
}

export default App;
