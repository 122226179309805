import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchUpcomingLiveStreamsStart,
  fetchMoreUpcomingLiveStreamsStart
} from "../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import LiveVideoLoader from "../Helper/LiveVideosLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import LiveVideoCard from "./LiveVideoCard";
import { useNavigate } from "react-router-dom";

const UpcomingLiveStreams = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchUpcomingLiveStreamsStart({
      skip: 0,
      take: 12
    }));
    window.scrollTo(0, 0);
  }, []);
  const fetchMoreData = () => {
    props.dispatch(fetchMoreUpcomingLiveStreamsStart({
      skip: props.upcomingLiveStreams.data.live_streamings.length,
      take: 12,
    }));
  }

  return (
    <>
  <div className="new-outfyt-home-page-sec">
        <div className="new-main-wrapper">
          <div className="new-category-sec-1">
            <Container>
              <div className="new-category-header-sec">
                <Link to="#" onClick={() => navigate(-1)}>
                  <Image src={window.location.origin + "/images/back.svg"} />
                </Link>
                <h1>{t("upcoming_livestream")}</h1>
              </div>
            </Container>
          </div>
          <Container>
            {props.upcomingLiveStreams.loading ?
              <LiveVideoLoader />
              :
              Object.keys(props.upcomingLiveStreams.data).length > 0 &&
                props.upcomingLiveStreams.data.live_streamings.length > 0 ?
                <InfiniteScroll
                  className="category-sec"
                  id="category-sec"
                  dataLength={props.upcomingLiveStreams.data.live_streamings.length}
                  next={fetchMoreData}
                  hasMore={
                    props.upcomingLiveStreams.data.live_streamings.length <
                    props.upcomingLiveStreams.data.total}
                  loader={<LiveVideoLoader />}
                >
                  <div className="new-home-latest-live-streaming-box-video">
                    {props.upcomingLiveStreams.data.live_streamings.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  upcomingLiveStreams: state.homepage.upcomingLiveStreams,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(UpcomingLiveStreams));


