import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SidebarLoader = () => {
  return (
    <>
      <div className="new-home-left" >
        <div className="categories-head ">
          <Skeleton count={1} height={23} width={150} />
        </div>
        <div className="categories-nav-sec ">
          <div className="accordion">
            {[...Array(6)].map(() =>
              <Skeleton count={1} height={45} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SidebarLoader;