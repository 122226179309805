import React, { useState } from "react";
import { connect } from "react-redux";
import {
  t,
  withTranslation,
  useTranslation,
} from "react-multi-lang";
import {Button} from "react-bootstrap";
import configuration from "react-global-configuration";
import ShippingViewModal from "./ShippingViewModal";
import { Link } from "react-router-dom";

const LiveProducts = (props) => {
  const [shippingView, setShippingView] = useState(false);

  const closeShippingViewModal = () => {
    setShippingView(false);
  };

  return (
    <>
      <div className="video-product-list-card">
        <div className="video-product-list-left-sec">
          <h4>
            <Link target="_blank" to={`/single-product/${props.product.unique_id}`}>
              {props.product.name}
            </Link>
          </h4>
          <h4 className="sold-color">{props.product.price_formatted}</h4>
        </div>

        <div className="video-product-list-right-sec">
          {
            props.liveVideo.user_id != localStorage.getItem("userId") ? (
              <Button
                type="button"
                className="buy-now"
                onClick={() => setShippingView(true)}
              >
                {t("buy_now")}
              </Button>
            ) : (null)
          }
        </div>
        {configuration.get("configData.is_offline_products_available") ===
          1 ? (
          props.liveVideo.user_id != localStorage.getItem("userId") ? (
            // props.user.is_online_status === 1 && props.user.is_user_online === 1 ?
            <Button
              className="buy-now-btn"
              type="button" onClick={() => setShippingView(true)}>
              {t("buy_now")}
            </Button>
          ) : (null)
        ) : (null)

          // : ''
        }
      </div>
      {props.liveVideo.is_owner !== 1 && shippingView ?
        <ShippingViewModal
          product={props.product}
          shippingView={shippingView}
          closeShippingViewModal={closeShippingViewModal}
          setShippingView={setShippingView}
          liveVideo={props.liveVideo}
        />
        :
        null
      }
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveProducts));
