import React, { useEffect } from 'react'
import { useParams } from "react-router";
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  fetchSubCategoryStart,
  fetchSubCategoryVideosStart,
  fetchMoreSubCategoryVideosStart,
} from '../../store/actions/HomePageAction';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NoDataFound from '../Helper/NoDataFound';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image } from "react-bootstrap";
import { liveVideoBookmarkSaveStart } from '../../store/actions/HomePageAction';
import { useNavigate } from 'react-router';
import CategorySidebar from './CategorySidebar';

const CategoryIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const { category_id, sub_category_id } = useParams();

  useEffect(() => {
    props.dispatch(fetchSubCategoryStart({
      category_id: category_id,
    }));
  }, []);

  useEffect(() => {
    props.dispatch(fetchSubCategoryVideosStart({
      sub_category_id: sub_category_id,
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreSubCategoryVideosStart({
        sub_category_id: sub_category_id,
        skip: props.subCategoryVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  const bookmarkVideo = (e, videoId) => {
    e.preventDefault();
    if (Object.keys(props.profile.data).length > 0) {
      props.dispatch(liveVideoBookmarkSaveStart({
        live_video_id: videoId,
      }));
    } else {
      navigate("/login");
    }
  }


  return (
    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
          <div className="new-card-head pt-8">
            <h3 className="sub-catdata">{t("sub_categories")}</h3>
          </div>
          {props.subCategory.loading ?
            <div className="category-tab-card">
              <ul>
                <li> <Skeleton count={1} width={110} height={50} /></li>
                <li> <Skeleton count={1} width={110} height={50} /></li>
              </ul>
            </div>
            :
            Object.keys(props.subCategory.data).length > 0 &&
              props.subCategory.data.sub_categories.length > 0
              ?
              <div className="category-tab-card">
                {props.subCategory.data.sub_categories.map(
                  (subcategory, i) => (
                    <ul key={i}>
                      <li><Link to="#">{subcategory.name}</Link></li>
                    </ul>
                  ))}
              </div>
              :
              null
          }
          <div className="category-card-sec mt-4">
            {props.subCategoryVideos.loading ?
              <div className="category-card-box">
                {[...Array(5)].map((i) => (
                  <Skeleton count={1} width={218} height={210} />
                ))}
              </div>
              :
              Object.keys(props.subCategoryVideos.data).length > 0 &&
                props.subCategoryVideos.data.live_videos.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.subCategoryVideos.data.live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.subCategoryVideos.data.live_videos.length <
                    props.subCategoryVideos.data.total_live_videos
                  }
                  loader={<div className="category-card-box">
                    {[...Array(5)].map((i) => (
                      <Skeleton count={1} width={218} height={210} />
                    ))}
                  </div>}
                >
                  <div className="new-category-card-sec">
                    <h1> {props.subCategoryVideos.data.total_live_videos} {t("shows")}</h1>
                    <div className="category-total-card">
                      {props.subCategoryVideos.data.live_videos.map(
                        (live_video) => (
                          <div className="category-card">
                            <div className="category-card-img">
                              <Link to={`/live-stream/${live_video.live_video_id}`}>
                                <Image
                                  src={live_video.preview_file}
                                  className="list-card-img"
                                />
                                <div className="category-card-view">
                                  <div className="category-card-icon">
                                    <Image src={window.location.origin + "/images/card/eye.svg"} />
                                    <p>{live_video.viewer_cnt}</p>
                                  </div>
                                  <div className="category-card-icon">
                                    <p>{live_video.total_bookmarks}</p>
                                    <div className="card-top-img" onClick={(e) => bookmarkVideo(e, live_video.live_video_id)}>
                                      {live_video.is_video_bookmarked === 1
                                        ?
                                        <Image
                                          src={window.location.origin + "/images/card/bookmark-active.svg"}
                                        />
                                        :
                                        <Image
                                          src={window.location.origin + "/images/card/bookmark.svg"}
                                        />
                                      }
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div className="category-card-profile">
                              <Link to={`/live-stream/${live_video.live_video_id}`}>
                                <Image
                                  src={live_video.user_picture}
                                  className="cat-pro-pic"
                                />
                                <h5>{live_video.username}</h5>
                              </Link>
                            </div>
                            <div className="category-card-content">
                              <h4>
                                {live_video.description}
                              </h4>
                              <Link to={`/live-stream/${live_video.live_video_id}`}>{props.subCategoryVideos.data.name}</Link>
                            </div>
                          </div>
                        ))}
                    </div>

                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  subCategory: state.homepage.subCategory,
  subCategoryVideos: state.homepage.subCategoryVideos,
  liveBookmarkSave: state.liveVideo.liveBookmarkSave,
  profile: state.users.profile,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(CategoryIndex));