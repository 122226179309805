import React, { useState, useEffect } from "react";
import { Container, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NumericInput from "react-numeric-input";
import { saveCartDetailsStart } from "../../../store/actions/ProductsAction";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { userProductViewForOthersStart } from "../../../store/actions/ProductsAction";
import SingleProductLoader from "../../Helper/SingleProductLoader";
import NoDataFound from "../../Helper/NoDataFound";
import { categoryUserProductsStart } from "../../../store/actions/ProductsAction";
import ExploreProducts from "./ExploreProducts";

const NewSingleProductIndex = (props) => {

  const params = useParams();
  const [skipRender, setSkipRender] = useState(true);
  const [count, setCount] = useState(1);
  const [image, setImage] = useState("");
  const [inputValue, setInputValue] = useState({ product_variant_id: 0, product_size_id: 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
    props.dispatch(
      userProductViewForOthersStart({
        user_products_unique_id: params.product_unique_id,
      })
    );
    props.dispatch(categoryUserProductsStart({
      user_products_unique_id: params.product_unique_id
    }));
  }, [params.product_unique_id]);

  const t = useTranslation();

  useEffect(() => {
    if (!skipRender &&
      !props.productView.loading &&
      Object.keys(props.productView.data).length > 0) {
      setImage(props.productView.data.user_product.picture);
    }
    setSkipRender(false);
  }, [props.productView]);

  const handleSubmit = () => {
    props.dispatch(saveCartDetailsStart({
      user_product_id: props.productView.data.user_product.user_product_id,
      quantity: count,
      product_size_id: inputValue.product_size_id,
      product_variant_id: inputValue.product_variant_id,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.cartSave.loading &&
      Object.keys(props.cartSave.data).length > 0) {
      props.dispatch(
        userProductViewForOthersStart({
          user_products_unique_id: params.product_unique_id,
        })
      );
    }
    setSkipRender(false);
  }, [props.cartSave]);

  useEffect(() => {
    if (!skipRender &&
      !props.productView.loading &&
      Object.keys(props.productView.data).length > 0) {
      setImage(props.productView.data.user_product.picture);
    }
    setSkipRender(false);
  }, [props.productView]);

  const images = [
    {
      original: image,
      thumbnail: image,
    }
  ];

  const settings = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrow: false,
        }
      }
    ]
  };

  return (
    <>
      {props.productView.loading ?
        <SingleProductLoader />
        : (
          <div className="new-outfyt-single-product-sec">
            {Object.keys(props.productView.data).length > 0 ? (
              <div className="new-main-wrapper">
                <Container>
                  <div className="new-outfyt-single-product-header-sec">
                    <p>{props.productView.data.user_product.category_name} / {props.productView.data.user_product.sub_category_name} / {props.productView.data.user_product.name}</p>
                  </div>
                </Container>
                <div className="new-outfyt-single-product-body-sec">
                  <div className="new-outfyt-single-product-body-left-sec">
                    <div className="new-outfyt-single-product-img-box">
                      {props.productView.data.user_product.userProductFiles.length > 0 ?
                        props.productView.data.user_product.userProductFiles.map((files, index) => (
                          <div className="new-outfyt-single-product-img-card">
                            {files.file_type == "image" ?
                              <Image
                                src={files.picture}
                                className="new-outfyt-single-product-img"
                              />
                              :
                              <video
                                autoplay
                                controls
                                id="myVideo"
                                className="single-product w-100"
                              >
                                <source src={files.picture} type="video/mp4" />
                              </video>
                            }
                          </div>
                        ))
                        :
                        <div className="new-outfyt-single-product-img-card">
                          <Image
                            src={props.productView.data.user_product.picture}
                            className="new-outfyt-single-product-img"
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div className="new-outfyt-single-product-body-right-sec">
                    <div className="new-outfyt-single-product-details-info-sec">
                      <h2>{props.productView.data.user_product.name}</h2>
                      {/* <p className="desc">{props.productView.data.user_product.description}</p> */}
                      <div className="new-outfyt-single-product-amount-sec">
                        <h3>{props.productView.data.user_product.price_formatted}</h3>
                        <h4>{props.productView.data.user_product
                          .is_outofstock === 0
                          ? t("out_of_stock")
                          : t("in_stock")}</h4>
                      </div>
                      <div className="new-outfyt-single-product-qty-sec">
                        <label>{t("quantity")} </label>
                        <NumericInput
                          min={1}
                          value={count}
                          onChange={setCount}
                          mobile
                          className="form-control"
                          strict={true}
                          max={props.productView.data.user_product.quantity}
                        />
                      </div>
                      {props.productView.data.user_product.product_variants.length > 0 ?
                        <div className="new-outfyt-single-product-varient-sec">
                          <label className="form-label">{t("select_variant")}</label>
                          <div className="new-outfyt-single-product-varient-box">
                            {props.productView.data.user_product.product_variants.map(
                              (product_variant, index) => (
                                <label>
                                  <input
                                    type="radio"
                                    id={`inline-radio-${index + 1}`}
                                    name="product_variant_id"
                                    class="card-varient-element"
                                    onClick={() =>
                                      setInputValue({ ...inputValue, product_variant_id: product_variant.product_variant_id })
                                    }
                                  />
                                  <div className="new-outfyt-single-product-varient-card">
                                    <div className="new-outfyt-single-product-varient-img-sec">
                                      <Image
                                        src={product_variant.picture}
                                        className="new-outfyt-single-product-varient-img"
                                        alt="Product Variant"
                                      />
                                    </div>
                                    <div className="new-outfyt-single-product-varient-info">
                                      <h4>{product_variant.name}</h4>
                                      <p>{product_variant.price_formatted}</p>
                                    </div>
                                  </div>
                                </label>
                              ))}
                          </div>
                        </div>
                        : ''}
                      {props.productView.data.user_product.product_size.length > 0 ?
                        <div className="new-outfyt-single-product-size-sec">
                          <label className="form-label">{t("size")}</label>
                          <div className="new-outfyt-single-product-size-box">
                            {props.productView.data.user_product.product_size.map(
                              (product_size, index) => (
                                <label>
                                  <input
                                    id={`inline-radio-${index + 1}`}
                                    type="radio"
                                    name="product_size_id"
                                    class="card-size-element"
                                    onClick={() =>
                                      setInputValue({ ...inputValue, product_size_id: product_size.product_size_id })
                                    }
                                  />
                                  <div className="new-outfyt-single-product-size-card">
                                    <h5>{product_size.name}</h5>
                                    <p>{product_size.price_formatted}</p>
                                  </div>
                                </label>
                              ))}
                            <Link
                              className="size-guide"
                              to="#">{t("size_guide")}</Link>
                          </div>
                        </div>
                        : ''}
                      {props.productView.data.user_product.user_id != localStorage.getItem("userId") ?
                        <div className="new-outfyt-single-product-btn-sec">
                          {props.productView.data.user_product.add_to_cart == 1 ?
                            <Button className="add-to-cart-btn"
                              onClick={handleSubmit}
                              disabled={
                                props.productView.data.user_product.user_id ==
                                localStorage.getItem("userId") ||
                                props.productView.data.user_product.is_outofstock === 0
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                fill="none"
                                viewBox="0 0 31 31"
                              >
                                <path
                                  fill="#151515"
                                  d="M13.625 26.464a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM22.375 26.464a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75zM26.75 8.964H9.667L8.225 5.5a2.494 2.494 0 00-2.31-1.537H3v2.5h2.917l5.929 14.231c.194.465.65.769 1.153.769h10c.522 0 .988-.324 1.172-.81l3.75-10a1.255 1.255 0 00-1.171-1.69zm-8.75 10l-3.75-3.75h2.5v-3.75h2.5v3.75h2.5L18 18.964z"
                                ></path>
                              </svg>
                              {t("add_to_cart")}
                            </Button>
                            :
                            <Link className="add-to-cart-btn"
                              to="/ecom-cart"
                            >
                              <Image
                                src={window.location.origin + "/images/view-cart.svg"}
                                className="cart-product-img"
                              />
                              {t("view_cart")}
                            </Link>
                          }
                        </div>
                        :
                        null
                      }
                      {props.productView.data.user_product.description ?
                        <div className="new-outfyt-single-product-description-sec">
                          <h3>{t("product_description")}</h3>
                          <ul className="new-outfyt-single-product-description-list list-unstyled">
                            <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                fill="none"
                                viewBox="0 0 16 17"
                              >
                                <g fill="#151515" clipPath="url(#clip0_411_3380)">
                                  <path d="M6.829 11.09L4 8.262l.943-.942 1.886 1.885 3.77-3.771.944.943-4.714 4.713v.002z"></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M.667 3.548A2.667 2.667 0 013.333.88h9.334a2.667 2.667 0 012.666 2.667v9.333a2.667 2.667 0 01-2.667 2.666H3.333a2.667 2.667 0 01-2.666-2.666V3.548zm2.666-1.334h9.334A1.333 1.333 0 0114 3.548v9.333a1.333 1.333 0 01-1.334 1.333H3.333A1.334 1.334 0 012 12.881V3.548a1.333 1.333 0 011.333-1.334z"
                                    clipRule="evenodd"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_411_3380">
                                    <path
                                      fill="#fff"
                                      d="M0 0H16V16H0z"
                                      transform="translate(0 .214)"
                                    ></path>
                                  </clipPath>
                                </defs>
                              </svg>
                              <span>{props.productView.data.user_product.description}</span>
                            </li>
                          </ul>
                        </div>
                        :
                        ""
                      }
                      <div className="new-outfyt-single-product-details-sec">
                        <h3>{t("product_details")}</h3>
                        <div className="new-outfyt-single-product-details-box">
                          <div className="new-outfyt-single-product-details-card">
                            <h5>{props.productView.data.user_product.category_name}</h5>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="new-outfyt-single-product-body-footer-sec">
                  <ExploreProducts categoryProducts={props.categoryProducts} />
                </div>
              </div>
            ) : (
              <NoDataFound />
            )}
          </div >
        )}
    </>
  );
};

const mapStateToPros = (state) => ({
  productView: state.userProducts.productViewForOthers,
  cartSave: state.userProducts.cartSave,
  categoryProducts: state.userProducts.categoryUserProducts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewSingleProductIndex));