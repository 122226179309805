import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchStaticPageStart } from "../../../store/actions/PageAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";

const NewstaticIndex = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(
      fetchStaticPageStart({ unique_id: params.unique_id })
    );
    window.scrollTo(0, 0);
  }, [params.unique_id]);


  return (
    <>
      <div className="new-static-home-page-sec">
        <div className="new-main-wrapper">
          <Container>
            {props.pageData.loading ?
              <div className="static-box">
                <Skeleton count={1} height={30} className="mb-2" />
                <Skeleton count={1} height={175} />
              </div>
              : Object.keys(props.pageData.data).length > 0 ? (
                <div className="new-static-chat-sec">
                  <Row>
                    <Col className="text-center head-title-static">
                      <h1 className="text-capitalize">{props.pageData.data.title}</h1>
                    </Col>
                    <div className="new-static-general-sec">
                      <ul>
                      <li className="text-capitalize"
                        dangerouslySetInnerHTML={{
                          __html: props.pageData.data.description,
                        }}
                      ></li>
                      </ul>
                    </div>
                  </Row>
                </div>
              ) :
                <NoDataFound />
            }
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(NewstaticIndex));

