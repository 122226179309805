import React, { useEffect, useState } from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  resetPasswordStart,
  forgotPasswordStart,
} from "../../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const NewForgotPasswordIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);

  const handleForgotPassword = (values) => {
    props.dispatch(forgotPasswordStart(values));
  };
  const forgotpwdSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_address"))
      .required(t("email_required")),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.forgotPassword.loading &&
      props.forgotPassword.data.success === true
    ) {
      navigate("/outfyt-verify-forgot-password");
    }
    setSkipRender(false);
  }, [props.forgotPassword]);

  return (
    <>
      <div className="outfyt-auth-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="outfyt-auth-box">
                <div className="outfyt-auth-left-sec">
                  <CustomLazyLoad
                    src={
                      window.location.origin +
                      "/images/outfyt/auth/auth-image.jpg"
                    }
                    className="outfyt-auth-img"
                  />
                </div>
                <div className="outfyt-auth-right-sec">
                  <div className="outfyt-auth-body-sec">
                    <Button className="select-back-btn" onClick={() => navigate(-1)}>
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4999 4.99999H4.32992L7.20992 2.11999C7.3969 1.93301 7.50195 1.67942 7.50195 1.41499C7.50195 1.15056 7.3969 0.896967 7.20992 0.70999C7.02295 0.523012 6.76935 0.417969 6.50492 0.417969C6.2405 0.417969 5.9869 0.523012 5.79992 0.70999L1.20992 5.29999C1.11722 5.3925 1.04367 5.50239 0.993492 5.62337C0.94331 5.74434 0.91748 5.87402 0.91748 6.00499C0.91748 6.13596 0.94331 6.26564 0.993492 6.38661C1.04367 6.50759 1.11722 6.61748 1.20992 6.70999L5.79992 11.3C5.89251 11.3926 6.00242 11.466 6.12338 11.5161C6.24435 11.5662 6.37399 11.592 6.50492 11.592C6.63586 11.592 6.7655 11.5662 6.88647 11.5161C7.00743 11.466 7.11734 11.3926 7.20992 11.3C7.30251 11.2074 7.37595 11.0975 7.42605 10.9765C7.47616 10.8556 7.50195 10.7259 7.50195 10.595C7.50195 10.4641 7.47616 10.3344 7.42605 10.2134C7.37595 10.0925 7.30251 9.98257 7.20992 9.88999L4.32992 6.99999H17.4999C18.0499 6.99999 18.4999 6.54999 18.4999 5.99999C18.4999 5.44999 18.0499 4.99999 17.4999 4.99999Z" fill="#151515" />
                        </svg>
                        {t("back")}
                    </Button> 
                    <Image
                      src={
                        window.location.origin + "/images/outfyt/logo-black.png"
                      }
                      className="outfyt-auth-logo"
                    />
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={forgotpwdSchema}
                      onSubmit={(values) => handleForgotPassword(values)}
                    >
                      <FORM className="outfyt-auth-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder={t("email_address")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger"
                          />
                        </Form.Group>
                        <div className="outfyt-auth-btn-sec">
                          <Button
                            className="outfyt-auth-btn w-100"
                            type="submit"
                            disabled={props.forgotPassword.buttonDisable}
                          >
                            {props.forgotPassword.loadingButtonContent !== null
                              ? props.forgotPassword.loadingButtonContent
                              : t("forgot_password")}
                          </Button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                  <div className="outfyt-footer-sec">
                    <Link to="/login">{t("login")}</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewForgotPasswordIndex));
