import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-multi-lang';
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import {
  addMoneyViaPaypalStart,
} from '../../store/actions/WalletAction';
import Skeleton from 'react-loading-skeleton';
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import {
  getErrorNotificationMessage,
} from "../../components/Helper/ToastNotification";
import {
  generateStripeStart,
  fetchWalletDetailsStart
} from '../../store/actions/WalletAction';
import StripePaymentSec from './StripePaymentSec';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchAllTransactionStart } from "../../store/actions/TransactionAction";

const AddWalletAmountModal = (props) => {

  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);
  const [paymentType, setPaymentType] = useState("card");
  const [amount, setAmount] = useState(1);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentAddCard, setPaymentAddCard] = useState(false);

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "card") {
      props.dispatch(
        generateStripeStart({
          amount: amount,
        })
      );
      setPaymentAddCard(true);
    }
  }

  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
  };

  useEffect(() => {
    if (!skipRender && !props.addAmount.loading && Object.keys(props.addAmount.data).length > 0) {
      props.dispatch(fetchWalletDetailsStart());
      props.dispatch(fetchAllTransactionStart());
      props.closeAddWalletAmountModal();
    }
    setSkipRender(false);
  }, [props.addAmount]);

  const paypalOnSuccess = (payment) => {
    setTimeout(() => {
      props.dispatch(
        addMoneyViaPaypalStart({
          payment_id: payment.paymentID,
          amount: amount,
        })
      );
    }, 1000);
    props.dispatch(fetchWalletDetailsStart());
    props.closeAddWalletAmountModal();
  };

  const paypalOnError = (err) => {
    props.dispatch(getErrorNotificationMessage(err.message));
  };

  const paypalOnCancel = (data) => {
    props.dispatch(getErrorNotificationMessage(t("Payment_cancelled")));
  }

  let env = configuration.get("configData.PAYPAL_MODE");
  let currency = "USD";

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  useEffect(() => {
    if (!skipRender && !props.generateStripe.loading && Object.keys(props.generateStripe.data).length > 0) {
      setClientSecret(props.generateStripe.data.clientSecret);
      props.closeAddWalletAmountModal();
    }
    setSkipRender(false);
  }, [props.generateStripe]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Modal
        className="modal-dialog-center  withdraw-modal"
        size="md"
        centered
        show={props.addWalletAmountModal}
        onHide={props.closeAddWalletAmountModal}
      >
        {props.addWalletAmountModal === true ?
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t("add_wallet_amount")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {props.payments.loading ?
                <Skeleton count={1} height={100} />
                :
                Object.keys(props.payments.data).length > 0 &&
                  props.payments.data.user_wallet ?
                  < Row >
                    <Col md="12">
                      <div className="mb-5">
                        <h4 className="text-muted">
                          {t("wallet_balance")}:{" "}
                          <span className="text-muted">
                            {props.payments.data.user_wallet
                              ? props.payments.data.user_wallet.remaining
                              : null}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  :
                  null
              }
              <div className="floating-form">
                <div className="floating-label">
                  <input
                    className="floating-input"
                    type="number"
                    placeholder={t("amount")}
                    min="1"
                    step="any"
                    value={amount}
                    onChange={(event) =>
                      setAmount(event.currentTarget.value)
                    }
                  />
                  <span className="highlight"></span>
                  <label className="default-label">{t("enter_amount")}</label>
                </div>

                <div className="floating-label">
                  <label className="label-default-1">{t("payment_type")}</label>
                  <Form className="mt-4">
                    {["radio"].map((type) => (
                      <div key={`custom-inline-${type}`} className="form-checks mb-3">
                        <Form.Check
                          custom
                          inline
                          label="Card"
                          type={type}
                          id="card"
                          value="card"
                          name="payment_type"
                          defaultChecked={true}
                          onChange={() => setPaymentType("card")}
                        />

                        <Form.Check
                          custom
                          inline
                          label="Paypal"
                          type={type}
                          id="paypal"
                          value="paypal"
                          name="payment_type"
                          onChange={() => setPaymentType("paypal")}
                        />

                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger cancel-btn"
                onClick={() => props.closeAddWalletAmountModal()}
              >
                {t("cancel")}
              </Button>
              {paymentType === "paypal" && amount != 0 ? (
                <PaypalExpressBtn
                  env={env}
                  client={client}
                  currency={currency}
                  total={amount}
                  onError={paypalOnError}
                  onSuccess={paypalOnSuccess}
                  onCancel={paypalOnCancel}
                />
              ) : null}
              {paymentType === "card" && amount != 0 ? (
                <Button
                  type="button"
                  className="btn btn-success pay-btn"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                >
                  {props.generateStripe.loadingButtonContent != null
                    ? props.generateStripe.loadingButtonContent
                    : t("submit")}
                </Button>
              ) : ''}

            </Modal.Footer>
          </Form>
          :
          null
        }
      </Modal>
      {paymentAddCard && clientSecret ?
        <Elements options={options} stripe={stripePromise}>
          <StripePaymentSec
            closePaymentAddCardModal={closePaymentAddCardModal}
            clientSecret={clientSecret} />
        </Elements>
        : null
      }

    </>
  )
}

const mapStateToPros = (state) => ({
  payments: state.wallet.walletData,
  addAmount: state.wallet.addMoneyInput,
  generateStripe: state.wallet.generateStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(AddWalletAmountModal));