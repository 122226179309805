import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Image,
} from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoEndStart,
} from "../../store/actions/LiveVideoAction";
import {
  unFollowUserStart,
  followUserStart,
} from "../../store/actions/FollowAction";
import configuration from "react-global-configuration";
import "../VideoCall/VideoCall.css"
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const $ = window.$;

const AgoraLive = (props) => {

  var rtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };

  const localTrackState = {
    videoTrackEnabled: true,
    audioTrackEnabled: true,
  };

  var options = {
    // Pass your app ID here.
    appId: configuration.get("configData.agora_app_id"),
    // set UID
    uid: props.isOwner ? 1 : 0,
    // Set the channel name.
    channel: props.liveVideo.data.virtual_id,
    // Pass a token if your project enables the App Certificate.
    token: props.liveVideo.data.agora_token
      ? props.liveVideo.data.agora_token
      : null,
    // Set the user role in the channel. // "audience"
    role: props.isOwner ? "host" : "audience",
  };

  var remoteUsers = {};

  async function startBasicCall() {
    AgoraRTC.setLogLevel(4);
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    client.setClientRole(options.role);

    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      // If the subscribed track is video.
      if (mediaType === "video") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;

        remoteVideoTrack.play("agora_local");
        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      props.dispatch(
        liveViewerUpdateStart({
          live_video_id: props.liveVideo.data.live_video_id,
        })
      );

      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }
    });

    const uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token || null,
      options.uid || null
    );

    if (options.role === "host") {
      // Create an audio track from the audio sampled by a microphone.
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera.
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      // Publish the local audio and video tracks to the channel.
      rtc.localVideoTrack.play("agora_local");

      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    }
  }

  async function leaveCall() {
    if (options.role === "host") {
      // Destroy the local audio and video tracks.
      // rtc.localAudioTrack.close();
      // rtc.localVideoTrack.close();

      // Traverse all remote users.
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
      props.dispatch(
        liveVideoEndStart({
          live_video_id: props.liveVideo.data.live_video_id,
        })
      );
    } else {
      // Leave the channel.
      await rtc.client.leave();
      window.location.assign("/live-videos-history");
    }
  }

  async function muteAudio() {
    console.log("Mute audio");
    if (!rtc.localAudioTrack) return;
    console.log("rtc.localAudioTrack", rtc.localAudioTrack);
    if (localTrackState.audioTrackEnabled == true) {
      await rtc.localAudioTrack.setEnabled(false);
      localTrackState.audioTrackEnabled = false;
      $("#mute-audio").hide();
      $("#unmute-audio").show();
    } else {
      await rtc.localAudioTrack.setEnabled(true);
      localTrackState.audioTrackEnabled = true;
      $("#mute-audio").show();
      $("#unmute-audio").hide();
    }
  }

  async function muteVideo() {
    if (!rtc.localVideoTrack) return;
    if (localTrackState.videoTrackEnabled == true) {
      await rtc.localVideoTrack.setEnabled(false);
      localTrackState.videoTrackEnabled = false;
      $("#mute-video").hide();
      $("#unmute-video").show();
    } else {
      await rtc.localVideoTrack.setEnabled(true);
      localTrackState.videoTrackEnabled = true;
      $("#mute-video").show();
      $("#unmute-video").hide();
    }
  }

  startBasicCall();

  return (
    <>
      <div className="app-main">
        <div className="video-call-wrapper">
          <div className="video-participant">
            <div className="participant-actions">
              <ul className="list-unstyled participant-notify">
                <li as="li">
                  {props.liveVideo.data.status === 0 ? (
                    <h6>{t("live")}</h6>
                  ) : (
                    <h6>{t("offline")}</h6>
                  )}
                </li>
                <li as="li">
                  <i className="fas fa-eye"></i>
                  {props.liveVideo.data.viewer_cnt}
                </li>
                {props.isOwner ? (
                  <li as="li">
                    <Link
                      to="#"
                      onClick={(e) =>
                        window.confirm("Are you sure? You want to end the Live Show")
                          ? leaveCall()
                          : e.preventDefault()
                      }
                      title="Close"
                    >
                      <i className="fas fa-close"></i>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                      </svg> */}
                    </Link>
                  </li>
                ) : (
                  <li as="li">
                    <Link to={"/"} title="Close">
                      <i className="fas fa-close"></i>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                      </svg> */}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div className="live-video-icons">
              <div className="live-action-icon-sec">
                <ul className="list-unstyled live-action-flex">
                  {props.isOwner ? (
                    <>
                      <li as="li" id="mute-audio">
                        <Link to="#" onClick={() => muteAudio()} title="Mute">
                          <Image
                            src={
                              window.location.origin +
                              "/images/icons/audio.png"
                            }
                            className="action-live-icon"
                          />
                        </Link>
                      </li>
                      <li
                        as="li"
                        id="unmute-audio"
                        style={{ display: "none" }}
                      >
                        <Link to="#" onClick={() => muteAudio()} title="Unmute">
                          <Image
                            src={
                              window.location.origin +
                              "/images/icons/mute.png"
                            }
                            className="action-live-icon"
                          />
                        </Link>
                      </li>
                      <li as="li" id="mute-video">
                        <Link
                          to="#"
                          onClick={() => muteVideo()}
                          title="Close Cam"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/images/icons/video-white.png"
                            }
                            className="action-live-icon"
                          />
                        </Link>
                      </li>
                      <li
                        as="li"
                        id="unmute-video"
                        style={{ display: "none" }}
                      >
                        <Link
                          to="#"
                          onClick={() => muteVideo()}
                          title="Open Cam"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/images/icons/mute-video.png"
                            }
                            className="action-live-icon"
                          />
                        </Link>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            {props.isOwner ? (
              <Link
                to={`/` + props.liveVideo.data.user_unique_id}
                className="name-tag"
              >
                {t("you")}
              </Link>
            ) : (
              <>
                <Link
                  to={`/` + props.liveVideo.data.user_unique_id}
                  className="name-tag"
                >
                  {props.liveVideo.data.user_displayname}
                </Link>
                <div className="live-follow-btn">
                  {props.liveVideo.data.is_following ?
                    <Button
                      onClick={() => {
                        props.dispatch(
                          unFollowUserStart({
                            user_id: props.liveVideo.data.user_id,
                          })
                        );
                      }}
                    >
                      {t("unfollow")}
                    </Button>
                    :
                    <Button
                      onClick={() => {
                        props.dispatch(
                          followUserStart({
                            user_id: props.liveVideo.data.user_id,
                          })
                        );
                      }}
                    >
                      {t("follow")}
                    </Button>
                  }
                </div>
              </>
            )}
            <div
              id="agora_local"
              style={{ width: "100%", height: "100%", position: "relative" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
  userDetails: state.otherUser.userDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AgoraLive));
