import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./Profile.css";
import * as Yup from "yup";
import ProfileSidebar from "./ProfileSidebar";
import { deleteAccountStart } from "../../store/actions/UserAction";

const DeactivateAccountIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("password_is_required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("space_values_not_valid"))
      .min(6, t("must_have_6_characters")),
  });

  useEffect(() => {
    if (!skipRender && !props.deleteAccount.loading && Object.keys(props.deleteAccount.data).length > 0) {
      navigate("/logout");
    }
    setSkipRender(false);
  }, [props.deleteAccount]);

  const handleSubmit = (values) => {
    props.dispatch(deleteAccountStart(values));
  };

  return (
    <>
      <div className="home-page-sec">
        <Container fluid className="p-0">
          <div className="new-category-sec">
            <div className="new-category-left-sec">
              <ProfileSidebar />
            </div>
            <div className="new-category-right-sec">
              <div className="profile-sidebar-right-sec">
                <div className="profile-sidebar-right-detail-sec right-section-inactivate">
                  <div className="profile-right-box">
                    <h3>{t("inactivate")}</h3>
                    <p>{t("delete_account_para")}</p>
                  </div>
                  <div className="profile-details-form">
                    <Formik
                      initialValues={{
                        password: ""
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      <FORM>
                        <Row>
                          <Col md={6}>
                            <InputGroup className="mb-3">
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder={t("enter_your_password")}
                                className="form-control"
                              />
                              <InputGroup.Text className="delete-passwd">
                                <button
                                  onClick={() =>
                                    setShowPassword(!showPassword)
                                  }
                                  className="btn password-eye"
                                  type="button"
                                >{showPassword ?
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                                  />
                                  :
                                  <Image
                                    className="pass-eye-open"
                                    src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                                  />
                                  }
                                </button>
                              </InputGroup.Text>
                              <ErrorMessage
                                component={"div"}
                                name="password"
                                className="text-danger pass-txt"
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <div className="submit-btn">
                          <Button type="submit"
                            className="default-btn"
                            disabled={props.deleteAccount.buttonDisable}
                          >{props.deleteAccount.loadingButtonContent !== null ?
                            props.deleteAccount.loadingButtonContent
                            :
                            t("deactivate_account")
                            }</Button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  deleteAccount: state.users.deleteAccount,
})
function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(DeactivateAccountIndex));
