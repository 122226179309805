import React from 'react';
import { Row,Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const OrderViewLoader = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="order-view-card">
            <div className="order-view-img-sec">
              <Skeleton className="order-view-img" count={1} height={200} />
            </div>
            <div className="order-view-info">
              <Row>
                <Col md={6}>
                  <h4><Skeleton className="mb-2" count={6} height={30} /></h4>
                </Col>
                <Col md={6}>
                  <h4><Skeleton className="mb-2" count={6} height={30} width={150} /></h4>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="order-view-summary-sec">
            <h3 className="sub-title">
              <Skeleton className="mb-2" count={1} height={15} width={100} />
            </h3>
            <div className="product-details-body-sec">
              <Row>
                <Col md={6}>
                  <h4><Skeleton className="mb-2" count={6} height={30} /></h4>
                </Col>
                <Col md={6}>
                  <h4><Skeleton className="mb-2" count={6} height={30} width={150} /></h4>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default OrderViewLoader