import React from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const LandingFooter = () => {
  return (
    <>
      <div className="new-footer-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={4} className="resp-marg-btm-xs">
              <div className="new-copyrighttext">
                {configuration.get("configData.copyright_content")}
              </div>
            </Col>
            <Col md={8}>
              <div className="new-footer-link-sec">
                <ul className="list-unstyled new-footer-link">
                  {configuration
                    .get("configData.footer_pages1")
                    .map((static_page, index) => (
                      <li className="text-capitalize"
                        key={"sp-" + static_page.static_page_unique_id}
                      >
                        <Link
                          className="footer-static-page"
                          to={`/page/${static_page.static_page_unique_id}`}
                          key={static_page.static_page_unique_id}
                        >
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
                {configuration
                    .get("configData.footer_pages2").length > 0 && (
                <ul className="list-unstyled new-footer-link mt-3">
                  {configuration
                    .get("configData.footer_pages2")
                    .map((static_page, index) => (
                      <li className="text-capitalize"
                        key={"sp-" + static_page.static_page_unique_id}
                      >
                        <Link
                          className="footer-static-page"
                          to={`/page/${static_page.static_page_unique_id}`}
                          key={static_page.static_page_unique_id}
                        >
                          {static_page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingFooter;
