import React from "react";
import { Container, Image, Row, Col, Form, InputGroup, Navbar, Nav, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

const NewHeaderIndex = () => {
  return (
    <>
     <div className="new-header-sec">
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand href="#home">
                <Image
                  className="lazyload logo"
                  src={
                    window.location.origin + "/images/outfyt/logo-white.png"
                  }
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="lazyload hamburger-menu"
                src={
                  window.location.origin + "/images/hamburger-menu.png"
                }
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="#">CATEGORIES</Nav.Link>
                <Nav.Link href="#">DESIGNERS</Nav.Link>
                <Nav.Link href="#">RETAILERS</Nav.Link>
              </Nav>
              <div className="new-header-search-sec m-auto">
                  <Form>
                    <InputGroup className="mb-0">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#fff"
                          x="0"
                          y="0"
                          enableBackground="new 0 0 513.749 513.749"
                          version="1.1"
                          viewBox="0 0 513.749 513.749"
                          xmlSpace="preserve"
                        >
                          <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </Form>
                </div>
              <Nav>
                <Nav.Link href="#">BECOME A SELLER</Nav.Link>
                <Nav.Link href="#">MESSAGES</Nav.Link>
                <Nav.Link href="#">ACCOUNT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
     </div>
    </>
  );
};

export default NewHeaderIndex;
