import {
    FETCH_PICKUP_ADDRESS_START,
    FETCH_PICKUP_ADDRESS_SUCCESS,
    FETCH_PICKUP_ADDRESS_FAILURE,
    SAVE_PICKUP_ADDRESS_START,
    SAVE_PICKUP_ADDRESS_SUCCESS,
    SAVE_PICKUP_ADDRESS_FAILURE,
    DELETE_PICKUP_ADDRESS_START,
    DELETE_PICKUP_ADDRESS_SUCCESS,
    DELETE_PICKUP_ADDRESS_FAILURE,
    SET_DEFAULT_PICKUP_ADDRESS_START,
    SET_DEFAULT_PICKUP_ADDRESS_SUCCESS,
    SET_DEFAULT_PICKUP_ADDRESS_FAILURE,
    VIEW_PICKUP_ADDRESS_START,
    VIEW_PICKUP_ADDRESS_SUCCESS,
    VIEW_PICKUP_ADDRESS_FAILURE,
} from './ActionConstant';


export function fetchPickupAddressStart(data) {
    return {
        type: FETCH_PICKUP_ADDRESS_START,
        data,
    };
}

export function fetchPickupAddressSuccess(data) {
    return {
        type: FETCH_PICKUP_ADDRESS_SUCCESS,
        data,
    };
}

export function fetchPickupAddressFailure(error) {
    return {
        type: FETCH_PICKUP_ADDRESS_FAILURE,
        error,
    };
}

export function savePickupAddressStart(data) {
    return {
        type: SAVE_PICKUP_ADDRESS_START,
        data,
    }
}

export function savePickupAddressSuccess(data) {
    return {
        type: SAVE_PICKUP_ADDRESS_SUCCESS,
        data,
    }
}

export function savePickupAddressFailure(error) {
    return {
        type: SAVE_PICKUP_ADDRESS_FAILURE,
        error,
    }
}

export function deletePickupAddressStart(data) {
    return {
        type: DELETE_PICKUP_ADDRESS_START,
        data,
    }
}

export function deletePickupAddressSuccess(data) {
    return {
        type: DELETE_PICKUP_ADDRESS_SUCCESS,
        data,
    }
}

export function deletePickupAddressFailure(error) {
    return {
        type: DELETE_PICKUP_ADDRESS_FAILURE,
        error,
    }
}

export function setDefaultPickupAddressStart(data) {
    return {
        type: SET_DEFAULT_PICKUP_ADDRESS_START,
        data,
    }
}

export function setDefaultPickupAddressSuccess(data) {
    return {
        type: SET_DEFAULT_PICKUP_ADDRESS_SUCCESS,
        data,
    }
}

export function setDefaultPickupAddressFailure(error) {
    return {
        type: SET_DEFAULT_PICKUP_ADDRESS_FAILURE,
        error,
    }
}

export function viewPickupAddressStart(data) {
    return {
        type: VIEW_PICKUP_ADDRESS_START,
        data,
    }
}

export function viewPickupAddressSuccess(data) {
    return {
        type: VIEW_PICKUP_ADDRESS_SUCCESS,
        data,
    }
}

export function viewPickupAddressFailure(error) {
    return {
        type: VIEW_PICKUP_ADDRESS_FAILURE,
        error,
    }
}