import React from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchLiveShowsHistoryStart, fetchMoreLiveShowsHistoryStart } from "../../../store/actions/LiveVideoAction";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import NoDataFound from "../../Helper/NoDataFound";
import LiveVideoCard from "../../LiveVideos/LiveVideoCard";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";

const NewLiveHistory = (props) => {

  const t = useTranslation();
  const params = useParams();

  useEffect(() => {
    props.dispatch(fetchLiveShowsHistoryStart({
      user_unique_id: params.user_unique_id,
      skip: 0,
      take: 12,
    }))
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreLiveShowsHistoryStart({
        user_unique_id: params.user_unique_id,
        skip: props.liveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  return (
    <div className="category-card-sec mt-4">
      {props.liveVideos.loading ?
        <LiveVideoLoader />
        :
        Object.keys(props.liveVideos.data).length > 0 &&
          props.liveVideos.data.live_videos.length > 0 ?
          <>
            <InfiniteScroll
              dataLength={props.liveVideos.data.live_videos.length}
              next={fetchMoreData}
              hasMore={
                props.liveVideos.data.live_videos.length <
                props.liveVideos.data.total}
              loader={<LiveVideoLoader />}
            >
              <div className="new-home-latest-live-streaming-box-video">
                {props.liveVideos.data.live_videos.map(
                  (live_video, i) => (
                    <LiveVideoCard live_video={live_video} />
                  ))}
              </div>
            </InfiniteScroll>
          </>
          :
          <NoDataFound />
      }
    </div >
  );
};

const mapStateToProps = (state) => ({
  liveVideos: state.homepage.liveShowsHistory,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(NewLiveHistory));
