import React from 'react';
import { Row,Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const WalletLoader = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="wallet-box">
            <div className="wallet-card">
              <Skeleton count={1} height={200} />
            </div>
            <div className="wallet-card">
              <Skeleton count={1} height={200} />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Skeleton count={1} height={200} />
        </Col>
      </Row>
    </>
  )
}

export default WalletLoader;