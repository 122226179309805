import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewChatList from "./NewChatList";
import NewChatRoom from "./NewChatRoom";
import { connect } from "react-redux";
import { changeChatAudio, chatUser } from "../../../store/actions/ChatAction";
import useWindowDimensions from "../../Helper/WindowHelper";
import { useTranslation, withTranslation } from "react-multi-lang";
import HeaderIndex from "../../layouts/Header/HeaderIndex";
import NewFooterIndex from "../Home/NewFooterIndex";

const NewChatIndex = (props) => {
  const t = useTranslation();

  const [showContent, setShowContent] = useState(true);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (props.chatUser) {
      props.dispatch(changeChatAudio({ src: "" }));
      setTimeout(() => {
        setSelectedUser(props.chatUser);
        if (!skipRender && width < 768) {
          window.location.assign("/chat-room");
        }
      }, 100);
    }
    setSkipRender(false);
  }, [props.chatUser]);

  // useEffect(() => {
  //   if (selectedUser) {
  //     props.dispatch(chatUser(selectedUser));
  //   }
  // }, [selectedUser]);

  return (
    <>
      <div className="new-outfyt-chat-page-sec">
        <div className="new-main-wrapper">
          <Container>
            <div className="chat-ui-sec">
              {showContent
                ?
                <><div className="chat-ui-sidebar">
                  <NewChatList
                    setShowContent={setShowContent}
                    setSelectedUser={setSelectedUser}
                  />
                </div>
                  <div className="chat-ui-main-wrapper order-bottom">
                    {selectedUser ?
                      <>
                        <NewChatRoom selectedUser={selectedUser} setShowContent={setShowContent} />
                      </>
                      : <div className="new-chat-room-sec start-conversation-container mobile-hide">
                        <Image
                          className="start-conversation"
                          src={window.location.origin + "/images/start-new-conversation.png"}
                        />
                      </div>
                    }
                  </div>
                </>
                : <div className="chat-something-went-wrong">
                  <Image
                    src={window.location.origin + "/images/start-new-conversation.png"}
                  />
                  <button
                    className="btn gradient-btn gradientcolor btn btn-primary retry-btn"
                    onClick={() => {
                      setSelectedUser(null);
                      setShowContent(true)
                    }}
                  >{t("retry")}</button>
                  <Link to="/home">{t("home")}</Link>
                </div>
              }
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  chatUser: state.chat.chatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewChatIndex));
