import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Wallet.css";
import { connect } from "react-redux";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { fetchAllTransactionStart, fetchMoreAllTransactionStart } from "../../store/actions/TransactionAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import AddWalletAmountModal from "../Helper/AddWalletAmountModal";
import WithdrawModal from "../Helper/WithdrawModal";
import WalletLoader from "../Helper/WalletLoader";

const WalletIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();
  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    props.dispatch(fetchAllTransactionStart({
      skip: 0,
      take: 12,
    }))
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreAllTransactionStart({
        skip: props.transaction.data.history.length,
        take: 12,
      })
    );
  };

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  }

  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <div className="single-page-desc">
                <h3>{t("overview")}</h3>
                {/* <p>{t("overview")}</p> */}
              </div>
            </div>
          </div>
          <div className="wallet-header-sec">
            {props.wallet.loading ?
              <WalletLoader />
              :
              <Row>
                <Col md={8}>
                  {
                    Object.keys(props.wallet.data).length > 0 &&
                      props.wallet.data.user_wallet
                      ?
                      <div className="wallet-box">
                        <div className="wallet-card">
                          <div className="wallet-card-left-border"></div>
                          <div className="wallet-icon-sec">
                            <Image src={window.location.origin + "/images/wallet/wallet-1.svg"} />
                          </div>
                          <div className="wallet-info">
                            <h6>{t("total_balance")}</h6>
                            <h3>{props.wallet.data.user_wallet.total_formatted}</h3>
                            {props.wallet.data.user_wallet.total_formatted === "$0.00"
                              ? <p>{t("please_add_amount_to_the_account")}</p>
                              : null
                            }
                          </div>
                        </div>
                        <div className="wallet-card">
                          <div className="wallet-card-left-border"></div>
                          <div className="wallet-icon-sec">
                            <Image src={window.location.origin + "/images/wallet/wallet-1.svg"} />
                          </div>
                          <div className="wallet-info">
                            <h6>{t("wallet_balance")}</h6>
                            <h3>{props.wallet.data.user_wallet.remaining_formatted}</h3>
                            {props.wallet.data.user_wallet.remaining_formatted === "$0.00"
                              ? <p>{t("please_add_amount_to_the_account")}</p>
                              : null
                            }
                          </div>
                        </div>
                      </div>
                      :
                      <NoDataFound />
                  }
                </Col>
                <Col md={4}>
                  <div className="wallet-btn-box">
                    {/* <div className="wallet-btn-img-card">
                    </div> */}
                    <div className="wallet-btn-card">
                      <Button
                        className="add-money-btn"
                        onClick={() => setAddWalletAmountModal(true)}
                      >
                        {t("add_money")}
                        <Image src={window.location.origin + "/images/wallet/add-money-icon.svg"} className="wallet-btn-icon" />
                      </Button>
                      <Button
                        className="add-money-btn"
                        onClick={() => setWithdrawModal(true)}
                      >
                        {t("withdraw_money")}
                        <Image src={window.location.origin + "/images/wallet/withdraw-money-icon.svg"} className="wallet-btn-icon" />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </div>
          <div className="wallet-body">
            <div className="order-list-table">
              {props.transaction.loading ?
                <Skeleton count={5} height={100} />
                :
                Object.keys(props.transaction.data).length > 0 &&
                  props.transaction.data.history.length > 0
                  ?
                  <InfiniteScroll
                    dataLength={props.transaction.data.history.length}
                    next={fetchMoreData}
                    hasMore={
                      props.transaction.data.history.length <
                      props.transaction.data.total}
                    loader={[...Array(4)].map((i) => (
                      <Skeleton count={1} height={100} />
                    ))}
                  >
                    < Table responsive>
                      <thead>
                        <tr>
                          <th>{t("status")}</th>
                          <th>{t("paid_amount")}</th>
                          <th>{t("service_fee")}</th>
                          <th>{t("payment_type")}</th>
                          <th>{t("received")}</th>
                          <th>{t("payment_id")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.transaction.data.history.map((wallet) => (
                          <tr>
                            {wallet.status_formatted === "Waiting" ?
                              <td className="text-danger">
                                {wallet.status_formatted}
                              </td> :
                              <td className="text-success">
                                {wallet.status_formatted}
                              </td>
                            }
                            <td>{wallet.paid_amount_formatted}</td>
                            <td>{wallet.paid_amount_formatted}
                            </td>
                            <td className="text-capitalize">{wallet.payment_type}</td>
                            <td>
                              {wallet.received_from_username
                                ? wallet.received_from_username
                                : t("you")}
                            </td>
                            <td>{wallet.payment_id}</td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                  :
                  <NoDataFound />
              }
            </div>
          </div>
        </Container>
      </div >
      <AddWalletAmountModal
        addWalletAmountModal={addWalletAmountModal}
        closeAddWalletAmountModal={closeAddWalletAmountModal}
        payments={props.wallet}
      />

      {withdrawModal ?
        <WithdrawModal
          withdrawModal={withdrawModal}
          closeWithdrawModal={closeWithdrawModal}
          payments={props.wallet}
        />
        :
        null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  transaction: state.transaction.allTransaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(WalletIndex));

