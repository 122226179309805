import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "./Ecom.css";
import { Link } from "react-router-dom";
import {
  fetchCartListStart,
  removeCartDetailsStart,
  ordersPaymentByPaypalStart,
} from "../../../store/actions/ProductsAction";
import {
  fetchDeliveryAddressStart,
} from "../../../store/actions/DeliveryAddressAction";
import { connect } from "react-redux";
import NoDataFound from "../../Helper/NoDataFound";
import configuration from "react-global-configuration";
import { useTranslation, withTranslation } from "react-multi-lang";
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";
import { createOrderByWalletStart } from "../../../store/actions/OrderAction";
import AddWalletAmountModal from "../../Helper/AddWalletAmountModal";
import ProductsPaymentModal from "./ProductsPaymentModal";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const EcomPaymentIndex = (props) => {

  const t = useTranslation();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState("card");
  const [formAddress, setFormAddress] = useState();
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();

  const [newAddressInputData, setNewAddressInputData] = useState({
    name: "",
    contact_number: "",
    address: "",
    landmark: "",
    pincode: "",
    state: ""
  });
  const [isOnlyWalletPayment, setIsOnlyWalletPayment] = useState(
    configuration.get("configData.is_only_wallet_payment")
  );

  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  }

  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  useEffect(() => {
    props.dispatch(fetchCartListStart());
    props.dispatch(fetchDeliveryAddressStart());
    props.dispatch(fetchWalletDetailsStart());
  }, []);

  const handleDeliveryAddressSelect = (address) => {
    setSelectedAddress(address);
    setNewAddressInputData({
      name: "",
      contact_number: "",
      address: "",
      landmark: "",
      pincode: "",
      state: ""
    })
  };

  const handlePaymentChange = (payment) => {
    setSelectedPayment(payment);
  };

  useEffect(() => {
    switch (selectedPayment) {

      case "wallet": {
        props.dispatch(fetchWalletDetailsStart());
        break;
      }
      default:
        return console.log("select payment");
    }
  }, [selectedPayment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.dispatch(
      createOrderByWalletStart({
        delivery_address_id: selectedAddress.delivery_address_id,
      })
    );
  }

  useEffect(() => {
    if (selectedAddress === "" &&
      !props.deliveryAddress.loading &&
      props.deliveryAddress.data.delivery_addresses.length > 0) {
      let address = props.deliveryAddress.data.delivery_addresses.filter(
        (address) => address.is_default === 1
      );
      setSelectedAddress(address[0]);
    }
  }, [props.deliveryAddress]);

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  useEffect(() => {
    if (!skipRender && !props.wallet.loading && props.wallet.data.user_wallet) {
      setFormAddress(props.wallet.data.user_wallet.remaining_formatted);
    }
    setSkipRender(false);
  }, [props.wallet]);

  useEffect(() => {
    if (!skipRender && !props.ordersCreateWallet.loading &&
      Object.keys(props.ordersCreateWallet.data).length > 0) {
      navigate(`/order-view/${props.ordersCreateWallet.data.unique_id}`);
    }
    setSkipRender(false);
  }, [props.ordersCreateWallet.data]);

  return (
    <>
      <div className="ecom-payment-sec">
        <Container>
          <h2 className="text-uppercase">{t("checkout")}</h2>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col md={6} className="resp-mrg-btn-xs">
                <div className="border-right-divider">
                  {props.deliveryAddress.loading ? (
                    <Skeleton count={1} height={218} />
                  ) : (
                    <>
                      {
                        Object.keys(props.deliveryAddress.data).length > 0 &&
                          props.deliveryAddress.data.delivery_addresses.length > 0 ?

                          <Row className="payment-method">
                            {props.deliveryAddress.data.delivery_addresses.map(
                              (address, index) => (
                                <>
                                  <Col
                                    lg={12}
                                    className="resp-mrg-btn-xs mb-3"
                                    key={index}
                                  >
                                    <div className="radiobtn payment-method-card-1">
                                      <input
                                        type="radio"
                                        // id="inline-radio-1"
                                        id={`inline-radio-${index + 1}`}
                                        className="form-check-input"
                                        checked={
                                          selectedAddress != null &&
                                            selectedAddress.delivery_address_id ==
                                            address.delivery_address_id
                                            ? true
                                            : false
                                        }
                                        name="delivery_address"
                                      />
                                      <label
                                        type="radio"
                                        for="inline-radio-1"
                                        className="form-check-label card-label"
                                        onClick={() =>
                                          handleDeliveryAddressSelect(address)
                                        }
                                      >
                                        <p className="no-margin mb-3">
                                          <span className="card-option">
                                            {address.contact_number}
                                          </span>
                                        </p>
                                        <p className="no-margin mb-3">
                                          <span className="card-option">
                                            {address.landmark}
                                          </span>
                                        </p>

                                        <p className="no-margin mb-3">
                                          <span className="card-option">
                                            {address.address}
                                          </span>
                                        </p>
                                        <p className="no-margin mb-3">
                                          <span className="card-option">
                                            {address.country}
                                          </span>
                                        </p>
                                      </label>
                                    </div>
                                  </Col>
                                </>
                              )
                            )}
                          </Row>
                          :
                          <Link
                          className="ecom-delivery-btn"
                           to="/delivery-address">{t("add_delivery_address")}</Link>
                      }
                    </>
                  )}
                  <div className="shipping-address-sec">

                    <Row className="payment-method mb-3 ">
                      <Col md={6} className="mt-4">
                        <div className="radiobtn payment-method-card-1">
                          <Form.Control
                            type="radio"
                            id="inline-radio-3"
                            className="form-check-input"
                            checked={selectedPayment == "wallet" ? true : false}
                          />
                          <label
                            type="radio"
                            for="inline-radio-3"
                            className="form-check-label"
                            onClick={() => handlePaymentChange("wallet")}
                          >
                            {t("wallet")}
                          </label>
                        </div>
                      </Col>
                      <Col md={6} className="mt-4">
                        <div className="radiobtn payment-method-card-1">
                          <Form.Control
                            type="radio"
                            id="inline-radio-3"
                            className="form-check-input"
                            checked={selectedPayment == "card" ? true : false}
                          />
                          <label
                            type="radio"
                            for="inline-radio-3"
                            className="form-check-label"
                            onClick={() => handlePaymentChange("card")}
                          >
                            {t("card")}
                          </label>
                        </div>
                      </Col>
                    </Row>
                    {selectedPayment == "wallet" ? (
                      props.wallet.loading ? (
                        <div className="radiobtn mb-4 payment-method-card">
                          <Skeleton count={1} height={112} />
                        </div>
                      ) : (
                        <>
                          {props.wallet.data.user_wallet && (
                            <>
                              <Row className="payment-method">
                                <Col md={12}>
                                  <div className="radiobtn mb-4 payment-method-card">
                                    <div>
                                      <h3 className="payment-head-tit">
                                        {t("wallet_balance")} :{" "}
                                        {
                                          props.wallet.data.user_wallet
                                            .remaining_formatted
                                        }&nbsp;
                                        <Button onClick={() => setAddWalletAmountModal(true)}
                                          className="continue-shipping-btn ml-4">
                                          {t("add_wallet_amount")}
                                        </Button>
                                      </h3>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )
                    ) : null}
                    <div className="ecom-payment-btn-sec mt-4">
                      <Button
                        disabled={!selectedAddress}
                        onClick={() => setPaymentModal(selectedPayment)}
                        className="continue-shipping-btn">
                        {t("buy_now")}
                      </Button>

                      <Link to={`/ecom-cart`} className="return-to-cart-link">
                        {t("return_to_cart")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                {props.cartList.loading ? (
                  <div className="ecom-payment-product-details-sec">
                    <Skeleton count={1} height={200} />
                  </div>
                ) : props.cartList.data.carts.length > 0 ? (
                  <div className="ecom-payment-product-details-sec">
                    {props.cartList.data.carts.map((cart) => (
                      <div className="product-details-header-sec">
                        <div className="product-details-header-card">
                          <div className="product-details-info">
                            <div>
                              {cart.user_product.file_type == "image" ?
                                <Link
                                  to={`/single-product/${cart.user_product.unique_id}`}
                                >
                                  <Image
                                    className="product-thumbnail-img"
                                    src={cart.user_product.picture}
                                  />
                                </Link>
                                :
                                <Link
                                  to={`/single-product/${cart.user_product.unique_id}`}
                                >
                                  <video
                                    autoplay
                                    controls
                                    id="myVideo"
                                    className="product-thumbnail-img"
                                  >
                                    <source src={cart.user_product.picture} type="video/mp4" />
                                  </video>
                                </Link>
                              }
                            </div>
                            <div>
                              <h6><span className="product-name">{t("product_name")} </span> :
                                <Link
                                  to={`/single-product/${cart.user_product.unique_id}`}
                                >{cart.user_product.name}
                                </Link></h6>
                              <h6>  {cart.user_product_variant || cart.user_product_size ?
                                <>
                                  {cart.user_product_variant ?
                                    <p><span className="product-name">{t("product_variant")}</span> : {cart.user_product_variant.name}</p>
                                    :
                                    ''
                                  }
                                </>
                                : ''}</h6>
                              <h6 className="mt-3"><span className="product-name">{t("product_quantity")} : </span></h6>
                            </div>
                          </div>
                          <div className="ecom-payment-product-amount">
                            <p>{cart.sub_total_formatted}</p>
                            <p className="mt-3">{cart.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="product-details-body-sec">
                      <div className="product-details-card">
                        <h5>{t("subtotal")}</h5>
                        <p className="product-amount">
                          {props.cartList.data.sub_total_formatted}
                        </p>
                      </div>
                      <div className="product-details-card">
                        <h5>{t("shipping")}</h5>
                        <p>{t("calculated_at_next_step")}</p>
                      </div>
                    </div>
                    <div className="product-details-footer-sec">
                      <h5>{t("total")}</h5>
                      <div className="product-details-final-amount">
                        <span>
                          {configuration.get("configData.currency_code")}
                        </span>
                        <p>{props.cartList.data.total_formatted}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NoDataFound />
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <AddWalletAmountModal
        addWalletAmountModal={addWalletAmountModal}
        closeAddWalletAmountModal={closeAddWalletAmountModal}
        payments={props.wallet}
      />

      {paymentModal && !props.deliveryAddress.loading &&
        Object.keys(props.deliveryAddress.data).length > 0 ?
        <ProductsPaymentModal
          delivery_address_id={selectedAddress.delivery_address_id}
          paymentModal={paymentModal}
          closePaymentModal={closePaymentModal}
          cart={props.cartList}
          cartAmount={props.cartList.data.total_formatted}
        />
        :
        ''}
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.userProducts.cartList,
  deliveryAddress: state.deliveryAddress.deliveryAddress,
  wallet: state.wallet.walletData,
  ordersCreateWallet: state.order.ordersCreateWallet
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(EcomPaymentIndex));
