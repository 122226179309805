import React, { useState, useEffect } from "react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
    Container,
    Image,
    Nav,
    Tab,
    Row,
    Col,
    InputGroup,
    Form,
    Table,
    Button
} from "react-bootstrap";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changePasswordStart } from "../../store/actions/UserAction";
import "./Profile.css";
import ProfileSidebar from "./ProfileSidebar";
import { fetchStripeConnectStart, getStripeConnectUrlStart, deleteStripeConnectStart } from "../../store/actions/UserAction";

const StripeConnectIndex = (props) => {
    const t = useTranslation();
    const navigate = useNavigate();
    const [skipRender, setSkipRender] = useState(true);
    const [showPassword, setShowPasswordVisible] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    })

    const validationSchema = Yup.object().shape({
        old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")).required(t("required")),
        password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
            .required(t("required")),
        password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
            .required(t("required"))
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    t("passwords_need_to_be_same")
                ),
            }),
    });
    useEffect(() => {
        if (!skipRender &&
            !props.changePassword.loading &&
            props.changePassword.data.success === true &&
            props.changePassword.data.code === 104) {
            navigate("/logout");
        }
        setSkipRender(false);
    }, [props.changePassword]);


    const [inputData, setInputData] = useState({});

    useEffect(() => {
        if (props.stripeConnect.loading) props.dispatch(fetchStripeConnectStart());
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(getStripeConnectUrlStart(inputData));
    };

    const handleRemove = (event) => {
        event.preventDefault();
        props.dispatch(deleteStripeConnectStart());
    }


    return (
        <>
            <div className="home-page-sec">
                <Container fluid className="p-0">
                    <div className="new-category-sec">
                        <div className="new-category-left-sec">
                            <ProfileSidebar />
                        </div>
                        <div className="new-category-right-sec">
                            <div className="profile-sidebar-right-sec">
                                <div className="profile-sidebar-right-detail-sec right-section">
                                    <div className="profile-right-box">
                                        <h3 className="title">Stripe Connect</h3>
                                        <h6>As a Creator, you may charge users to create request, therefore, you are required to connect your stripe account so our system can automatically transfer your payments into your stripe account. Stripe will then transfer your funds into your bank account.</h6>

                                        <br></br>
                                        <h6><b>Account Status: {props.stripeConnect.data.account_status == 0 ? 'Not connected' : props.stripeConnect.data.account_status == 2 ? 'Verified' : 'Pending'}</b></h6>
                                        <h6><b>Email Address: {props.stripeConnect.data.stripe_connected_account_email}</b></h6>
                                        <h6><b>Is Connected: {props.stripeConnect.data.stripe_connected_account_email ? 'Yes' : 'No'}</b></h6>
                                    </div>
                                    <div className="profile-details-form">
                                        {props.stripeConnect.data.stripe_connected_account_email == '' ?
                                            <Form
                                                className="add-bank-form"
                                                onSubmit={handleSubmit}
                                                method="POST"
                                            >
                                                <Row>

                                                    <Col md={6}>
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Email Id *</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Email Id"
                                                                value={inputData.stripe_email}
                                                                name="stripe_email"
                                                                onChange={(event) => {
                                                                    setInputData({
                                                                        ...inputData,
                                                                        stripe_email: event.currentTarget.value,
                                                                    });
                                                                }}
                                                            />
                                                        </Form.Group>

                                                    </Col>
                                                    <div className="submit-btn">
                                                        <Button
                                                            className="btn-continue"
                                                            type="submit"
                                                            disabled={props.stripeConnectUrl.buttonDisable}
                                                        >
                                                            {props.stripeConnectUrl.loadingButtonContent !== null
                                                                ? props.stripeConnectUrl.loadingButtonContent
                                                                : "Submit"}
                                                        </Button>
                                                    </div>

                                                </Row>
                                            </Form>
                                            : ''}
                                        {props.stripeConnect.data.account_status == 1 ?

                                            <div className="submit-btn">
                                                <a
                                                    href={props.stripeConnect.data.express_connect_url ? props.stripeConnect.data.express_connect_url : '#'}
                                                    target={props.stripeConnect.data.express_connect_url ? "_blank" : ''}
                                                    className="btn-continue"
                                                >
                                                    Click me to Connect
                                                </a>
                                            </div>
                                            : props.stripeConnect.data.account_status == 2 ?
                                                <>
                                                    <Button
                                                        className="mr-2"
                                                        type="submit"
                                                        onClick={(event) =>
                                                            handleRemove(event)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>

                                                    <a
                                                        href={props.stripeConnect.data.login_url ? props.stripeConnect.data.login_url : '#'}
                                                        target={props.stripeConnect.data.login_url ? "_blank" : ''}
                                                        className="btn-continue"
                                                    >
                                                        View
                                                    </a>

                                                </>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div >
        </>
    );
};

const mapStateToPros = (state) => ({
    stripeConnect: state.users.stripeConnect,
    stripeConnectUrl: state.users.getStripeConnectUrl,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(StripeConnectIndex));
