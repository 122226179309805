import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchUserProductsForOwnerStart,
  fetchMoreUserProductsForOwnerStart,
} from "../../store/actions/ProductsAction";
import { deleteUserProductStart } from "../../store/actions/ProductsAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import TableOrderLoader from "../Helper/TableOrderLoader";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InfiniteScroll from "react-infinite-scroll-component";
import Badge from 'react-bootstrap/Badge';

const ProductsList = (props) => {

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchUserProductsForOwnerStart({
      skip: 0,
      take: 12,
    }
    ));
  }, []);

  const fetchMoreData = (event) => {
    props.dispatch(fetchMoreUserProductsForOwnerStart({
      skip: props.products.data.user_products.length,
      take: 12,
    }
    ));
  }

  const handleChange = (event) => {
    setSearch(event.target.value);
    props.dispatch(
      fetchUserProductsForOwnerStart({ search_key: event.target.value })
    );
  }

  const deleteConfirmation = (event, user_product_id) => {
    confirmAlert({
      message: t("are_you_sure_Want_to_delete_this_account"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => deleteAccount(event, user_product_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  }

  const deleteAccount = (event, user_product_id) => {
    event.preventDefault();
    props.dispatch(
      deleteUserProductStart({
        user_product_id: user_product_id,
      })
    );
  }

  const clearItem = () => {
    setSearch("");
    props.dispatch(fetchUserProductsForOwnerStart());
  }

  return (
    <>
      <div className="order-list-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="order-list-table product-list-table">

                <div className="tab-search-end search-end-sec">
                  <ul className="list-unstyled ecom-nav-link">
                    <li>
                      <Link to="/add-product">
                        <Image
                          className="add-product-img"
                          src={
                            window.location.origin + "/images/add-product.svg"
                          }
                        />
                        <span>{t("add_product")}</span>
                      </Link>
                    </li>
                  </ul>
                  <div className="search-box">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            window.location.origin +
                            "/images/order/search.svg"
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t("searches")}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={search}
                        onChange={(event) => handleChange(event)}
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        className="search-close"
                        style={{
                          border: "0",
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          cursor: "pointer",
                        }}
                      >
                        {search ?
                          <span onClick={clearItem}>
                            <i class="fa-solid fa-xmark"></i>
                          </span>
                          :
                          null
                        }
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                </div>
                {props.products.loading
                  ?
                  <TableOrderLoader />
                  :
                  Object.keys(props.products.data).length > 0 &&
                    props.products.data.user_products.length > 0 ?
                    < InfiniteScroll
                      dataLength={props.products.data.user_products.length}
                      next={fetchMoreData}
                      hasMore={
                        props.products.data.user_products.length <
                        props.products.data.total
                      }
                      loader={<TableOrderLoader />}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t("image")}</th>
                            <th>{t("product")}</th>
                            <th>{t("price")}</th>
                            <th>{t("quantity")}</th>
                            <th>{t("in_stock")}</th>
                            <th>{t("action_order")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.products.data.user_products.map((product) => (
                            <tr>
                              <td>
                                <div className="product-list-img-sec">
                                  {product.file_type == "image" ?
                                    <Image
                                      src={product.picture}
                                      className="product-list-img"
                                    />
                                    :
                                    <video
                                      autoplay
                                      controls
                                      id="myVideo"
                                      className="user-profile1 w-100"
                                    >
                                      <source src={product.picture} type="video/mp4" />
                                    </video>
                                  }
                                </div>
                              </td>
                              <td>
                                <div className="product-details">
                                  <h4>{product.name}</h4>
                                  <h6>{product.description}</h6>
                                </div>
                              </td>
                              <td>
                                <h5>{product.price}</h5>
                              </td>
                              <td>{product.quantity}</td>
                              <td>

                                {product.is_outofstock == "0" ? (
                                  <>
                                    {t("no")}
                                  </>
                                ) : (
                                  <>
                                    {t("yes")}
                                  </>
                                )}

                              </td>
                              <td>
                                <div className="product-list-action-btn">
                                  <ul className="product-list-action-icons-sec list-unstyled">
                                    <li>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip
                                          id="tooltip-edit"
                                        >{t("edit_product")}
                                        </Tooltip>}
                                      >
                                        <Link
                                          to={"/edit-product/" + product.user_product_id}
                                          className="product-list-view-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 122.88 121.51"
                                          >
                                            <path d="M28.66 1.64h30.22L44.46 16.71h-15.8a13.52 13.52 0 00-9.59 4 13.52 13.52 0 00-4 9.59v76.14h76.14a13.5 13.5 0 009.59-4 13.5 13.5 0 004-9.59V77.3l15.07-15.74v31.29a28.6 28.6 0 01-8.41 20.22v.05a28.58 28.58 0 01-20.2 8.39H11.5a11.47 11.47 0 01-8.1-3.37A11.52 11.52 0 010 110V30.3a28.58 28.58 0 018.41-20.21l.05-.09a28.58 28.58 0 0120.2-8.4zM73 76.47l-29.42 6 4.25-31.31L73 76.47zM57.13 41.68L96.3.91a2.74 2.74 0 013.39-.53l22.48 21.76a2.39 2.39 0 01-.19 3.57L82.28 67 57.13 41.68z"></path>
                                          </svg>
                                        </Link>
                                      </OverlayTrigger>
                                    </li>
                                    <li>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip
                                          id="tooltip-view">
                                          {t("view_product")}
                                        </Tooltip>}
                                      >
                                        <Link
                                          to={`/single-product/${product.unique_id}`}
                                          className="product-list-view-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0"
                                            y="0"
                                            enableBackground="new 0 0 122.88 65.06"
                                            version="1.1"
                                            viewBox="0 0 122.88 65.06"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M.95 30.01c2.92-3.53 5.98-6.74 9.15-9.63C24.44 7.33 41.46.36 59.01.01c17.51-.35 35.47 5.9 51.7 19.29 3.88 3.2 7.63 6.77 11.24 10.74a3.564 3.564 0 01.23 4.51c-4.13 5.83-8.88 10.82-14.07 14.96-12.99 10.37-28.77 15.47-44.76 15.55-15.93.07-32.06-4.86-45.8-14.57A82.228 82.228 0 01.77 34.64c-1.11-1.4-1-3.37.18-4.63zm60.49-3.55c.59 0 1.17.09 1.71.24-.46.5-.73 1.17-.73 1.9 0 1.56 1.26 2.82 2.82 2.82.77 0 1.46-.3 1.97-.8.2.6.3 1.24.3 1.9 0 3.35-2.72 6.07-6.07 6.07s-6.07-2.72-6.07-6.07c0-3.34 2.72-6.06 6.07-6.06zm0-15.64c5.99 0 11.42 2.43 15.35 6.36 3.93 3.93 6.36 9.35 6.36 15.35 0 5.99-2.43 11.42-6.36 15.35a21.628 21.628 0 01-15.35 6.36c-5.99 0-11.42-2.43-15.35-6.36a21.628 21.628 0 01-6.36-15.35c0-5.99 2.43-11.42 6.36-15.35 3.93-3.93 9.36-6.36 15.35-6.36zm10.45 11.26a14.746 14.746 0 00-10.45-4.33c-4.08 0-7.78 1.65-10.45 4.33a14.746 14.746 0 00-4.33 10.45c0 4.08 1.65 7.78 4.33 10.45 2.67 2.67 6.37 4.33 10.45 4.33 4.08 0 7.78-1.65 10.45-4.33 2.67-2.67 4.33-6.37 4.33-10.45 0-4.08-1.66-7.78-4.33-10.45zm-57 3.55a86.454 86.454 0 00-6.7 6.82c4.07 4.72 8.6 8.8 13.45 12.23 12.54 8.85 27.21 13.35 41.69 13.29 14.42-.07 28.65-4.67 40.37-14.02 4-3.19 7.7-6.94 11.03-11.25-2.79-2.91-5.63-5.54-8.51-7.92C91.33 12.51 75 6.79 59.15 7.1c-15.81.32-31.22 6.66-44.26 18.53z"></path>
                                          </svg>
                                        </Link>
                                      </OverlayTrigger>
                                    </li>
                                    <li>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip
                                          id="tooltip-delete">
                                          {t("delete_product")}
                                        </Tooltip>}
                                      >
                                        <Button className="product-list-delete-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 110.61 122.88"
                                            type="button"
                                            onClick={(event) => {
                                              deleteConfirmation(event, product.user_product_id)
                                            }}>
                                            <path d="M39.27 58.64a4.74 4.74 0 119.47 0v35.08a4.74 4.74 0 11-9.47 0V58.64zm63.6-19.86L98 103a22.29 22.29 0 01-6.33 14.1 19.41 19.41 0 01-13.88 5.78h-45a19.4 19.4 0 01-13.86-5.78 22.31 22.31 0 01-6.34-14.1L7.74 38.78H0V25c0-3.32 1.63-4.58 4.84-4.58h22.74v-9.63A10.82 10.82 0 0138.37 0h33.87A10.82 10.82 0 0183 10.79v9.62h23.35a6.19 6.19 0 011 .06 3.86 3.86 0 013.24 3.53V38.78zm-9.5.17H17.24L22 102.3a12.82 12.82 0 003.57 8.1 10 10 0 007.19 3h45a10.06 10.06 0 007.19-3 12.8 12.8 0 003.59-8.1L93.37 39zM71 20.41v-8.36H39.64v8.36zm-9.13 38.23a4.74 4.74 0 119.47 0v35.08a4.74 4.74 0 11-9.47 0V58.64z"></path>
                                          </svg>
                                        </Button>
                                      </OverlayTrigger>
                                    </li>
                                  </ul>
                                  <div className="submit-btn">
                                    <Link to={`/product-variants/${product.user_product_id}`}
                                      className="single-orders-view"
                                    >{t("product_variants")}</Link>
                                  </div>
                                  <div className="submit-btn">
                                    <Link to={`/view-order/${product.unique_id}/${product.user_product_id}`}
                                      className="single-orders-view"
                                    >{t("view_orders")}</Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                    :
                    <NoDataFound />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
const mapStateToPros = (state) => ({
  products: state.userProducts.productsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProductsList));
