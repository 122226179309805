import React from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewHeaderIndex from "../Home/NewHeaderIndex";
import NewFooterIndex from "../Home/NewFooterIndex";

const AboutUsIndex = () => {
  return (
    <>
      <div className="new-outfyt-home-page-sec">
        <NewHeaderIndex />
        <div className="new-main-wrapper">
          <div className="new-about-us-sec">
            <Container>
              <Row className="justify-content-center">
                <Col md={7}>
                  <div className="new-about-header-sec">
                    <div className="new-about-header-img-sec">
                      <Image
                        src={window.location.origin + "/images/outfyt/logo-big.png"}
                        className="outfyt-auth-big-logo"
                      />
                    </div>
                    <div className="new-about-header-info">
                      <h1>The future of fashion.</h1>
                      <p>For decades the online shopping experience has been uni-dimensional, devoid of any personal touch, particularly when it comes to purchasing luxury goods.</p>
                      <p>Welcome to OUTFYT, the exclusive virtual showroom of the world's most luxurious brands, where we offer a visionary experience beyond a mere storefront. We have curated an unparalleled destination for the most discerning customers to intimately engage and immerse themselves in the lavish world of their favorite brands.</p>
                      <p>Interested in selling?</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="designed-for-discovery-sec">
                <Row className="justify-content-md-center">
                  <Col md={7}>
                    <h2>Designed for discovery.</h2>
                  </Col>
                </Row>
                <Row className="justify-content-md-center align-items-center">
                  <Col md={4}>
                    <h3>Let your customers experience shopping in our immersive Livestream platform.</h3>
                  </Col>
                  <Col md={6}>
                    <div className="about-us-website-img-sec">
                      <Image
                        src={window.location.origin + "/images/outfyt/webiste-screenshot.png"}
                        className="about-us-website-img"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="live-streaming-conversion-rate-sec">
                <Row className="justify-content-md-center align-items-center">
                  <Col md={4}>
                    <h3>Live streaming has <span>35%</span> conversion rates in the U.S.</h3>
                  </Col>
                  <Col md={6}>
                    <div className="live-streaming-conversion-rate-content">
                      Sell alongside exclusively handpicked brands and retailers.
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="live-streaming-shopping-sec">
                <Row className="justify-content-md-center align-items-center">
                  <Col md={4}>
                    <h3>Livestream shopping estimated to be a $65 Billion Market in the U.S. by 2026.</h3>
                  </Col>
                  <Col md={6}>
                    <div className="live-streaming-conversion-rate-content">
                      Numbers don’t lie.
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="live-streaming-conversion-rate-sec">
                <Row className="justify-content-md-center align-items-center">
                  <Col md={4}>
                    <h3>17% of U.S. consumers frequently watch influencer livestreams</h3>
                  </Col>
                  <Col md={6}>
                    <div className="flex-data">
                      <div className="arrow-right">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="47"
                          height="24"
                          fill="none"
                          viewBox="0 0 47 24"
                        >
                          <path
                            fill="#000"
                            d="M46.044 13.06a1.5 1.5 0 000-2.12l-9.546-9.547a1.5 1.5 0 10-2.121 2.122L42.862 12l-8.485 8.485a1.5 1.5 0 102.121 2.122l9.546-9.546zm-45.06.44h44v-3h-44v3z"
                          ></path>
                        </svg>
                      </div>
                      <div className="live-streaming-conversion-rate-content">
                        with 70% of them likely to buy products from influences they follow.
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="live-streaming-shopping-sec">
                <Row className="justify-content-md-center align-items-center">
                  <Col md={4}>
                    <h3>60% of shoppers who tried livestream shopping said it improved their shopping experience.</h3>
                  </Col>
                  <Col md={6}>
                    <div className="live-streaming-conversion-rate-content">
                      <Image
                        src={window.location.origin + "/images/outfyt/arrow-right-img.png"}
                        className="about-us-website-img"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <NewFooterIndex />
      </div>
    </>
  );
};

export default AboutUsIndex;
