import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { connect } from 'react-redux';
import { resetPasswordStart } from '../../store/actions/UserAction';
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Auth.css";
import Slider from "react-slick";
import configuration from "react-global-configuration";

const ResetPassword = (props) => {

  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [token, setToken] = useState("");
  const [inputData, setInputdata] = useState({
    password: "",
    password_confirmation: "",
  })

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, []);

  const handleSubmit = () => {
    props.dispatch(resetPasswordStart({
      reset_token: token,
      password: inputData.password,
      password_confirmation: inputData.password_confirmation,
    }));
  }

  const loginSchema = Yup.object().shape({
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required")),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("passwords_need_to_be_same")
        ),
      }),
  });

  useEffect(() => {
    if (!skipRender &&
      !props.resetPassword.loading &&
      Object.keys(props.resetPassword.data).length > 0) {
      navigate("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
  };

  return (
    <>
      <div className="auth-page-sec">
        <div className="auth-page-left-sec">
          <Slider {...settings}>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-1.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-2.jpeg"}
              />
            </div>
            <div>
              <Image
                className="auth-slider-img"
                src={window.location.origin + "/images/auth/bg-3.jpeg"}
              />
            </div>
          </Slider>
          <div className="auth-nav-switch-sec">
            <Link to="/login"
              className={`login-link ${location.pathname === "/login" ? "active" : ""
                }`}
            >
              {t("login")}</Link>
            <Link to="/register"
              className={`login-link ${location.pathname === "/register" ? "active" : ""
                }`}

            >{t("register")}</Link>
          </div>
        </div>
        <div className="auth-page-right-sec">
          <div className="auth-details-sec">
            <div className="auth-logo-sec">
              <Image
                className="auth-logo"
                src={window.location.origin + "/images/logo-black.png"}
              />
            </div>
            <Formik
              initialValues={inputData}
              enableReinitialize={true}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={loginSchema}
            >
              <FORM className="auth-form-sec">
                <div className="auth-info">
                  <h2>{t("reset_password")}</h2>
                </div>
                <div className="auth-form">
                  <InputGroup className="mb-3" controlId="exampleForm.ControlInput1">
                    <Field
                      type={showPassword.newPassword ? "text" : "password"}
                      placeholder={t("enter_new_password")}
                      name="password"
                      className="form-control"
                      value={inputData.password}
                      onChange={(event) => {
                        setInputdata({
                          ...inputData,
                          password: event.currentTarget.value,
                        })
                      }}
                    />
                    <InputGroup.Text>
                      <button
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            newPassword: !showPassword.newPassword,
                          })
                        }
                        className="btn password-eye"
                        type="button"
                      >
                        {showPassword ?
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                          />
                          :
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                          />
                        }
                      </button>
                    </InputGroup.Text>
                    <ErrorMessage
                      component={"div"}
                      name="password"
                      className="text-danger"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Field
                      type={showPassword.confirmPassword ? "text" : "password"}
                      placeholder={t("confirm_password")}
                      name="password_confirmation"
                      className="form-control"
                      value={inputData.confirmPassword}
                      onChange={(event) => {
                        setInputdata({
                          ...inputData,
                          password_confirmation: event.currentTarget.value,
                        })
                      }}
                    />
                    <InputGroup.Text>
                      <button
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmPassword: !showPassword.confirmPassword,
                          })
                        }
                        className="btn password-eye"
                        type="button"
                      >
                        {showPassword ?
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-open.svg"}
                          />
                          :
                          <Image
                            className="pass-eye-open"
                            src={window.location.origin + "/images/auth/icon/eye-hide.svg"}
                          />
                        }
                      </button>
                    </InputGroup.Text>
                    <ErrorMessage
                      component={"div"}
                      name="password_confirmation"
                      className='text-danger pass-txt'
                    />
                  </InputGroup>
                  <div className="log-btn-sec mt-3 reset-btn">
                    <Button
                      className="default-btn"
                      type="submit"
                      disabled={props.resetPassword.buttonDisable}
                    >
                      {props.resetPassword.loadingButtonContent !== null ?
                        props.resetPassword.loadingButtonContent :
                        t("reset_password")
                      }

                    </Button>
                    <span>{t("or")}</span>
                    <Link to="/login">{t("cancel")}</Link>
                  </div>
                  <div className="auth-social-btn-sec">
                    <Link to="#">
                      <Image
                        className="social-logo"
                        src={window.location.origin + "/images/auth/icon/facebook.svg"}
                      />
                    </Link>
                    <Link to="#">
                      <Image
                        className="social-logo"
                        src={window.location.origin + "/images/auth/icon/google.svg"}
                      />
                    </Link>
                  </div>
                </div>
              </FORM>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  resetPassword: state.users.resetPassword,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(ResetPassword));
