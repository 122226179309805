import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  fetchLiveVideoOrdersStart,
  fetchMoreLiveVideoOrdersStart,
} from '../../store/actions/LiveVideoAction';
import { cancelOrderStart } from '../../store/actions/OrderAction';
import { useState } from 'react';
import NoDataFound from '../Helper/NoDataFound';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableOrderLoader from '../Helper/TableOrderLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import UpdateTrackingModal from './UpdateTrackingModal';
import Skeleton from 'react-loading-skeleton';

const LiveVideoOrder = (props) => {

  const t = useTranslation();
  const params = useParams();
  // const [search, setSearch] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [updateTracking, setUpdateTracking] = useState(false);
  const [order, setOrder] = useState({})

  const closeUpdateTrackingModal = () => {
    setUpdateTracking(false);
  };

  useEffect(() => {
    props.dispatch(fetchLiveVideoOrdersStart({
      live_video_id: params.live_video_id,
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreLiveVideoOrdersStart({
      live_video_id: params.live_video_id,
      skip: props.liveVideoOrders.data.orders.length,
      take: 12,
    }));
  }

  // const handleChange = (event) => {
  //   setSearch(event.target.value);
  //   props.dispatch(fetchLiveVideoOrdersStart({
  //     live_video_id: params.live_video_id,
  //     search_key: event.target.value,
  //   }));
  // }

  const cancelOrderConfirmation = (event, order_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_cancel_order"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => cancelOrder(event, order_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  }

  const cancelOrder = (event, order_id) => {
    event.preventDefault();
    props.dispatch(cancelOrderStart({
      order_id: order_id,
    }))
  }

  useEffect(() => {
    if (!skipRender && !props.cancelOrder.loading &&
      Object.keys(props.cancelOrder.data).length > 0) {
      props.dispatch(fetchLiveVideoOrdersStart({
        live_video_id: params.live_video_id,
      }));
    }
    setSkipRender(false);
  }, [props.cancelOrder]);

  const handleUpdateTracking = (order) => {
    setOrder(order);
    setUpdateTracking(true);
  }

  useEffect(() => {
    if (!props.updateTrackingNumber.loading &&
      Object.keys(props.updateTrackingNumber.data).length > 0) {
      props.dispatch(fetchLiveVideoOrdersStart({
        live_video_id: params.live_video_id,
      }));
    }
  }, [props.updateTrackingNumber]);

  return (
    <>
      <Container>
        <div className="search-end-sec mt-4">
          {/* <Form>
            <div className="tab-search-end">
              <div className="search-box">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <Image
                      src={
                        window.location.origin +
                        "/images/order/search.svg"
                      }
                    />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={t("searches")}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={search}
                    onChange={(event) => handleChange(event)}
                  />
                </InputGroup>
              </div>
            </div>
          </Form> */}
        </div>
        {props.liveVideoOrders.loading ?
          <Skeleton count={3} height={150} />
          :
          Object.keys(props.liveVideoOrders.data).length > 0 &&
            props.liveVideoOrders.data.orders.length > 0
            ?
            <InfiniteScroll
              dataLength={props.liveVideoOrders.data.orders.length}
              next={fetchMoreData}
              hasMore={
                props.liveVideoOrders.data.orders.length <
                props.liveVideoOrders.data.total}
              loader={<TableOrderLoader />}
            >
              < Table responsive>
                <thead>
                  <tr>
                    <th>{t("product")}</th>
                    <th>{t("shipping_address")}</th>
                    <th>{t("phone_number")}</th>
                    <th>{t("amount")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("status")}</th>
                    <th className="text-right">{t("action_order")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.liveVideoOrders.data.orders.map(
                    (order) => (
                      <tr>
                        <td>
                          <div
                            className="img-order"
                          >
                            {order.order_product.user_product_details.file_type == "image" ?
                              <Image
                                src={order.order_product.user_product_details.picture} />
                              :
                              <video
                                autoplay
                                controls
                                id="myVideo"
                                className="live-order-video"
                              >
                                <source
                                  src={order.order_product.user_product_details.picture}
                                  type="video/mp4" />
                              </video>
                            }
                          </div>
                          <h5> {order.order_product.product_name}</h5>
                        </td>
                        <td>
                          {order.delivery_address ?
                            <>
                              {order.delivery_address.name},
                              {order.delivery_address.address},
                              {order.delivery_address.landmark}, <br />
                              {order.delivery_address.state}-
                              {order.delivery_address.pincode} <br />
                              {order.delivery_address.country}
                            </>
                            : null
                          }
                        </td>
                        <td>
                          {order.delivery_address ? <>
                            {order.delivery_address.contact_number ? order.delivery_address.contact_number : "-"}
                          </>
                            : '-'
                          }
                        </td>
                        <td>
                          {order.total_formatted}
                        </td>
                        <td>{order.order_product ? order.order_product.quantity : 0}</td>
                        <td>
                          {order.order_status}
                        </td>
                        <td className="action-column">
                          <Link
                            className="order-view-btn mr-3"
                            to={`/order-view/${order.unique_id}`}
                          >
                            {t("view")}
                          </Link>
                          {order.order_btn_status.cancel_btn_status ?
                            <Button
                              type="button"
                              className="order-view-btn mt-3"
                              onClick={(event) => {
                                cancelOrderConfirmation(event,
                                  order.id)
                              }}
                            >
                              {t("cancel")}
                            </Button>
                            :
                            null}
                          {order.tracking_cancel_btn_status ?
                            <Button
                              type="button"
                              className="order-view-btn mt-3"
                              onClick={() => handleUpdateTracking(order)}
                            >
                              {t("update_tracking")}
                            </Button> : null}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </InfiniteScroll>
            :
            <NoDataFound />
        }
        {updateTracking &&
          <UpdateTrackingModal
            updateTracking={updateTracking}
            setUpdateTracking={setUpdateTracking}
            closeUpdateTrackingModal={closeUpdateTrackingModal}
            order={order}
          />
        }
      </Container>
    </>
  )
}

const mapStateToProps = (state) => ({
  liveVideoOrders: state.liveVideo.liveVideoOrders,
  cancelOrder: state.order.cancelOrder,
  updateTrackingNumber: state.order.updateTrackingNumber,
});

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(LiveVideoOrder));