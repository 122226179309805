import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchBookmarkedVideosStart,
  liveVideoBookmarkSaveStart,
  fetchMoreBookmarkedVideosStart,
} from "../../store/actions/HomePageAction";
import { useTranslation } from "react-multi-lang";
import HomeCategoryLoader from "../Helper/HomeCategoryLoader";
import NoDataFound from "../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const BookmarIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [noMoreData, setNoMoreData] = useState(false);

  useEffect(() => {
    props.dispatch(fetchBookmarkedVideosStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const bookmarkVideo = (videoId) => {
    props.dispatch(liveVideoBookmarkSaveStart({
      live_video_id: videoId,
    }));
  }


  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreBookmarkedVideosStart({
        skip: props.bookmarkedVideos.data.live_video_bookmarks.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="single-page-header">
            <div className="single-page-title">
              <Link to="#" onClick={() => navigate(-1)}>
                <Image src={window.location.origin + "/images/back.svg"} />
              </Link>
              <h3>{t("all_bookmarks")}</h3>
            </div>
          </div>
          {props.bookmarkedVideos.loading ?
            <HomeCategoryLoader />
            :
            Object.keys(props.bookmarkedVideos.data).length > 0 &&
              props.bookmarkedVideos.data.live_video_bookmarks.length > 0
              ?
              <InfiniteScroll
                dataLength={props.bookmarkedVideos.data.live_video_bookmarks.length}
                next={fetchMoreData}
                hasMore={
                  props.bookmarkedVideos.data.live_video_bookmarks.length <
                  props.bookmarkedVideos.data.total
                }
                loader={<div className="category-card-box">
                  {[...Array(5)].map((i) => (
                    <Skeleton count={1} width={218} height={210} />
                  ))}
                </div>}
              >
                <div className="live-streaming-category-card-list">
                  {props.bookmarkedVideos.data.live_video_bookmarks.map(
                    (live_video_bookmark, i) => (
                      <div className="category-card" key={i}>
                        <div className="category-card-img">
                          <Link to="#">
                            <Image
                              src={live_video_bookmark.preview_file}
                              className="list-card-img"
                            />
                            <div className="category-card-view">
                              <div className="category-card-icon">
                                <Image src={window.location.origin + "/images/card/eye.svg"} />
                                <p>{live_video_bookmark.viewer_cnt}</p>
                              </div>
                              <div className="category-card-icon">
                                <p>{live_video_bookmark.total_bookmarks}</p>
                                <div className="card-top-img" onClick={() => bookmarkVideo(live_video_bookmark.live_video_id)}>
                                  {live_video_bookmark.is_video_bookmarked === 1
                                    ?
                                    <Image
                                      src={window.location.origin + "/images/card/bookmark-active.svg"}
                                    />
                                    :
                                    <Image
                                      src={window.location.origin + "/images/card/bookmark.svg"}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="category-card-profile">
                          <Link to={`/${live_video_bookmark.username}`}>
                            <Image src=
                              {live_video_bookmark.user_picture}
                              className="cat-pro-pic"
                            />
                            <h5>{live_video_bookmark.username}</h5>
                          </Link>
                        </div>
                        <div className="category-card-content">
                          <h4>
                            {live_video_bookmark.description}
                          </h4>
                          <Link to={`/live-stream/${live_video_bookmark.live_video_id}`}>{live_video_bookmark.category_name}</Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </InfiniteScroll>
              :
              <NoDataFound />
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  bookmarkedVideos: state.homepage.bookmarkedVideos,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarIndex);


