import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deletePickupAddressStart,
  fetchPickupAddressStart,
  savePickupAddressStart,
  setDefaultPickupAddressStart,
} from "../../store/actions/PickupAddressAction";
import { t, useTranslation, withTranslation } from "react-multi-lang";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import { Formik, Form as FORM, Field, ErrorMessage } from 'formik';
import NoDataFound from '../Helper/NoDataFound';
import * as Yup from "yup";
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select'
import getAllCountries from 'react-select-country-list';
const getCountryISO3 = require("country-iso-2-to-3");

const PickupAddressModal = (props) => {
  const [step, setStep] = useState(1);
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [pickaddress, setPickAddress] = useState(props.selectedAddress);

  const [mobileValue, setMobileValue] = useState(0);

  const addAddressSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    // contact_number: Yup.string().required(t("contact_number_is_required")),
    address: Yup.string().required(t("address_is_required")),
    landmark: Yup.string(),
    city: Yup.string().required(t("city_is_required")),
    state: Yup.string().required(t("state_is_required")),
    country: Yup.string().required(t("country_is_required")),
    pincode: Yup.string().required(t("pincode_is_required"))
      .matches(/^(?=.*[0-9])/, t("space_values_not_valid"))
  });

  useEffect(() => {
    props.dispatch(fetchPickupAddressStart());
    setStep(1);
  }, []);

  useEffect(() => {
    props.dispatch(fetchPickupAddressStart());
  }, [step]);

  useEffect(() => {
    if (pickaddress === "" &&
      !props.pickupAddress.loading &&
      Object.keys(props.pickupAddress.data).length > 0) {
      let address = props.pickupAddress.data.pickup_addresses.filter(
        (address) => address.is_default === 1
      );
      setPickAddress(address[0]);
    }
  }, [props.pickupAddress]);

  const handleSubmit = (values) => {
    let newValues = {};
    newValues = { ...values, contact_number: mobileValue };
    props.dispatch(savePickupAddressStart(newValues));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.pickupAddressSave.loading &&
      Object.keys(props.pickupAddressSave.data).length > 0
    ) {
      props.dispatch(fetchPickupAddressStart());
      setStep(1);
    }
    setSkipRender(false);
  }, [props.pickupAddressSave]);

  const handleSetDefault = (pickup_address_id) => {
    props.dispatch(
      setDefaultPickupAddressStart({
        pickup_address_id: pickup_address_id,
      })
    );
  };

  const handleDeleteAddress = (pickup_address_id) => {
    if (window.confirm(t("delete_delivery_address_confirmation"))) {
      props.dispatch(
        deletePickupAddressStart({
          pickup_address_id: pickup_address_id,
        })
      );
    }
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "30px",
        backgroundColor: "#000",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#000",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "30px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#000",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      border: "1px solid rgba(255, 255, 255, 0.2) !important",
      boxShadow: "none",
      height: "50px",
      width: "100%",
      borderRadius: "30px",
      backgroundColor: "#181818",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#999",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : "#000",
        color: "#fff",
      };
    },
    ///.....
  };

  const excludedCountries = [
    { value: 'PK', label: 'Pakistan' },
  ];

  const allCountries = getAllCountries().data.filter(country =>
    !excludedCountries.some(obj => obj.value === country.value)
  );

  return (
    <>
      <Modal
        className="modal-dialog-center shipping-view-modal-sec"
        size="lg"
        centered
        show={props.pickupAddress}
        onHide={props.closePickupAddress}
      >
        <div className="close-btn-sec">
          <Button
            type="button"
            className="close-modal-popup"
            onClick={() => props.setPickupAddress(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#fff"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M23.229 9.219l-3.224-5.5A5.52 5.52 0 0015.26 1H8.709a5.523 5.523 0 00-4.745 2.719l-3.222 5.5a5.502 5.502 0 000 5.562l3.223 5.501A5.522 5.522 0 008.71 23h6.551a5.52 5.52 0 004.745-2.719l3.224-5.5a5.505 5.505 0 000-5.561zm-2.588 4.045l-3.224 5.5A2.51 2.51 0 0115.26 20H8.709c-.883 0-1.71-.474-2.157-1.236l-3.223-5.5a2.502 2.502 0 010-2.528l3.223-5.499A2.51 2.51 0 018.709 4h6.551c.884 0 1.71.474 2.157 1.236l3.224 5.5c.456.78.456 1.749 0 2.529zM15.81 10.31l-1.689 1.689 1.689 1.689a1.5 1.5 0 11-2.122 2.121l-1.689-1.689-1.689 1.689c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121l1.689-1.689-1.689-1.689a1.5 1.5 0 112.121-2.121l1.689 1.689 1.689-1.689a1.5 1.5 0 112.121 2.121z"></path>
            </svg>
          </Button>
        </div>
        <Modal.Body>
          {step === 1 && (
            <div className="step-2">
              <div className="shipping-change-address-sec">
                <div className="shipping-change-address-header-sec">
                  <div className="shipping-change-address-header-left-sec">
                    <h4>{t("pickup_address")}</h4>
                  </div>
                  <div className="shipping-change-address-header-right-sec">
                    <Button
                      className="change-address-btn"
                      onClick={() => setStep(2)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17 11h-4V7a1 1 0 00-1-1 1 1 0 00-1 1v4H7a1 1 0 00-1 1 1 1 0 001 1h4v4a1 1 0 001 1 1 1 0 001-1v-4h4a1 1 0 001-1 1 1 0 00-1-1z"></path>
                      </svg>
                      {t("add_address")}
                    </Button>
                  </div>
                </div>
                <div className="shipping-change-address-body-sec">
                  <div className="shipping-change-address-box">
                    {props.pickupAddress.loading ? (
                      <CommonCenterLoader />
                    ) : (
                      <>
                        {!props.pickupAddress.loading &&
                          Object.keys(props.pickupAddress.data).length > 0
                          ? props.pickupAddress.data.pickup_addresses.map(
                            (address) => (
                              <div className="shipping-change-address-card w-full">
                                <input
                                  type="radio"
                                  name="pricing"
                                  id="card1"
                                  checked={
                                    pickaddress &&
                                    pickaddress.pickup_address_id ===
                                    address.pickup_address_id
                                  }
                                  onClick={() => {
                                    setPickAddress(address);
                                  }}
                                />
                                <label for="card1">
                                  <div className="shipping-change-address-item">
                                    <div className="default-address-sec">
                                      <h4>{address.name}</h4>
                                      {address.is_default ? (
                                        <div className="default-address-info">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="18"
                                            fill="none"
                                            viewBox="0 0 17 18"
                                          >
                                            <path
                                              fill="#fff"
                                              d="M8.5.5A8.503 8.503 0 000 9c0 4.692 3.808 8.5 8.5 8.5S17 13.692 17 9 13.192.5 8.5.5zm0 15.3A6.809 6.809 0 011.7 9c0-3.748 3.051-6.8 6.8-6.8 3.748 0 6.8 3.051 6.8 6.8 0 3.748-3.052 6.8-6.8 6.8zm3.902-10.557L6.8 10.844 4.598 8.652 3.4 9.85l3.4 3.4 6.8-6.8-1.198-1.207z"
                                            ></path>
                                          </svg>
                                          <span>Default Address</span>
                                        </div>
                                      ) : (
                                        <Button
                                          className="set-as-default-btn"
                                          type="button"
                                          onClick={() =>
                                            handleSetDefault(
                                              address?.pickup_address_id
                                            )
                                          }
                                        >
                                          {t("set_as_default")}
                                        </Button>
                                      )}
                                    </div>
                                    <p>
                                      {address.address +
                                        "," +
                                        address.landmark +
                                        "," +
                                        address.state +
                                        "," +
                                        address.country +
                                        "," +
                                        address.pincode}
                                    </p>
                                    <p>
                                      {t("mobile")}:{" "}
                                      <span>{address.contact_number}</span>
                                    </p>
                                    {!address.is_default && (
                                      <div className="shipping-change-address-btn-sec">
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            handleDeleteAddress(
                                              address.pickup_address_id
                                            )
                                          }
                                        >
                                          {t("delete")}
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </label>
                              </div>
                            )
                          )
                          : <NoDataFound />}
                      </>
                    )}
                  </div>
                </div>
                <div className="shipping-view-product-description-btn-sec">
                  <Button
                    className="buy-now-btn"
                    onClick={() => {
                      props.setSelectedAddress(pickaddress);
                      props.closePickupAddressModal();
                    }}
                  >
                    {t("confirm_pickup_address")}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="step-3">
              <div className="shipping-add-new-address-sec">
                <div className="shipping-change-address-header-sec">
                  <div className="shipping-change-address-header-left-sec">
                    <Link to="#" onClick={() => setStep(1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="none"
                        viewBox="0 0 37 38"
                      >
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          d="M18.096 30.332L7.226 19.463l10.87-10.87m-9.36 10.87h22.04"
                        ></path>
                      </svg>
                    </Link>
                    <h4>{t("add_new_address")}</h4>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    name: "",
                    contact_number: "",
                    address: "",
                    address_line_2: "",
                    landmark: "",
                    city: "",
                    state: "",
                    country: "",
                    pincode: "",
                  }}
                  validationSchema={addAddressSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({
                    touched,
                    errors,
                    values,
                    setFieldValue,
                  }) => (
                    <FORM>
                      <div className="shipping-add-new-address-body-sec">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("name")}: (*)</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("name")}
                            type="text"
                            autoFocus={true}
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("mobile")}: (*)
                          </Form.Label>
                          <PhoneInput
                            id="contact-select"
                            defaultCountry="IN"
                            placeholder={t("contact_number")}
                            name="contact_number"
                            value={values.contact_number}
                            onChange={setMobileValue}
                            international
                          />
                          <ErrorMessage
                            name="contact_number"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("address")}: (*)</Form.Label>
                          <Field
                            as="textarea"
                            rows={2}
                            className="form-control"
                            placeholder={t("address")}
                            type="text"
                            name="address"
                          />
                          <ErrorMessage
                            name="address"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("address_line_2")}: ({t("optional")})</Form.Label>
                          <Field
                            as="textarea"
                            rows={2}
                            className="form-control"
                            placeholder={t("address_line_2")}
                            type="text"
                            name="address_line_2"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("landmark")}: ({t("optional")})
                          </Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("landmark")}
                            type="text"
                            name="landmark"
                          />
                          <ErrorMessage
                            name="landmark"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("city")}: (*)</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("city")}
                            type="text"
                            name="city"
                          />
                          <ErrorMessage
                            name="city"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("state")}: (*)</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("state")}
                            type="text"
                            name="state"
                          />
                          <ErrorMessage
                            name="state"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("country")}: (*)</Form.Label>
                          <Select
                            name="country_name"
                            options={allCountries}
                            styles={customStyles}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                              setFieldValue("country", selectedOption.label)
                              setFieldValue("country_code", getCountryISO3(selectedOption.value))
                            }}
                            placeholder={t("country")}
                          />
                          <ErrorMessage
                            name="country"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("pincode")} / {t("zipcode")}: (*)</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={t("pincode")}
                            type="text"
                            name="pincode"
                          />
                          <ErrorMessage
                            name="pincode"
                            component={"div"}
                            className="text-danger pass-txt"
                          />
                        </Form.Group>
                      </div>
                      <div className="shipping-view-product-description-btn-sec">
                        <Button
                          className="buy-now-btn"
                          disabled={props.pickupAddressSave.buttonDisable}
                          type="submit">
                          {props.pickupAddressSave.loadingButtonContent !== "" ?
                            props.pickupAddressSave.loadingButtonContent : t("add_address")
                          }
                        </Button>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  pickupAddress: state.pickupAddress.pickupAddress,
  pickupAddressSave: state.pickupAddress.pickupAddressSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(PickupAddressModal));
