import {
    FETCH_HOME_FEATURED_SELLERS_FAILURE,
    FETCH_HOME_FEATURED_SELLERS_START,
    FETCH_HOME_FEATURED_SELLERS_SUCCESS,
    FETCH_CATEGORIES_START,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_HOME_CATEGORIES_START,
    FETCH_HOME_CATEGORIES_SUCCESS,
    FETCH_HOME_CATEGORIES_FAILURE,
    FETCH_SUB_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_SUCCESS,
    FETCH_SUB_CATEGORIES_FAILURE,
    FOLLOW_CATEGORIES_START,
    FOLLOW_CATEGORIES_SUCCESS,
    FOLLOW_CATEGORIES_FAILURE,
} from "./ActionConstant";



export function fetchHomeFeaturedSellersStart(data) {
    return {
        type: FETCH_HOME_FEATURED_SELLERS_START,
        data,
    };
}

export function fetchHomeFeaturedSellersSuccess(data) {
    return {
        type: FETCH_HOME_FEATURED_SELLERS_SUCCESS,
        data
    };
}

export function fetchHomeFeaturedSellersFailure(error) {
    return {
        type: FETCH_HOME_FEATURED_SELLERS_FAILURE,
        error
    };
}

export function fetchHomeCategoriesStart(data) {
    return {
        type: FETCH_HOME_CATEGORIES_START,
        data,
    };
}

export function fetchHomeCategoriesSuccess(data) {
    return {
        type: FETCH_HOME_CATEGORIES_SUCCESS,
        data
    };
}

export function fetchHomeCategoriesFailure(error) {
    return {
        type: FETCH_HOME_CATEGORIES_FAILURE,
        error
    };
}

export function fetchCategoriesStart(data) {
    return {
        type: FETCH_CATEGORIES_START,
        data,
    };
}

export function fetchCategoriesSuccess(data) {
    return {
        type: FETCH_CATEGORIES_SUCCESS,
        data,
    };
}

export function fetchCategoriesFailure(error) {
    return {
        type: FETCH_CATEGORIES_FAILURE,
        error,
    };
}

export function fetchSubCategoriesStart(data) {
    return {
        type: FETCH_SUB_CATEGORIES_START,
        data,
    };
}

export function fetchSubCategoriesSuccess(data) {
    return {
        type: FETCH_SUB_CATEGORIES_SUCCESS,
        data,
    };
}

export function fetchSubCategoriesFailure(error) {
    return {
        type: FETCH_SUB_CATEGORIES_FAILURE,
        error,
    };
}


export function followCategoriesStart(data) {
    return {
        type: FOLLOW_CATEGORIES_START,
        data,
    };
}

export function followCategoriesSuccess(data) {
    return {
        type: FOLLOW_CATEGORIES_SUCCESS,
        data,
    }
}

export function followCategoriesFailure(error) {
    return {
        type: FOLLOW_CATEGORIES_FAILURE,
        error,
    }
}