import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect, useDispatch } from "react-redux";
import { useFormikContext } from 'formik';
import {
  fetchProductCategoriesStart,
} from "../../../store/actions/ProductsAction";
import { fetchSubCategoriesStart } from "../../../store/actions/HomePageAction";
import * as Yup from "yup";
import { userCategoriesSaveStart } from "../../../store/actions/UserAction";

const DetectCategories = () => {
  
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.category_id) {
      dispatch(fetchSubCategoriesStart({ category_id: values.category_id }));
    }
  }, [values.category_id]);
}

const SellerCategories = (props) => {

  const t = useTranslation();
  const dispatch = useDispatch();
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const [selectValues, setSelectValues] = useState({
    category_name: "",
    category_id: "",
    sub_category_id: "",
    sub_category_name: "",
  });
  const categorySchema = Yup.object().shape({
    sub_category_id: Yup.string().required(t("sub_category_id_is_required")),
    pickup_address_id: Yup.string().required(t("pickup_address_id_is_required"))
  })

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#fff!important',
      borderColor: '#ced4da!important',
      height: '60px',
      borderRadius: '50px',
      // minWidth: "150px",
      fontSize: "1em",
      paddingLeft: "1em",
      boxShadow: 'none',
      outline: 'none'
    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1em", fontWeight: "600"
    }),
    indicatorsContainer: provided =>({ ...provided, paddingRight: "1em", }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important', paddingRight: "1em", }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
  }

  useEffect(()=>{
    props.dispatch(fetchProductCategoriesStart());
  },[])

  useEffect(()=>{
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0 && props.profile.data.user_category){
      setSelectValues({
        category_name: props.profile.data.user_category.category.name,
        category_id: props.profile.data.user_category.category.category_id,
        sub_category_id: props.profile.data.user_category.sub_category.sub_category_id,
        sub_category_name: props.profile.data.user_category.sub_category.name,
      })
    }
  },[props.profile])

  useEffect(()=>{
    if (!props.categories.loding && Object.keys(props.categories.data).length > 0 && props.categories.data.product_categories.length>0 )
    {
      let categories = props.categories.data.product_categories.map((category)=>(
        {
          "label":category.name,
          "value":category.category_id,
        }
      ))
      setCategoriesOptions(categories);
    }
  },[props.categories]);

  const handleCategoryInputChange = (selectedOption) => {
    setSelectValues({
      category_name: selectedOption.label,
      category_id: selectedOption.value,
      sub_category_name: "",
      sub_category_id: "",
    })
    props.dispatch(fetchSubCategoriesStart({ category_id: selectedOption.value }));
  };

  useEffect(()=>{
    if (!props.subCategories.loding && Object.keys(props.subCategories.data).length > 0 && props.subCategories.data.sub_categories.length>0 )
    {
      let categories = props.subCategories.data.sub_categories.map((category)=>(
        {
          "label":category.name,
          "value":category.sub_category_id,
        }
      ))
      setSubCategoriesOptions(categories);
    }
  },[props.subCategories])

  const handleSubCategoryInputChange = (selectedOption) => {
    setSelectValues({
      ...selectValues,
      sub_category_name: selectedOption.label,
      sub_category_id: selectedOption.value,
    })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
      props.dispatch(userCategoriesSaveStart(selectValues));
  };

  useEffect(()=>{
    if(!skipRender && !props.userCategories.loading && Object.keys(props.userCategories.data).length>0)
    {
      props.setStep(2);
    }
    setSkipRender(false);
  }, [props.userCategories])

  return (
    <>
      <div className="categories-new-outlyt-sec">
        <Row className="justify-content-md-center">
          <Col md={7}>
            <Formik
            initialValues={selectValues}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
          >
            <Form onSubmit={handleSubmit}>
              <div className="input-cat-sec">
                <h3>{t("which_categories_are_you_interested_in_selling_in")}</h3>
                <Form.Group className="mb-3">
                  <Form.Label>{t("primary_category")}</Form.Label>
                  <Select
                    name="category_id"
                    options={categoriesOptions}
                    value={{label:selectValues.category_name, value:selectValues.category_id}}
                    styles={customStyles}
                    isSearchable={false}
                    onChange={handleCategoryInputChange}
                  />
                  <DetectCategories />
                  <ErrorMessage
                    name="category_id"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("subcategory")}</Form.Label>
                  <Select
                    name="sub_category_id"
                    options={subCategoriesOptions}
                    value={{label:selectValues.sub_category_name, value:selectValues.sub_category_id}}
                    styles={customStyles}
                    isSearchable={false}
                    onChange={handleSubCategoryInputChange}
                  />
                  <ErrorMessage
                    name="sub_category_id"
                    component={"div"}
                    className="text-danger text-right"
                  />
                </Form.Group>
              </div>
              <div className="new-outfyt-seller-footer-btn-sec">
                <Button className="outfyt-outline-btn" onClick={() => props.setStep(0)}>
                  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4999 4.99999H4.32992L7.20992 2.11999C7.3969 1.93301 7.50195 1.67942 7.50195 1.41499C7.50195 1.15056 7.3969 0.896967 7.20992 0.70999C7.02295 0.523012 6.76935 0.417969 6.50492 0.417969C6.2405 0.417969 5.9869 0.523012 5.79992 0.70999L1.20992 5.29999C1.11722 5.3925 1.04367 5.50239 0.993492 5.62337C0.94331 5.74434 0.91748 5.87402 0.91748 6.00499C0.91748 6.13596 0.94331 6.26564 0.993492 6.38661C1.04367 6.50759 1.11722 6.61748 1.20992 6.70999L5.79992 11.3C5.89251 11.3926 6.00242 11.466 6.12338 11.5161C6.24435 11.5662 6.37399 11.592 6.50492 11.592C6.63586 11.592 6.7655 11.5662 6.88647 11.5161C7.00743 11.466 7.11734 11.3926 7.20992 11.3C7.30251 11.2074 7.37595 11.0975 7.42605 10.9765C7.47616 10.8556 7.50195 10.7259 7.50195 10.595C7.50195 10.4641 7.47616 10.3344 7.42605 10.2134C7.37595 10.0925 7.30251 9.98257 7.20992 9.88999L4.32992 6.99999H17.4999C18.0499 6.99999 18.4999 6.54999 18.4999 5.99999C18.4999 5.44999 18.0499 4.99999 17.4999 4.99999Z" fill="#151515" />
                  </svg>
                 {t("back")}
                </Button>
                <Button 
                  type="submit" 
                  className="outfyt-btn" 
                  onClick={(event) => handleSubmit(event)}
                  disabled={props.userCategories.buttonDisable}
                  >
                  {props.userCategories.buttonDisable?t("loading"):t("next")}
                </Button>
              </div>
            </Form>
            </Formik>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.userProducts.productCategories,
  subCategories: state.homepage.subCategories,
  userCategories: state.users.userCategories,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(SellerCategories));