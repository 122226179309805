import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchBookmarkedVideosStart,
  fetchMoreBookmarkedVideosStart,
} from "../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import LiveVideoLoader from "../Helper/LiveVideosLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import LiveVideoCard from "./LiveVideoCard";
import { useNavigate } from "react-router-dom";

const BookmarkIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchBookmarkedVideosStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreBookmarkedVideosStart({
      skip: props.bookmarkedVideos.data.live_video_bookmarks.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="new-outfyt-home-page-sec">
        <div className="new-main-wrapper">
          <div className="new-category-sec-1">
            <Container>
              <div className="new-category-header-sec">
                <Link to="#" onClick={() => navigate(-1)}>
                  <Image src={window.location.origin + "/images/back.svg"} />
                </Link>
                <h1>{t("all_bookmarks")}</h1>
              </div>
            </Container>
          </div>
          <Container>
            {props.bookmarkedVideos.loading ?
              <LiveVideoLoader />
              :
              Object.keys(props.bookmarkedVideos.data).length > 0 &&
                props.bookmarkedVideos.data.live_video_bookmarks.length > 0 ?
                <InfiniteScroll
                  dataLength={props.bookmarkedVideos.data.live_video_bookmarks.length}
                  next={fetchMoreData}
                  hasMore={props.bookmarkedVideos.data.live_video_bookmarks.length <
                    props.bookmarkedVideos.data.total
                  }
                  loader={<LiveVideoLoader />}
                >
                  <div className="new-home-latest-live-streaming-box-video bookmark-box">
                    {props.bookmarkedVideos.data.live_video_bookmarks.map(
                      (live_video) => (
                        live_video.is_streaming == 1 ?
                          <LiveVideoCard live_video={live_video} />
                          :
                          null
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  bookmarkedVideos: state.homepage.bookmarkedVideos,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(BookmarkIndex))

