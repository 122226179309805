import React from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import "./Orders.css";
import { ordersViewForOthersStart } from "../../store/actions/OrderAction";
import { Modal, Container, Row, Col, Image, Media } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import OrderViewLoader from "../Helper/OrderViewLoader";

const OrderView = (props) => {
  const params = useParams();
  const t = useTranslation();
  const navigate = useNavigate();
  const orderDetail = props.ordersViewForOthers;

  useEffect(() => {
    props.dispatch(
      ordersViewForOthersStart({
        order_unique_id: params.unique_id,
      })
    );
  }, []);

  return (
    <>
      <div className="order-view-sec">
        <Container>
          <div className="single-page-title">
            <Link to="#" onClick={() => navigate(-1)}>
              <Image src={window.location.origin + "/images/back.svg"} />
            </Link>
            <h3>{t("order_view")}</h3>
          </div>
          {orderDetail.loading ?
            <OrderViewLoader />
            : (
              <>
                <Row>
                  {Object.keys(orderDetail.data).length > 0 ? (
                    <>
                      <Col md={6}>
                        {orderDetail.data.order.order_product.map(
                          (product, index) => (
                            <>
                              <div className="order-view-card">
                                <div className="order-view-img-sec" key={index}>
                                {product.user_product_details.file_type == "image" ?
                                       <Image
                                       className="order-view-img"
                                       src={product.user_product_details.picture}
                                     />
                                    :
                                    <video
                                      autoplay
                                      controls
                                      id="myVideo"
                                      className="order-view-img"
                                    >
                                      <source src={product.user_product_details.picture}
                                       type="video/mp4" />
                                    </video>
                                  }
                                </div>
                                <div className="order-view-info">
                                  <h4>
                                    {t("order_id")}{" "}
                                    <span>
                                      {props.ordersViewForOthers.data.order.unique_id}
                                    </span>
                                  </h4>
                                  <h4>
                                    {t("product_name")} :{" "}
                                    <span>
                                      <Link
                                        to={
                                          "/single-product/" +
                                          product.user_product_details.unique_id
                                        }
                                      >
                                        {product.user_product_details.name}
                                      </Link>
                                    </span>
                                  </h4>
                                  <h4>
                                    {t("quantity")} :{" "}
                                    <span>
                                      <Link to="#">{product.quantity}</Link>
                                    </span>
                                  </h4>
                                  <h4>
                                    {t("per_quantity_price")} :{" "}
                                    <span>
                                      {product.per_quantity_price_formatted}
                                    </span>
                                  </h4>
                                  <h4>
                                    {t("list_price")} :{" "}
                                    <span>{product.sub_total_formatted}</span>
                                  </h4>
                                  <h4>
                                    {t("order_date")} :{" "}
                                    <span>
                                      {
                                        props.ordersViewForOthers.data.order
                                          .order_payment.paid_date_formatted
                                      }
                                    </span>
                                  </h4>
                                  <h4 className="border-bottom-zero">
                                    {t("payment_method")} :{" "}
                                    <span>
                                      {
                                        props.ordersViewForOthers.data.order
                                          .order_payment.payment_mode
                                      }
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            {orderDetail.data.order.order_payment ? (
                              <div className="order-view-summary-sec">
                                <h3 className="sub-title">{t("order_summary")}</h3>
                                <div className="product-details-body-sec">
                                  {orderDetail.data.order.delivery_address ? (
                                    <div className="product-details-card">
                                      <h5>{t("shipping_address")}</h5>
                                      <p className="product-amount order text-right">
                                        <span>
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.name
                                          }, <br />
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.address
                                          }, <br />
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.landmark
                                          }, <br />
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.state
                                          }{", "}{
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.country
                                          }
                                          -{" "}
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.pincode
                                          }{" "}
                                          <br />
                                          {
                                            props.ordersViewForOthers.data.order
                                              .delivery_address.contact_number
                                          }{" "}
                                        </span>
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="product-details-card">
                                    <h5>{t("order_id")}</h5>
                                    <p className="product-amount order">
                                      <span>{orderDetail.data.order.unique_id} </span>
                                    </p>
                                  </div>
                                  {orderDetail.data.order.tracking_id ?
                                    <>
                                      <hr></hr>
                                      <div className="product-details-card">
                                        <h5>{t("tracking_id")}</h5>
                                        <p className="product-amount order">
                                          <span>#{orderDetail.data.order.tracking_id} </span>
                                        </p>
                                      </div>
                                    </>
                                    :
                                    null
                                  }
                                  {orderDetail.data.tracking_details.subtag_message ?
                                    <>
                                      <hr></hr>
                                      <div className="product-details-card">
                                        <h5>{t("tracking_status")}</h5>
                                        <p className="product-amount order">
                                          <span>{orderDetail.data.tracking_details.subtag_message} </span>
                                        </p>
                                      </div>
                                    </>
                                    :
                                    null
                                  }
                                  {orderDetail.data.tracking_details.courier_tracking_link ?
                                    <>
                                      <hr></hr>
                                      <div className="product-details-card">
                                        <h5>{t("tracking_url")}</h5>
                                        <p className="product-amount order">
                                          <a href={orderDetail.data.tracking_details.courier_tracking_link} target="_blank">{orderDetail.data.tracking_details.courier_tracking_link} </a>
                                        </p>
                                      </div>
                                    </>
                                    :
                                    null
                                  }
                                  <hr></hr>
                                  <h3 className="sub-title">{t("price_details")}</h3>
                                  <div className="product-details-card">
                                    <h5>{t("list_price")}</h5>
                                    <p className="product-amount order">
                                      {
                                        orderDetail.data.order.order_payment
                                          .sub_total_formatted
                                      }
                                    </p>
                                  </div>
                                  <div className="product-details-card">
                                    <h5>{t("shipping_fee")}</h5>
                                    <p className="product-amount order">
                                      {
                                        orderDetail.data.order.order_payment
                                          .delivery_price_formatted
                                      }
                                    </p>
                                  </div>
                                  <div className="product-details-card">
                                    <h5>{t("taxes")}</h5>
                                    <p className="product-amount order">
                                      {
                                        orderDetail.data.order.order_payment
                                          .tax_price_formatted
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="product-details-footer-sec">
                                  <h5>{t("total")}</h5>
                                  <div className="product-details-final-amount">
                                    <p>
                                      {
                                        orderDetail.data.order.order_payment
                                          .total_formatted
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </Col>
                          <Col md={12}>
                            <div className="track">
                              <div
                                className={
                                  orderDetail.data.order.status >= 1
                                    ? "step active"
                                    : "step"
                                }
                              >
                                {" "}
                                <span className="icon">
                                  {" "}
                                  <i className="fa fa-check"></i>{" "}
                                </span>{" "}
                                <span className="text">{t("order_placed")}</span>{" "}
                              </div>
                              <div
                                className={
                                  orderDetail.data.order.order_status_formatted ==
                                    "Approved" || orderDetail.data.order.status == 4
                                    ? "step active"
                                    : "step"
                                }
                              >
                                {" "}
                                <span className="icon">
                                  {" "}
                                  <i className="fa fa-check"></i>{" "}
                                </span>{" "}
                                <span className="text">
                                  {orderDetail.data.order.order_status_formatted}
                                </span>{" "}
                              </div>

                              <div
                                className={
                                  orderDetail.data.order.status >= 2 &&
                                    orderDetail.data.order.order_status_formatted !=
                                    "Cancelled"
                                    ? "step active"
                                    : "step"
                                }
                              >
                                {" "}
                                <span className="icon">
                                  {" "}
                                  <i className="fa fa-truck"></i>{" "}
                                </span>{" "}
                                <span className="text">{t("order_shipped")}</span>{" "}
                              </div>
                              <div
                                className={
                                  orderDetail.data.order.status == 3
                                    ? "step active"
                                    : "step"
                                }
                              >
                                {" "}
                                <span className="icon">
                                  {" "}
                                  <i className="fa fa-box"></i>{" "}
                                </span>{" "}
                                <span className="text">{t("delivered")}</span>{" "}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </>) : "No Data Found"}
                </Row>
              </>
            )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  ordersViewForOthers: state.order.ordersViewForOthers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderView));
