import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  sellerListStart,
  fetchMoreSellerListStart
} from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import CategorysLoader from "../../Helper/CategorysLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";


const NewSellerCategory = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(sellerListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreSellerListStart({
      skip: props.sellerList.data.sellers.length,
      take: 12,
    }));
  }

  return (
    <>
      {props.sellerList.loading ?
        <CategorysLoader />
        :
        Object.keys(props.sellerList.data).length > 0 &&
          props.sellerList.data.sellers.length > 0 ?
          <div className="new-explore-category-sec">
            <Container>
              <div className="new-home-title-sec">
                <h2 className="text-uppercase">{t("sellers")}</h2>
              </div>
              <InfiniteScroll
                dataLength={props.sellerList.data.sellers.length}
                next={fetchMoreData}
                hasMore={
                  props.sellerList.data.sellers.length <
                  props.sellerList.data.total}
                loader={<div className="new-explore-category-box">
                {[...Array(5)].map((i) => (
                  <div className="new-explore-category-card">
                    <Skeleton count={1} height={50} width={160} />
                  </div>
                ))}
              </div>
              }
              >
                <div className="new-explore-category-box">
                  {props.sellerList.data.sellers.map(
                    (seller) => (
                      <div className="new-explore-category-card">
                        <Link className="expo-btn"
                          to={`/${seller.unique_id}`}>
                          {seller.name}
                        </Link>
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </Container>
          </div>
          :
          ''
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  sellerList: state.homepage.sellerList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(NewSellerCategory));



