import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Media,
  Image,
} from "react-bootstrap";
import {
  liveViewerUpdateStart,
  liveVideoStartCallStart,
} from "../../store/actions/LiveVideoAction";
import configuration from "react-global-configuration";
import Counter from "../Utils/Counter";
AgoraRTC.setLogLevel(4);
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

const LiveSample = (props) => {
  return (
    <>
      <div className="app-main">
        <div className="video-call-wrapper">
          <div className="video-participant">
            <div className="participant-actions">
              <ul className="list-unstyled participant-notify">
                <Media as="li">
                  {props.liveVideo.data.formatted_schedule_time}{" "}
                </Media>
                <Media as="li">
                  <i className="fas fa-eye mr-2"></i>
                  {props.liveVideo.data.viewer_cnt}
                </Media>
                {props.isOwner ? (
                  <Media as="li">
                    <Link
                      to="#"
                      className="live-start-now"
                      onClick={() => {
                        props.dispatch(
                          liveVideoStartCallStart({
                            live_video_id: props.liveVideo.data.live_video_id,
                          })
                        );
                      }}
                    >
                      {t("start_now")}
                    </Link>
                  </Media>
                ) : (
                  null
                )}
              </ul>
            </div>
            {props.liveVideo.data.preview_file_type == "image" ?
              <Image
              className="blur-image"
              src={props.liveVideo.data.preview_file}
              />
              :
              <video
                autoplay
                controls
                id="myVideo"
                className="live-preview w-100"
              >
                <source src={props.liveVideo.data.preview_file} type="video/mp4" />
              </video>
            }
            <Counter
              preText={"Starts In "}
              targetDate={props.liveVideo.data.schedule_time}
            />
            <Link
              to={`/` + props.liveVideo.data.user_unique_id}
              className="name-tag"
            >
              {props.liveVideo.data.user_displayname}
            </Link>
            <div
              id="agora_local"
              style={{ width: "100%", height: "100%", position: "relative" }}
            />
          </div>
        </div>
      </div>
      <div className="agora-card">
        <div className="button-group mt-3 mb-3">
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LiveSample));
