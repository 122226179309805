import React, { useState } from "react";
import NewExploreCategory from "../Home/NewExploreCategory";
import NewLatestLiveStreamingIndex from "../Home/NewLatestLiveStreamingIndex";
import ShippingViewModal from "./ShippingViewModal";
import HomeSidebar from "../Home/HomeSidebar";
import CategoryFollowIndex from "../Home/CategoryFollowIndex";
import AccessoriesHomeIndex from "../Home/AccessoriesHomeIndex";
import UpComingLiveStreamingIndex from "../Home/UpComingLiveStreamingIndex";

const NewCategoriesIndex = () => {

  const [shippingView, setShippingView] = useState(false);

  const closeShippingViewModal = () => {
    setShippingView(false);
  };

  return (
    <>
      <div className="new-outfyt-home-page-sec">
        <div className="new-main-wrapper">
          <div className="new-index-section">
            <HomeSidebar />
            <div className="new-home-right" >
              <NewExploreCategory />
              {localStorage.getItem("userId") &&
                localStorage.getItem("accessToken") ?
                <CategoryFollowIndex />
                : null
              }
              <NewLatestLiveStreamingIndex />
              <AccessoriesHomeIndex />
              <UpComingLiveStreamingIndex />
            </div>
          </div>
        </div>
      </div>
      <ShippingViewModal
        shippingView={shippingView}
        closeShippingViewModal={closeShippingViewModal}
        setShippingView={setShippingView}
      />
    </>
  );
};

export default NewCategoriesIndex;
