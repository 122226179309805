import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { liveVideoBookmarkSaveStart } from '../../store/actions/HomePageAction';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-multi-lang';

const LiveVideoCard = (props) => {

  const { live_video } = props;
  const navigate = useNavigate();

  const bookmarkVideo = (e, videoId) => {
    e.preventDefault();
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(liveVideoBookmarkSaveStart({
        live_video_id: videoId,
      }));
    } else {
      navigate("/login");
    }
  }

  return (
    <>
      <div className="new-home-latest-live-streaming-card">
        <div className="new-home-latest-live-streaming-img-sec">
          <Link to={props.redirection_status === 0 ? '#' : localStorage.getItem("userId") && localStorage.getItem("accessToken") ? `/live-stream/${live_video.live_video_unique_id}` : '/login'}>
            {live_video.preview_file_type == "image" ?
             <Image
             src={live_video.preview_file}
             className="new-home-latest-live-streaming-img"
           />
              :
              <video
                autoplay
                controls
                id="myVideo"
                className="live-card-video w-100"
              >
                <source src={live_video.preview_file} type="video/mp4" />
              </video>
            }
            <div className="new-home-latest-live-streaming-top-count-sec">
              <div className="new-home-latest-live-streaming-top-left-count">
                <i className="fas fa-eye"></i>
                <span>{live_video.viewer_cnt}</span>
              </div>
              <div className="new-home-latest-live-streaming-top-right-count"
                onClick={(e) => bookmarkVideo(e, live_video.live_video_id)}
              >
                {live_video.is_video_bookmarked
                  ?
                  <Image
                    src={window.location.origin +
                      "/images/outfyt/new-live-streaming/bookmark-filled.svg"}
                    className="new-home-latest-live-streaming-bid-icon"
                  />
                  :
                  <Image
                    src={window.location.origin +
                      "/images/outfyt/new-live-streaming/bookmark-icon.svg"}
                    className="new-home-latest-live-streaming-bid-icon"
                  />
                }
                {/* <span>{live_video.total_bookmarks}</span> */}
              </div>
            </div>
            <div className={
              live_video.is_streaming ?
                "new-home-latest-live-streaming-bottom-day-time-sec"
                :
                "new-home-latest-live-streaming-bottom-day-time-sec time-schedule"}
            >
              {!live_video.is_streaming ? live_video.formatted_schedule_time : null}
            </div>
          </Link>
        </div>
        <div className="new-home-latest-live-streaming-info">
          <div className="category-card-profile">
            <Link to={`/${live_video.user_unique_id}`}>
              <Image src=
                {live_video.user_picture}
                className="cat-pro-pic"
              />
              <h5>{live_video.user_displayname}</h5>
            </Link>
          </div>
          <h4>{live_video.description}</h4>
        </div>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(withTranslation(LiveVideoCard));