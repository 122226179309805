import React, { useState, useEffect } from "react";
import { Image, InputGroup, FormControl, Button, Form } from "react-bootstrap";
import {
  saveCartDetailsStart,
  removeCartDetailsStart,
  fetchCartListStart,
} from "../../../store/actions/ProductsAction";
import { connect } from "react-redux";
import "./Ecom.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { confirmAlert } from 'react-confirm-alert';

const SingleCartCard = (props) => {

  const t = useTranslation();
  const { cart } = props;
  const [skipRender, setSkipRender] = useState(true);
  const [subTotal, setSubTotal] = useState(cart.sub_total_formatted);

  // Set the initial count state to zero, 0
  const [count, setCount] = useState(cart.quantity);

  // Create handleIncrement event handler
  const handleIncrement = () => {
    if (cart.user_product_size) {
      if (cart.user_product_size.quantity > count) {
        props.dispatch(saveCartDetailsStart({ cart_id: cart.cart_id, user_product_id: cart.user_product_id, product_size_id: cart.product_size_id, quantity: count + 1 }));
        setCount(prevCount => prevCount + 1);
      }
    } else {
      if (cart.user_product.quantity > count) {
        props.dispatch(saveCartDetailsStart({ cart_id: cart.cart_id, user_product_id: cart.user_product_id, product_size_id: cart.product_size_id, quantity: count + 1 }));
        setCount(prevCount => prevCount + 1);
      }
    }


  };

  //Create handleDecrement event handler
  const handleDecrement = () => {
    if (count > 1) {
      props.dispatch(saveCartDetailsStart({ cart_id: cart.cart_id, user_product_id: cart.user_product_id, product_size_id: cart.product_size_id, quantity: count - 1 }));
      setCount(prevCount => prevCount - 1);
    }
  };

  const removeCartConfirmation = (cart_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_remove_from_cart"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => props.dispatch(removeCartDetailsStart({ cart_id: cart_id }))
        },
        {
          label: t("no"),
        }
      ]
    });
  }

  useEffect(() => {
    if (!skipRender &&
      !props.cartRemove.loading &&
      Object.keys(props.cartRemove.data).length > 0) {
      props.dispatch(fetchCartListStart());
    }
    setSkipRender(false);
  }, [props.cartRemove]);

  return (
    <>
      <tr>
        <td>
          {cart.user_product.file_type == "image" ?
            <Image
              className="ecom-cart-table-img"
              src={cart.user_product.picture}
            />
            :
            <video
              autoplay
              controls
              id="myVideo"
              className="ecom-products"
            >
              <source src={cart.user_product.picture} type="video/mp4" />
            </video>
          }
        </td>
        <td>
          {cart.user_product.name}
          {cart.user_product_variant || cart.user_product_size ?
            <>
              <br />
              {cart.user_product_variant ? cart.user_product_variant.name : ''}<br />
              {cart.user_product_size ? cart.user_product_size.name : ''}<br /></>
            : ''}

        </td>
        <td>{cart.user_product_size ? cart.user_product_size.price_formatted : cart.user_product.user_product_price_formatted}</td>
        <td>
          <div className="ecom-cart-inc-dec">
            <Form>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="product-handle"
                    onClick={handleDecrement}
                  ><i className="fas fa-minus"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl className="product-count" placeholder={count}></FormControl>
                <InputGroup.Append>
                  <InputGroup.Text className="product-handle"
                    onClick={handleIncrement}>
                    <i className="fas fa-plus"></i>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </td>
        <td>{subTotal}</td>
        <td>
          <Button type="submit" className="btn-button btn-update tiny"
            onClick={() => removeCartConfirmation(cart.cart_id)}
          >
            {t("remove")}
          </Button>
        </td>
      </tr>
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.userProducts.cartList,
  cartRemove: state.userProducts.cartRemove,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(SingleCartCard));
