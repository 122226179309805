import React from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const OtherProfileLoader = () => {
  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <div className="new-outfyt-profile-header-sec">
            <div className="new-outfyt-profile-img-sec">
              <Skeleton
                className="mb-3"
                count={1}
                width={144}
                height={144}
              />
            </div>
            <div className="new-outfyt-profile-info-sec">
              <h3>
                <Skeleton width={500} height={30} count={1} />
              </h3>
              <h3>
                <Skeleton width={500} height={30} count={1} />
              </h3>
              <h3>
                <Skeleton height={20} count={1} />
              </h3>
              <h3 className="flex flex-row gap-4">
                <Skeleton height={30} width={150} count={1} />
              </h3>
              <h3 className="flex flex-row gap-4">
                <Skeleton height={30} width={250} count={1} />
              </h3>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OtherProfileLoader;
