import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { loginStart, registerStart } from "../../../store/actions/UserAction";
import configuration from "react-global-configuration";
import SocialButton from '../../Helper/SocialButton';
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const NewLoginIndex = (props) => {
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (values) => {
    props.dispatch(loginStart(values));
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalid_email_address"))
      .required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_contain_6_characters")),
  });
  useEffect(() => {
    if (
      !skipRender &&
      !props.login.loading &&
      Object.keys(props.login.data).length > 0
    ) {
      if (props.login.data.is_email_verified === 1) {
        navigate("/");
      } else {
        navigate("/register/verify");
      }
    }
    setSkipRender(false);
  }, [props.login]);

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  const handleFacebookLogin = (user) => {
    props.dispatch(
      registerStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    props.dispatch(
      registerStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  return (
    <>
      <div className="outfyt-auth-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="outfyt-auth-box">
                <div className="outfyt-auth-left-sec">
                  <CustomLazyLoad
                    src={
                      window.location.origin +
                      "/images/outfyt/auth/auth-image.jpg"
                    }
                    className="outfyt-auth-img"
                  />
                </div>
                <div className="outfyt-auth-right-sec">
                  <div className="outfyt-auth-body-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/images/site-logo.png"
                      }
                      className="outfyt-auth-logo"
                    />
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      enableReinitialize={true}
                      onSubmit={(values) => handleLogin(values)}
                      validationSchema={loginSchema}
                    >
                      <FORM className="outfyt-auth-form">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Field
                            type="text"
                            placeholder={t("enter_email_address")}
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger"
                          />
                        </Form.Group>
                        <InputGroup
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Field
                            type={showPassword ? "text" : "password"}
                            placeholder={t("enter_password")}
                            name="password"
                            className="form-control"
                          />
                          <InputGroup.Text id="basic-addon2">
                            <button
                              onClick={() => setShowPassword(!showPassword)}
                              className="btn password-eye"
                              type="button"
                            >
                              {showPassword ? (
                                <Image
                                  className="pass-eye-open"
                                  src={
                                    window.location.origin +
                                    "/images/auth/icon/eye-hide.svg"
                                  }
                                />
                              ) : (
                                <Image
                                  className="pass-eye-open"
                                  src={
                                    window.location.origin +
                                    "/images/auth/icon/eye-open.svg"
                                  }
                                />
                              )}
                            </button>
                          </InputGroup.Text>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger pass-txt"
                          />
                        </InputGroup>
                        <div className="outfyt-auth-btn-sec">
                          <Button
                            className="outfyt-auth-btn w-100 text-uppercase"
                            type="submit"
                            disabled={props.login.buttonDisable}
                          >
                            {props.login.loadingButtonContent !== null
                              ? props.login.loadingButtonContent
                              : t("login")}
                          </Button>
                        </div>
                      </FORM>
                    </Formik>
                    <div className="outfyt-divider-or-sec">
                      <span>{t("or")}</span>
                    </div>
                    {configuration.get("configData.FB_CLIENT_ID") ||
                      configuration.get("configData.GOOGLE_CLIENT_ID") ?
                      <div className="outfyt-auth-social-link-sec">
                        <ul className="list-unstyled outfyt-auth-social-link-list">
                          {configuration.get("configData.FB_CLIENT_ID") ?
                            <li>

                              <SocialButton
                                provider="facebook"
                                appId={configuration.get("configData.FB_CLIENT_ID")}
                                onLoginSuccess={handleFacebookLogin}
                                onLoginFailure={handleSocialLoginFailure}
                                className="social-button"
                                id="facebook-connect"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                                  <path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"></path>
                                </svg>
                                {t("login_facebook")}
                              </SocialButton>

                            </li>
                            :
                            null
                          }

                          {configuration.get("configData.GOOGLE_CLIENT_ID") ?
                            <li>
                              <SocialButton
                                provider="google"
                                key={"google"}
                                appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                                onLoginSuccess={handleGoogleLogin}
                                onLoginFailure={handleSocialLoginFailure}
                                className="social-button"
                                id="google-connect"
                              >
                                <Image
                                  className="outfyt-auth-social-link-icon"
                                  src={window.location.origin + "/images/outfyt/auth/gmail-icon.svg"}
                                />
                                {t("login_google")}
                              </SocialButton>
                            </li>
                            :
                            null
                          }
                        </ul>
                      </div> : null}
                    <div className="outfyt-forgot-link-sec">
                      <Link
                        to="/forgot-password"
                        className="outfyt-forgot-link"
                      >
                        {t("forgot_password")}
                      </Link>
                    </div>
                  </div>
                  <div className="outfyt-footer-sec">
                    {t("dont_have_an_account_yet")}{" "}
                    <Link to="/register">
                      {t("sign_up_for", {
                        siteName: configuration.get("configData.site_name"),
                      })}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  login: state.users.login,
  register: state.users.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(NewLoginIndex));
