import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Button, Form, FormGroup, InputGroup, ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDropzone } from 'react-dropzone';
import Select from "react-select";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { userInventoryFilesUploadStart, userVerificationUpdateStart } from "../../../store/actions/UserAction";
import * as Yup from "yup";
import { Formik, Form as FORM, Field, ErrorMessage, useFormikContext } from 'formik';
import {
  fetchUserDetailsStart,
} from "../../../store/actions/UserAction";
import CommonCenterLoader from "../../Helper/CommonCenterLoader";

const SecondSellerIndex = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const BtnTitle = () => {
    if (page === 0) {
      return "I AGREE"
    } else if (page === 7) {
      return "SUBMIT"
    } else {
      return "CONTINUE"
    }
  }
  const Formhead = [t("terms_of_services"), t("phone_numbers"),
  t("are_you_para"), t("attach_a_photo_or_video"), t("sales_in_last_days"), t("inventory_value"),
  t("what_is_your_monthly"), t("how_often_do_you_plan")];

  const [value, setValue] = useState(0);

  const options = [
    { value: 1, label: '$0 - $5000' },
    { value: 2, label: '$5000 - $10000' },
    { value: 3, label: '$10000 - $20000' },
  ]

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent!important',
      borderColor: '#FFFFFF33!important',
      height: '60px',
      borderRadius: '50px',
      // minWidth: "150px",
      fontSize: "1em",
      paddingLeft: "1em",
      boxShadow: 'none',
      outline: 'none'
    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1em", fontWeight: "600"
    }),
    indicatorsContainer: provided => ({ ...provided, paddingRight: "1em", }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important', paddingRight: "1em", }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    placeholder: provided => ({ ...provided, textAlign: "left", }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#fff" : "#ccc",
        color: "#000"
      };
    }
  }

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'video/mp4': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    }
  });

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  const docs = files.map((file, index) => (
    <div className="upload-preview-outfyt-sec" key={file.name}>
      <svg width="24" height="24" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z" fill="#151515" />
      </svg>
      <div className="w-100">
        <p>{file.name}</p>
        <span>{(file.size / 1024).toFixed(2) + "kb"}</span>
        <ProgressBar now='100%' />
      </div>verify-upload-file-note-sec
      <div onClick={() => removeFile(index)}>
        <svg className="seller-close" width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
          />
        </svg>
      </div>
    </div>
  ));

  const handleInventoryUpload = () => {
    props.dispatch(userInventoryFilesUploadStart({
      files: files[0],
    }));
  };

  const handleSubmit = (values) => {
    let newValues = {}
    if (page != 7) {
      setPage((currPage) => currPage + 1);
    }
    if (page === 3) {
      if (files[0]) {
        handleInventoryUpload();
      }
    }
    if (page === 1) {
      newValues = {
        ...values,
        mobile: value,
      }
    }
    if (page === 7) {
      newValues = { ...values, mobile: value }
      // console.log("values",newValues)
      props.dispatch(userVerificationUpdateStart(newValues));
    }
  }

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !props.userVerificationUpdate.loading && Object.keys(props.userVerificationUpdate.data).length > 0) {
      navigate("/new-seller");
    }
    setSkipRender(false)
  }, [props.userVerificationUpdate]);

  useEffect(() => {
    props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <div className="new-outfyt-second-seller-sec">
        <div className="new-main-wrapper d-flex align-items-center">
          <Container>
            {props.profile.loading ? <CommonCenterLoader /> :
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="processing-section">
                    <div
                      style={{ width: page === 0 ? "137.37px" : page == 1 ? "274.69px" : page == 2 ? "412.01px" : page == 3 ? "549.33px" : page == 4 ? "686.65px" : page == 5 ? "823.97px" : page == 6 ? "961.29px" : "100%" }}
                    ></div>
                  </div>
                  <div className="second-seller-inner-head-sec">
                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Formik
                          initialValues={{
                            mobile: props.profile.data.mobile,
                            age_eighteen_plus: props.profile.data.age_eighteen_plus,
                            sales_range: "",
                            sales_balance: "",
                            sales_goal: props.profile.data.sales_goal,
                            live_plan: props.profile.data.live_plan,
                          }}
                          onSubmit={(values) => handleSubmit(values)}
                        >
                          {({ touched, errors, values, setFieldValue }) => (
                            <FORM>
                              <div className="second-seller-inner-desc">
                                <h3>{Formhead[page]}</h3>
                                {page == 0 && (
                                  <div>
                                    <p className="tile-par">{t("terms_para")}</p>
                                  </div>
                                )}
                                {page == 1 && (
                                  <div>
                                    <p className="tile-par">{t("by_providing_para")}</p>
                                    <div className="phone-second-sec">
                                      <FormGroup
                                        className="mb-4"
                                      >
                                        <Form.Label>{t("mobile_number")}</Form.Label>
                                        <PhoneInput
                                          id="seller-contact"
                                          className="seller-contact"
                                          defaultCountry="IN"
                                          placeholder={t("mobile")}
                                          name="mobile"
                                          value={values.mobile}
                                          onChange={setValue}
                                          international
                                        />
                                        <ErrorMessage
                                          name="mobile"
                                          component={"div"}
                                          className="text-danger pass-txt"
                                        />
                                      </FormGroup>

                                    </div>
                                  </div>
                                )}
                                {page == 2 && (
                                  <div>
                                    <p className="tile-par">{t("you_must_be_to_sell_on_outfyt")}</p>
                                    <div className="radio-age-part">
                                      <FormGroup>
                                        <Form.Check
                                          inline
                                          label="Yes"
                                          name="age_eighteen_plus"
                                          type="radio"
                                          id="inline-radio-1"
                                          checked={values.age_eighteen_plus == 1}
                                          onChange={() => setFieldValue("age_eighteen_plus", 1)}

                                        />
                                        <Form.Check
                                          inline
                                          label="No"
                                          name="age_eighteen_plus"
                                          type="radio"
                                          id="inline-radio-2"
                                          checked={values.age_eighteen_plus == 0}
                                          onChange={() => setFieldValue("age_eighteen_plus", 0)}
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                )}
                                {page == 3 && (
                                  <div>
                                    <p className="tile-par">{t("make_sure_images_para")}</p>
                                    <div className="second-upload-sec-part">
                                      <FormGroup>
                                        <label>{t("upload_your_inventory")}</label>
                                        <div className="verify-upload-file-card">
                                          <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Image src={window.location.origin + "/images/upload-img-out-1.png"} />
                                            <p>{t("drag_and_drop")} <br></br> {t("ors")}
                                              <span className="browse-files">{t("browse")}</span></p>
                                          </div>
                                        </div>
                                        <div className="verify-upload-file-note-sec">
                                          <p>{t("file_support")} </p>
                                        </div>
                                        {docs}
                                      </FormGroup>
                                    </div>
                                  </div>
                                )}
                                {page == 4 && (
                                  <div>
                                    <div className="incluse-part-sec-out">
                                      <p className="tile-par">{t("include_both_online")}</p>
                                      <div className="sales-outfy-part-sec">
                                        <Select
                                          options={options}
                                          styles={customStyles}
                                          defaultValue={options[props.profile.data.sales_range - 1]}
                                          value={{
                                            label: values.sales_range == 1 ? "$0 - $5000" :
                                              values.sales_range == 2 ? "$5000 - $10000" :
                                                values.sales_range == 3 ? "$10000 - $20000" :
                                                  t("select"),
                                            value: values.sales_range

                                          }}
                                          isSearchable={false}
                                          onChange={(selectedOption) => {
                                            setFieldValue("sales_range", selectedOption.value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {page == 5 && (
                                  <div>
                                    <div className="incluse-part-sec-out">
                                      <p className="tile-par">{t("what_is_estimited_para")}</p>
                                      <div className="sales-outfy-part-sec">
                                        <Select
                                          options={options}
                                          styles={customStyles}
                                          isSearchable={false}
                                          defaultValue={options[props.profile.data.sales_balance - 1]}
                                          value={{
                                            label: values.sales_balance == 1 ? "$0 - $5000" :
                                              values.sales_balance == 2 ? "$5000 - $10000" :
                                                values.sales_balance == 3 ? "$10000 - $20000" :
                                                  t("select"),
                                            value: values.sales_balance
                                          }}
                                          onChange={(selectedOption) => {
                                            setFieldValue("sales_balance", selectedOption.value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {page == 6 && (
                                  <div>
                                    <div className="radio-age-part">
                                      <FormGroup>
                                        <Form.Check
                                          inline
                                          label="$0 - $5K"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-1"
                                          checked={values.sales_goal === 1}
                                          onChange={() => setFieldValue("sales_goal", 1)}
                                        />
                                        <Form.Check
                                          inline
                                          label="$5 - $10K"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-2"
                                          checked={values.sales_goal === 2}
                                          onChange={() => setFieldValue("sales_goal", 2)}
                                        />
                                        <Form.Check
                                          inline
                                          label="$10 - $50K"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-3"
                                          checked={values.sales_goal === 3}
                                          onChange={() => setFieldValue("sales_goal", 3)}
                                        />
                                        <Form.Check
                                          inline
                                          label="$50 - $70K"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-4"
                                          checked={values.sales_goal === 4}
                                          onChange={() => setFieldValue("sales_goal", 4)}
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                )}
                                {page == 7 && (
                                  <div>
                                    <div className="radio-age-part">
                                      <FormGroup>
                                        <Form.Check
                                          inline
                                          label="A couple times per month"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-1"
                                          checked={values.live_plan === 1}
                                          onChange={() => setFieldValue("live_plan", 1)}
                                        />
                                        <Form.Check
                                          inline
                                          label="Once per week"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-2"
                                          checked={values.live_plan === 2}
                                          onChange={() => setFieldValue("live_plan", 2)}
                                        />
                                        <Form.Check
                                          inline
                                          label="Multiple times per week"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-3"
                                          checked={values.live_plan === 3}
                                          onChange={() => setFieldValue("live_plan", 3)}
                                        />
                                        <Form.Check
                                          inline
                                          label="Everyday"
                                          name="group1"
                                          type="radio"
                                          id="inline-radio-4"
                                          checked={values.live_plan === 4}
                                          onChange={() => setFieldValue("live_plan", 4)}
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {console.log(value)}
                              {console.log(value.length)}
                              <div className={page === 0 ? "btn-part-for-second-sec" : " btn-part-for-second-sec-two"}>
                                <Button disabled={page == 1 && !value ||
                                  value.length <= 12 ||
                                  page == 3 && files.length == 0 ||
                                  (page === 2 && values.age_eighteen_plus === 0) ||
                                  (page === 6 && values.sales_goal == "") ||
                                  (page === 7 && values.live_plan == "")}
                                  className="next-btn-second" onClick={() => handleSubmit(values)}>
                                  {BtnTitle()}
                                </Button>
                                <Button className="back-btn-second" onClick={() => {
                                  page == 0 ? navigate(-1) :
                                    setPage((currPage) => currPage - 1);
                                }}>
                                  <svg width="24" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5004 11H5.33041L8.21041 8.11999C8.39739 7.93301 8.50243 7.67942 8.50243 7.41499C8.50243 7.15056 8.39739 6.89697 8.21041 6.70999C8.02344 6.52301 7.76984 6.41797 7.50541 6.41797C7.24099 6.41797 6.98739 6.52301 6.80041 6.70999L2.21041 11.3C2.11771 11.3925 2.04416 11.5024 1.99398 11.6234C1.9438 11.7443 1.91797 11.874 1.91797 12.005C1.91797 12.136 1.9438 12.2656 1.99398 12.3866C2.04416 12.5076 2.11771 12.6175 2.21041 12.71L6.80041 17.3C6.89299 17.3926 7.00291 17.466 7.12387 17.5161C7.24483 17.5662 7.37448 17.592 7.50541 17.592C7.63634 17.592 7.76599 17.5662 7.88696 17.5161C8.00792 17.466 8.11783 17.3926 8.21041 17.3C8.30299 17.2074 8.37643 17.0975 8.42654 16.9765C8.47664 16.8556 8.50243 16.7259 8.50243 16.595C8.50243 16.4641 8.47664 16.3344 8.42654 16.2134C8.37643 16.0925 8.30299 15.9826 8.21041 15.89L5.33041 13H18.5004C19.0504 13 19.5004 12.55 19.5004 12C19.5004 11.45 19.0504 11 18.5004 11Z" fill="white" />
                                  </svg>
                                  {t("back")}
                                </Button>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            }
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInventoryFiles: state.users.userInventoryFiles,
  userVerificationUpdate: state.users.userVerificationUpdate,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(SecondSellerIndex));