import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategoriesFollowStart } from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";
import LiveVideoCard from "../../LiveVideos/LiveVideoCard";

const CategoryFollowIndex = (props) => {

  const t = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchCategoriesFollowStart({
        skip: 0,
        take: 12
      }));
    }
  }, []);

  return (
    <>
      <div className="new-home-latest-live-streaming-sec">
        <Container>
          {props.categoriesFollow.loading ?
            <LiveVideoLoader />
            :
            <>
              {Object.keys(props.categoriesFollow.data).length > 0 &&
                props.categoriesFollow.data.category_live_videos.length > 0 ?
                <div className="new-home-title-sec">
                  <h2>{t("categories_follow")}</h2>
                  <h3><Link to="/categories-follow">{t("view_all")}</Link></h3>
                </div>
                :
                null
              }
              {Object.keys(props.categoriesFollow.data).length > 0 &&
                props.categoriesFollow.data.category_live_videos.length > 0 ?
                <>
                  <div className="new-home-latest-live-streaming-box">
                    {props.categoriesFollow.data && props.categoriesFollow.data.category_live_videos.map(
                      (live_video) =>
                        <LiveVideoCard live_video={live_video} />
                    )}
                  </div>
                </>
                :
                ''}
            </>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoriesFollow: state.homepage.categoriesFollow,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(CategoryFollowIndex));

