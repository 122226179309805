import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchCategoriesFollowStart,
  fetchMoreCategoriesFollowStart
} from "../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import LiveVideoLoader from "../Helper/LiveVideosLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import LiveVideoCard from "./LiveVideoCard";
import { useNavigate } from "react-router-dom";

const CategoriesFollow = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchCategoriesFollowStart({
      skip: 0,
      take: 12
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreCategoriesFollowStart({
      skip: props.categoriesFollow.data.category_live_videos.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="new-outfyt-home-page-sec">
        <div className="new-main-wrapper">
          <div className="new-category-sec-1">
            <Container>
              <div className="new-category-header-sec">
                <Link to="#" onClick={() => navigate(-1)}>
                  <Image src={window.location.origin + "/images/back.svg"} />
                </Link>
                <h1>{t("categories_follow")}</h1>
              </div>
            </Container>
          </div>
          <Container>
            {props.categoriesFollow.loading ?
              <LiveVideoLoader />
              :
              Object.keys(props.categoriesFollow.data).length > 0 &&
                props.categoriesFollow.data.category_live_videos.length > 0 ?
                <InfiniteScroll
                  dataLength={props.categoriesFollow.data.category_live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.categoriesFollow.data.category_live_videos.length <
                    props.categoriesFollow.data.total_category_live_videos}
                  loader={<LiveVideoLoader />}
                >
                  <div className="new-home-latest-live-streaming-box-video">
                    {props.categoriesFollow.data.category_live_videos.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoriesFollow: state.homepage.categoriesFollow,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(CategoriesFollow));


