import {
    USER_PRODUCTS_SAVE_START,
    USER_PRODUCTS_SAVE_SUCCESS,
    USER_PRODUCTS_SAVE_FAILURE,
    FETCH_PRODUCT_CATEGORIES_START,
    FETCH_PRODUCT_CATEGORIES_SUCCESS,
    FETCH_PRODUCT_CATEGORIES_FAILURE,
    FETCH_PRODUCT_SUB_CATEGORIES_START,
    FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS,
    FETCH_PRODUCT_SUB_CATEGORIES_FAILURE,
    FETCH_USER_SINGLE_PRODUCT_START,
    FETCH_USER_SINGLE_PRODUCT_SUCCESS,
    FETCH_USER_SINGLE_PRODUCT_FAILURE,
    FETCH_USER_PRODUCTS_FOR_OWNER_START,
    FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS,
    FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE,
    FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START,
    DELETE_USER_PRODUCT_START,
    DELETE_USER_PRODUCT_SUCCESS,
    DELETE_USER_PRODUCT_FAILURE,
    FETCH_SINGLE_PRODUCT_ORDERS_START,
    FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS,
    FETCH_SINGLE_PRODUCT_ORDERS_FAILURE,
    USER_PRODUCT_VIEW_FOR_OTHERS_START,
    USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS,
    USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE,
    FETCH_USER_PRODUCTS_START,
    FETCH_USER_PRODUCTS_SUCCESS,
    FETCH_USER_PRODUCTS_FAILURE,
    USER_PRODUCTS_SEARCH_START,
    USER_PRODUCTS_SEARCH_SUCCESS,
    USER_PRODUCTS_SEARCH_FAILURE,
    USER_PRODUCTS_MORE_SEARCH_START,
    USER_PRODUCT_PICTURE_SAVE_START,
    USER_PRODUCT_PICTURE_SAVE_SUCCESS,
    USER_PRODUCT_PICTURE_SAVE_FAILURE,
    USER_PRODUCT_VARIANTS_SAVE_START,
    USER_PRODUCT_VARIANTS_SAVE_SUCCESS,
    USER_PRODUCT_VARIANTS_SAVE_FAILURE,
    FETCH_CART_LIST_START,
    FETCH_CART_LIST_SUCCESS,
    FETCH_CART_LIST_FAILURE,
    SAVE_CART_DETAILS_START,
    SAVE_CART_DETAILS_SUCCESS,
    SAVE_CART_DETAILS_FAILURE,
    REMOVE_CART_DETAILS_START,
    REMOVE_CART_DETAILS_SUCCESS,
    REMOVE_CART_DETAILS_FAILURE,
    FETCH_ECOMM_HOME_START,
    FETCH_ECOMM_HOME_SUCCESS,
    FETCH_ECOMM_HOME_FAILURE,
    FETCH_USER_PRODUCT_VARIANTS_START,
    FETCH_USER_PRODUCT_VARIANTS_SUCCESS,
    FETCH_USER_PRODUCT_VARIANTS_FAILURE,
    CATEGORY_USER_PRODUCTS_START,
    CATEGORY_USER_PRODUCTS_SUCCESS,
    CATEGORY_USER_PRODUCTS_FAILURE,
    FETCH_MORE_SINGLE_PRODUCT_ORDERS_START
} from "../actions/ActionConstant";

const initialState = {
    products: {
        data: {},
        loading: true,
        error: false,
    },
    productSave: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    productCategories: {
        data: {},
        loading: true,
        error: false,
    },
    productSubCategories: {
        data: {},
        loading: true,
        error: false,
    },
    productView: {
        data: {},
        loading: true,
        error: false,
    },
    productsList: {
        data: {
            user_products: [],
            total: 0,
        },
        loading: true,
        error: false,
    },
    productDelete: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false
    },
    singleProductOrders: {
        data: {},
        loading: true,
        error: false,
    },
    productViewForOthers: {
        data: {},
        loading: true,
        error: false,

    },
    productSearch: {
        data: {
            user_products: [],
            total: 0,
        },
        loading: true,
        error: false,
    },
    productPictureSave: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    productVariantSave: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    cartList: {
        data: {},
        loading: true,
        error: false,
    },
    cartSave: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    cartRemove: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    ecommHome: {
        data: {},
        loading: true,
        error: false,
    },
    productVariants: {
        data: {},
        loading: true,
        error: false,
    },
    categoryUserProducts: {
        data: {},
        loading: true,
        error: false,
    },
}

const ProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_PRODUCTS_START:
            return {
                ...state,
                products: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_PRODUCTS_FAILURE:
            return {
                ...state,
                products: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };

        case USER_PRODUCTS_SAVE_START:
            return {
                ...state,
                productSave: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case USER_PRODUCTS_SAVE_SUCCESS:
            return {
                ...state,
                productSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case USER_PRODUCTS_SAVE_FAILURE:
            return {
                ...state,
                productSave: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case FETCH_PRODUCT_CATEGORIES_START:
            return {
                ...state,
                productCategories: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                productCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_PRODUCT_CATEGORIES_FAILURE:
            return {
                ...state,
                productCategories: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_PRODUCT_SUB_CATEGORIES_START:
            return {
                ...state,
                productSubCategories: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_PRODUCT_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                productSubCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_PRODUCT_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                productSubCategories: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_USER_SINGLE_PRODUCT_START:
            return {
                ...state,
                productView: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                productView: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_SINGLE_PRODUCT_FAILURE:
            return {
                ...state,
                productView: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_USER_PRODUCTS_FOR_OWNER_START:
            return {
                ...state,
                productsList: {
                    data: {
                        user_products: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                    errorCount: 0,
                },
            };
        case FETCH_USER_PRODUCTS_FOR_OWNER_SUCCESS:
            return {
                ...state,
                productsList: {
                    data: {
                        user_products: [...state.productsList.data.user_products, ...action.data.user_products],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_PRODUCTS_FOR_OWNER_FAILURE:
            return {
                ...state,
                productsList: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START:
            return state;
        case DELETE_USER_PRODUCT_START:
            return {
                ...state,
                productDelete: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: true
                },
            };
        case DELETE_USER_PRODUCT_SUCCESS:
            return {
                ...state,
                productDelete: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case DELETE_USER_PRODUCT_FAILURE:
            return {
                ...state,
                productDelete: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case FETCH_SINGLE_PRODUCT_ORDERS_START:
            return {
                ...state,
                singleProductOrders: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_SINGLE_PRODUCT_ORDERS_SUCCESS:
            return {
                ...state,
                singleProductOrders: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_SINGLE_PRODUCT_ORDERS_FAILURE:
            return {
                ...state,
                singleProductOrders: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };

        case FETCH_MORE_SINGLE_PRODUCT_ORDERS_START:
            return state;

        case USER_PRODUCT_VIEW_FOR_OTHERS_START:
            return {
                ...state,
                productViewForOthers: {
                    data: {},
                    loading: true,
                    error: false,

                },
            };
        case USER_PRODUCT_VIEW_FOR_OTHERS_SUCCESS:
            return {
                ...state,
                productViewForOthers: {
                    data: action.data,
                    loading: false,
                    error: false,

                },
            };
        case USER_PRODUCT_VIEW_FOR_OTHERS_FAILURE:
            return {
                ...state,
                productViewForOthers: {
                    data: {},
                    loading: false,
                    error: action.error,

                },
            };
        case USER_PRODUCTS_SEARCH_START:
            return {
                ...state,
                productSearch: {
                    data: {
                        user_products: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case USER_PRODUCTS_SEARCH_SUCCESS:
            return {
                ...state,
                productSearch: {
                    data: {
                        user_products: [...state.productSearch.data.user_products, ...action.data.user_products],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                },
            };
        case USER_PRODUCTS_SEARCH_FAILURE:
            return {
                ...state,
                productSearch: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };

        case USER_PRODUCTS_MORE_SEARCH_START:
            return state;

        case USER_PRODUCT_PICTURE_SAVE_START:
            return {
                ...state,
                productPictureSave: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case USER_PRODUCT_PICTURE_SAVE_SUCCESS:
            return {
                ...state,
                productPictureSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case USER_PRODUCT_PICTURE_SAVE_FAILURE:
            return {
                ...state,
                productPictureSave: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };

        case USER_PRODUCT_VARIANTS_SAVE_SUCCESS:
            return {
                ...state,
                productVariantSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };
        case USER_PRODUCT_VARIANTS_SAVE_START:
            return {
                ...state,
                productVariantSave: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case USER_PRODUCT_VARIANTS_SAVE_FAILURE:
            return {
                ...state,
                productVariantSave: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false
                },
            };

        case FETCH_CART_LIST_START:
            return {
                ...state,
                cartList: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_CART_LIST_SUCCESS:
            return {
                ...state,
                cartList: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_CART_LIST_FAILURE:
            return {
                ...state,
                cartList: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case SAVE_CART_DETAILS_START:
            return {
                ...state,
                cartSave: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case SAVE_CART_DETAILS_SUCCESS:
            return {
                ...state,
                cartSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case SAVE_CART_DETAILS_FAILURE:
            return {
                ...state,
                cartSave: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case REMOVE_CART_DETAILS_START:
            return {
                ...state,
                cartRemove: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };

        case REMOVE_CART_DETAILS_SUCCESS:
            return {
                ...state,
                cartRemove: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case REMOVE_CART_DETAILS_FAILURE:
            return {
                ...state,
                cartRemove: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case FETCH_ECOMM_HOME_START:
            return {
                ...state,
                ecommHome: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_ECOMM_HOME_SUCCESS:
            return {
                ...state,
                ecommHome: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_ECOMM_HOME_FAILURE:
            return {
                ...state,
                ecommHome: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_USER_PRODUCT_VARIANTS_START:
            return {
                ...state,
                productVariants: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_PRODUCT_VARIANTS_SUCCESS:
            return {
                ...state,
                productVariants: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_PRODUCT_VARIANTS_FAILURE:
            return {
                ...state,
                productVariants: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case CATEGORY_USER_PRODUCTS_START:
            return {
                ...state,
                categoryUserProducts: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case CATEGORY_USER_PRODUCTS_SUCCESS:
            return {
                ...state,
                categoryUserProducts: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case CATEGORY_USER_PRODUCTS_FAILURE:
            return {
                ...state,
                categoryUserProducts: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        default:
            return state;
    }

}

export default ProductsReducer;

