import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LiveVideoChatLoader = ({ count }) => {
  return (
    <SkeletonTheme baseColor="#dee2e6" highlightColor="#ced4da">
      <div className="flex flex-col gap-3">
        {[...Array(count).keys()].map((index) => {
          return (
            <div
              className="d-flex justify-content-start align-items-center gap-3 mb-3"
              key={index}
            >
              <Skeleton circle height={56} width={56}></Skeleton>
              <Skeleton height={24} width={200} borderRadius={8}></Skeleton>
            </div>
          );
        })}
      </div>
    </SkeletonTheme>
  );
};

export default LiveVideoChatLoader;
