import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateTrackingNumberStart } from "../../store/actions/OrderAction";
import { Link } from "react-router-dom";

const UpdateTrackingModal = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const trackingSchema = Yup.object().shape({
    tracking_number: Yup.string().required(t("tracking_number_is_required")),
    courier: Yup.string().required(t("courier_is_required")),
    tracking_url: Yup.string().when('tracking_type', {
      is: "2",
      then: Yup.string().required(t("tracking_url_is_required")),
      otherwise: Yup.string()
    }),
  });

  const handleSubmit = (values) => {
    props.dispatch(updateTrackingNumberStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateTrackingNumber.loading &&
      Object.keys(props.updateTrackingNumber.data).length > 0
    ) {
      props.setUpdateTracking(false);
    }
    setSkipRender(false);
  }, [props.updateTrackingNumber]);

  return (
    <>
      <Modal
        className="modal-dialog-center shipping-view-modal-sec"
        size="md"
        centered
        show={props.updateTracking}
        onHide={props.closeUpdateTrackingModal}
      >
        <div className="close-btn-sec">
          <Button
            type="button"
            className="close-modal-popup"
            onClick={() => props.setUpdateTracking(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="#fff"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M23.229 9.219l-3.224-5.5A5.52 5.52 0 0015.26 1H8.709a5.523 5.523 0 00-4.745 2.719l-3.222 5.5a5.502 5.502 0 000 5.562l3.223 5.501A5.522 5.522 0 008.71 23h6.551a5.52 5.52 0 004.745-2.719l3.224-5.5a5.505 5.505 0 000-5.561zm-2.588 4.045l-3.224 5.5A2.51 2.51 0 0115.26 20H8.709c-.883 0-1.71-.474-2.157-1.236l-3.223-5.5a2.502 2.502 0 010-2.528l3.223-5.499A2.51 2.51 0 018.709 4h6.551c.884 0 1.71.474 2.157 1.236l3.224 5.5c.456.78.456 1.749 0 2.529zM15.81 10.31l-1.689 1.689 1.689 1.689a1.5 1.5 0 11-2.122 2.121l-1.689-1.689-1.689 1.689c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121l1.689-1.689-1.689-1.689a1.5 1.5 0 112.121-2.121l1.689 1.689 1.689-1.689a1.5 1.5 0 112.121 2.121z"></path>
            </svg>
          </Button>
        </div>
        <Modal.Body>
            <div className="shipping-add-new-address-sec">
              <div className="shipping-change-address-header-sec my-2">
                <div className="shipping-change-address-header-left-sec">
                  <h4>{t("update_tracking_info")}</h4>
                </div>
              </div>
              <Formik
                initialValues={{
                  tracking_type: props.order.tracking_type.toString(),
                  tracking_number: props.order.tracking_number,
                  courier: props.order.courier_service,
                  order_unique_id: props.order.unique_id,
                  tracking_url: props.order.tracking_url ? props.order.tracking_url : "",
                }}
                validationSchema={trackingSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, values }) => (
                  <FORM>
                    <div className="shipping-add-new-address-body-sec">
                      <Form.Group
                        className="mb-3 shipping-change-address-sec"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>{t("tracking_type")}: (*)</Form.Label>
                        <div className="shipping-change-address-card w-full mb-3">
                          <label>
                            <Field
                              type="radio"
                              name="tracking_type"  
                              id="card1"
                              value="1"
                            />
                            <div className="shipping-change-address-item">
                              <h4 className="mt-2">{t("online_tracking")}</h4>
                            </div>
                          </label>
                        </div>
                        <div className="shipping-change-address-card w-full">
                          <label>
                            <Field
                              type="radio"
                              name="tracking_type"
                              id="card2"
                              value="2"
                            />
                            <div className="shipping-change-address-item">
                              <h4 className="mt-2">{t("offline_tracking")}</h4>
                            </div>
                          </label>
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>{t("tracking_number")}: (*)</Form.Label>
                        <Field
                          className="form-control"
                          placeholder={t("tracking_number")}
                          type="text"
                          autoFocus={true}
                          name="tracking_number"
                        />
                        <ErrorMessage
                          name="tracking_number"
                          component={"div"}
                          className="text-danger pass-txt"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>{t("courier")}: (*)</Form.Label>
                        <Field
                          className="form-control"
                          placeholder={t("courier")}
                          type="text"
                          name="courier"
                        />
                        <ErrorMessage
                          name="courier"
                          component={"div"}
                          className="text-danger pass-txt"
                        />
                      </Form.Group>
                      {values.tracking_type==="2"&&
                        <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>{t("tracking_url")}: (*)</Form.Label>
                        <Field
                          className="form-control"
                          placeholder={t("tracking_url")}
                          type="text"
                          name="tracking_url"
                        />
                        <ErrorMessage
                          name="tracking_url"
                          component={"div"}
                          className="text-danger pass-txt"
                        />
                        </Form.Group>
                      }
                    </div>
                    <div className="shipping-view-product-description-btn-sec">
                      <Button
                        className="buy-now-btn"
                        disabled={props.updateTrackingNumber.buttonDisable}
                        type="submit"
                      >
                        {props.updateTrackingNumber.loadingButtonContent !==
                        null
                          ? props.updateTrackingNumber.loadingButtonContent
                          : t("update_tracking")}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  updateTrackingNumber: state.order.updateTrackingNumber,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(UpdateTrackingModal));
