import React, { useState } from "react";
import { Image, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchOngoingLiveVideosStart,
  fetchMoreOngoingLiveVideosStart,
} from "../../store/actions/HomePageAction";
import { useEffect } from "react";
import HomeCategoryLoader from "../Helper/HomeCategoryLoader";
import { liveVideoBookmarkSaveStart } from "../../store/actions/HomePageAction";
import { useNavigate } from "react-router-dom";
import CategorySidebar from "../Category/CategorySidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";

const OnGoingLiveVideos = (props) => {

  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchOngoingLiveVideosStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreOngoingLiveVideosStart({
        skip: props.onGoingLiveVideos.data.live_videos.length,
        take: 12,
      })
    );
  };

  const bookmarkVideo = (e, videoId) => {
    e.preventDefault();
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      if (Object.keys(props.onGoingLiveVideos.data).length > 0) {
        props.dispatch(liveVideoBookmarkSaveStart({
          live_video_id: videoId,
        }));
      }
    } else {
      navigate("/login");
    }
  }
  return (
    <Container fluid className="p-0">
      <div className="new-category-sec">
        <div className="home-page-right-sec">
          <div className="new-card-head pt-8">
            <h3 className="sub-catdata">{t("live_shows")}</h3>
          </div>
          <div className="category-card-sec mt-4">
            {props.onGoingLiveVideos.loading ?
              <div className="category-card-box">
                {[...Array(5)].map((i) => (
                  <Skeleton count={1} width={218} height={210} />
                ))}
              </div>
              :
              Object.keys(props.onGoingLiveVideos.data).length > 0 &&
                props.onGoingLiveVideos.data.live_videos.length > 0
                ?
                <InfiniteScroll
                  dataLength={props.onGoingLiveVideos.data.live_videos.length}
                  next={fetchMoreData}
                  hasMore={
                    props.onGoingLiveVideos.data.live_videos.length <
                    props.onGoingLiveVideos.data.total_live_videos
                  }
                  loader={<div className="category-card-box">
                    {[...Array(5)].map((i) => (
                      <Skeleton count={1} width={218} height={210} />
                    ))}
                  </div>}
                >
                  <div className="category-card-box">
                    {props.onGoingLiveVideos.data.live_videos.map(
                      (live_video) => (
                        <div className="category-card">
                          <div className="category-card-img">
                          <Link to={`/${live_video.username}`}>
                              <Image
                                src={live_video.preview_file}
                                className="list-card-img"
                              />
                              <div className="category-card-view">
                                <div className="category-card-icon">
                                  <Image src={window.location.origin + "/images/card/eye.svg"} />
                                  <p>{live_video.viewer_cnt}</p>
                                </div>
                                <div className="category-card-icon">
                                  <p>{live_video.total_bookmarks}</p>
                                  <div className="card-top-img" onClick={(e) => bookmarkVideo(e, live_video.live_video_id)}>
                                    {live_video.is_video_bookmarked
                                      ?
                                      <Image
                                        src={window.location.origin + "/images/card/bookmark-active.svg"}
                                      />
                                      :
                                      <Image
                                        src={window.location.origin + "/images/card/bookmark.svg"}
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="category-card-profile">
                            <Link to={`/${live_video.username}`}>
                              <Image src=
                                {live_video.user_picture}
                                className="cat-pro-pic"
                              />
                              <h5>{live_video.username} </h5>
                            </Link>
                          </div>
                          <div className="category-card-content">
                            <h4>
                              {live_video.description}
                            </h4>
                            <Link to={`/live-stream/${live_video.live_video_id}`}>{live_video.category_name}</Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </InfiniteScroll>
                :
                <NoDataFound />
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  onGoingLiveVideos: state.homepage.onGoingLiveVideos,
  liveBookmarkSave: state.liveVideo.liveBookmarkSave,
})

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnGoingLiveVideos);
