import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchUpcomingLiveStreamsStart } from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import LiveVideoCard from "../../LiveVideos/LiveVideoCard";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";

const UpComingLiveStreamingIndex = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchUpcomingLiveStreamsStart({
      skip: 0,
      take: 12
    }));
  }, []);

  return (
    <>
      <div className="new-home-latest-live-streaming-sec">
        <Container>

          {props.upcomingLiveStreams.loading ?
            <LiveVideoLoader />
            :
            <>
              {Object.keys(props.upcomingLiveStreams.data).length > 0 &&
                props.upcomingLiveStreams.data.live_streamings.length > 0 ?
                <div className="new-home-title-sec">
                  <h2>{t("upcoming_livestreams")}</h2>
                  <h3><Link to="/upcoming-livestreams">{t("view_all")}</Link></h3>
                </div>
                :
                null
              }
              {Object.keys(props.upcomingLiveStreams.data).length > 0 &&
                props.upcomingLiveStreams.data.live_streamings.length > 0 ?
                <>
                  <div className="new-home-latest-live-streaming-box">
                    {props.upcomingLiveStreams.data.live_streamings.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </>
                :
                ''}
            </>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  upcomingLiveStreams: state.homepage.upcomingLiveStreams,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(UpComingLiveStreamingIndex));

