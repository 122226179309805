import React, { useEffect } from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchLatestLiveVideosStart } from "../../../store/actions/HomePageAction";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import LiveVideoCard from "../../LiveVideos/LiveVideoCard";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";

const NewLatestLiveStreamingIndex = (props) => {

  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchLatestLiveVideosStart({
      skip: 0,
      take:12
      }));
  }, []);

  return (
    <>
      <div className="new-home-latest-live-streaming-sec">
        <Container>
          {props.latestLiveVideos.loading ?
            <LiveVideoLoader />
            :
            <>
              {Object.keys(props.latestLiveVideos.data).length > 0 &&
                props.latestLiveVideos.data.latest_live_videos.length > 0 ?
                <div className="new-home-title-sec">
                  <h2>{t("the_latest")} {t("take_look_para")}</h2>
                  <h3><Link to="/latest-live-videos">{t("view_all")}</Link></h3>
                </div>
                :
                null
              }
              {Object.keys(props.latestLiveVideos.data).length > 0 &&
                props.latestLiveVideos.data.latest_live_videos.length > 0 ?
                <>
                  <div className="new-home-latest-live-streaming-box">
                    {props.latestLiveVideos.data.latest_live_videos.map(
                      (live_video) => (
                        <LiveVideoCard live_video={live_video} />
                      ))}
                  </div>
                </>
                :
                ''}
            </>
          }
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  latestLiveVideos: state.homepage.latestLiveVideos,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
  (withTranslation(NewLatestLiveStreamingIndex));
