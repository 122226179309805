import {
  FETCH_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS,
  FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE,
  FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_LIVE_VIDEOS_HISTORY_SUCCESS,
  FETCH_LIVE_VIDEOS_HISTORY_FAILURE,
  FETCH_LIVE_VIDEO_ORDERS_START,
  FETCH_LIVE_VIDEO_ORDERS_SUCCESS,
  FETCH_LIVE_VIDEO_ORDERS_FAILURE,
  FETCH_MORE_LIVE_VIDEO_ORDERS_START,
  LIVE_VIDEO_CALL_BROADCAST_START,
  LIVE_VIDEO_CALL_BROADCAST_FAILURE,
  LIVE_VIDEO_CALL_BROADCAST_SUCCESS,
  FETCH_LIVE_VIDEOS_START,
  FETCH_LIVE_VIDEOS_SUCCESS,
  FETCH_LIVE_VIDEOS_FAILURE,
  LIVE_VIDEOS_END_START,
  LIVE_VIDEOS_END_SUCCESS,
  LIVE_VIDEOS_END_FAILURE,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  FETCH_SINGLE_LIVE_VIDEOS_SUCCESS,
  FETCH_SINGLE_LIVE_VIDEOS_FAILURE,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS,
  LIVE_VIDEOS_VIEWER_UPDATE_FAILURE,
  FETCH_LIVE_VIDEO_MESSAGE_START,
  FETCH_LIVE_VIDEO_MESSAGE_SUCCESS,
  FETCH_LIVE_VIDEO_MESSAGE_FAILURE,
  ADD_LIVE_VIDEO_MESSAGE_CONTENT,
  FETCH_PAST_LIVE_VIDEOS_START,
  FETCH_PAST_LIVE_VIDEOS_SUCCESS,
  FETCH_PAST_LIVE_VIDEOS_FAILURE,
  FETCH_MORE_PAST_LIVE_VIDEOS_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_SUCCESS,
  FETCH_SCHEDULED_LIVE_VIDEOS_FAILURE,
  FETCH_MORE_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_LIVE_SHOWS_HISTORY_START,
  FETCH_LIVE_SHOWS_HISTORY_SUCCESS,
  FETCH_LIVE_SHOWS_HISTORY_FAILURE,
  FETCH_MORE_LIVE_SHOWS_HISTORY_START,
  LIVE_VIDEOS_DELETE_START,
  LIVE_VIDEOS_DELETE_SUCCESS,
  LIVE_VIDEOS_DELETE_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  saveLiveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveEnd: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveStart: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  joinliveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleLiveVideo: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveVideos: {
    data: {
      videos: [],
    },
    responseData: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  liveVideosHistory: {
    data: {
      live_videos: [],
      live_video_upcomings: [],
      total: 0,
      total_live_video_upcomings: 0,
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  liveShowsHistory: {
    data: {
      live_videos: [],
      live_video_upcomings: [],
      total: 0,
      total_live_video_upcomings: 0,
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  upcommingLiveStreamings: {
    data: {},
    loading: true,
    error: false,
  },
  liveVideoChat: {
    data: {
      messages: [],
    },
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  liveVideosProducts: {
    data: {
      products: [],
    },
    loading: true,
    error: false,
    inputData: {},
    skip: 0,
    length: 0,
    productData: {},
    loadMore: false,
  },
  liveVideoOrders: {
    data: {
      orders: [],
    },
    loading: true,
    error: false,
    inputData: {},
    skip: 0,
    length: 0,
    orderData: {},
    loadMore: false,
  },
  pastLiveVideos: {
    data: {
      live_videos: [],
      live_video_upcomings: [],
      total: 0,
      total_live_video_upcomings: 0,
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  ScheduledLiveVideos: {
    data: {
      live_videos: [],
      total: 0,
    },
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  deleteLiveStream: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const LiveVideoReducer = (state = initialState, action) => {
  switch (action.type) {

    case LIVE_VIDEO_CALL_BROADCAST_START:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEO_CALL_BROADCAST_SUCCESS:
      return {
        ...state,
        saveLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEO_CALL_BROADCAST_FAILURE:
      return {
        ...state,
        saveLiveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_LIVE_VIDEOS_START:
      return {
        ...state,
        liveVideos: {
          data: {
            videos: [...state.liveVideos.data.videos],
          },
          loading: true,
          error: false,
          skip: state.liveVideos.skip,
          length: state.liveVideos.length,
        },
      };
    case FETCH_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        liveVideos: {
          data: action.data,
          data: {
            videos: [
              ...state.liveVideos.data.videos,
              ...action.data.live_videos,
            ],
          },
          loading: false,
          error: false,
          skip: action.data.live_videos.length + state.liveVideos.skip,
          length: action.data.live_videos.length,
        },
      };
    case FETCH_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        liveVideos: {
          data: {},
          loading: false,
          error: action.error,
          skip: state.liveVideos.skip,
          length: state.liveVideos.length,
        },
      };

    case LIVE_VIDEOS_END_START:
      return {
        ...state,
        liveEnd: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_END_SUCCESS:
      return {
        ...state,
        liveEnd: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_END_FAILURE:
      return {
        ...state,
        liveEnd: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_SINGLE_LIVE_VIDEOS_START:
      return {
        ...state,
        singleLiveVideo: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_SINGLE_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        singleLiveVideo: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SINGLE_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        singleLiveVideo: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_LIVE_VIDEOS_HISTORY_START:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.liveVideosHistory.data.live_videos : [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_SUCCESS:
      return {
        ...state,
        liveVideosHistory: {
          data: {
            ...action.data,
            live_videos: [...state.liveVideosHistory.data.live_videos, ...action.data.live_videos],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_HISTORY_FAILURE:
      return {
        ...state,
        liveVideosHistory: {
          data: state.liveVideosHistory.data,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_LIVE_VIDEOS_HISTORY_START:
      return state;

    case LIVE_VIDEOS_VIEWER_UPDATE_START:
      return {
        ...state,
        liveViewerUpdate: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case LIVE_VIDEOS_VIEWER_UPDATE_SUCCESS:
      return {
        ...state,
        liveViewerUpdate: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case LIVE_VIDEOS_VIEWER_UPDATE_FAILURE:
      return {
        ...state,
        liveViewerUpdate: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case FETCH_LIVE_VIDEOS_PRODUCTS_START:
      return {
        ...state,
        liveVideosProducts: {
          data: {
            live_video_products: [],
            purchased_products: [],
            products_available: 0,
            products_sold: 0,
            products_purchased: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_PRODUCTS_SUCCESS:
      return {
        ...state,
        liveVideosProducts: {
          data: {
            live_video_products: [...state.liveVideosProducts.data.live_video_products, ...action.data.live_video_products],
            purchased_products: [...state.liveVideosProducts.data.purchased_products, ...action.data.purchased_products],
            products_available: action.data.products_available,
            products_sold: action.data.products_sold,
            products_purchased: action.data.products_purchased,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEOS_PRODUCTS_FAILURE:
      return {
        ...state,
        liveVideosProducts: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START:
      return state;

    case FETCH_LIVE_VIDEO_ORDERS_START:
      return {
        ...state,
        liveVideoOrders: {
          data: {
            orders: [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEO_ORDERS_SUCCESS:
      return {
        ...state,
        liveVideoOrders: {
          data: {
            orders: [...state.liveVideoOrders.data.orders, ...action.data.orders],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVE_VIDEO_ORDERS_FAILURE:
      return {
        ...state,
        liveVideoOrders: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case FETCH_MORE_LIVE_VIDEO_ORDERS_START:
      return state;

    case FETCH_LIVE_VIDEO_MESSAGE_START:
      return {
        ...state,
        liveVideoChat: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case FETCH_LIVE_VIDEO_MESSAGE_SUCCESS:
      return {
        ...state,
        liveVideoChat: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_LIVE_VIDEO_MESSAGE_FAILURE:
      return {
        ...state,
        liveVideoChat: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    case ADD_LIVE_VIDEO_MESSAGE_CONTENT:
      return {
        ...state,
        liveVideoChat: {
          data: {
            messages: [...state.liveVideoChat.data.messages, ...action.data],
          },
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_PAST_LIVE_VIDEOS_START:
      return {
        ...state,
        pastLiveVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.pastLiveVideos.data.live_videos : [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_PAST_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        pastLiveVideos: {
          data: {
            ...action.data,
            live_videos: [...state.pastLiveVideos.data.live_videos, ...action.data.live_videos],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_PAST_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        pastLiveVideos: {
          data: state.pastLiveVideos.data,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_PAST_LIVE_VIDEOS_START:
      return state;
    case FETCH_SCHEDULED_LIVE_VIDEOS_START:
      return {
        ...state,
        ScheduledLiveVideos: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.ScheduledLiveVideos.data.live_videos : [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_SCHEDULED_LIVE_VIDEOS_SUCCESS:
      return {
        ...state,
        ScheduledLiveVideos: {
          data: {
            ...action.data,
            live_videos: [...state.ScheduledLiveVideos.data.live_videos, ...action.data.live_video_upcomings],
            total: action.data.total_live_video_upcomings,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_SCHEDULED_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        ScheduledLiveVideos: {
          data: state.ScheduledLiveVideos.data,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_SCHEDULED_LIVE_VIDEOS_START:
      return state;
    case FETCH_LIVE_SHOWS_HISTORY_START:
      return {
        ...state,
        liveShowsHistory: {
          data: {
            live_videos: action.data?.fetchMore === true ? state.liveShowsHistory.data.live_videos : [],
            total: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_LIVE_SHOWS_HISTORY_SUCCESS:
      return {
        ...state,
        liveShowsHistory: {
          data: {
            ...action.data,
            live_videos: [...state.liveShowsHistory.data.live_videos, ...action.data.live_videos],
            total: action.data.total,
          },
          loading: false,
          error: false,
        },
      };
    case FETCH_LIVE_SHOWS_HISTORY_FAILURE:
      return {
        ...state,
        liveShowsHistory: {
          data: state.liveShowsHistory.data,
          loading: false,
          error: action.error,
        },
      };
    case FETCH_MORE_LIVE_SHOWS_HISTORY_START:
      return state;
    case LIVE_VIDEOS_DELETE_START:
      return {
        ...state,
        deleteLiveStream: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        }
      };
    case LIVE_VIDEOS_DELETE_SUCCESS:
      return {
        ...state,
        deleteLiveStream: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case LIVE_VIDEOS_DELETE_FAILURE:
      return {
        ...state,
        deleteLiveStream: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
};

export default LiveVideoReducer;
