import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START,
  FETCH_LIVE_VIDEOS_HISTORY_START,
  FETCH_MORE_LIVE_VIDEOS_HISTORY_START,
  FETCH_LIVE_VIDEO_ORDERS_START,
  FETCH_MORE_LIVE_VIDEO_ORDERS_START,
  LIVE_VIDEO_CALL_BROADCAST_START,
  LIVE_VIDEOS_END_START,
  LIVE_VIDEOS_START_CALL_START,
  FETCH_SINGLE_LIVE_VIDEOS_START,
  LIVE_VIDEOS_VIEWER_UPDATE_START,
  FETCH_LIVE_VIDEO_MESSAGE_START,
  FETCH_PAST_LIVE_VIDEOS_START,
  FETCH_MORE_PAST_LIVE_VIDEOS_START,
  FETCH_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_MORE_SCHEDULED_LIVE_VIDEOS_START,
  FETCH_LIVE_SHOWS_HISTORY_START,
  FETCH_MORE_LIVE_SHOWS_HISTORY_START,
  LIVE_VIDEOS_DELETE_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
  fetchLiveVideosProductsSuccess,
  fetchLiveVideosProductsFailure,
  fetchLiveVideosHistorySuccess,
  fetchLiveVideosHistoryFailure,
  fetchLiveVideoOrdersSuccess,
  fetchLiveVideoOrdersFailure,
  videoCallBroadcastSuccess,
  videoCallBroadcastFailure,
  liveVideoStartCallSuccess,
  liveVideoStartCallFailure,
  liveVideoEndSuccess,
  liveVideoEndFailure,
  fetchSingleLiveVideoSuccess,
  fetchSingleLiveVideoFailure,
  liveViewerUpdateSuccess,
  liveViewerUpdateFailure,
  fetchLiveVideoMessageFailure,
  fetchLiveVideoMessageSuccess,
  fetchPastLiveVideosSuccess,
  fetchPastLiveVideosFailure,
  fetchScheduledLiveVideosSuccess,
  fetchScheduledLiveVideosFailure,
  fetchLiveShowsHistorySuccess,
  fetchLiveShowsHistoryFailure,
  liveVideoDeleteSuccess,
  liveVideoDeleteFailure,
  fetchScheduledLiveVideosStart,
} from "../actions/LiveVideoAction";
import config from "react-global-configuration";
import io from "socket.io-client";

function* liveVideoSaveAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_create",
      action.data
    );
    if (response.data.success) {
      yield put(videoCallBroadcastSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message)

      if (action.data.live_schedule_type === 1) {
        window.location.assign(
          window.location.origin +
          "/live-stream/" +
          response.data.data.live_video_unique_id
        );
      } 
      else {
        window.location.assign(window.location.origin + "/scheduled-live-videos");
      }
    } else {
      yield put(videoCallBroadcastFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(videoCallBroadcastFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}

function* liveEndAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveEnd.inputData
    );
    const response = yield api.postMethod(
      "live_videos_broadcast_stop",
      inputData
    );
    if (response.data.success) {
      yield put(liveVideoEndSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message)
      window.location.assign(window.location.origin + "/live-videos-history");
    } else {
      yield put(liveVideoEndFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error)
    }
  } catch (error) {
    yield put(liveVideoEndFailure(error));
    yield call(getErrorNotificationMessage, error)
  }
}

function* liveStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "live_videos_broadcast_start",
      action.data
    );
    if (response.data.success) {
      yield put(liveVideoStartCallSuccess(response.data.data));
      window.location.assign(
        window.location.origin +
        "/live-stream/" +
        response.data.data.live_video_unique_id
      );

    } else {
      yield put(liveVideoStartCallFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(liveVideoStartCallFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchSingleLiveVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.singleLiveVideo.inputData
    );
    const response = yield api.postMethod("live_videos_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleLiveVideoSuccess(response.data.data));
    } else {
      yield put(fetchSingleLiveVideoFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
      setInterval(() => {
        window.location.assign("/");
      }, 500);
    }
  } catch (error) {
    yield put(fetchSingleLiveVideoFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}

function* liveViewerUpdateAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveViewerUpdate.inputData
    );
    const response = yield api.postMethod(
      "live_videos_viewer_update",
      inputData
    );
    if (response.data.success) {
      yield put(liveViewerUpdateSuccess(response.data.data));
      // const notificationMessage = getSuccessNotificationMessage(
      //   response.data.message
      // );
      // yield put(createNotification(notificationMessage));
      let chatSocket;
      let chatSocketUrl = config.get("configData.chat_socket_url");
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + response.data.data.virtual_id + `'`,
      });

      chatSocket.emit("livevideo viewerupdate", response.data.data.virtual_id);
    } else {
      yield put(liveViewerUpdateFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);

    }
  } catch (error) {
    yield put(liveViewerUpdateFailure(error));
    yield call(getErrorNotificationMessage, error);

  }
}

function* liveVideosProductAPI(action) {
  try {
    const response = yield api.postMethod("live_video_products_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosProductsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchLiveVideosProductsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideosProductsFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* liveVideosHistoryAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosHistorySuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideosHistoryFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* pastLiveVideosAPI(action) {
  try {
    const response = yield api.postMethod("past_live_videos_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchPastLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchPastLiveVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchPastLiveVideosFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchLiveVideoOrdersAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_orders_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideoOrdersSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoOrdersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideoOrdersFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* liveVideoChatAPI() {
  try {
    const inputData = yield select(
      (state) => state.liveVideo.liveVideoChat.inputData
    );
    const response = yield api.postMethod(
      "live_video_chat_messages",
      inputData
    );
    if (response.data.success) {
      yield put(fetchLiveVideoMessageSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideoMessageFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveVideoMessageFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* scheduledLiveVideosAPI(action) {
  try {
    const response = yield api.postMethod("live_videos_scheduled_owner", action.data);
    if (response.data.success) {
      yield put(fetchScheduledLiveVideosSuccess(response.data.data));
    } else {
      yield put(fetchScheduledLiveVideosFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchScheduledLiveVideosFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}


function* liveShowsHistoryAPI(action) {
  try {
    const response = yield api.postMethod("live_shows_owner_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveShowsHistorySuccess(response.data.data));
    } else {
      yield put(fetchLiveShowsHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchLiveShowsHistoryFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* liveVideoDeleteAPI(action) {
  try {
      const response = yield api.postMethod("live_videos_delete", action.data);
      if (response.data.success) {
          yield put(liveVideoDeleteSuccess(response.data));
          yield call(getSuccessNotificationMessage, response.data.message);
          yield put(fetchScheduledLiveVideosStart());
      } else {
          yield put(liveVideoDeleteFailure(response.data.error));
          yield call(getErrorNotificationMessage, response.data.error);
      }
  } catch (error) {
      yield put(liveVideoDeleteFailure(error));
      yield call(getErrorNotificationMessage, error);
  }
}

export default function* LiveVideoSaga() {
  yield all([yield takeLatest(LIVE_VIDEO_CALL_BROADCAST_START, liveVideoSaveAPI),]);
  yield all([yield takeLatest(LIVE_VIDEOS_END_START, liveEndAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_START_CALL_START, liveStartAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_LIVE_VIDEOS_START, fetchSingleLiveVideoAPI)]);
  yield all([yield takeLatest(LIVE_VIDEOS_VIEWER_UPDATE_START, liveViewerUpdateAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_PRODUCTS_START, liveVideosProductAPI)]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_PRODUCTS_START, liveVideosProductAPI)]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEOS_HISTORY_START, liveVideosHistoryAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEO_ORDERS_START, fetchLiveVideoOrdersAPI),]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_VIDEO_ORDERS_START, fetchLiveVideoOrdersAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_VIDEO_MESSAGE_START, liveVideoChatAPI)]);
  yield all([yield takeLatest(FETCH_PAST_LIVE_VIDEOS_START, pastLiveVideosAPI),]);
  yield all([yield takeLatest(FETCH_MORE_PAST_LIVE_VIDEOS_START, pastLiveVideosAPI),]);
  yield all([yield takeLatest(FETCH_SCHEDULED_LIVE_VIDEOS_START, scheduledLiveVideosAPI),]);
  yield all([yield takeLatest(FETCH_MORE_SCHEDULED_LIVE_VIDEOS_START, scheduledLiveVideosAPI),]);
  yield all([yield takeLatest(FETCH_LIVE_SHOWS_HISTORY_START, liveShowsHistoryAPI),]);
  yield all([yield takeLatest(FETCH_MORE_LIVE_SHOWS_HISTORY_START, liveShowsHistoryAPI),]);
  yield all([yield takeLatest(LIVE_VIDEOS_DELETE_START, liveVideoDeleteAPI),]);
}
