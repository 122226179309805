import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";
import { fetchSingleUserStart } from "../../../store/actions/UserAction";
import NewLiveHistory from "../Home/NewLiveHistory";
import PastLiveHistory from "../Home/PastLiveHistory";
import NewUserProducts from "./NewUserProducts";
import OtherProfileLoader from "./OtherProfileLoader";
import { saveChatUserStart } from "../../../store/actions/ChatAction";
import {
  getErrorNotificationMessage
} from "../../../components/Helper/ToastNotification";
import { followUserStart, unFollowUserStart } from "../../../store/actions/FollowAction";

const NewProfileIndex = (props) => {
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [followStatus, setFollowStatus] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);

  useEffect(() => {
    props.dispatch(
      fetchSingleUserStart({
        user_unique_id: params.user_unique_id,
      })
    );
    window.scrollTo(0, 0);
  }, [params.user_unique_id]);


  useEffect(() => {
    if (!props.singleUser.loading && props.singleUser.data.user) {
      setFollowStatus(props.singleUser.data.is_following);
      setFollowerCount(props.singleUser.data.total_followers);
    }
  }, [props.singleUser]);

  useEffect(() => {
    setFollowStatus(1);
    if (!props.followUser.loading && props.followUser.data) {
      setFollowerCount(props.followUser.data.total_followers);
    }
  }, [props.followUser]);

  useEffect(() => {
    setFollowStatus(0);
    if (!props.unFollowUser.loading && props.unFollowUser.data) {
      setFollowerCount(props.unFollowUser.data.total_followers);
    }
  }, [props.unFollowUser]);


  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      props.dispatch(getErrorNotificationMessage(t("login_to_continue")));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  useEffect(() => {
    if (!skipRender && !props.saveChatUser.loading && Object.keys(props.saveChatUser.data).length > 0) {
      navigate("/chat");
    }
    setSkipRender(false);
  }, [props.saveChatUser]);

  return (
    <>
      <div className="new-outfyt-profile-sec">
        <div className="new-main-wrapper">
          <Container>
            {props.singleUser.loading ? (
              <OtherProfileLoader />
            ) : Object.keys(props.singleUser.data).length > 0 &&
              props.singleUser.data.user ? (
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="new-outfyt-profile-header-sec">
                    <div className="new-outfyt-profile-img-sec">
                      <Image
                        src={
                          props.singleUser.data.user.picture
                            ? props.singleUser.data.user.picture
                            : window.location.origin +
                            "/images/profile/profile.png"
                        }
                        className="new-outfyt-profile-img"
                      />
                    </div>
                    <div className="new-outfyt-profile-info-sec">
                      <div className="new-outfyt-profile-name-rating-sec">
                        <div className="new-outfyt-profile-name-rating-card">
                          <h3>{props.singleUser.data.user.name}</h3>
                          <div className="new-outfyt-profile-rating-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/images/outfyt/profile/verified.svg"
                              }
                              className="new-outfyt-new-outfyt-profile-rating-icon"
                            />
                            <span>
                              {props.singleUser.data.total_ratings} {t("ratings")}
                            </span>
                            <span>
                              {props.singleUser.data.total_reviews} {t("reviews")}
                            </span>
                          </div>
                        </div>
                        <Link
                          to="#"
                          className="new-outfyt-profile-user-name-link"
                        >
                          @{props.singleUser.data.user.username}
                        </Link>
                        <ul className="list-unstyled new-outfyt-profile-followers-count-list">
                          <li>
                            {followerCount}{" "}
                            <span>{t("followers")}</span>
                          </li>
                          <li>
                            {props.singleUser.data.total_followings}{" "}
                            <span>{t("following")}</span>
                          </li>
                        </ul>
                        {props.singleUser.data.user.about ? (
                          <div className="new-outfyt-profile-description-sec">
                            <p className="user-about">{props.singleUser.data.user.about}</p>
                          </div>
                        ) : null}
                        {props.singleUser.data.user.instagram_link ||
                          props.singleUser.data.user.facebook_link ||
                          props.singleUser.data.user.pinterest_link ?
                          <ul className="list-unstyled new-outfyt-profile-social-link-list">
                            {props.singleUser.data.user.instagram_link ?
                              <li>
                                <Link
                                  target="_blank"
                                  to={props.singleUser.data.user.instagram_link}>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/outfyt/auth/instagram.svg"
                                    }
                                    className="new-outfyt-profile-social-icon"
                                  />
                                </Link>
                              </li>
                              :
                              null
                            }
                            {props.singleUser.data.user.facebook_link ?
                              <li>
                                <Link
                                  target="_blank"
                                  to={props.singleUser.data.user.facebook_link}>
                                <Image
                                    src={
                                      window.location.origin +
                                      "/images/outfyt/auth/facebook.svg"
                                    }
                                    className="new-outfyt-profile-social-icon"
                                  />
                                </Link>
                              </li>
                              :
                              null
                            }
                            {props.singleUser.data.user.tiktok_link ?
                              <li>
                                <Link
                                  target="_blank"
                                  to={props.singleUser.data.user.tiktok_link}>
                                 <Image
                                    src={
                                      window.location.origin +
                                      "/images/outfyt/auth/tiktok.svg"
                                    }
                                    className="new-outfyt-profile-social-icon"
                                  />
                                </Link>
                              </li>
                              :
                              null
                            }
                          </ul>
                          :
                          null
                        }
                        <div className="new-outfyt-profile-follow-button-sec">
                          {followStatus ? (
                            <Button
                              className="outfyt-btn"
                              onClick={() => {
                                props.dispatch(
                                  unFollowUserStart({
                                    user_id: props.singleUser.data.user.user_id,
                                  })
                                );
                              }}
                            >
                              {t("unfollow")}
                            </Button>
                          ) : (
                            <Button
                              className="outfyt-btn"
                              onClick={() => {
                                props.dispatch(
                                  followUserStart({
                                    user_id: props.singleUser.data.user.user_id,
                                  })
                                );
                              }}
                            >
                              {t("follow")}
                            </Button>
                          )}
                          <Button
                            className="outfyt-btn-mesg"
                            onClick={(event) =>
                              handleChatUser(
                                event,
                                props.singleUser.data.user.user_id
                              )
                            }
                          >
                            {t("message")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="shop">
            <div className="new-outfyt-profile-product-sec">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={10}>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="shop">{t("shop")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="shows">{t("streams")}</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="past-shows">{t("past_streams")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="new-outfyt-profile-product-tab-content-sec">
              <Container>
                <Row>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="shop">
                        <NewUserProducts userId={params.user_unique_id} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="shows">
                        <div className="new-outfyt-live-streaming-shows-sec">
                          <div className="new-outfyt-live-streaming-shows-title-sec">
                            <h2>{t("live_streams")}</h2>
                          </div>
                          <NewLiveHistory />
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="reviews">Second tab content</Tab.Pane> */}
                      <Tab.Pane eventKey="past-shows">
                        <div className="new-outfyt-live-streaming-shows-sec">
                          <div className="new-outfyt-live-streaming-shows-title-sec profile-tab-title-sec">
                            <h2>{t("past_streams")}</h2>
                          </div>
                          <PastLiveHistory />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Container>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  singleUser: state.users.singleUser,
  saveChatUser: state.chat.saveChatUser,
  followUser: state.follow.followUser,
  unFollowUser: state.follow.unFollowUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(NewProfileIndex));
