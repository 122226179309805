import React from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";

const ChatUserList = (props) => {
  const { chatUsers } = props;
  return (
    <>
      {chatUsers.users.map((chatUser, index) => (
        <div
          key={index}
          className="user-chat-msg"
          onClick={e => {
            e.preventDefault();
            props.changeUser(chatUser, index)
          }}
        >
          <div
            className={
              props.activeChat === index
                ? "user-list-card active"
                : "user-list-card"
            }
          >
            <div className="user-list-img-sec">
              <Image
                src={chatUser.to_userpicture}
                alt={chatUser.to_displayname}
                className="user-list-img"
              />
            </div>
            <div className="user-list-info">
              <div className="user-list-info-header-sec">
                <h6>{chatUser.to_displayname}</h6>
                <p className="date">{chatUser.time_formatted}</p>
              </div>
              <p>{chatUser.message}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default withTranslation(ChatUserList);
