import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col } from "react-bootstrap";

const LiveStreamingLoader = () => {
  return (
    <>
        <Col md={6}>
      <div className="app-main">
        <div className="video-call-wrapper">
          <div className="video-participant">
        
            <Skeleton count={1} width={480} height={400} />
          
          </div>
        </div>
      </div>
      </Col>
    </>
  );
};

export default LiveStreamingLoader;
