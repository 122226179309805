import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchOrderListStart,
  fetchMoreOrderListStart,
  cancelOrderStart,
} from "../../store/actions/OrderAction";
import NoDataFound from "../Helper/NoDataFound";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableOrderLoader from "../Helper/TableOrderLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import UpdateTrackingModal from "../LiveStream/UpdateTrackingModal";


const OrderListIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();

  const [updateTracking, setUpdateTracking] = useState(false);
  const [order, setOrder] = useState({})

  useEffect(() => {
    props.dispatch(fetchOrderListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreOrderListStart({
      skip: props.orderList.data.orders.length,
      take: 12,
    }));
  }

  const cancelOrder = (event, order_id) => {
    event.preventDefault();
    props.dispatch(cancelOrderStart({
      order_id: order_id,
    }));
  }

  const cancelOrderConfirmation = (event, order_id) => {
    confirmAlert({
      message: t("are_you_sure_want_to_cancel_order"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => cancelOrder(event, order_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  }

  const handleUpdateTracking = (order) => {
    setOrder(order);
    setUpdateTracking(true);
  }

  const closeUpdateTrackingModal = () => {
    setUpdateTracking(false);
  };

  return (
    <>
      <div className="single-page-sec">
        <Container>
          <div className="order-list-sec">
            <div className="order-list-tab">
              <Row>
                <Col sm={12}>
                  <div className="nav-search-tab">
                    <div className="single-page-title">
                      <Link to="#" onClick={() => navigate(-1)}>
                        <Image src={window.location.origin + "/images/back.svg"} />
                      </Link>
                      <h3>{t("order_list")}</h3>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="order-list-table">
                    {props.orderList.loading
                      ?
                      <TableOrderLoader />
                      :
                      Object.keys(props.orderList.data).length > 0 &&
                        props.orderList.data.orders.length > 0
                        ?
                        <InfiniteScroll
                          dataLength={props.orderList.data.orders.length}
                          next={fetchMoreData}
                          hasMore={
                            props.orderList.data.orders.length <
                            props.orderList.data.total}
                          loader={<TableOrderLoader />}
                        >
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>{t("products")}</th>
                                <th>{t("order_id")}</th>
                                <th>{t("shipping_address")}</th>
                                <th>{t("Phone Number")}</th>
                                <th>{t("amount")}</th>
                                <th>{t("status")}</th>
                                <th>{t("action_order")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.orderList.data.orders.map((order, index) => (
                                <tr key={index}>
                                  <td>
                                    {order.order_product.map(
                                      (product, index) => (
                                        <>
                                          {product.user_product_details ? (
                                            <div
                                              className={`order-list-product ${order.total_products > 1
                                                ? "mb-3"
                                                : ""
                                                }`}
                                              key={index}
                                            >
                                              <div>
                                                {product.user_product_details.file_type == "image" ?
                                                  <Image
                                                    src={product.user_product_details.picture}
                                                    className="product-list-img"
                                                  />
                                                  :
                                                  <video
                                                    autoplay
                                                    controls
                                                    id="myVideo"
                                                    className="order-video"
                                                  >
                                                    <source src={product.user_product_details.picture}
                                                      type="video/mp4" />
                                                  </video>
                                                }
                                                <div className="mt-2">
                                                <span className="order-quantity">
                                                  {
                                                    product
                                                      .user_product_details
                                                      .name
                                                  }{" "}
                                                </span>
                                                <span className="order-quantity">
                                                  {t("quantity")} :{" "}
                                                  {product.quantity}
                                                </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                  </td>
                                  <td>{order.unique_id}</td>
                                  {order.delivery_address ?
                                    <>
                                      <td className="address">
                                        {order.delivery_address.landmark}, <br />
                                        {order.delivery_address.address},
                                        {order.delivery_address.state},{order.delivery_address.country}-{order.delivery_address.pincode} <br />
                                      </td>
                                      <td>{order.delivery_address.contact_number}</td>
                                    </>
                                    : <><td className="address">-</td><td></td></>
                                  }
                                  <td className="quantity">
                                    {order.total_formatted}
                                  </td>
                                  <td className="quantity">
                                    {order.after_ship_status_message ? order.after_ship_status_message : order.order_status}
                                  </td>
                                  <td className="text-right">
                                    <Link
                                      type="button"
                                      className="order-view-btn mr-3"
                                      to={`/order-view/${order.unique_id}`}
                                    >
                                      {t("view")}
                                    </Link>
                                    {order.tracking_cancel_btn_status ?
                                      <Button
                                        type="button"
                                        className="order-view-btn mt-3"
                                        onClick={(event) => cancelOrderConfirmation(event, order.id)}
                                      >
                                        {t("cancel")}
                                      </Button>
                                      :
                                      null
                                    }
                                    {!props.profile.loading &&
                                      props.profile.data.is_content_creator === 2 ?
                                      order.tracking_cancel_btn_status ?
                                        <Button
                                          type="button"
                                          className="order-view-btn mt-3"
                                          onClick={() => handleUpdateTracking(order)}
                                        >
                                          {t("update_tracking")}
                                        </Button>
                                        :
                                        ""
                                      : null
                                    }

                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                        :
                        <NoDataFound />
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div >
      {updateTracking &&
        <UpdateTrackingModal
          updateTracking={updateTracking}
          setUpdateTracking={setUpdateTracking}
          closeUpdateTrackingModal={closeUpdateTrackingModal}
          order={order}
        />
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  orderList: state.order.orderList,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderListIndex));

