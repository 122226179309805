import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  createOrderByStripeStart,
  createOrderByWalletStart,
} from "../../store/actions/OrderAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { translate, t, withTranslation } from "react-multi-lang";

const LivePaymentModal = (props) => {
  const [amount, setAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("WALLET");

  useEffect(() => {
    if (props.paymentModal === true) {
      props.dispatch(fetchWalletDetailsStart());
    }
  }, [props.paymentModal]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (paymentType === "CARD")
      props.dispatch(
        createOrderByStripeStart({
          user_product_id: props.product.user_product_id,
          live_video_id: props.liveVideo.live_video_id,
          delivery_address_id: props.delivery_address_id,
        })
      );
    if (paymentType === "WALLET")
      props.dispatch(
        createOrderByWalletStart({
          user_product_id: props.product.user_product_id,
          live_video_id: props.liveVideo.live_video_id,
          delivery_address_id: props.delivery_address_id,
        })
      );
    props.closePaymentModal();
  };

  console.log(props.wallet.data.user_wallet);
  console.log(props.product.price);
  return (
    <>
      <Modal
        className="modal-dialog-center  withdraw-modal"
        size="md"
        centered
        show={props.paymentModal}
        onHide={props.closePaymentModal}
      >
        {props.paymentModal === true ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{t("buy_now")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="subscription-tip-ppv-tab">
            {props.wallet.loading ?
              ""
              :
              Object.keys(props.wallet.data).length > 0 &&
                props.wallet.data.user_wallet ?
                < Row >
                  <Col md="12">
                    <div className="mb-3">
                      <h4 className="text-muted">
                        {t("wallet_balance")}:{" "}
                        <span className="text-muted">
                          {props.wallet.data.user_wallet
                            ? props.wallet.data.user_wallet.remaining
                            : null}
                        </span>
                      </h4>
                    </div>
                  </Col>
                </Row>
                :
                null
            }
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={paymentType}
              >
                <Row>
                  <Col sm={12}>
                    <div className="card-stripe-box">
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder={t("pay_amount")}
                            value={props.product.price_formatted}
                            disabled
                          />
                        </Form.Group>
                        <Tab.Content>
                          {configuration.get("configData.is_stripe_enabled") ==
                            1 &&
                          configuration.get(
                            "configData.stripe_publishable_key"
                          ) !== "" &&
                          configuration.get("configData.stripe_secret_key") !==
                            "" ? (
                            <Tab.Pane eventKey="CARD">
                              <div className="card-stripe-item">
                                <Link to="/wallet">
                                  <div className="add-account-item">
                                    <Image
                                      className="add-account-icon"
                                      src={
                                        window.location.origin +
                                        "/images/icons/new/add-card.svg"
                                      }
                                    />
                                    <h5 className="text-muted">
                                      {t("add_money")}
                                    </h5>
                                  </div>
                                </Link>
                              </div>
                            </Tab.Pane>
                          ) : null}
                          {configuration.get(
                            "configData.is_wallet_payment_enabled"
                          ) == 1 ? (
                            <Tab.Pane eventKey="WALLET">
                              {props.wallet.loading ? (
                                ""
                              ) : (
                                <div className="card-stripe-box">
                                  {Number(props.product.price) >
                                  Number(props.wallet.data.user_wallet.remaining) ? (
                                    <div className="">
                                      <p className="conv-desc desc">
                                        {t(
                                          "low_wallet_balance_tips_payment_para"
                                        )}
                                      </p>
                                      <div className="d-flex">
                                        <Link
                                          to="/wallet"
                                          className="withdraw-money-btn"
                                        >
                                          {t("add_wallet_amount")}
                                        </Link>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </Tab.Pane>
                          ) : null}
                        </Tab.Content>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-danger cancel-btn"
                data-dismiss="modal"
                onClick={props.closePaymentModal}
              >
                {t("cancel")}
              </Button>
              <Button
                type="button"
                className="btn btn-success pay-option"
                data-dismiss="modal"
                onClick={handleSubmit}
                disabled={props.livePayStripe.buttonDisable}
              >
                {props.livePayStripe.loadingButtonContent !== null
                  ? props.livePayStripe.loadingButtonContent
                  : t("pay_now")}
              </Button>
            </Modal.Footer>
          </>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  livePayStripe: state.order.createOrderStripe,
  wallet: state.wallet.walletData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LivePaymentModal));
