import React, { useEffect, useRef, useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, Media, Dropdown, InputGroup, Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";
import SendChat from "./SendChat";
import ReceivedChat from "./ReceivedChat";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
    changeChatAudio,
    fetchChatMessagesStart,
    fetchMoreChatMessagesStart,
    updateChatMessagesSuccess,
    chatAssetFilesUploadStart
} from "../../../store/actions/ChatAction";
import InfiniteScroll from "react-infinite-scroll-component";
import configuration from "react-global-configuration";
import io from "socket.io-client";
import dayjs from 'dayjs';
import NewChatUploadModal from "./NewChatUploadModal";
import useWindowDimensions from "../../Helper/WindowHelper";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import FancyBox from "../../Helper/FancyBox";

let chatSocket;

const NewChatRoom = (props) => {
    const t = useTranslation();

    const { height, width } = useWindowDimensions();

    const userId = localStorage.getItem("userId");

    const chatSocketUrl = configuration.get("configData.chat_socket_url");
    const [skipRender, setSkipRender] = useState(true);
    const [requestVideoCall, setRequestVideoCall] = useState(false);
    const [requestAudioCall, setRequestAudioCall] = useState(false);
    const [newChatUpload, setNewChatUpload] = useState(false);
    const [message, setMessage] = useState("");
    const [showEmojis, setShowEmojis] = useState(false);
    const [cursorPointer, setCursorPointer] = useState(0);
    const [newMsg, setNewMsg] = useState(false);
    const [fileType, setFileType] = useState("text");

    const messageField = useRef();
    const latest = useRef();

    let chatDate = "";
    let index = 0;

    useEffect(() => {
        if (newMsg) {
            const rect = latest.current.getBoundingClientRect();
            const container = document.getElementById("scrollableDiv").getBoundingClientRect();
            if (rect.bottom > container.bottom + 30) {
                // showNewMsg(true);
                // const notificationMessage = getSuccessNotificationMessage("New Message Received");
                // props.dispatch(createNotification(notificationMessage));
            }
        }
        setNewMsg(false);
    }, [newMsg]);

    useEffect(() => {
        props.dispatch(fetchChatMessagesStart({
            from_user_id: userId,
            to_user_id: props.selectedUser.user_id,
        }));
        if (chatSocket) {
            chatSocket.disconnect();
        }
        chatSocketConnect(props.selectedUser.user_id);
        return () => {
            chatSocket.disconnect();
        }
    }, [props.selectedUser.user_id]);

    useEffect(() => {
        if (!skipRender) {
            messageField.current.selectionEnd = cursorPointer;
        }
    }, [cursorPointer]);

    const fetchMoreMessages = () => {
        props.dispatch(fetchMoreChatMessagesStart({
            skip: props.chatMessages.data.messages.length,
            take: 12,
            from_user_id: userId,
            to_user_id: props.selectedUser.user_id,
        }));
    }

    useEffect(() => {
        if (!skipRender && !props.chatMessages.loading) {
            if (!Object.keys(props.chatMessages.data).length > 0) {
                props.setShowContent(false);
            }
        }
        setSkipRender(false);
    }, [props.chatMessages]);

    useEffect(() => {
        if (!skipRender && !props.chatFilesUpload.loading && Object.keys(props.chatFilesUpload.data).length > 0) {
            handleMessageSubmit({
                chatAssets: props.chatFilesUpload.data.chat_asset,
            });
        }
        setSkipRender(false);

    }, [props.chatFilesUpload]);


    const closeNewChatUploadModal = () => {
        setNewChatUpload(false);
    };

    const chatSocketConnect = (to_user_id) => {
        // check the socket url is configured
        console.log("chatSocket", chatSocketUrl);
        console.log("Input ID", to_user_id);
        if (chatSocketUrl) {
            chatSocket = io(chatSocketUrl, {
                query: `commonid:'user_id_${userId}_to_user_id_${props.selectedUser.user_id}',myid:${userId}`,
            });
            console.log("chatSocket", chatSocket);
            chatSocket.emit("update sender", {
                commonid: `user_id_${userId}_to_user_id_${props.selectedUser.user_id}`,
                myid: userId,
            });
            chatSocket.on("message", (newData) => {
                console.log(newData);
                setNewMsg(true);
                props.dispatch(updateChatMessagesSuccess(newData));
            });
        }
    };


    // Message Send
    const handleMessageSubmit = ({ msgAmount = 0, chatAssets = [] }) => {

        if ((message && message.trim()) || chatAssets.length > 0) {
            console.log('inside');
            console.log(message);
            console.log(chatAssets);
            const now = new Date();
            const date = `${("0" + now.getDate()).slice(-2)} ${now.toLocaleString('default', { month: 'short' })} ${now.getFullYear()}`;
            const time = dayjs(now).format("hh:mm a");

            const chatData = {
                user_picture: localStorage.getItem("user_picture"),
                from_username: localStorage.getItem("username"),
                from_displayname: localStorage.getItem("name"),
                from_userpicture: localStorage.getItem("user_picture"),
                from_user_id: userId,
                to_user_id: props.selectedUser.user_id,
                message: message,
                amount: msgAmount,
                is_user_needs_pay: msgAmount > 0 ? 1 : 0,
                file_type: fileType,
                loggedin_user_id: userId,
                chat_asset_id: chatAssets.map(asset => asset.chat_asset_id).toString(),
                date_formatted: date,
                created: time,
                amount_formatted: msgAmount + " " + configuration.get("configData.currency"),
                chat_assets: chatAssets,
            }
            chatSocket.emit("message", chatData);
            setMessage("");
            props.dispatch(updateChatMessagesSuccess({ ...chatData, chat_assets: chatAssets }));
            setNewChatUpload(false);
            setShowEmojis(false);
            messageField.current.focus();
            latest.current.scrollIntoView()
        }
    }

    const handleToggleEmojis = () => {
        messageField.current.focus();
        setShowEmojis(!showEmojis);
    }

    const onEmojiPick = (data) => {
        const ref = messageField.current;
        ref.focus();
        const start = message.substring(0, ref.selectionStart);
        const end = message.substring(ref.selectionStart);
        const text = start + data.native + end;
        setMessage(text);
        setCursorPointer(start.length + data.native.length);
    }

    if (props.chatMessages.data.messages && props.chatMessages.data.messages.length > 0) {
        chatDate = props.chatMessages.data.messages[0].date_formatted;
        index = props.chatMessages.data.messages.length;

    }

    const updateChatDate = newDate => {
        chatDate = newDate;
        index--;
    }

    const handleFileSelect = e => {
        let files = {};
        if (e.target.files.length > 0) {
            let file_type = '';
            [...e.target.files].forEach((file, key) => {
                let name = 'file[' + key + ']';
                files = { ...files, [name]: file };
                file_type = file.type.split('/')[0];
            });
            setFileType(file_type);
            setSkipRender(true);
            props.dispatch(chatAssetFilesUploadStart({
                from_user_id: props.profile.data.user_id,
                to_user_id: props.selectedUser.user_id,
                file_type: file_type,
                ...files,
            }));
            e.target.value = null;
        }
    }

    return (
        <>

            <div className="chat-ui-main-wrapper-header" onClick={e => e.preventDefault()}>
                <Link
                    to={`/` + props.selectedUser.user_unique_id}
                    className="user-click">
                    <div className="chat-ui-main-wrapper-user">
                        <div className="user-message-img-sec">
                            <Image
                                src={props.selectedUser.picture}
                                className="user-message-header-img"
                            />
                        </div>
                        <div className="user-message-header-info">
                            <h5>{props.selectedUser.name}</h5>
                            {props.chatMessages.data.user && props.chatMessages.data.user.is_online_status == 1 ?
                                <p>{props.chatMessages.data.user.is_user_online == 1 ?
                                     t("online"): 
                                     t("offline")}</p>
                                : ""}
                        </div>
                    </div>
                </Link>
                <div className="chat-ui-main-wrapper-icon-sec">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Link
                                className="dropdown-item"
                                to={`/${props.selectedUser.user_unique_id}`}
                            >
                                {t("view_profile")}
                            </Link>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>



            <>
                {props.chatMessages.data.messages ?
                    <>
                        <div
                            className="chat-ui-main-wrapper-body"
                            id="scrollableDiv"
                            style={{
                                minHeight: 'calc(100vh - 220px)',
                                maxHeight: 'calc(100vh - 215px)',
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column-reverse",
                                marginTop: '0em',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={props.chatMessages.data.messages.length}
                                next={fetchMoreMessages}
                                hasMore={props.chatMessages.data.messages.length < props.chatMessages.data.total}
                                loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                                inverse={true}
                                style={{ padding: '2em', display: "flex", flexDirection: "column-reverse", overflow: "inherit" }}
                                scrollableTarget="scrollableDiv"
                            >
                                <div className="message-content-sec" ref={latest} >
                                    <FancyBox delegate={"[data-fancybox-chat]"} options={{ groupAll: true }}>
                                        {props.chatMessages.data.messages.map((message, i) =>
                                            <>
                                                {/* {chatDate != message.date_formatted ?
                                                    <div className="chat-day-container" style={{ zIndex: index }}>
                                                        <div className="chat-day">
                                                            {chatDate}
                                                            {updateChatDate(message.date_formatted)}
                                                        </div>
                                                    </div> : null
                                                } */}
                                                {message.from_user_id == userId ?
                                                    <SendChat message={message} key={i} />
                                                    : <ReceivedChat message={message} key={i} />
                                                }
                                            </>
                                        )}
                                        {/* {chatDate ?
                                            <div className="chat-day-container" style={{ zIndex: index }}>
                                                <div className="chat-day">
                                                    {chatDate}
                                                </div>
                                            </div> : null
                                        } */}
                                    </FancyBox>
                                </div>
                            </InfiniteScroll>
                        </div>
                        <div className="chat-ui-main-wrapper-footer">
                            <Form className="new-chat-room-form" onSubmit={e => {
                                e.preventDefault();
                                handleMessageSubmit({});
                            }}>

                                <InputGroup className="mb-0 chat">
                                    <InputGroup.Text>
                                        <div className="upload-btn-wrapper">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M6.892 17.153l-.864-.864 2.121-2.122.864.864a3.517 3.517 0 004.966 0l6.005-6.004a3.516 3.516 0 000-4.966c-1.195-1.195-3.042-1.378-4.403-.489a8.517 8.517 0 00-3.1-.58h-.002c-.233 0-.465.01-.694.028l1.112-1.112A6.491 6.491 0 0117.502.003c1.667 0 3.237.577 4.684 2.025 2.454 2.545 2.43 6.61-.08 9.12l-6.005 6.004c-1.27 1.27-2.937 1.904-4.604 1.904s-3.335-.635-4.604-1.904zm-5.066 4.822C3.355 23.504 4.845 24.001 6.512 24a6.486 6.486 0 004.602-1.904l1.112-1.112c-.23.018-.461.028-.694.028h-.002a8.496 8.496 0 01-3.1-.58c-1.36.889-3.208.705-4.403-.49a3.516 3.516 0 010-4.966l6.005-6.004a3.516 3.516 0 014.966 0l.864.864 2.121-2.122-.864-.864a6.519 6.519 0 00-9.208 0l-6.005 6.005c-2.51 2.51-2.534 6.575-.08 9.12z"></path>
                                            </svg>
                                            <input
                                                type="file"
                                                title="Choose file"
                                                name="myfile"
                                                onChange={handleFileSelect}
                                            />
                                        </div>
                                    </InputGroup.Text>
                                    <Form.Control
                                        ref={messageField}
                                        placeholder={t("type_a_message")}
                                        aria-label="Type a message"
                                        aria-describedby="basic-addon2"
                                        value={!newChatUpload ? message : ""}
                                        onChange={e => setMessage(e.target.value)}
                                        // onKeyPress={e => {
                                        //   if (e.key === "Enter")
                                        //     handleMessageSubmit({})
                                        // }}
                                        autoFocus={true}
                                        on
                                    />

                                    <InputGroup.Text onClick={() => handleMessageSubmit({})}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="#151515"
                                                d="M23.445 11.117L2.387.574a.956.956 0 00-1.034.115.959.959 0 00-.316.958l2.537 9.365H14.4v1.917H3.574L.999 22.264a.96.96 0 00.957 1.208c.15 0 .297-.037.43-.105l21.06-10.544a.957.957 0 000-1.706z"
                                            ></path>
                                        </svg>
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-0">


                                </InputGroup>
                            </Form>
                        </div>
                    </>
                    : null
                }
            </>
            {
                newChatUpload ?
                    <NewChatUploadModal
                        newChatUpload={newChatUpload}
                        selectedUser={props.selectedUser}
                        message={message}
                        setMessage={setMessage}
                        handleMessageSubmit={handleMessageSubmit}
                        closeNewChatUploadModal={closeNewChatUploadModal}
                        setNewChatUpload={setNewChatUpload}
                    />
                    : null
            }

        </>
    );
};

const mapStateToPros = (state) => ({
    chatMessages: state.chat.chatMessages,
    profile: state.users.profile,
    chatFilesUpload: state.chat.chatFilesUpload,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(withTranslation(NewChatRoom));