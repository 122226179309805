import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
    USER_PRODUCTS_SAVE_START,
    FETCH_PRODUCT_CATEGORIES_START,
    FETCH_PRODUCT_SUB_CATEGORIES_START,
    FETCH_USER_SINGLE_PRODUCT_START,
    FETCH_USER_PRODUCTS_FOR_OWNER_START,
    DELETE_USER_PRODUCT_START,
    FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START,
    FETCH_SINGLE_PRODUCT_ORDERS_START,
    USER_PRODUCT_VIEW_FOR_OTHERS_START,
    FETCH_USER_PRODUCTS_START,
    USER_PRODUCTS_SEARCH_START,
    USER_PRODUCT_PICTURE_SAVE_START,
    USER_PRODUCT_VARIANTS_SAVE_START,
    FETCH_CART_LIST_START,
    SAVE_CART_DETAILS_START,
    REMOVE_CART_DETAILS_START,
    FETCH_ECOMM_HOME_START,
    FETCH_USER_PRODUCT_VARIANTS_START,
    CATEGORY_USER_PRODUCTS_START,
    FETCH_MORE_SINGLE_PRODUCT_ORDERS_START,
    USER_PRODUCTS_MORE_SEARCH_START
} from "../actions/ActionConstant";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage
} from "../../components/Helper/ToastNotification";
import {
    userProductsSaveSuccess,
    userProductsSaveFailure,
    fetchProductCategoriesSuccess,
    fetchProductCategoriesFailure,
    fetchProductSubCategoriesSuccess,
    fetchProductSubCategoriesFailure,
    fetchUserSingleProductSuccess,
    fetchUserSingleProductFailure,
    fetchUserProductsForOwnerSuccess,
    fetchUserProductsForOwnerFailure,
    fetchUserProductsForOwnerStart,
    deleteUserProductSuccess,
    deleteUserProductFailure,
    fetchSingleProductOrdersSuccess,
    fetchSingleProductOrdersFailure,
    userProductViewForOthersSuccess,
    userProductViewForOthersFailure,
    fetchUserProductsSuccess,
    fetchUserProductsFailure,
    userProductsSearchSuccess,
    userProductsSearchFailure,
    userProductPictureSaveSuccess,
    userProductPictureSaveFailure,
    userProductVariantsSaveSuccess,
    userProductVariantsSaveFailure,
    fetchCartListSuccess,
    fetchCartListFailure,
    saveCartDetailsSuccess,
    saveCartDetailsFailure,
    removeCartDetailsSuccess,
    removeCartDetailsFailure,
    fetchEcommHomeStart,
    fetchEcommHomeSuccess,
    fetchEcommHomeFailure,
    fetchCartListStart,
    userProductsSearchStart,
    fetchUserProductVariantsSuccess,
    fetchUserProductVariantsFailure,
    categoryUserProductsSuccess,
    categoryUserProductsFailure,
} from "../actions/ProductsAction";

import { fetchUserDetailsSuccess } from "../actions/UserAction";

function* userProductsSaveAPI(action) {
    try {
        const response = yield api.postMethod("user_products_save", action.data);

        if (response.data.success) {
            yield put(userProductsSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message)
        } else {
            yield put(userProductsSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductsSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* userProductPictureSaveAPI(action) {
    try {
        const response = yield api.postMethod("user_product_pictures_save", action.data);

        if (response.data.success) {
            yield put(userProductPictureSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message)
        } else {
            yield put(userProductPictureSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductPictureSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchProductCategoriesAPI() {
    try {
        const response = yield api.postMethod("product_categories");

        if (response.data.success) {
            yield put(fetchProductCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchProductCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchProductCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchProductSubCategoriesAPI() {
    try {
        const response = yield api.postMethod("product_sub_categories");

        if (response.data.success) {
            yield put(fetchProductSubCategoriesSuccess(response.data.data));
        } else {
            yield put(fetchProductSubCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchProductSubCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchUserSingleProductAPI(action) {
    try {
        const response = yield api.postMethod("user_products_view", action.data);

        if (response.data.success) {
            yield put(fetchUserSingleProductSuccess(response.data.data));
        } else {
            yield put(fetchUserSingleProductFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserSingleProductFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchUserProductsAPI() {
    try {
        const response = yield api.postMethod("user_products");

        if (response.data.success) {
            yield put(fetchUserProductsSuccess(response.data.data));
        } else {
            yield put(fetchUserProductsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserProductsFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* fetchUserProductsListAPI(action) {
    try {
        const response = yield api.postMethod("user_products_for_owner", action.data);

        if (response.data.success) {
            yield put(fetchUserProductsForOwnerSuccess(response.data.data));
        } else {
            yield put(fetchUserProductsForOwnerFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserProductsForOwnerFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* deleteUserProductAPI(action) {
    try {
        const response = yield api.postMethod("user_products_delete", action.data);

        if (response.data.success) {
            yield put(deleteUserProductSuccess(response.data.data));
            yield put(fetchUserProductsForOwnerStart());
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(deleteUserProductFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(deleteUserProductFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchSingleProductOrdersAPI(action) {
    try {
        const response = yield api.postMethod("user_products_orders_list", action.data);

        if (response.data.success) {
            yield put(fetchSingleProductOrdersSuccess(response.data.data));
        } else {
            yield put(fetchSingleProductOrdersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchSingleProductOrdersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}
function* userProductViewForOthersAPI(action) {
    try {
        const response = yield api.postMethod("user_products_view_for_others", action.data);

        if (response.data.success) {
            yield put(userProductViewForOthersSuccess(response.data.data));
        } else {
            yield put(userProductViewForOthersFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductViewForOthersFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* userProductsSearchAPI(action) {
    try {
        const response = yield api.postMethod("other_model_product_list", action.data);

        if (response.data.success) {
            yield put(userProductsSearchSuccess(response.data.data));
        } else {
            yield put(userProductsSearchFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductsSearchFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchCartListAPI() {
    try {
        const response = yield api.postMethod("carts_list");

        if (response.data.success) {
            yield put(fetchCartListSuccess(response.data.data));
        } else {
            yield put(fetchCartListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchCartListFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* saveCartDetailsAPI(action) {
    try {
        const response = yield api.postMethod("carts_save", action.data);

        if (response.data.success) {
            yield put(saveCartDetailsSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            const profileData = yield select((state) => state.users.profile.data);
            yield put(fetchUserDetailsSuccess({
                ...profileData,
                cart_count: response.data.data.cart_count,
              }));
            if (action.data.type == "userProfile")
                yield put(userProductsSearchStart({ user_unique_id: action.data.otherUserUniquId }));
            else
                yield put(fetchEcommHomeStart());
            yield put(fetchCartListStart());
        } else {
            yield put(saveCartDetailsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(saveCartDetailsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* removeCartDetailsAPI(action) {
    try {
        const response = yield api.postMethod("carts_remove", action.data);
        if (response.data.success) {
            yield put(removeCartDetailsSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            const profileData = yield select((state) => state.users.profile.data);
            yield put(fetchUserDetailsSuccess({
                ...profileData,
                cart_count: response.data.data.cart_count,
              }));
        } else {
            yield put(removeCartDetailsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(removeCartDetailsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}


function* fetchEcommHomeAPI(action) {
    try {
        const response = yield api.postMethod("ecommerce_home", action.data);

        if (response.data.success) {
            yield put(fetchEcommHomeSuccess(response.data.data));
        } else {
            yield put(fetchEcommHomeFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchEcommHomeFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}


function* userProductVariantSaveAPI(action) {
    try {
        const response = yield api.postMethod("user_products_variants_save", action.data);
        if (response.data.success) {
            yield put(userProductVariantsSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(userProductVariantsSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(userProductVariantsSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchUserProductVariantsAPI(action) {
    try {
        const response = yield api.postMethod("user_products_variants", action.data);

        if (response.data.success) {
            yield put(fetchUserProductVariantsSuccess(response.data.data));
        } else {
            yield put(fetchUserProductVariantsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserProductVariantsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* categoryUserProductsAPI(action) {
    try {
        const response = yield api.postMethod("category_user_products", action.data);

        if (response.data.success) {
            yield put(categoryUserProductsSuccess(response.data.data));
        } else {
            yield put(categoryUserProductsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(categoryUserProductsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

export default function* ProductSaga() {
    yield all([
        yield takeLatest(FETCH_USER_PRODUCTS_START, fetchUserProductsAPI),
        yield takeLatest(USER_PRODUCTS_SAVE_START, userProductsSaveAPI),
        yield takeLatest(FETCH_PRODUCT_CATEGORIES_START, fetchProductCategoriesAPI),
        yield takeLatest(FETCH_PRODUCT_SUB_CATEGORIES_START, fetchProductSubCategoriesAPI),
        yield takeLatest(FETCH_USER_SINGLE_PRODUCT_START, fetchUserSingleProductAPI),
        yield takeLatest(FETCH_USER_PRODUCTS_FOR_OWNER_START, fetchUserProductsListAPI),
        yield takeLatest(FETCH_MORE_USER_PRODUCTS_FOR_OWNER_START, fetchUserProductsListAPI),
        yield takeLatest(DELETE_USER_PRODUCT_START, deleteUserProductAPI),
        yield takeLatest(FETCH_SINGLE_PRODUCT_ORDERS_START, fetchSingleProductOrdersAPI),
        yield takeLatest(FETCH_MORE_SINGLE_PRODUCT_ORDERS_START, fetchSingleProductOrdersAPI),
        yield takeLatest(USER_PRODUCT_VIEW_FOR_OTHERS_START, userProductViewForOthersAPI),
        yield takeLatest(USER_PRODUCT_PICTURE_SAVE_START, userProductPictureSaveAPI),
        yield takeLatest(USER_PRODUCT_VARIANTS_SAVE_START, userProductVariantSaveAPI),
        yield takeLatest(USER_PRODUCTS_SEARCH_START, userProductsSearchAPI),
        yield takeLatest(USER_PRODUCTS_MORE_SEARCH_START, userProductsSearchAPI),
        yield takeLatest(FETCH_CART_LIST_START, fetchCartListAPI),
        yield takeLatest(SAVE_CART_DETAILS_START, saveCartDetailsAPI),
        yield takeLatest(REMOVE_CART_DETAILS_START, removeCartDetailsAPI),
        yield takeLatest(FETCH_ECOMM_HOME_START, fetchEcommHomeAPI),
        yield takeLatest(FETCH_USER_PRODUCT_VARIANTS_START, fetchUserProductVariantsAPI),
        yield takeLatest(CATEGORY_USER_PRODUCTS_START, categoryUserProductsAPI),
    ]);
}
