import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { chatUser, fetchChatUsersStart, fetchMoreChatUsersStart, forceChatUsersSuccess } from "../../../store/actions/ChatAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const NewChatList = (props) => {
  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.dispatch(fetchChatUsersStart({
      search_key: search,
    }));
  }, [search]);

  useEffect(() => {
    if (!skipRender && !props.chatMessages.loading && Object.keys(props.chatMessages.data).length > 0) {
      if (props.chatMessages.data.messages.length > 0) {
        const latestMsg = props.chatMessages.data.messages[0];
        const updatedUsers = {
          ...props.chatUsers.data,
          users: props.chatUsers.data.users.map((user) =>
            user.from_user_id === props.chatUser.user_id || user.to_user_id === props.chatUser.user_id
              ? {
                ...user,
                message: latestMsg.message ? latestMsg.message : latestMsg.file_type.toUpperCase(),
                time_formatted: latestMsg.time_formatted,
              }
              : user
          )
        };
        props.dispatch(forceChatUsersSuccess(updatedUsers));
      }
    }
  }, [props.chatMessages]);

  useEffect(() => {
    if (!skipRender && !props.chatUsers.loading) {
      if (!Object.keys(props.chatUsers.data).length > 0) {
        props.setShowContent(false);
      }
    }
    setSkipRender(false);
  }, [props.chatUsers]);

  const fetchMoreUsers = () => {
    props.dispatch(fetchMoreChatUsersStart({
      skip: props.chatUsers.data.users.length,
      take: 12,
      search_key: search,
    }));
  }

  return (
    <>
      <div className="chat-ui-sidebar-search">
        <Form onSubmit={e => e.preventDefault()}>
          <InputGroup className="mb-0">
            <Form.Control
              placeholder={t("searches")}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <InputGroup.Text id="basic-addon2"><i className="fas fa-search"></i></InputGroup.Text>
          </InputGroup>
        </Form>
      </div>
      <div className="chat-ui-sidebar-collapse">
        <div className="chat-ui-collapse-body">
          {props.chatUsers.loading ?
            ''

            : props.chatUsers.data.users &&
              props.chatUsers.data.users.length > 0 ?
              <>
                <div style={{
                  maxHeight: 'calc(100vh - 190px)',
                  overflowY: 'auto',
                  // paddingRight: '1em',
                  // marginTop: '2em'
                }}>
                  <InfiniteScroll
                    dataLength={props.chatUsers.data.users.length}
                    next={fetchMoreUsers}
                    hasMore={props.chatUsers.data.users.length < props.chatUsers.data.total}
                    loader={<div className="new-chat-list-box">
                      {[...Array(6)].map((val, i) =>
                        <div className="new-chat-list-card" key={i} >
                          <div className="new-chat-list-user-msg-sec">
                            <div className="new-chat-list-user-img-sec">
                              <Skeleton circle={true} className="new-chat-list-user-img" />
                            </div>
                            <div className="new-chat-list-user-msg">
                              <Skeleton height={50} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    }
                    scrollableTarget="usersDiv"
                  >
                    {props.chatUsers.data.users.map((user, index) =>
                      <div
                        key={index}
                        className="user-chat-msg"
                        onClick={e => {
                          e.preventDefault();
                          props.dispatch(chatUser(user.to_user), index)
                        }}
                      >

                        <div
                          className={
                            props.activeChat === index
                              ? "user-list-card active"
                              : "user-list-card"
                          }
                        >
                          <div className="user-list-img-sec">
                            <Image
                              className="new-chat-list-user-img"
                              src={user.to_userpicture}
                            />
                          </div>
                          <div className="user-list-info">
                            <div className="user-list-info-header-sec">
                              <h6>{user.to_displayname}</h6>
                              <p className="date">{user.time_formatted}</p>
                            </div>
                            <p>{user.message}</p>
                          </div>
                        </div>

                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              </>
              : <>
                {search ?
                  <Image
                    style={{ width: "100%", marginTop: "1em" }}
                    alt="No user found"
                    src={window.location.origin + "/images/no-user-found.png"} />
                  : <div className="no-chats-container">
                    <Image
                      style={{ width: "100%" }}
                      src={window.location.origin + "/images/no-chats-yet.png"} />
                  </div>
                }
              </>
          }
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  chatUsers: state.chat.chatUsers,
  chatUser: state.chat.chatUser,
  chatMessages: state.chat.chatMessages,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewChatList));