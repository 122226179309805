import React, { useState, useEffect } from 'react'
import {
  Col,
  Row,
  Nav,
  Tab,
  InputGroup,
  Image,
  Form,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from 'react-redux';
import { fetchLiveVideosProductsStart, fetchMoreLiveVideosProductsStart } from '../../store/actions/LiveVideoAction';
import { useParams } from 'react-router-dom';
import NoDataFound from '../Helper/NoDataFound';
import Skeleton from 'react-loading-skeleton';

const ProductListCard = (props) => {

  const params = useParams();
  const t = useTranslation();
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.dispatch(fetchLiveVideosProductsStart({
      live_video_id: params.live_video_id,
      search_key: search,
    }));
  }, [search]);

  return (
    <Col sm={12}>
      <Form className="live-streaming-form">
        <InputGroup>
          <InputGroup.Text id="basic-addon1">
            <Image
              src={
                window.location.origin +
                "/images/search.svg"
              }
            />
          </InputGroup.Text>
          <Form.Control
            placeholder={t("username")}
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <InputGroup.Text
            id="basic-addon1"
            className="search-close"
            style={{
              border: "0",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
              cursor: "pointer",
            }}
          >
            {search != "" &&
              <Image
                onClick={() => setSearch("")}
                src={window.location.origin + "/images/closes.svg"}
              />
            }
          </InputGroup.Text>
        </InputGroup>
      </Form>
      < Tab.Content >
        <Tab.Pane eventKey="buy_now">
          <div className="live-streaming-tab-details">
            {props.liveVideosProducts.loading ?
              <div className="live-streaming-box">
                <Skeleton count={1} height={110} width={"100%"} />
              </div>
              : props.liveVideosProducts.data.live_video_products &&
                props.liveVideosProducts.data.live_video_products.length > 0 ?
                <div className="live-streaming-box">
                  {props.liveVideosProducts.data.live_video_products.map(
                    (livevideoproduct) =>
                      !livevideoproduct.is_outofstock ? (
                        <div className="live-streaming-card">
                          <div className="live-streaming-card-img">
                            <Image
                              src={livevideoproduct.picture}
                            />
                          </div>
                          <div className="live-streaming-card-details">
                            <h5>{livevideoproduct.name}</h5>
                            <p>{livevideoproduct.category_name}</p>
                            <h3>{livevideoproduct.price_formatted}</h3>
                          </div>
                        </div>
                      )
                        :
                        ""
                  )}
                </div>
                :
                <NoDataFound />
            }
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="sold">
          <div className="live-streaming-tab-details">
            {props.liveVideosProducts.loading ?
              <div className="live-streaming-box">
                <Skeleton count={1} height={110} width={"100%"} />
              </div>
              : props.liveVideosProducts.data.live_video_products &&
                props.liveVideosProducts.data.live_video_products.length > 0 ?
                <div className="live-streaming-box">
                  {props.liveVideosProducts.data.live_video_products.map(
                    (livevideoproduct) =>
                      !livevideoproduct.is_outofstock ? (
                        <div className="live-streaming-card">
                          <div className="live-streaming-card-img">
                            <Image
                              src={livevideoproduct.picture}
                            />
                          </div>
                          <div className="live-streaming-card-details">
                            <h5>{livevideoproduct.name}</h5>
                            <p>{livevideoproduct.category_name}</p>
                            <h3>{livevideoproduct.price_formatted}</h3>
                          </div>
                        </div>
                      )
                        :
                        ""
                  )}
                </div>
                :
                <NoDataFound />
            }
          </div>

        </Tab.Pane>
        <Tab.Pane eventKey="purchased">
          <div className="live-streaming-tab-details">
            {props.liveVideosProducts.loading ?
              <div className="live-streaming-box">
                <Skeleton count={1} height={110} width={"100%"} />
              </div>
              : props.liveVideosProducts.data.purchased_products &&
                props.liveVideosProducts.data.purchased_products.length > 0 ?
                <div className="live-streaming-box">
                  {props.liveVideosProducts.data.purchased_products.map(
                    (purchased_product) =>
                    (
                      <div className="live-streaming-card">
                        <div className="live-streaming-card-img">
                          <Image
                            src={purchased_product.picture}
                          />
                        </div>
                        <div className="live-streaming-card-details">
                          <h5>{purchased_product.name}</h5>
                          <p>{purchased_product.category_name}</p>
                          <h3>{purchased_product.price_formatted}</h3>
                        </div>
                      </div>
                    ))}
                </div>
                :
                <NoDataFound />
            }
          </div>
        </Tab.Pane>
      </Tab.Content>

    </Col >
  )
}

const mapStateToProps = (state) => ({
  liveVideosProducts: state.liveVideo.liveVideosProducts,
})
function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(ProductListCard));