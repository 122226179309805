import React from 'react'
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Media,
} from "react-bootstrap";

const StaticSidebar = () => {
  const t = useTranslation();
  return (
    <div className="sidebar-footer-list">
      <ul className="list-unstyled">
        {configuration
          .get("configData.footer_pages2")
          .map((static_page, index) => (
            <Media
              as="li"
              key={"sp-" + static_page.static_page_unique_id}
            >
              <Link
                to={`/page/${static_page.static_page_unique_id}`}
                key={static_page.static_page_unique_id}
                target='_blank'
              >
                <Image src={window.location.origin + "/images/about.svg"} />
                <span>{static_page.title}</span>
              </Link>
            </Media>
          ))}
        {configuration
          .get("configData.footer_pages1")
          .map((static_page, index) => (

            <Media
              as="li"
              key={"sp-" + static_page.static_page_unique_id}
            >
              <Link
                to={`/page/${static_page.static_page_unique_id}`}
                key={static_page.static_page_unique_id}
                target='_blank'
              >
                <Image src={window.location.origin + "/images/about.svg"} />
                <span>{static_page.title}</span>
              </Link>
            </Media>
          ))}
      </ul>
    </div>

  )
}

export default StaticSidebar;