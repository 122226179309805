import React from "react";
import { Container, Image, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-multi-lang";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";

const ExploreProducts = (props) => {

  const t = useTranslation();

  return (
    <>
      <div className="accessories-home-sec mt-4">
        <Container>
          {props.categoryProducts.loading ?
            <LiveVideoLoader />
            :
            <>
              {Object.keys(props.categoryProducts.data).length > 0 &&
                props.categoryProducts.data.category_products.length > 0 ?
                <>
                  <div className="new-home-title-sec">
                    <h2>{t("you_may_also_like")}</h2>
                  </div>
                  <div className="accessories-box">
                    {props.categoryProducts.data.category_products.map((product, index) => (
                      <Link to={`/single-product/${product.unique_id}`}>
                        <div className="accessories-card">
                          <div className="accessories-img-sec">
                            {product.file_type == "image" ?
                              <Image
                                src={product.picture}
                                className="accessories-img"
                              />
                              :
                              <video
                                autoplay
                                controls
                                id="myVideo"
                                className="user-product w-100"
                              >
                                <source src={product.picture}
                                  type="video/mp4" />
                              </video>
                            }
                          </div>
                          <div className="new-outfyt-you-may-like-slider-info-sec">
                            <h4>{product.name}</h4>
                            <h6>{product.category_name}</h6>
                            <p>{product.price_formatted}</p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </>
                :
                ''}
            </>
          }
        </Container>
      </div>
    </>
  );
};


export default withTranslation(ExploreProducts);

