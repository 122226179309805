import React from 'react'
import Skeleton from "react-loading-skeleton";

const HomeCategoryLoader = () => {
    return (
        <div className="category-card-box">
            {[...Array(4)].map((i) => (
                <div className="new-home-latest-live-streaming-img-sec">
                    <Skeleton count={1} height={210} width={240} />
                    <Skeleton count={1} width={100} />
                    <Skeleton count={1} width={150} />
                </div>
            ))}
        </div>
    )
}

export default HomeCategoryLoader;