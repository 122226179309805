import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Tab,
  Row,
  Col,
  InputGroup,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  verifyForgotPasswordStart,
  forgotPasswordStart,
} from "../../../store/actions/UserAction";

const NewVerifyForgotPassword = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    }
  }, []);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const submitVerfication = (event) => {
    event.preventDefault();
    if (otp.length === 6) {
      props.dispatch(
        verifyForgotPasswordStart({
          email: email,
          verification_code: otp,
        })
      );
    }
  };
  useEffect(() => {
    if (
      !skipRender &&
      !props.verifyForgotPassword.loading &&
      props.verifyForgotPassword.data &&
      Object.keys(props.verifyForgotPassword.data).length > 0
    ) {
      navigate("/outfyt-reset-password");
    }
    setSkipRender(false);
  }, [props.verifyForgotPassword]);

  const resendVerfication = () => {
    props.dispatch(
      forgotPasswordStart({
        email: email,
      })
    );
  };

  return (
    <>
      <div className="outfyt-auth-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <div className="outfyt-auth-box">
                <div className="outfyt-auth-right-sec">
                  <Button className="select-back-btn" onClick={() => navigate(-1)}>
                      <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.4999 4.99999H4.32992L7.20992 2.11999C7.3969 1.93301 7.50195 1.67942 7.50195 1.41499C7.50195 1.15056 7.3969 0.896967 7.20992 0.70999C7.02295 0.523012 6.76935 0.417969 6.50492 0.417969C6.2405 0.417969 5.9869 0.523012 5.79992 0.70999L1.20992 5.29999C1.11722 5.3925 1.04367 5.50239 0.993492 5.62337C0.94331 5.74434 0.91748 5.87402 0.91748 6.00499C0.91748 6.13596 0.94331 6.26564 0.993492 6.38661C1.04367 6.50759 1.11722 6.61748 1.20992 6.70999L5.79992 11.3C5.89251 11.3926 6.00242 11.466 6.12338 11.5161C6.24435 11.5662 6.37399 11.592 6.50492 11.592C6.63586 11.592 6.7655 11.5662 6.88647 11.5161C7.00743 11.466 7.11734 11.3926 7.20992 11.3C7.30251 11.2074 7.37595 11.0975 7.42605 10.9765C7.47616 10.8556 7.50195 10.7259 7.50195 10.595C7.50195 10.4641 7.47616 10.3344 7.42605 10.2134C7.37595 10.0925 7.30251 9.98257 7.20992 9.88999L4.32992 6.99999H17.4999C18.0499 6.99999 18.4999 6.54999 18.4999 5.99999C18.4999 5.44999 18.0499 4.99999 17.4999 4.99999Z" fill="#151515" />
                      </svg>
                      {t("back")}
                  </Button>
                  <Link to="/login">
                    <Image
                      src={
                        window.location.origin + "/images/outfyt/logo-black.png"
                      }
                      className="outfyt-auth-logo"
                    />
                  </Link>
                  <div className="outfyt-auth-body-sec">
                    <h4>{t("verify_forgot_password_para")}</h4>
                    <p>
                      {t("we_emailed_verification_to")} {email}
                    </p>
                    <div className="verification-item">
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span>-</span>}
                      />
                    </div>
                    <div className="verification-btn-sec">
                      <Button
                        className="outfyt-auth-btn"
                        onClick={submitVerfication}
                        disabled={
                          otp.length < 6 || props.verifyForgotPassword.buttonDisable
                        }
                      >
                        {props.verifyForgotPassword.loadingButtonContent
                          ? props.verifyForgotPassword.loadingButtonContent
                          : t("validate")}
                      </Button>
                      <Button
                        className="outfyt-auth-outline-btn"
                        onClick={resendVerfication}
                        disabled={props.forgotPassword.buttonDisable}
                      >
                        {props.forgotPassword.loadingButtonContent != null
                          ? props.forgotPassword.loadingButtonContent
                          : t("resend")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyForgotPassword: state.users.verifyForgotPassword,
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewVerifyForgotPassword));
