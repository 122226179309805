import React from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import {
    fetchPastLiveVideosStart,
    fetchMorePastLiveVideosStart
} from "../../../store/actions/LiveVideoAction";
import { useEffect } from "react";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../Helper/NoDataFound";
import LiveVideoCard from "../../LiveVideos/LiveVideoCard";

const PastShows = (props) => {

    const t = useTranslation();
    useEffect(() => {
        props.dispatch(fetchPastLiveVideosStart({
            skip: 0,
            take: 12,
        }))
    }, []);

    const fetchMoreData = () => {
        props.dispatch(
            fetchMorePastLiveVideosStart({
                skip: props.liveVideos.data.live_videos.length,
                take: 12,
            })
        );
    };


    return (
        <div className="category-card-sec mt-4">
            {props.liveVideos.loading ?
                <LiveVideoLoader />
                :
                Object.keys(props.liveVideos.data).length > 0 &&
                    props.liveVideos.data.live_videos.length > 0 ?
                    <>
                        <InfiniteScroll
                            dataLength={props.liveVideos.data.live_videos.length}
                            next={fetchMoreData}
                            hasMore={
                                props.liveVideos.data.live_videos.length <
                                props.liveVideos.data.total}
                            loader={<LiveVideoLoader />}
                        >
                            <div className="new-home-latest-live-streaming-box-past-shows">
                                {props.liveVideos.data.live_videos.map(
                                    (live_video, i) => (
                                        <LiveVideoCard live_video={live_video} redirection_status={0} />
                                    ))}
                            </div>
                        </InfiniteScroll>
                    </>
                    :
                    <NoDataFound />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    liveVideos: state.homepage.pastLiveVideos,
})

function mapDispatchToProps(dispatch) {
    return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(PastShows));
