import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Button,
  Form,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import {
  fetchProductCategoriesStart,
  userProductsSearchStart,
  userProductsMoreSearchStart,
} from "../../../store/actions/ProductsAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation, t } from "react-multi-lang";
import CommonCenterLoader from "../../Helper/CommonCenterLoader";
import NoDataFound from "../../Helper/NoDataFound";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import InfiniteScroll from "react-infinite-scroll-component";
import LiveVideoLoader from "../../Helper/LiveVideosLoader";

const NewUserProducts = (props) => {

  const params = useParams();
  const [filterData, setFilterData] = useState({
    user_unique_id: props.userId, search_key: params.search_key ? params.search_key : "",
    selectedOption: "",
    selectedCategory: "",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [clearItems, setClearItems] = useState(false);

  const options = [
    { value: '', label: 'Sort by' },
    { value: 'a-z', label: 'A to Z' },
    { value: 'z-a', label: 'Z to A' },
    { value: 'price_lh', label: 'Low to High' },
    { value: 'price_hl', label: 'High to Low' },
  ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#000",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#000",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#000",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(57, 57, 57, .07)!important",
      border: "0!important",
      boxShadow: "none",
      height: "50px",
      width: "200px",
      borderRadius: "8px",
      color: "#fff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff !important",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#fff', // Set the color to white
    }),

    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#ccc" : "#000",
        color: "#fff",
      };
    },
    ///.....
  };
  const customStyle = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#fff !important', // Set the color to white
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
        color: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#fff",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#000",
      border: "0!important",
      boxShadow: "none",
      height: "50px",
      width: "200px",
      borderRadius: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "1em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#ccc" : "#000",
        color: "#fff",
      };
    },
    ///.....
  };

  useEffect(() => {
    props.dispatch(fetchProductCategoriesStart());
  }, []);

  useEffect(() => {
    props.dispatch(userProductsSearchStart({
      ...filterData,
      skip: 0,
      take: 12,
    }));
  }, [filterData]);

  const fetchMoreData = () => {
    props.dispatch(
      userProductsMoreSearchStart({
        user_unique_id: params.user_unique_id,
        skip: props.productSearch.data.user_products.length,
        take: 12,
      })
    );
  };


  const handleCategoryInputChange = (selectedOptions) => {
    setFilterData({
      ...filterData,
      product_category_id: selectedOptions.value
    })
  };

  const handleSortByInputChange = (selectedOptions) => {
    setFilterData({
      ...filterData,
      sort_by: selectedOptions.value
    })
  };


  useEffect(() => {
    if (!props.categories.loding && Object.keys(props.categories.data).length > 0 &&
      props.categories.data.product_categories.length > 0) {
      let categories = props.categories.data.product_categories.map((category) => (
        {
          "label": category.name,
          "value": category.category_id,
        }
      ))
      setCategoriesOptions(categories);
    }
  }, [props.categories]);

  const handleChange = (event) => {
    setFilterData({
      ...filterData,
      search_key: event.target.value,
    });
    setClearItems(true)
  };

  const clearItem = () => {
    setClearItems(false)
    setFilterData({
      ...filterData,
      search_key: "",
    });
    props.dispatch(userProductsSearchStart({ user_unique_id: params.user_unique_id }));
  }


  return (
    <>
      <div className="new-outfyt-sub-contetn-product-sec">
        <Formik
          initialValues={{
            search_key: "",
          }}
          enableReinitialize={true}
        >
          <FORM>
            <div className="new-outfyt-shop-search-sec">
              <div className="new-outfyt-shop-search">
                <InputGroup className="mb-0">
                  <Form.Control
                    name="search_key"
                    placeholder={t("searches")}
                    value={filterData.search_key}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  <InputGroup.Text id="basic-addon2">
                    {clearItems ?
                      <Image
                        onClick={clearItem}
                        src={window.location.origin + "/images/closes.svg"}
                      />
                      :
                      <Button type="submit" variant="ghost">
                        <i className="fas fa-search"></i>
                      </Button>
                    }
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="outfyt-sort-cato-sec">
                <div className="sort-outfyt-sec">
                  <Select
                    defaultValue={selectedOption}
                    onChange={handleSortByInputChange}
                    options={options}
                    styles={customStyle}
                    placeholder={t("sort_by")}
                    className="select-texts text-uppercase"
                  />
                </div>
                <div className="cartog-outfyt-sec">
                  <Select
                    className="select-text text-uppercase"
                    options={categoriesOptions}
                    onChange={handleCategoryInputChange}
                    styles={customStyles}
                    placeholder={t("categories")}
                  />
                </div>
              </div>
            </div>
          </FORM>
        </Formik>
        {props.productSearch.loading ? (
          <CommonCenterLoader />
        ) : (
          <>
            {Object.keys(props.productSearch.data).length > 0 &&
              props.productSearch.data.user_products.length > 0
              ?
              <InfiniteScroll
                dataLength={props.productSearch.data.user_products.length}
                next={fetchMoreData}
                hasMore={
                  props.productSearch.data.user_products.length <
                  props.productSearch.data.total}
                loader={<LiveVideoLoader />}
              >
                <div className="new-outfyt-product-shop-box">
                  {props.productSearch.data.user_products.map(
                    (product, key) => (
                      <div className="new-outfyt-you-may-like-slider-card" key={key}>
                        <div className="new-outfyt-you-may-like-slider-img-sec">
                          <Link to={`/single-product/${product.unique_id}`}>
                            {product.file_type == "image" ?
                              <Image
                                src={product.picture}
                                className="new-outfyt-you-may-like-slider-img"
                              />
                              :
                              <video
                                autoplay
                                controls
                                id="myVideo"
                                className="profile-products w-100"
                              >
                                <source src={product.picture} type="video/mp4" />
                              </video>
                            }

                          </Link>
                        </div>
                        <div className="new-outfyt-you-may-like-slider-info-sec">
                          <h4>{product.name}</h4>
                          <h6>{product.category_name}</h6>
                          <p>${product.price}</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </InfiniteScroll>
              : <NoDataFound />}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  userProducts: state.userProducts.products,
  categories: state.userProducts.productCategories,
  productSearch: state.userProducts.productSearch,
  singleUser: state.users.singleUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NewUserProducts));
