import {
    ORDERS_LIST_START,
    ORDERS_LIST_SUCCESS,
    ORDERS_LIST_FAILURE,
    FETCH_ORDER_PAYMENT_LIST_START,
    FETCH_ORDER_PAYMENT_LIST_SUCCESS,
    FETCH_ORDER_PAYMENT_LIST_FAILURE,
    ORDERS_MORE_LIST_START,
    ORDERS_VIEW_FOR_OTHERS_START,
    ORDERS_VIEW_FOR_OTHERS_SUCCESS,
    ORDERS_VIEW_FOR_OTHERS_FAILURE,
    FETCH_MORE_ORDER_PAYMENT_LIST_START,
    CANCEL_ORDER_START,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILURE,
    CREATE_ORDER_BY_WALLET_START,
    CREATE_ORDER_BY_WALLET_SUCCESS,
    CREATE_ORDER_BY_WALLET_FAILURE,
    CREATE_ORDER_BY_STRIPE_START,
    CREATE_ORDER_BY_STRIPE_SUCCESS,
    CREATE_ORDER_BY_STRIPE_FAILURE,
    UPDATE_TRACKING_NUMBER_START,
    UPDATE_TRACKING_NUMBER_SUCCESS,
    UPDATE_TRACKING_NUMBER_FAILURE,
    ORDERS_CREATE_BY_WALLET_START,
    ORDERS_CREATE_BY_WALLET_SUCCESS,
    ORDERS_CREATE_BY_WALLET_FAILURE,
    ORDERS_CREATE_BY_STRIPE_START,
    ORDERS_CREATE_BY_STRIPE_SUCCESS,
    ORDERS_CREATE_BY_STRIPE_FAILURE,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_FAILURE,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_SUCCESS,
    CREATE_ORDER_BY_STRIPE_CHECKOUT_START,

} from "../actions/ActionConstant";

const initialState = {
    orderList: {
        data: {},
        loading: true,
        error: false,
    },
    orderPayments: {
        data: {},
        loading: true,
        error: false,
    },
    ordersViewForOthers: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: "",
        buttonDisable: false
    },
    cancelOrder: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    createOrderWallet: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    createOrderStripe: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    updateTrackingNumber: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    ordersCreateWallet: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    ordersCreateStripe: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
    createOrderCheckoutStripe: {
        data: {},
        loading: true,
        error: false,
        loadingButtonContent: null,
        buttonDisable: false,
    },
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_LIST_START:
            return {
                ...state,
                orderList: {
                    data: {
                        orders: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                },
            };
        case ORDERS_LIST_SUCCESS:
            return {
                ...state,
                orderList: {
                    data: {
                        orders: [...state.orderList.data.orders, ...action.data.orders],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                },
            };
        case ORDERS_LIST_FAILURE:
            return {
                ...state,
                orderList: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_ORDER_PAYMENT_LIST_START:
            return {
                ...state,
                orderPayments: {
                    data: {
                        orders: [],
                        total: 0,
                    },
                    loading: true,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                orderPayments: {
                    data: {
                        orders: [...state.orderPayments.data.orders, ...action.data.orders],
                        total: action.data.total
                    },
                    loading: false,
                    error: false,
                }
            };
        case FETCH_ORDER_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                orderPayments: {
                    data: {},
                    loading: true,
                    error: action.error,
                }
            };
        case FETCH_MORE_ORDER_PAYMENT_LIST_START:
            return state;

        case ORDERS_MORE_LIST_START:
            return state;

        case ORDERS_VIEW_FOR_OTHERS_START:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Uploading....",
                    buttonDisable: true
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_SUCCESS:
            return {
                ...state,
                ordersViewForOthers: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case ORDERS_VIEW_FOR_OTHERS_FAILURE:
            return {
                ...state,
                ordersViewForOthers: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: "",
                    buttonDisable: false
                },
            };
        case CANCEL_ORDER_START:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                cancelOrder: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                cancelOrder: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
            case CREATE_ORDER_BY_WALLET_START:
            return {
                ...state,
                createOrderWallet: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CREATE_ORDER_BY_WALLET_SUCCESS:
            return {
                ...state,
                createOrderWallet: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_WALLET_FAILURE:
            return {
                ...state,
                createOrderWallet: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_STRIPE_START:
            return {
                ...state,
                createOrderStripe: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CREATE_ORDER_BY_STRIPE_SUCCESS:
            return {
                ...state,
                createOrderStripe: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_STRIPE_FAILURE:
            return {
                ...state,
                createOrderStripe: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        
        case UPDATE_TRACKING_NUMBER_START:
            return {
                ...state,
                updateTrackingNumber: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case UPDATE_TRACKING_NUMBER_SUCCESS:
            return {
                ...state,
                updateTrackingNumber: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case UPDATE_TRACKING_NUMBER_FAILURE:
            return {
                ...state,
                updateTrackingNumber: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
            case ORDERS_CREATE_BY_WALLET_START:
            return {
                ...state,
               ordersCreateWallet: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case ORDERS_CREATE_BY_WALLET_SUCCESS:
            return {
                ...state,
               ordersCreateWallet: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case ORDERS_CREATE_BY_WALLET_FAILURE:
            return {
                ...state,
               ordersCreateWallet: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case ORDERS_CREATE_BY_STRIPE_START:
            return {
                ...state,
                ordersCreateStripe: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case ORDERS_CREATE_BY_STRIPE_SUCCESS:
            return {
                ...state,
                ordersCreateStripe: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case ORDERS_CREATE_BY_STRIPE_FAILURE:
            return {
                ...state,
                ordersCreateStripe: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        
        case CREATE_ORDER_BY_STRIPE_CHECKOUT_START:
            return {
                ...state,
                createOrderCheckoutStripe: {
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "Loading... Please wait",
                    buttonDisable: true,
                }
            };
        case CREATE_ORDER_BY_STRIPE_CHECKOUT_SUCCESS:
            return {
                ...state,
                createOrderCheckoutStripe: {
                    data: action.data,
                    loading: false,
                    error: false,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        case CREATE_ORDER_BY_STRIPE_CHECKOUT_FAILURE:
            return {
                ...state,
                createOrderCheckoutStripe: {
                    data: {},
                    loading: true,
                    error: action.error,
                    loadingButtonContent: null,
                    buttonDisable: false,
                }
            };
        
        
        default:
            return state;
    }
};

export default OrderReducer;
